@charset "UTF-8";
/* 2018 Update */
/** M3 Icon colours **/
/* 2018 */
.internal__content {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
  padding-right: 40px;
}
@media screen and (max-width: 768px) {
  .internal__content {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .internal__content {
    padding-right: 0;
  }
}
.internal__content h1, .internal__content h2, .internal__content h3, .internal__content h4, .internal__content ul, .internal__content p {
  margin-bottom: 22px;
}
.internal__content h1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .internal__content h1 {
    font-size: 34px;
    line-height: 1.15;
  }
}
.internal__content h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.15;
  margin-top: 0;
  margin-top: 44px;
}
.internal__content h2:first-child {
  margin-top: 0;
}
.internal__content > p:first-of-type {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.4;
}
@media screen and (max-width: 768px) {
  .internal__content > p:first-of-type {
    font-size: 20px;
  }
}
.internal__content p a:not(.default-btn) {
  color: #005996;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  display: inline-block;
  font-weight: 300;
  border-bottom: 2px solid transparent;
  padding-bottom: 3px;
  line-height: 1;
}
.internal__content p a:not(.default-btn):active, .internal__content p a:not(.default-btn):visited, .internal__content p a:not(.default-btn):focus {
  color: #005996;
  text-decoration: none;
}
.internal__content p a:not(.default-btn):hover {
  text-decoration: none;
  color: #005996;
  border-bottom: 2px solid #005996;
}
.internal__content a:not(.default-btn) {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.internal__content a:not(.default-btn):active, .internal__content a:not(.default-btn):visited, .internal__content a:not(.default-btn):focus {
  text-decoration: none;
  color: inherit;
}
.internal__content a:not(.default-btn):hover {
  text-decoration: none;
  color: inherit;
}
.internal__content img {
  display: block;
  margin-top: 44px;
  margin-bottom: 22px;
  max-width: 100%;
  height: auto;
}
.internal__content__list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 30px;
  margin-bottom: 30px;
}
.internal__content__list li {
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
}
.internal__content__list li:before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #102542;
  z-index: 100;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 0;
}
.internal__aside {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
  padding-right: 25%;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .internal__aside {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .internal__aside {
    padding-right: 0;
  }
}
.internal__aside h2 {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 20px;
}
.internal__aside ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.internal__aside li {
  border-top: 1px solid #e2e2e2;
  padding: 15px 0;
}
.internal__aside li:first-child {
  border-top: none;
}
.internal__aside li a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  display: block;
}
.internal__aside li a:active, .internal__aside li a:visited, .internal__aside li a:focus {
  text-decoration: none;
  color: inherit;
}
.internal__aside li a:hover {
  text-decoration: none;
  color: inherit;
}
.internal__aside li a:hover {
  font-weight: 400;
  color: black;
}

.profile {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
}
@media screen and (max-width: 768px) {
  .profile {
    font-size: 16px;
  }
}
.profile__img {
  padding-right: 25%;
}
.profile__img img {
  width: 100%;
  height: auto;
  max-width: 100%;
  border-radius: 50%;
  margin-bottom: 30px;
}
@media screen and (max-width: 992px) {
  .profile__img img {
    max-width: 250px;
  }
}
.profile__title {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 30px;
  margin-bottom: 5px !important;
}
@media screen and (max-width: 768px) {
  .profile__title {
    font-size: 34px;
    line-height: 1.15;
  }
}

.inline-message--error {
  margin-bottom: 15px;
  font-size: 12px;
  background-position: center left 10px;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  padding: 15px 15px 15px 50px;
  overflow: hidden;
  display: block;
  text-align: left;
  color: #de2e2e;
  border: 1px solid #de2e2e;
  background-color: #fff;
  background-image: url(/content/images/admin/remove.svg);
}
.inline-message--warning {
  margin-bottom: 15px;
  font-size: 12px;
  background-position: center left 10px;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  padding: 15px 15px 15px 50px;
  overflow: hidden;
  display: block;
  text-align: left;
  color: #daa520;
  border: 1px solid #daa520;
  background-color: #fff;
  background-image: url(/content/images/admin/warning.svg);
}
.inline-message--success {
  margin-bottom: 15px;
  font-size: 12px;
  background-position: center left 10px;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  padding: 15px 15px 15px 50px;
  overflow: hidden;
  display: block;
  text-align: left;
  color: #8bc34a;
  border: 1px solid #8bc34a;
  background-color: #fff;
  background-image: url(/content/images/admin/check.svg);
}
.inline-message--lg {
  font-size: 1.2rem;
  padding-left: 60px;
  background-size: 50px;
}
.inline-message--lg a {
  color: inherit !important;
  font-weight: bold;
  border-bottom: 1.5px solid transparent;
  text-decoration: none;
}
.inline-message--lg a:hover, .inline-message--lg a:active, .inline-message--lg a:visited, .inline-message--lg a:link {
  text-decoration: none;
}
.inline-message--lg a:hover {
  border-color: inherit;
}

.flex-grid-auto {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
@media screen and (max-width: 992px) {
  .flex-grid-auto {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 520px) {
  .flex-grid-auto {
    display: block;
  }
}
.flex-grid-auto .col {
  flex: 1;
}
@media screen and (max-width: 992px) {
  .flex-grid-auto .col {
    flex-basis: 50%;
    flex-grow: 0;
  }
}
@media screen and (max-width: 768px) {
  .flex-grid-auto .col {
    flex-basis: 100%;
  }
}

.flex-grid-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -15px;
}
@media screen and (max-width: 520px) {
  .flex-grid-2 {
    display: block;
  }
}
.flex-grid-2 > .col {
  margin: 15px;
  flex: 0 1 calc((100% / 2) - (15px * 2));
  flex-basis: calc((100% / 2) - (15px * 2));
}
@media screen and (max-width: 992px) {
  .flex-grid-2 > .col {
    flex: 0 1 calc((100% / 2) - (15px * 2));
    flex-basis: calc((100% / 2) - (15px * 2));
  }
}
@media screen and (max-width: 768px) {
  .flex-grid-2 > .col {
    flex: 0 1 calc((100% / 1) - (15px * 2));
    flex-basis: calc((100% / 1) - (15px * 2));
  }
}
@media screen and (max-width: 520px) {
  .flex-grid-2 > .col {
    flex: 1;
  }
}

.flex-grid-3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -15px;
}
@media screen and (max-width: 520px) {
  .flex-grid-3 {
    display: block;
  }
}
.flex-grid-3 > .col {
  margin: 15px;
  flex: 0 1 calc((100% / 3) - (15px * 2));
  flex-basis: calc((100% / 3) - (15px * 2));
}
@media screen and (max-width: 992px) {
  .flex-grid-3 > .col {
    flex: 0 1 calc((100% / 2) - (15px * 2));
    flex-basis: calc((100% / 2) - (15px * 2));
  }
}
@media screen and (max-width: 768px) {
  .flex-grid-3 > .col {
    flex: 0 1 calc((100% / 1) - (15px * 2));
    flex-basis: calc((100% / 1) - (15px * 2));
  }
}
@media screen and (max-width: 520px) {
  .flex-grid-3 > .col {
    flex: 1;
  }
}

.flex-grid-4 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -15px;
}
@media screen and (max-width: 520px) {
  .flex-grid-4 {
    display: block;
  }
}
.flex-grid-4 > .col {
  margin: 15px;
  flex: 0 1 calc((100% / 4) - (15px * 2));
  flex-basis: calc((100% / 4) - (15px * 2));
}
@media screen and (max-width: 992px) {
  .flex-grid-4 > .col {
    flex: 0 1 calc((100% / 2) - (15px * 2));
    flex-basis: calc((100% / 2) - (15px * 2));
  }
}
@media screen and (max-width: 768px) {
  .flex-grid-4 > .col {
    flex: 0 1 calc((100% / 2) - (15px * 2));
    flex-basis: calc((100% / 2) - (15px * 2));
  }
}
@media screen and (max-width: 520px) {
  .flex-grid-4 > .col {
    flex: 1;
  }
}

.flex-grid-5 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -15px;
}
@media screen and (max-width: 520px) {
  .flex-grid-5 {
    display: block;
  }
}
.flex-grid-5 > .col {
  margin: 15px;
  flex: 0 1 calc((100% / 5) - (15px * 2));
  flex-basis: calc((100% / 5) - (15px * 2));
}
@media screen and (max-width: 992px) {
  .flex-grid-5 > .col {
    flex: 0 1 calc((100% / 3) - (15px * 2));
    flex-basis: calc((100% / 3) - (15px * 2));
  }
}
@media screen and (max-width: 768px) {
  .flex-grid-5 > .col {
    flex: 0 1 calc((100% / 2) - (15px * 2));
    flex-basis: calc((100% / 2) - (15px * 2));
  }
}
@media screen and (max-width: 520px) {
  .flex-grid-5 > .col {
    flex: 1;
  }
}

.flex-grid-6 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -15px;
}
@media screen and (max-width: 520px) {
  .flex-grid-6 {
    display: block;
  }
}
.flex-grid-6 > .col {
  margin: 15px;
  flex: 0 1 calc((100% / 6) - (15px * 2));
  flex-basis: calc((100% / 6) - (15px * 2));
}
@media screen and (max-width: 992px) {
  .flex-grid-6 > .col {
    flex: 0 1 calc((100% / 3) - (15px * 2));
    flex-basis: calc((100% / 3) - (15px * 2));
  }
}
@media screen and (max-width: 768px) {
  .flex-grid-6 > .col {
    flex: 0 1 calc((100% / 2) - (15px * 2));
    flex-basis: calc((100% / 2) - (15px * 2));
  }
}
@media screen and (max-width: 520px) {
  .flex-grid-6 > .col {
    flex: 1;
  }
}

.btn--submit {
  appearance: none !important;
  border: 0;
  border-radius: 2px;
  background-color: #005996;
  display: inline-block;
  text-align: center;
  transition: all 0.2s ease;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  outline: 0 !important;
  width: 100%;
  max-width: 100%;
  font-weight: 500;
  padding: 15px;
  position: relative;
  text-decoration: none;
}
.btn--submit:hover, .btn--submit:visited, .btn--submit:active, .btn--submit:link {
  color: white;
}
.btn--submit:not([disabled]):hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  text-decoration: none !important;
  background: #0068b0;
  color: white;
}
.btn--submit:disabled {
  background: #ddd !important;
  color: rgba(0, 0, 0, 0.2) !important;
  cursor: default;
}
.btn--submit:disabled img {
  opacity: 0.5;
}
.btn--submit img {
  max-width: 22px;
  transform: translateY(-3px);
  margin-right: 4px;
}

.btn--cart {
  appearance: none !important;
  border: 0;
  border-radius: 2px;
  background-color: #005996;
  display: inline-block;
  text-align: center;
  transition: all 0.2s ease;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  outline: 0 !important;
  width: 100%;
  max-width: 220px;
  font-weight: 500;
  padding: 15px;
  position: relative;
  text-decoration: none;
  padding: 10px 15px;
}
.btn--cart:hover, .btn--cart:visited, .btn--cart:active, .btn--cart:link {
  color: white;
}
.btn--cart:not([disabled]):hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  text-decoration: none !important;
  background: #0068b0;
  color: white;
}
.btn--cart:disabled {
  background: #ddd !important;
  color: rgba(0, 0, 0, 0.2) !important;
  cursor: default;
}
.btn--cart:disabled img {
  opacity: 0.5;
}
.btn--cart img {
  max-width: 22px;
  transform: translateY(-3px);
  margin-right: 4px;
}

.btn--light {
  appearance: none !important;
  border: 0;
  border-radius: 2px;
  background-color: #005996;
  display: inline-block;
  text-align: center;
  transition: all 0.2s ease;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  outline: 0 !important;
  width: 100%;
  max-width: 100%;
  font-weight: 500;
  padding: 15px;
  position: relative;
  text-decoration: none;
  background: white;
  border: 1px solid #102542;
}
.btn--light:hover, .btn--light:visited, .btn--light:active, .btn--light:link {
  color: white;
}
.btn--light:not([disabled]):hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  text-decoration: none !important;
  background: #0068b0;
  color: white;
}
.btn--light:disabled {
  background: #ddd !important;
  color: rgba(0, 0, 0, 0.2) !important;
  cursor: default;
}
.btn--light:disabled img {
  opacity: 0.5;
}
.btn--light img {
  max-width: 22px;
  transform: translateY(-3px);
  margin-right: 4px;
}

.btn--remove {
  font-weight: 500;
  font-size: 14px;
  color: #005996;
  padding: 0 6px;
  display: inline-block;
  opacity: 0.3;
  transition: opacity 0.18s ease-out;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-size: 18px;
  background-position: center;
  background-image: url("/content/images/icons/ico-remove-1.svg");
  background-repeat: no-repeat;
  color: transparent;
  width: 24px;
  height: 24px;
  transform: translateY(-3px);
}
.btn--remove:hover {
  opacity: 1;
}

.btn--remove-ico {
  font-weight: 500;
  font-size: 14px;
  color: #005996;
  padding: 0 6px;
  display: inline-block;
  opacity: 0.3;
  transition: opacity 0.18s ease-out;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-size: 18px;
  background-position: center;
  background-image: url("/content/images/icons/ico-remove-1.svg");
  background-repeat: no-repeat;
  color: transparent;
  width: 24px;
  height: 24px;
  transform: translateY(-3px);
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  text-indent: -9999px;
}
.btn--remove-ico:hover {
  opacity: 1;
}

.btn--link {
  color: #102542;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-left: 15px;
  margin-right: 15px;
  appearance: none;
  border: 0;
  outline: 0;
  position: relative;
}
.btn--link:hover, .btn--link:active, .btn--link:visited, .btn--link:link {
  color: #153157;
}
.btn--link:hover {
  text-decoration: underline;
}
.btn--link[hidden] {
  display: none;
}
.btn--link img {
  width: 18px;
  height: auto;
  display: inline-block;
  margin-right: 10px;
}

.btn--related {
  color: #005996;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-left: 15px;
  margin-right: 15px;
  appearance: none;
  border: 0;
  outline: 0;
  position: relative;
  background-image: url("/content/images/icons/ico-caret-down-blue.svg");
  background-position: left 5px center;
  background-repeat: no-repeat;
  background-size: 17px;
  background-color: transparent;
  padding-left: 28px;
  line-height: 1.4;
  margin-bottom: 15px;
  margin-left: 0;
}
.btn--related:hover, .btn--related:active, .btn--related:visited, .btn--related:link {
  color: #0068b0;
}
.btn--related:hover {
  text-decoration: underline;
}
.btn--related[hidden] {
  display: none;
}
.btn--related img {
  width: 18px;
  height: auto;
  display: inline-block;
  margin-right: 10px;
}
@media screen and (max-width: 992px) {
  .btn--related {
    margin: 15px 0;
  }
}

.btn--back {
  color: #102542;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-left: 15px;
  margin-right: 15px;
  appearance: none;
  border: 0;
  outline: 0;
  position: relative;
  background-image: url("/content/images/icons/ico-caret-left-thick.svg");
  background-position: left 5px center;
  background-repeat: no-repeat;
  background-size: 17px;
  padding-left: 28px;
  line-height: 1.4;
}
.btn--back:hover, .btn--back:active, .btn--back:visited, .btn--back:link {
  color: #153157;
}
.btn--back:hover {
  text-decoration: underline;
}
.btn--back[hidden] {
  display: none;
}
.btn--back img {
  width: 18px;
  height: auto;
  display: inline-block;
  margin-right: 10px;
}

.unlink--inline {
  color: #005996;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  display: inline-block;
  font-weight: 300;
  border-bottom: 2px solid transparent;
  padding-bottom: 3px;
  line-height: 1;
}
.unlink--inline:active, .unlink--inline:visited, .unlink--inline:focus {
  color: #005996;
  text-decoration: none;
}
.unlink--inline:hover {
  text-decoration: none;
  color: #005996;
  border-bottom: 2px solid #005996;
}

.link-ico {
  font-weight: 700;
  padding-bottom: 1px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  padding-left: 28px;
  border-bottom: 1px dotted #bbb;
}
.link-ico:active, .link-ico:visited, .link-ico:link {
  text-decoration: none;
  color: #004fb6;
  color: #3c4858;
}
.link-ico:hover {
  text-decoration: none;
  border-bottom: 1px solid #004fb6;
  color: #005ad0;
  color: black;
}
.link-ico--before:before {
  height: 24px;
  font-family: "Material Icons";
  left: 0px;
  top: -2px;
  margin-right: 5px;
  position: absolute;
  text-align: center;
  width: 24px;
  -webkit-font-feature-settings: "liga";
}
.link-ico--document:before {
  content: "receipt_long";
  content: "article";
  content: "copy_all";
  color: #004fb6;
}

.page-header {
  padding: 0;
  margin: 0;
}

.top-nav {
  background-color: #102542;
}
.top-nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.top-nav__item {
  margin-left: 20px;
  line-height: 1;
}
@media screen and (max-width: 540px) {
  .top-nav__item {
    flex: 1 1 auto;
    margin: 0;
  }
}
.top-nav__item:first-of-type {
  margin-left: 0;
}
.top-nav__item--search {
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .top-nav__item--search i {
    order: 1;
    margin-left: 5px;
  }
}
@media screen and (max-width: 540px) {
  .top-nav__item--search {
    margin-right: 0;
  }
}
.top-nav__item--user a {
  padding: 0 !important;
}
.top-nav__item--user a:hover span {
  color: white;
}
.top-nav__item--user span {
  display: inline-block;
  font-size: 14px;
  padding: 10px;
  color: #8490A1;
  line-height: 1.6;
}
@media screen and (max-width: 768px) {
  .top-nav__item--user {
    display: none;
  }
}
.top-nav a {
  color: #fff;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: 500;
  font-size: 14px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.top-nav a:active, .top-nav a:visited, .top-nav a:focus {
  text-decoration: none;
  color: #fff;
}
.top-nav a:hover {
  text-decoration: none;
  color: #fff;
}
.top-nav a i {
  margin-right: 5px;
  color: rgba(255, 255, 255, 0.2);
}
.top-nav a:hover {
  background: #1a3c6b;
  background: #005996;
}
@media screen and (max-width: 992px) {
  .top-nav a {
    font-weight: 400;
    font-size: 13px;
  }
}

.main-search {
  background: #f4f5f6;
  border-bottom: 1px solid #f4f5f6;
  transition: height 0.2s ease-out;
  position: absolute;
  top: 130px;
  z-index: 9999;
  width: 100%;
}

.main-search__container {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .main-search__container {
    padding: 30px 15px;
  }
}
.main-search__container #main-search__input {
  background: white;
  line-height: 30px;
  font-size: 16px;
  border-radius: 0;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  appearance: none;
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
  height: 50px;
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .main-search__container #main-search__input {
    padding: 5px;
    font-size: 16px;
    line-height: 15px;
  }
}
.main-search__container .main-search__button {
  width: 100%;
  height: 50px;
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  margin-top: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-search__container .main-search__button:active, .main-search__container .main-search__button:visited, .main-search__container .main-search__button:focus {
  text-decoration: none;
  color: inherit;
}
.main-search__container .main-search__button:hover {
  text-decoration: none;
  color: inherit;
}
.main-search__container .main-search__button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .main-search__container .main-search__button {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .main-search__container .main-search__button {
    width: 100%;
  }
}
.main-search__container .main-search__button:hover, .main-search__container .main-search__button:visited, .main-search__container .main-search__button:active {
  color: #fff;
}
.main-search__container .main-search__button:hover {
  background: #0068b0;
}
.main-search__container .main-search__button i {
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
  font-size: 30px;
  margin-top: 0px;
}
@media screen and (max-width: 768px) {
  .main-search__container .main-search__button i {
    font-size: 28px;
  }
}

.nav {
  position: relative;
  font-size: 15px;
}
@media screen and (max-width: 768px) {
  .nav {
    font-size: 14px;
  }
}
@media screen and (min-width: 992px) {
  .nav .container {
    display: flex;
    justify-content: space-between;
  }
}
.nav__brand {
  width: 190px;
  margin: 10px 0;
  display: inline-block;
  padding-bottom: 10px;
}
@media screen and (min-width: 992px) {
  .nav__brand {
    display: flex;
    margin: 0;
    padding: 0;
    order: 1;
    margin-left: auto;
    margin-right: auto;
  }
}
.nav__brand-link {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: bold;
  line-height: 1.1;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.nav__brand-link:active, .nav__brand-link:visited, .nav__brand-link:focus {
  text-decoration: none;
  color: inherit;
}
.nav__brand-link:hover {
  text-decoration: none;
  color: inherit;
}
.nav__brand-image {
  width: 64px;
  height: 64px;
  margin-right: 5px;
  min-width: 64px;
}
.nav__brand-tag {
  width: 126px;
}
.nav__menu {
  display: none;
  padding: 10px;
  font-size: 28px;
  right: 10px;
  font-weight: bold;
  float: right;
  cursor: pointer;
  margin-top: 15px;
}
@media screen and (max-width: 992px) {
  .nav__menu {
    display: inline-block;
  }
}
.nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  transition: opacity 0.2s ease-out;
}
@media screen and (max-width: 992px) {
  .nav__list {
    border-top: 1px solid #ececec;
    display: block;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}
.nav__list-item {
  margin-right: 28px;
  flex: 1 1 auto;
}
.nav__list-item:last-of-type {
  margin-right: 0;
}
@media screen and (max-width: 1200px) {
  .nav__list-item {
    margin: 0;
  }
}
.nav__list-link {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: 500;
  padding: 30px 10px 34px;
  display: block;
  position: relative;
}
.nav__list-link:active, .nav__list-link:visited, .nav__list-link:focus {
  text-decoration: none;
  color: inherit;
}
.nav__list-link:hover {
  text-decoration: none;
  color: inherit;
}
@media screen and (max-width: 992px) {
  .nav__list-link {
    padding: 15px 10px;
  }
}
.nav__list-link:hover {
  background: #f4f5f6;
}
.nav__list-link i {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-62%);
}
.nav__list-link.js-active i {
  transform: rotate(182deg) translateY(62%);
}
.nav__list-link.js-has-children {
  padding-right: 30px;
}
.nav__list--right {
  order: 2;
}
@media screen and (max-width: 992px) {
  .nav__list--right {
    order: 1;
  }
}
.nav__list--show {
  height: auto;
  opacity: 1;
  visibility: visible;
}
.nav__dropdown {
  z-index: 1001;
  transition: opacity 0.1s ease;
  border-bottom: 4px solid transparent;
}
@media screen and (min-width: 992px) {
  .nav__dropdown {
    border-top: 1px solid #eaeaea;
    border-bottom: 4px solid #102542;
    padding: 70px 0 50px;
    position: absolute;
    background: #fff;
    left: 0;
    right: 0;
  }
}
.nav__dropdown .container {
  display: block;
}
.nav__dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav__dropdown-list-item {
  /** inner list within the dropdown menu **/
}
@media screen and (min-width: 992px) {
  .nav__dropdown-list-item ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 40px;
  }
}
.nav__dropdown-list-label {
  font-weight: 500;
}
.nav__dropdown-link {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  transition: none;
  display: block;
  padding: 15px 0 15px;
}
.nav__dropdown-link:active, .nav__dropdown-link:visited, .nav__dropdown-link:focus {
  text-decoration: none;
  color: inherit;
}
.nav__dropdown-link:hover {
  text-decoration: none;
  color: inherit;
}
@media screen and (max-width: 992px) {
  .nav__dropdown-link {
    padding: 10px;
  }
}
.nav__dropdown-link:hover {
  background: #f4f5f6;
}
.nav__dropdown-logos {
  max-width: 56px;
  height: auto;
  display: inline-block;
  margin-right: 10px;
}
@media screen and (max-width: 1200px) {
  .nav__dropdown-logos {
    margin-right: 0;
  }
}
@media screen and (max-width: 992px) {
  .nav__dropdown-logos {
    display: none;
  }
}

.masonry {
  background: #005996;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 600px;
}
@media screen and (max-width: 1200px) {
  .masonry {
    height: 750px;
  }
}
@media screen and (max-width: 992px) {
  .masonry {
    display: block;
    height: auto;
  }
}
@media screen and (max-width: 768px) {
  .masonry {
    height: auto;
  }
}
.masonry__grid {
  display: flex;
  width: 66.6%;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
}
@media screen and (max-width: 992px) {
  .masonry__grid {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .masonry__grid {
    display: block;
    height: auto;
  }
}
.masonry__row {
  display: flex;
  height: 50%;
}
@media screen and (max-width: 768px) {
  .masonry__row {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .masonry__row .masonry__item {
    width: 100%;
    display: block;
  }
}
.masonry__item {
  color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 33.3%;
  flex: 1;
  align-items: stretch;
  /* Carousel column*/
  /* Products column */
}
@media screen and (max-width: 768px) {
  .masonry__item {
    padding: 40px 20px;
  }
}
.masonry__item-1 {
  background: #005996;
  height: 100%;
  justify-content: center;
  flex-basis: 33.3%;
}
@media screen and (max-width: 992px) {
  .masonry__item-1 {
    width: 100%;
  }
}
.masonry__item-2 {
  background-image: url("/content/images/home/layers.svg");
  background-color: #102542;
  background-repeat: no-repeat;
  background-size: 260px;
  background-position: bottom right;
}
@media screen and (max-width: 992px) {
  .masonry__item-2 {
    background-size: 200px;
    background-position: bottom right;
  }
}
.masonry__item-3 {
  background: #dedede;
  background-image: url("/content/images/home/support.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  position: relative;
}
.masonry__item-3:after {
  content: "";
  display: block;
  background: #020024;
  background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(23, 43, 70, 0.4262079832) 0%, #020024 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.masonry__item-3 p {
  background-color: rgba(0, 0, 0, 0.1);
}
.masonry__item-3 h1 {
  background-color: rgba(0, 0, 0, 0.05);
}
.masonry__item-4 {
  background: #dedede;
  background-image: url("/content/images/home/consultancy.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.masonry__item-4:after {
  content: "";
  display: block;
  background: #020024;
  background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(23, 43, 70, 0.4262079832) 0%, #020024 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.masonry__item-4 p {
  background-color: rgba(0, 0, 0, 0.1);
}
.masonry__item-4 h2 {
  background-color: rgba(0, 0, 0, 0.05);
}
.masonry__item-5 {
  background-color: #0397D7;
  background-color: #0b3875;
  background-image: url("/content/images/home/training-white.svg");
  background-position: right -50px bottom 20px;
  background-repeat: no-repeat;
  background-size: 180px;
}
.masonry__item-6 {
  background: #005996;
  background-image: url("/content/images/home/book.svg");
  background-position: right -50px bottom 20px;
  background-repeat: no-repeat;
  background-size: 180px;
}
.masonry__item__link {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  z-index: 1000;
  position: relative;
}
.masonry__item__link:active, .masonry__item__link:visited, .masonry__item__link:focus {
  text-decoration: none;
  color: inherit;
}
.masonry__item__link:hover {
  text-decoration: none;
  color: inherit;
}
.masonry__item-2 {
  flex: 2 1 auto;
}
@media screen and (max-width: 992px) {
  .masonry__item-2 {
    flex: 1 1 auto;
  }
}
.masonry__item-3 {
  flex: 0 1 auto;
}
@media screen and (max-width: 992px) {
  .masonry__item-3 {
    flex: 1 1 auto;
  }
}
.masonry h1 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 35px;
  margin-top: 0;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .masonry h1 {
    font-size: 34px;
    margin-bottom: 30px;
  }
}
.masonry h2 {
  z-index: 1;
  font-weight: bold;
}
.masonry h4 {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 10px;
  font-size: 18px;
  z-index: 1;
  text-shadow: none;
}
.masonry p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
  margin-bottom: 15px;
  max-width: 86%;
  width: auto;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .masonry p {
    font-size: 16px;
  }
}
@media screen and (max-width: 1200px) {
  .masonry p {
    width: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 992px) {
  .masonry p {
    width: 100%;
    max-width: 92%;
  }
}
@media screen and (max-width: 768px) {
  .masonry p {
    width: 100%;
    max-width: 85%;
  }
}

.homepage .news__heading, .homepage .events__heading, .homepage .clients__heading {
  font-size: 28px;
  font-weight: 600;
  margin-top: 0;
  line-height: 1.15;
}
.homepage .clients__heading {
  margin-bottom: 30px;
}
.homepage .important-notice {
  text-align: center;
  padding: 30px;
  background: #f6e58d;
  color: #3C4858;
}
.homepage .important-notice p {
  margin-bottom: 0;
}
.homepage .important-notice a {
  display: inline-block;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  border-bottom: 2px solid;
}
.homepage .important-notice a:active, .homepage .important-notice a:visited, .homepage .important-notice a:focus {
  text-decoration: none;
  color: inherit;
}
.homepage .important-notice a:hover {
  text-decoration: none;
  color: inherit;
}
.homepage .cta__link {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  display: block;
  padding: 30px;
  background: #fb0;
  color: black;
  background: #5a2165;
  font-weight: 400;
  color: white;
  font-size: 24px;
  text-align: center;
  line-height: 1.4;
  transition: all 0.14s ease;
  border-top: 1px solid transparent;
}
.homepage .cta__link:before, .homepage .cta__link:after {
  content: "";
  display: table;
}
.homepage .cta__link:after {
  clear: both;
}
.homepage .cta__link:active, .homepage .cta__link:visited, .homepage .cta__link:focus {
  text-decoration: none;
  color: inherit;
}
.homepage .cta__link:hover {
  text-decoration: none;
  color: inherit;
}
@media screen and (max-width: 800px) {
  .homepage .cta__link {
    text-align: left;
  }
}
.homepage .cta__link span {
  color: white;
  padding-right: 130px;
  position: relative;
}
@media screen and (max-width: 800px) {
  .homepage .cta__link span {
    display: block;
    padding-right: 100px;
  }
}
.homepage .cta__link span:after {
  content: "";
  display: inline-block;
  background-image: url("/content/images/icons/arrow-right.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: all 0.2s ease;
}
.homepage .cta__link:hover {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #6b2778;
}
.homepage .cta__link:hover span:after {
  right: -15px;
}
.clients__heading {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 30px;
  text-align: center;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .clients__heading {
    font-size: 34px;
    line-height: 1.15;
  }
}
.clients__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.clients__list li {
  max-width: 240px;
  padding: 0px 20px;
}
.clients__list li img {
  max-width: 100%;
  max-height: 100px;
  height: auto;
  filter: grayscale(1);
  opacity: 0.7;
}
@media screen and (max-width: 768px) {
  .clients__list li {
    max-width: 50%;
  }
}

.newsletter__heading {
  font-size: 28px;
  font-weight: 600;
  margin-top: 0;
  line-height: 1.15;
  margin-bottom: 15px;
}
.newsletter__input {
  background: white;
  line-height: 30px;
  font-size: 16px;
  border-radius: 0;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  appearance: none;
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
  height: 55px;
}
@media screen and (max-width: 768px) {
  .newsletter__input {
    padding: 5px;
    font-size: 16px;
    line-height: 15px;
  }
}
.newsletter__button {
  margin: 0 !important;
  padding: 10px !important;
  height: 55px;
  width: 100%;
}
.newsletter .checkbox-wrapper {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .newsletter .checkbox-wrapper {
    margin-bottom: 15px;
  }
}

.section--news {
  background: #fff;
  border-top: 1px solid #f4f5f6;
}

.news__heading {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 30px;
  margin-bottom: 40px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .news__heading {
    font-size: 34px;
    line-height: 1.15;
  }
}
@media screen and (max-width: 768px) {
  .news__heading {
    margin-bottom: 25px;
    text-align: left;
  }
}
.news__heading a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.news__heading a:active, .news__heading a:visited, .news__heading a:focus {
  text-decoration: none;
  color: inherit;
}
.news__heading a:hover {
  text-decoration: none;
  color: inherit;
}
.news__item {
  background: #fff;
}
@media screen and (max-width: 992px) {
  .news__item {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .news__item {
    margin-bottom: 15px;
  }
}
.news__item:nth-child(1n) {
  background-color: #F6F9FC;
}
.news__item:nth-child(1n) a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.news__item:nth-child(1n) a:active, .news__item:nth-child(1n) a:visited, .news__item:nth-child(1n) a:focus {
  text-decoration: none;
  color: inherit;
}
.news__item:nth-child(1n) a:hover {
  text-decoration: none;
  color: #0397D7;
}
.news__item:nth-child(2n) {
  background-color: #102542;
  color: white;
}
.news__item:nth-child(2n) a {
  color: #fff;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.news__item:nth-child(2n) a:active, .news__item:nth-child(2n) a:visited, .news__item:nth-child(2n) a:focus {
  text-decoration: none;
  color: #fff;
}
.news__item:nth-child(2n) a:hover {
  text-decoration: none;
  color: #0397D7;
}
.news__item:nth-child(3n) {
  background: #0397D7;
  background: #0b3875;
  background: #005996;
  color: white;
}
.news__item:nth-child(3n) a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.news__item:nth-child(3n) a:active, .news__item:nth-child(3n) a:visited, .news__item:nth-child(3n) a:focus {
  text-decoration: none;
  color: inherit;
}
.news__item:nth-child(3n) a:hover {
  text-decoration: none;
  color: #102542;
}
.news__image {
  height: auto;
  max-width: 100%;
  width: 100%;
}
.news__details {
  padding: 30px;
  position: relative;
}
.news__details:before {
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url("/content/images/icons/twitter.svg");
  background-size: cover;
  z-index: 100;
  position: absolute;
  right: 20px;
}
.news__date, .news__handle {
  font-size: 14px;
  font-weight: 500;
  display: block;
}
.news__handle {
  font-weight: 600;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  display: inline-block;
}
.news__handle:active, .news__handle:visited, .news__handle:focus {
  text-decoration: none;
  color: inherit;
}
.news__handle:hover {
  text-decoration: none;
  color: inherit;
}
.news__description {
  margin-top: 30px;
}
.news__description a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: 600;
  word-break: break-word;
}
.news__description a:active, .news__description a:visited, .news__description a:focus {
  text-decoration: none;
  color: inherit;
}
.news__description a:hover {
  text-decoration: none;
  color: inherit;
}

.section--events {
  background: #fff;
}

.events__heading {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 30px;
  margin-bottom: 40px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .events__heading {
    font-size: 34px;
    line-height: 1.15;
  }
}
@media screen and (max-width: 768px) {
  .events__heading {
    margin-bottom: 25px;
    text-align: left;
  }
}
.events__heading a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.events__heading a:active, .events__heading a:visited, .events__heading a:focus {
  text-decoration: none;
  color: inherit;
}
.events__heading a:hover {
  text-decoration: none;
  color: inherit;
}
.events__link {
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: bold;
  text-align: center;
  display: block;
  margin-top: 40px;
}
.events__link:active, .events__link:visited, .events__link:focus {
  text-decoration: none;
  color: #102542;
}
.events__link:hover {
  text-decoration: none;
  color: #295fa9;
}
.events .event {
  position: relative;
  background: #fafafa;
  margin-bottom: 30px;
  box-shadow: 0px 30px 50px 0px rgba(0, 46, 91, 0.22);
}
@media screen and (max-width: 768px) {
  .events .event {
    margin-bottom: 15px;
  }
}
.events .event__link {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.events .event__link:active, .events .event__link:visited, .events .event__link:focus {
  text-decoration: none;
  color: inherit;
}
.events .event__link:hover {
  text-decoration: none;
  color: inherit;
}
.events .event__image-container {
  position: relative;
  min-height: 250px;
  background-size: cover;
  background-position: center;
}
.events .event__image-container:after {
  content: "";
  display: block;
  background: #020024;
  background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(23, 43, 70, 0.4262079832) 0%, #020024 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.events .event__image-container:after {
  background: rgba(0, 0, 0, 0.06);
}
.events .event__image {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 250px;
}
.events .event__icons {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.events .event__details {
  padding: 30px;
}
.events .event__details a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  border-bottom: 1.5px solid black;
}
.events .event__details a:active, .events .event__details a:visited, .events .event__details a:focus {
  text-decoration: none;
  color: inherit;
}
.events .event__details a:hover {
  text-decoration: none;
  color: inherit;
}
.events .event__details__footer {
  margin-bottom: 30px;
}
.events .event__date, .events .event__location {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  margin-right: 40px;
  margin-bottom: 10px;
}
.events .event__location {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.events .event__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.15;
  margin-top: 0;
  font-size: 20px;
  margin-bottom: 15px;
}
.events .event__button {
  margin-top: 40px;
}
.events .event__button button {
  appearance: none;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 2px solid #005996;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}
.events .event:before {
  content: "";
  display: none;
  position: absolute;
  padding: 8px 20px;
  font-size: 15px;
  z-index: 100;
  top: 0;
  left: 0;
  background: #172B46;
  color: white;
  font-weight: 400;
}
.events .event.none:before {
  display: none;
}
.events .event.announcement:before {
  content: "Announcement";
  background: #172B46;
  display: block;
}
.events .event.news:before {
  content: "News";
  background: #172B46;
  display: block;
}
.events .event.nhmf:before {
  content: "NHMF";
  background: #fb0;
  display: block;
}
.events .event.awards:before {
  content: "NHMF Awards";
  background: #fb0;
  display: block;
}
.events .event.committee:before {
  content: "NHMF Committee";
  background: #fb0;
  display: block;
}
.events .event.conference:before {
  content: "NHMF Conference";
  background: #fb0;
  display: block;
}
.events .event.studygroup:before {
  content: "NHMF Study Group";
  background: #fb0;
  display: block;
}
.events .event.usergroup:before {
  content: "User Group";
  background: #005996;
  display: block;
}
.events .event.training:before {
  content: "Training";
  background: #0397D7;
  display: block;
}
.events .event.generic:before {
  content: "Event";
  background: #0397D7;
  display: block;
}
.events .event.exhibition:before {
  content: "Exhibition";
  background: #0397D7;
  display: block;
}
.events .event.showcase:before {
  content: "Showcase";
  background: #0397D7;
  display: block;
}
.events .event.webinar:before {
  content: "Webinar";
  background: #0397D7;
  display: block;
}

.internal__content.reset {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.internal__content .event__type {
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-bottom: 10px;
  text-transform: Capitalize;
}
.internal__content .event__title {
  font-size: 46px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 0;
  line-height: 1;
}
.internal__content .event__description {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 15px;
}
.internal__content .event__footer {
  display: flex;
  justify-content: space-between;
  font-weight: normal;
}
.internal__content .event__footer__authors {
  margin-right: 30px;
}
.internal__content .event__footer__icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 5px;
}
.internal__content .event__image {
  padding: 0;
  max-height: 100%;
}
.internal__content .event__date {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
  display: block;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .internal__content .event__date {
    font-size: 16px;
  }
}
.internal__content .event__content {
  margin-bottom: 80px;
}
.internal__content .event__content p {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 15px;
}
.internal__content .event__content p:last-child {
  margin-bottom: 0;
}
.internal__content .event__content strong {
  font-weight: 700;
}
.internal__content .event__content ul {
  line-height: 1.5;
  margin-bottom: 50px;
  margin-top: 40px;
  padding-left: 0;
  list-style: none;
}
.internal__content .event__content ul li {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
  padding-left: 40px;
  position: relative;
}
.internal__content .event__content ul li:before {
  background: #0397D7;
  border-radius: 50%;
  content: "";
  display: block;
  height: 8px;
  left: 10px;
  position: absolute;
  top: 8px;
  width: 8px;
}

.eventDate {
  display: block;
  text-align: center;
  margin-bottom: 30px;
  margin-top: -15px;
}

#eventDateFilter {
  appearance: none;
  border: 0;
  padding: 5px 10px;
  color: black;
  background-image: url("/content/images/icons/arrow_down.svg");
  background-color: #f4f5f6;
  background-repeat: no-repeat;
  background-position: 96%;
  background-size: 20px;
  border-radius: 2px;
}

.section--teams {
  background: #fff;
}

.teams__heading {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 30px;
  margin-bottom: 40px !important;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .teams__heading {
    font-size: 34px;
    line-height: 1.15;
  }
}
@media screen and (max-width: 768px) {
  .teams__heading {
    margin-bottom: 25px;
    text-align: left;
  }
}
.teams__heading a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.teams__heading a:active, .teams__heading a:visited, .teams__heading a:focus {
  text-decoration: none;
  color: inherit;
}
.teams__heading a:hover {
  text-decoration: none;
  color: inherit;
}
.teams__link {
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: bold;
  text-align: center;
  display: block;
  margin-top: 40px;
}
.teams__link:active, .teams__link:visited, .teams__link:focus {
  text-decoration: none;
  color: #102542;
}
.teams__link:hover {
  text-decoration: none;
  color: #295fa9;
}
.teams .team {
  background: #fafafa;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .teams .team {
    margin-bottom: 15px;
  }
}
.teams .team__link {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.teams .team__link:active, .teams .team__link:visited, .teams .team__link:focus {
  text-decoration: none;
  color: inherit;
}
.teams .team__link:hover {
  text-decoration: none;
  color: inherit;
}
.teams .team__link:hover .team__image {
  filter: grayscale(0);
}
.teams .team__link:hover .team__details {
  background: #102542;
  color: white;
}
.teams .team__image-container {
  position: relative;
}
.teams .team__image-container:after {
  content: "";
  display: block;
  background: #020024;
  background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(23, 43, 70, 0.4262079832) 0%, #020024 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.teams .team__image-container:after {
  background: rgba(0, 0, 0, 0.01);
}
.teams .team__image {
  height: auto;
  max-width: 100%;
  width: 100%;
  filter: grayscale(0.2);
  transition: all 0.2s ease;
}
.teams .team__details {
  padding: 30px;
  transition: all 0.2s ease;
}
.teams .team__date {
  font-size: 14px;
  font-weight: 500;
}
.teams .team__title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.15;
  margin-top: 0;
  margin-bottom: 5px;
}
.teams .team__description {
  margin-bottom: 0;
}

.footer {
  background: #102542;
  color: #8490A1;
  font-size: 16px;
  font-weight: 300;
  padding-top: 70px;
  padding-bottom: 30px;
}
.footer:before, .footer:after {
  content: "";
  display: table;
}
.footer:after {
  clear: both;
}
@media screen and (max-width: 768px) {
  .footer {
    font-size: 14px;
  }
}
.footer__nav {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}
.footer__nav a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.footer__nav a:active, .footer__nav a:visited, .footer__nav a:focus {
  text-decoration: none;
  color: inherit;
}
.footer__nav a:hover {
  text-decoration: none;
  color: inherit;
}
.footer__heading {
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 0;
}
.footer p {
  margin-bottom: 20px;
}
.footer a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  transition: color 0.2s ease;
  display: inline-block;
}
.footer a:active, .footer a:visited, .footer a:focus {
  text-decoration: none;
  color: inherit;
}
.footer a:hover {
  text-decoration: none;
  color: inherit;
}
.footer a:hover {
  color: white;
}
@media screen and (max-width: 768px) {
  .footer__info {
    margin-bottom: 30px;
  }
}
.footer__address span {
  display: block;
  color: #8490A1;
  font-size: inherit;
}
.footer__address span.adr {
  margin-bottom: 15px;
}
.footer__social__icon {
  display: inline-block;
  margin-right: 10px;
}
.footer__social__icon span {
  display: none;
}
.footer__social__icon svg {
  max-width: 24px;
}
.footer__social__icon svg path {
  fill: #fff;
  transition: fill 0.2s ease;
}
.footer__social__icon svg.linkedin {
  transform: translateY(-2px);
}
.footer__social__icon:hover svg path {
  fill: #005996;
}
.footer__legal {
  padding: 30px 0 0;
  margin-top: 30px;
  text-align: center;
  border-top: 1px solid rgba(132, 144, 161, 0.25);
}
.footer__legal p {
  margin-bottom: 0;
}
.footer__legal a {
  margin: 0 10px;
}
.footer__cert {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.footer__cert:before, .footer__cert:after {
  content: "";
  display: table;
}
.footer__cert:after {
  clear: both;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .footer__cert {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 768px) {
  .footer__cert {
    justify-content: center;
  }
}
.footer__cert__item {
  width: 50%;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .footer__cert__item {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .footer__cert__item {
    max-width: 140px;
  }
}
.footer__cert__item span {
  text-align: center;
  display: block;
  font-size: 12px;
  line-height: 1.4;
}
.footer__cert__item a {
  display: block;
}
.footer__cert__img {
  width: 100%;
  max-width: 130px;
  max-height: 80px;
  height: auto;
  border: 1px solid #102542;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

/** TO UPDATE THE CAROUSEL STYLES  **/
#myCarousel {
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 992px) {
  #myCarousel {
    height: 480px;
  }
}
@media screen and (max-width: 520px) {
  #myCarousel {
    height: 540px;
  }
}

#quoteCarousel {
  min-height: 474px;
}

.carousel-fade .carousel-inner {
  height: 100%;
}
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.carousel-fade .carousel-inner .item a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.carousel-fade .carousel-inner .item a:active, .carousel-fade .carousel-inner .item a:visited, .carousel-fade .carousel-inner .item a:focus {
  text-decoration: none;
  color: inherit;
}
.carousel-fade .carousel-inner .item a:hover {
  text-decoration: none;
  color: inherit;
}
@media screen and (max-width: 992px) {
  .carousel-fade .carousel-inner .item a {
    padding: 0;
  }
}
.carousel-fade .carousel-inner .item a .carousel-image {
  order: 1;
  height: auto;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  background-position: center center;
}
@media screen and (max-width: 520px) {
  .carousel-fade .carousel-inner .item a .carousel-image {
    background-position: left center;
  }
}
.carousel-fade .carousel-inner .item a .carousel-pre-header {
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
  font-size: 18px;
  z-index: 1;
}
.carousel-fade .carousel-inner .item a .carousel-header {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 30px;
  color: white;
  line-height: 1.05;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .carousel-fade .carousel-inner .item a .carousel-header {
    font-size: 34px;
    line-height: 1.15;
  }
}
.carousel-fade .carousel-inner .item a .carousel-header + .carousel-image {
  height: 260px;
  margin: 0 30px;
}
.carousel-fade .carousel-inner .item a .carousel-text {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  .carousel-fade .carousel-inner .item a .carousel-text {
    font-size: 16px;
  }
}
.carousel-fade .carousel-inner .item a .carousel-pre-header,
.carousel-fade .carousel-inner .item a .carousel-header,
.carousel-fade .carousel-inner .item a .carouse-text,
.carousel-fade .carousel-inner .item a > p {
  margin-left: 30px;
  margin-right: 30px;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left, .carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-inner p {
  max-width: 100%;
}
.carousel-fade .carousel-inner .custom-template-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
}
@media screen and (min-width: 1280px) {
  .carousel-fade .carousel-inner .custom-template-content {
    padding: 60px;
  }
}
.carousel-fade .carousel-inner .custom-template-content h1 {
  font-size: 55px;
  line-height: 1;
  margin-bottom: 15px;
}
@media screen and (max-width: 1280px) {
  .carousel-fade .carousel-inner .custom-template-content h1 {
    font-size: 42px;
  }
}
.carousel-fade .carousel-inner .custom-template-content p {
  font-size: 22px;
  line-height: 1.2;
}
.carousel-fade .carousel-inner .custom-template-content img {
  max-width: 100%;
  height: auto;
  max-height: 200px;
}
.carousel-fade .carousel-inner .custom-template-content .heading--underline {
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-size: 40px;
}
.carousel-fade .carousel-inner .custom-template-content table {
  text-align: left;
  table-layout: fixed;
}
.carousel-fade .carousel-inner .custom-template-content table th {
  font-weight: bold;
}
.carousel-fade .carousel-inner .custom-template-content table th, .carousel-fade .carousel-inner .custom-template-content table td {
  padding: 10px 10px 10px 0;
  font-size: 16px;
  min-width: 130px;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
.carousel-fade .carousel-control.left, .carousel-fade .carousel-control.right {
  opacity: 0;
  width: 40px;
  height: 100%;
  position: absolute;
  margin: 0;
  top: 0;
}
.carousel-fade .carousel-control.left {
  left: -20px;
}
.carousel-fade .carousel-control.right {
  right: -20px;
}
.carousel-fade .carousel-indicators {
  width: auto;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  margin: 0;
}
.carousel-fade .carousel-indicators li {
  border: 2px solid rgba(255, 255, 255, 0.4);
  width: 14px;
  height: 14px;
  margin: 2px;
}
.carousel-fade .carousel-indicators li.active {
  background: rgba(255, 255, 255, 0.8);
}
.carousel-fade .carousel-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.carouselWrapper {
  overflow: hidden;
}

.hero .masonry__item-1 {
  padding: 0;
}

.carousel-block {
  padding: 80px 40px;
  background-color: #f6f6f9;
  background-image: url(/content/images/icons/ico-at.svg);
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: left -180px top 0;
  background-size: 550px;
  background-position: right -90px top 30px;
}
@media screen and (max-width: 768px) {
  .carousel-block {
    padding: 60px 20px;
    background-image: none;
  }
}
.carousel-block .carousel-inner {
  max-width: 1200px;
  margin: 0 auto;
}

.quote {
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 260px;
}
@media screen and (max-width: 768px) {
  .quote {
    max-width: 100%;
    padding-left: 35px;
  }
}
.quote blockquote {
  border-left: 0;
  padding: 0;
  font-size: 30px;
  line-height: 1.4;
  font-weight: lighter;
  margin-bottom: 40px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .quote blockquote {
    font-size: 28px;
  }
}
.quote blockquote:before {
  content: "“";
  display: block;
  position: absolute;
  top: -14px;
  left: -54px;
  font-size: 120px;
  font-weight: bold;
  line-height: 1;
  opacity: 0.2;
  transform: rotate(-5deg);
}
@media screen and (max-width: 768px) {
  .quote blockquote:before {
    left: -40px;
  }
}
.quote figcaption {
  font-weight: bold;
  font-size: 20px;
}
.quote figcaption small {
  font-weight: 300;
  font-size: 20px;
  color: inherit;
}
@media screen and (max-width: 768px) {
  .quote figcaption small {
    font-size: 18px;
  }
}

#myCarousel .advert {
  display: flex;
  height: 100%;
  flex-direction: column;
  color: black;
}
#myCarousel .advert h4 {
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 15px;
}
#myCarousel .advert__heading {
  font-size: 48px;
  margin-bottom: 15px;
}
#myCarousel .advert__subheading {
  font-size: 30px;
  margin-bottom: 50px;
}
#myCarousel .advert__subheading.sm {
  font-size: 26px;
}
#myCarousel .advert__subheading.sm-b {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
}
#myCarousel .advert__bullet {
  font-size: 28px;
  max-width: 90%;
  position: relative;
  padding-left: 48px;
}
#myCarousel .advert__bullet:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 32px;
  height: 32px;
  background: url("/content/images/icons/ico-check--gray.svg") center no-repeat;
  background-size: contain;
}
#myCarousel .advert__link {
  font-size: 24px;
  margin-top: auto;
  display: block;
  height: auto;
  z-index: 999;
}
#myCarousel .advert__link strong:after {
  content: "";
  display: inline-block;
  margin-left: 20px;
  background: url("/content/images/icons/ico-caret-right.svg") center right no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  transform: translateY(2px);
}
#myCarousel .advert-training {
  color: black;
  padding-right: 20px;
}
#myCarousel .advert-training img {
  position: absolute;
  bottom: 0;
  right: 0;
  max-height: 100%;
  width: 90%;
  height: auto;
  z-index: 0;
}
#myCarousel .advert-training .material-icons {
  color: #686868;
  font-weight: medium;
  transform: translateY(2px);
}
#myCarousel .advert-training .advert__title {
  font-size: 28px;
  margin-bottom: 30px;
  font-weight: bold;
}
#myCarousel .advert-training .advert__subheading {
  font-size: 26px;
  margin-bottom: 5px;
}

.carousel__wrapper {
  position: relative;
}

#productcarousel {
  display: flex;
  width: 100% !important;
  position: relative;
}
@media screen and (max-width: 520px) {
  #productcarousel {
    display: block;
  }
}
#productcarousel > div {
  padding: 20px 15px;
}
#productcarousel > div > a {
  display: block;
}

.carousel__controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.carousel__controls a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.carousel__controls a:active, .carousel__controls a:visited, .carousel__controls a:focus {
  text-decoration: none;
  color: inherit;
}
.carousel__controls a:hover {
  text-decoration: none;
  color: inherit;
}
.carousel__controls a i {
  font-size: 40px;
}
.carousel__controls a.prev {
  float: left;
  margin-left: -40px;
}
.carousel__controls a.next {
  float: right;
  margin-right: -40px;
}

.crm-renewal__table {
  width: 100%;
  margin-bottom: 30px;
}
.crm-renewal__table td, .crm-renewal__table th {
  padding: 15px 30px 15px 15px;
  border: 1px solid #f4f5f6;
}
.crm-renewal form {
  padding: 50px;
  background: #fafafa;
  border-radius: 5px;
  border: 1px solid #efefef;
}
@media screen and (max-width: 768px) {
  .crm-renewal form {
    padding: 30px 15px;
  }
}
.crm-renewal form input, .crm-renewal form select, .crm-renewal form textarea {
  border: 1px solid #efefef;
  background-color: #fff;
  color: black;
  display: inline-block;
  font-weight: 300;
  line-height: 1.5;
  padding: 12px 12px 10px;
  width: 100%;
  height: auto;
}
.crm-renewal form label {
  color: #3C4858;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  padding: 10px 0;
  margin: 0;
  width: 100%;
  vertical-align: top;
}
.crm-renewal form .select-wrapper {
  background: url(/content/images/icons/arrow_down.svg);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 20px;
  border: 1px solid #dedede;
  background-color: #fff;
  overflow: hidden;
  width: 100%;
}
.crm-renewal form .checkbox-wrapper label:before {
  background: #fff;
}
.crm-renewal form .checkbox-wrapper label {
  padding: 0 0 0 30px;
}
.crm-renewal form select {
  background: transparent;
  width: 100%;
  border: 0;
  margin: 0;
  appearance: none;
  padding: 12px 36px 10px 12px;
}
.crm-renewal form button, .crm-renewal form input[type=submit] {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  padding: 15px 20px;
  margin: 15px 0;
}
.crm-renewal form button:active, .crm-renewal form button:visited, .crm-renewal form button:focus, .crm-renewal form input[type=submit]:active, .crm-renewal form input[type=submit]:visited, .crm-renewal form input[type=submit]:focus {
  text-decoration: none;
  color: inherit;
}
.crm-renewal form button:hover, .crm-renewal form input[type=submit]:hover {
  text-decoration: none;
  color: inherit;
}
.crm-renewal form button:hover, .crm-renewal form input[type=submit]:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .crm-renewal form button, .crm-renewal form input[type=submit] {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .crm-renewal form button, .crm-renewal form input[type=submit] {
    width: 100%;
  }
}
.crm-renewal form button:hover, .crm-renewal form button:visited, .crm-renewal form button:active, .crm-renewal form input[type=submit]:hover, .crm-renewal form input[type=submit]:visited, .crm-renewal form input[type=submit]:active {
  color: #fff;
}
.crm-renewal form button:hover, .crm-renewal form input[type=submit]:hover {
  background: #0068b0;
}
.crm-renewal form .error {
  color: #de2e2e;
}
.crm-renewal form abbr {
  border: 0;
  color: rgba(0, 0, 0, 0.4);
  line-height: 1.1;
  text-decoration: none;
  vertical-align: center;
  margin-left: 5px;
}
.crm-renewal form .privacy-notice {
  padding: 0 0 45px;
  background: transparent;
}
.crm-renewal form ol, .crm-renewal form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.crm-renewal form ol li, .crm-renewal form ul li {
  margin-bottom: 15px;
}
.crm-renewal form legend {
  border-bottom: 1px solid #e5e5e5;
  margin: 15px 0;
  padding-bottom: 15px;
  padding-top: 15px;
}
.crm-renewal form ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.internal-feedback form {
  padding: 50px;
  background: #fafafa;
  border-radius: 5px;
  border: 1px solid #efefef;
}
@media screen and (max-width: 768px) {
  .internal-feedback form {
    padding: 30px 15px;
  }
}
.internal-feedback form input, .internal-feedback form select, .internal-feedback form textarea {
  border: 1px solid #efefef;
  background-color: #fff;
  color: black;
  display: inline-block;
  font-weight: 300;
  line-height: 1.5;
  padding: 12px 12px 10px;
  width: 100%;
  height: auto;
}
.internal-feedback form label {
  color: #3C4858;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  padding: 10px 0;
  margin: 0;
  width: 100%;
  vertical-align: top;
}
.internal-feedback form .select-wrapper {
  background: url(/content/images/icons/arrow_down.svg);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 20px;
  border: 1px solid #dedede;
  background-color: #fff;
  overflow: hidden;
  width: 100%;
}
.internal-feedback form .checkbox-wrapper label:before {
  background: #fff;
}
.internal-feedback form .checkbox-wrapper label {
  padding: 0 0 0 30px;
}
.internal-feedback form select {
  background: transparent;
  width: 100%;
  border: 0;
  margin: 0;
  appearance: none;
  padding: 12px 36px 10px 12px;
}
.internal-feedback form button, .internal-feedback form input[type=submit] {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  padding: 15px 20px;
  margin: 15px 0;
}
.internal-feedback form button:active, .internal-feedback form button:visited, .internal-feedback form button:focus, .internal-feedback form input[type=submit]:active, .internal-feedback form input[type=submit]:visited, .internal-feedback form input[type=submit]:focus {
  text-decoration: none;
  color: inherit;
}
.internal-feedback form button:hover, .internal-feedback form input[type=submit]:hover {
  text-decoration: none;
  color: inherit;
}
.internal-feedback form button:hover, .internal-feedback form input[type=submit]:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .internal-feedback form button, .internal-feedback form input[type=submit] {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .internal-feedback form button, .internal-feedback form input[type=submit] {
    width: 100%;
  }
}
.internal-feedback form button:hover, .internal-feedback form button:visited, .internal-feedback form button:active, .internal-feedback form input[type=submit]:hover, .internal-feedback form input[type=submit]:visited, .internal-feedback form input[type=submit]:active {
  color: #fff;
}
.internal-feedback form button:hover, .internal-feedback form input[type=submit]:hover {
  background: #0068b0;
}
.internal-feedback form .error {
  color: #de2e2e;
}
.internal-feedback form abbr {
  border: 0;
  color: rgba(0, 0, 0, 0.4);
  line-height: 1.1;
  text-decoration: none;
  vertical-align: center;
  margin-left: 5px;
}
.internal-feedback form .privacy-notice {
  padding: 0 0 45px;
  background: transparent;
}
.internal-feedback form ol, .internal-feedback form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.internal-feedback form ol li, .internal-feedback form ul li {
  margin-bottom: 15px;
}
.internal-feedback form legend {
  border-bottom: 1px solid #e5e5e5;
  margin: 15px 0;
  padding-bottom: 15px;
  padding-top: 15px;
}
.internal-feedback form ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
.internal-feedback form ol label {
  text-align: left;
}
.internal-feedback form .radio-inline {
  margin-left: 20px;
}
.internal-feedback form .radio-inline span {
  display: inline-block;
  padding-left: 5px;
}

.training__aside {
  margin-bottom: 30px;
}
.training__aside a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: 400;
  display: block;
  margin-bottom: 10px;
}
.training__aside a:active, .training__aside a:visited, .training__aside a:focus {
  text-decoration: none;
  color: inherit;
}
.training__aside a:hover {
  text-decoration: none;
  color: inherit;
}
.training__accordion__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.15;
  margin-top: 0;
  border-top: 1px solid #dedede;
  margin-bottom: 30px;
  margin-top: 50px;
  padding-top: 50px;
}
.training__accordion__title:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
}
.training__accordion h5 a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-size: 16px;
  font-weight: 400;
  display: block;
  position: relative;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.training__accordion h5 a:active, .training__accordion h5 a:visited, .training__accordion h5 a:focus {
  text-decoration: none;
  color: inherit;
}
.training__accordion h5 a:hover {
  text-decoration: none;
  color: inherit;
}
.training__accordion h5 a:hover {
  background: #f9f9f9;
}
.training__accordion h5 a:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 20px;
  top: 0;
  right: 15px;
  background-image: url("/content/images/icons/arrow_down.svg");
  background-repeat: no-repeat;
  background-position: center 5px;
}
.training__accordion .rulecontent h5 {
  margin: 0;
}
.training__accordion .rulecontent li {
  margin-bottom: 15px;
}
.training__accordion .rulecontent .faqanswer {
  padding: 30px;
  background: #f9f9f9;
  border-radius: 5px;
  margin-bottom: 25px;
}
.training__accordion .rulecontent .faqanswer p:last-child {
  margin-bottom: 0;
}
.training__description {
  margin-bottom: 30px;
}

.info.panel-body {
  background: #fff;
  padding: 30px;
}
.info.panel-body .training__button {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  margin: 30px auto 0;
  padding: 10px;
  display: inline-block;
  min-width: 300px;
  max-width: 100%;
}
.info.panel-body .training__button:active, .info.panel-body .training__button:visited, .info.panel-body .training__button:focus {
  text-decoration: none;
  color: inherit;
}
.info.panel-body .training__button:hover {
  text-decoration: none;
  color: inherit;
}
.info.panel-body .training__button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .info.panel-body .training__button {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .info.panel-body .training__button {
    width: 100%;
  }
}
.info.panel-body .training__button:hover, .info.panel-body .training__button:visited, .info.panel-body .training__button:active {
  color: #fff;
}
.info.panel-body .training__button:hover {
  background: #0068b0;
}

.training .section-header {
  background-image: url("/content/images/training/training-01.png");
  background-size: cover;
  background-position: top left;
}
.training .pageheader {
  background-image: url("/content/images/training/training-01.png");
  background-size: cover;
  background-position: top left;
}
.training .pageheader__content {
  max-width: 70%;
  margin-top: 120px;
  margin-bottom: 120px;
}
@media screen and (max-width: 786px) {
  .training .pageheader__content {
    max-width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
.training .pageheader__content h1, .training .pageheader__content p {
  color: white;
  margin-bottom: 15px;
}
.training .pageheader__content h1 {
  font-size: 38px;
}
.training .pageheader__content p {
  font-size: 24px;
  font-weight: 300;
}
.training__aside {
  padding: 30px;
  background: #f4f5f6;
}
.training__aside .btn {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  width: 100%;
}
.training__aside .btn:active, .training__aside .btn:visited, .training__aside .btn:focus {
  text-decoration: none;
  color: inherit;
}
.training__aside .btn:hover {
  text-decoration: none;
  color: inherit;
}
.training__aside .btn:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .training__aside .btn {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .training__aside .btn {
    width: 100%;
  }
}
.training__aside .btn:hover, .training__aside .btn:visited, .training__aside .btn:active {
  color: #fff;
}
.training__aside .btn:hover {
  background: #0068b0;
}
.training__aside .btn[disabled] {
  background: #ddd !important;
  color: rgba(0, 0, 0, 0.4) !important;
}
.training__aside .btn--invert {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #fff;
  color: #333;
  width: 100%;
  padding: 15px;
}
.training__aside .btn--invert:active, .training__aside .btn--invert:visited, .training__aside .btn--invert:focus {
  text-decoration: none;
  color: inherit;
}
.training__aside .btn--invert:hover {
  text-decoration: none;
  color: inherit;
}
.training__aside .btn--invert:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.training__aside__maplink {
  display: inline-block;
  margin: 8px 0;
  font-size: 14px;
}
.training__cpd {
  display: flex;
  align-items: center;
}
.training__cpd h4 {
  display: inline-block;
  margin-right: 40px;
}
.training__cpd img.training__cpd__img {
  max-height: 190px;
  display: inline-block;
  margin-top: 15px;
}
.training__cpd iframe {
  transform: translateY(15px);
}
.training__title {
  margin-bottom: 30px;
}
.training__title + p {
  font-size: 18px;
  margin-bottom: 30px;
}
.training__item {
  text-align: center;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: 600;
  display: block;
  padding: 15px;
  border-radius: 10px;
}
.training__item:active, .training__item:visited, .training__item:focus {
  text-decoration: none;
  color: inherit;
}
.training__item:hover {
  text-decoration: none;
  color: inherit;
}
.training__item img {
  width: 100%;
  max-width: 100%;
  max-height: 240px;
  margin-bottom: 15px;
}
.training__item:hover {
  background: #f4f5f6;
}

.pageheader {
  background-repeat: no-repeat;
  background-size: 1120px;
  background-position: right -60px top -280px;
  background-color: #0397D7;
}
.pageheader__panel {
  width: 50%;
  min-height: 350px;
}
.pageheader__title {
  color: white;
  float: right;
  width: 100%;
  max-width: 585px;
  padding: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  min-height: 350px;
}
.pageheader__title h1 {
  margin-bottom: 0;
  font-size: 60px;
}
.pageheader.training__header {
  background: url("/content/images/training/training-01.png");
}
.pageheader.training__header p {
  margin-top: -15px;
  max-width: 900px;
  margin-bottom: 35px;
  font-size: 22px;
  font-weight: 300;
  line-height: 1.4;
}

.form {
  background: #f4f5f6;
  padding: 30px;
}
.form.flex .form__group {
  display: flex;
  align-items: center;
  position: relative;
}
.form.flex .form__group > * {
  flex: 1;
}
.form__group label {
  font-weight: 400;
  font-size: 15px;
}
.form__group label small {
  display: block;
  font-weight: 300;
  padding-right: 10px;
}
.form__group .g-recaptcha {
  margin-bottom: 15px;
}
.form__field {
  padding: 0 0 15px 0;
}
.form__field__discount {
  display: flex;
}
.form__field__discount .btn--link {
  border-radius: 4px !important;
  margin: 4px !important;
}
.form__field__discount + span {
  font-size: smaller;
}
.form__field input:not([type=submit]), .form__field textarea {
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #dedede;
  color: #000;
  font-weight: 300;
  line-height: 1.5;
  padding: 10px;
  width: 100%;
}
.form__field textarea {
  resize: none;
}
.form__error {
  background: #de2e2e;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  margin-top: 2px;
  padding: 4px;
}
.form__error--block {
  display: block;
  width: 100%;
  margin-bottom: 8px;
  padding: 10px;
}
.form__radio {
  margin-bottom: 15px;
}
.form__radio:before, .form__radio:after {
  content: "";
  display: table;
}
.form__radio:after {
  clear: both;
}
.form__radio .radio-inline + .radio-inline {
  margin-left: 20px;
}
.form__checkbox {
  display: flex;
  margin-top: 10px;
  margin-bottom: 15px;
  position: relative;
}
.form__checkbox input[type=checkbox] {
  cursor: pointer;
  height: 20px;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 20px;
  z-index: 100;
}
.form__checkbox input[type=checkbox]:checked ~ label:after {
  display: block;
}
.form__checkbox label {
  font-size: 14px;
  font-weight: 300 !important;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 0;
  padding-left: 30px;
  padding-top: 0;
  width: 100% !important;
}
.form__checkbox label:before {
  content: "";
  border: 1px solid #cdcdcd;
  border-radius: 2px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s ease;
  width: 20px;
  background: #fff;
}
.form__checkbox label:after {
  content: "";
  border: solid #000;
  border-width: 0 3px 3px 0;
  display: none;
  height: 10px;
  left: 7px;
  position: absolute;
  top: 4px;
  transform: rotate(45deg);
  width: 5px;
}
.form__select {
  background: url("/content/images/icons/arrow_down.svg");
  background-color: #fff;
  background-position: 98%;
  background-repeat: no-repeat;
  background-size: 20px;
  border: 1px solid #dedede;
  overflow: hidden;
  margin-bottom: 15px;
}
.form__select select {
  appearance: none;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 10px 36px 10px 10px;
  width: 100%;
  font-size: 14px;
}
.form__select select::-ms-expand {
  display: none;
}
.form__confirmation h4 {
  margin-bottom: 30px;
}
.form__confirmation .form__field {
  padding: 0;
}
.form__information {
  margin-top: 40px;
}
.form a.form__btn {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.form a.form__btn:active, .form a.form__btn:visited, .form a.form__btn:focus {
  text-decoration: none;
  color: inherit;
}
.form a.form__btn:hover {
  text-decoration: none;
  color: inherit;
}
.form .form__btn--submit {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  width: 100%;
  max-width: 100%;
  font-weight: 500;
  padding: 15px;
}
.form .form__btn--submit:active, .form .form__btn--submit:visited, .form .form__btn--submit:focus {
  text-decoration: none;
  color: inherit;
}
.form .form__btn--submit:hover {
  text-decoration: none;
  color: inherit;
}
.form .form__btn--submit:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .form .form__btn--submit {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .form .form__btn--submit {
    width: 100%;
  }
}
.form .form__btn--submit:hover, .form .form__btn--submit:visited, .form .form__btn--submit:active {
  color: #fff;
}
.form .form__btn--submit:hover {
  background: #0068b0;
}
.form .form__btn--submit:disabled {
  background: #ddd !important;
  color: rgba(0, 0, 0, 0.2) !important;
}
.form .form__btn--submit:disabled:hover {
  box-shadow: none;
}
.form .btn--edit, .form .btn--remove {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  border: 0;
  border-radius: 3px;
  padding: 4px;
  font-weight: 400;
  font-size: 13px;
  padding: 4px 12px;
}
.form .btn--edit:active, .form .btn--edit:visited, .form .btn--edit:focus, .form .btn--remove:active, .form .btn--remove:visited, .form .btn--remove:focus {
  text-decoration: none;
  color: inherit;
}
.form .btn--edit:hover, .form .btn--remove:hover {
  text-decoration: none;
  color: inherit;
}
.form .btn--edit:hover, .form .btn--remove:hover {
  background: #eaeaea;
}
.form .btn--add {
  appearance: none;
  color: #44bd32;
  background: none;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 0px;
  transition: all 0.15 ease;
  border-bottom: 2px solid transparent;
}
.form .btn--add:hover {
  color: #4cd137;
}
.form .btn--cancel {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #fff;
  color: #333;
}
.form .btn--cancel:active, .form .btn--cancel:visited, .form .btn--cancel:focus {
  text-decoration: none;
  color: inherit;
}
.form .btn--cancel:hover {
  text-decoration: none;
  color: inherit;
}
.form .btn--cancel:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.form .btn--update {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  color: white;
}
.form .btn--update:active, .form .btn--update:visited, .form .btn--update:focus {
  text-decoration: none;
  color: inherit;
}
.form .btn--update:hover {
  text-decoration: none;
  color: inherit;
}
.form .btn--update:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.form .btn--update:hover {
  background: #0068b0 !important;
  color: white !important;
}
.form .btn--link {
  appearance: none;
  border: 1px solid #dedede;
  color: black;
  border-radius: 30px;
  padding: 8px 16px;
  background: white;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  transition: all 0.15s ease;
}
.form .btn--link:hover {
  background: #eaeaea;
}

.attendee__list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0;
}
.attendee__item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 4px 8px;
  background: #fff;
  font-size: 14px;
}
.attendee__item p {
  flex: 1;
  margin: 0;
}
.attendee__form__actions {
  display: flex;
  justify-content: flex-end;
}
.attendee__form__actions button {
  margin-left: 5px;
}
.attendee__edit {
  background: #ededed;
  padding: 30px 30px 30px 50px;
  border-radius: 5px;
}

.cart {
  margin-bottom: 30px;
  margin-top: 30px;
  font-weight: 400;
}
.cart dl {
  background: white;
  display: flex;
  margin: 0;
}
.cart dt, .cart dd {
  display: inline-block;
  border: 1px solid #f4f5f6;
  margin: 0;
  padding: 8px;
  font-size: 14px;
}
.cart dt {
  flex: 1;
  font-weight: 500;
}
.cart dd {
  flex: 2;
}
.cart__total {
  font-weight: bold;
}
.cart__quantity {
  font-weight: 500;
  font-size: 12px;
  color: #bfc4c9;
}

#purchasebooks,
#purchasebooksqty {
  display: none;
}
#purchasebooks.displayflex,
#purchasebooksqty.displayflex {
  display: flex;
}
#purchasebooks label,
#purchasebooksqty label {
  padding-right: 10px;
}

/* JS RELATED CSS. DO NOT MODIFY */
.js-attendee-template {
  display: none;
}

.course {
  margin-bottom: 80px;
  position: relative;
}
.course h2 {
  margin-bottom: 15px;
}
.course > img {
  float: left;
  width: 120px;
  height: 120px;
  margin: 4px 25px 25px 0;
  background: #f4f5f6;
}
.course__desc {
  overflow: hidden;
}
.course__desc.remote:before {
  content: "Remote Training";
  display: block;
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 8px;
  border: 1px solid;
  font-weight: bold;
  font-size: 12px;
}
@media screen and (max-width: 768px) {
  .course__desc.remote:before {
    position: relative;
    margin-bottom: 15px;
    right: 0;
    left: 0;
  }
}
.course__table {
  width: 100%;
  text-align: left;
}
.course__table th, .course__table td {
  padding: 8px 12px;
  border: 1px solid #eaeaea;
}
.course__table td {
  background: #fff;
}
.course__table th {
  background: #f4f5f6;
}
.course__table small {
  display: block;
}

.payment-option {
  border: 2px solid #102542;
  padding: 20px;
  margin-bottom: 15px;
}
.payment-option + .form__field {
  margin-top: 30px;
}
.payment-option .checkbox-container {
  margin-bottom: 5px;
}
.payment-option input[type=radio] {
  border: 1px solid #efefef;
  display: inline-block;
  margin-right: 10px;
  margin-top: 3px;
  padding: 3px;
  vertical-align: top;
  width: 16px;
  height: 16px;
  float: none;
  padding: 6px 12px;
  border: 1px solid #efefef;
}

.training__grid {
  margin-bottom: 0;
}
.training__grid .col {
  border: 1px solid #f4f5f6;
}
.training__grid .col a {
  display: block;
  min-height: 232px;
}

.training .course {
  padding-right: 30px;
}
.training .events {
  justify-content: flex-start;
}
.training .btn--enquire {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  width: 100%;
}
.training .btn--enquire:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.course__content {
  display: flex;
  margin-bottom: 50px;
  margin-top: 50px;
}
.course__content__img {
  flex: 0 0 80px;
  margin-right: 20px;
}
.course__content__img img {
  max-width: 100%;
}
.course__content__description {
  padding-top: 10px;
}
.course__content__description h2 {
  font-size: 22px;
}
.course__content__description p {
  font-size: 16px;
}
.course__content__description ol {
  margin-left: 0px;
  padding-left: 20px;
}
.course__content__description ol > li {
  margin-bottom: 10px;
}
.course__content__description ol > li > ul {
  padding-left: 24px;
}
.course__content__description ol, .course__content__description ul {
  margin-bottom: 30px;
}

.training__dates {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.training__dates h4 {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 20px;
}
.training__dates a {
  display: block;
  margin-bottom: 5px;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-size: 14px;
}
.training__dates a:active, .training__dates a:visited, .training__dates a:focus {
  text-decoration: none;
  color: inherit;
}
.training__dates a:hover {
  text-decoration: none;
  color: inherit;
}
.training__dates a i {
  float: right;
}

.section--error {
  background: #005996;
}

.page-error {
  color: #fff;
  max-height: 700px;
  overflow: hidden;
}
.page-error .c {
  text-align: center;
  display: block;
  position: relative;
  width: 80%;
  margin: 100px auto;
}
.page-error ._404 {
  font-size: 220px;
  position: relative;
  display: inline-block;
  z-index: 2;
  height: 250px;
  letter-spacing: 15px;
}
.page-error ._1 {
  text-align: center;
  display: block;
  position: relative;
  letter-spacing: 12px;
  font-size: 4em;
  line-height: 80%;
}
.page-error ._2 {
  text-align: center;
  display: block;
  position: relative;
  font-size: 20px;
}
.page-error .text {
  font-size: 70px;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 19px 0px 0px 0px;
  /* top: 256.301px; */
  z-index: 3;
  width: 100%;
  line-height: 1.2em;
  display: inline-block;
}
.page-error .btn {
  background-color: white;
  position: relative;
  display: inline-block;
  width: 358px;
  padding: 5px;
  z-index: 5;
  font-size: 25px;
  margin: 0 auto;
  color: #102542;
  text-decoration: none;
  margin-right: 10px;
}
.page-error .right {
  float: right;
  width: 60%;
}
.page-error hr {
  padding: 0;
  border: none;
  border-top: 5px solid #fff;
  color: #fff;
  text-align: center;
  margin: 0px auto;
  width: 420px;
  height: 10px;
  z-index: -10;
}
.page-error hr:after {
  content: "•";
  display: inline-block;
  position: relative;
  top: -0.75em;
  font-size: 2em;
  padding: 0 0.2em;
  background: #102542;
}
.page-error .cloud {
  width: 350px;
  height: 120px;
  background: #FFF;
  background: linear-gradient(to top, #FFF 100%);
  border-radius: 100px;
  position: absolute;
  margin: 120px auto 20px;
  z-index: -1;
  transition: ease 1s;
}
.page-error .cloud:after, .page-error .cloud:before {
  content: "";
  position: absolute;
  background: #FFF;
  z-index: -1;
}
.page-error .cloud:after {
  width: 100px;
  height: 100px;
  top: -50px;
  left: 50px;
  border-radius: 100px;
}
.page-error .cloud:before {
  width: 180px;
  height: 180px;
  top: -90px;
  right: 50px;
  border-radius: 200px;
}
.page-error .x1 {
  top: -50px;
  left: 100px;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
  opacity: 0.9;
  -webkit-animation: moveclouds 15s linear infinite;
  -moz-animation: moveclouds 15s linear infinite;
  -o-animation: moveclouds 15s linear infinite;
}
.page-error .x1_5 {
  top: -80px;
  left: 250px;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-animation: moveclouds 17s linear infinite;
  -moz-animation: moveclouds 17s linear infinite;
  -o-animation: moveclouds 17s linear infinite;
}
.page-error .x2 {
  left: 250px;
  top: 30px;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
  opacity: 0.6;
  -webkit-animation: moveclouds 25s linear infinite;
  -moz-animation: moveclouds 25s linear infinite;
  -o-animation: moveclouds 25s linear infinite;
}
.page-error .x3 {
  left: 250px;
  bottom: -70px;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
  opacity: 0.8;
  -webkit-animation: moveclouds 25s linear infinite;
  -moz-animation: moveclouds 25s linear infinite;
  -o-animation: moveclouds 25s linear infinite;
}
.page-error .x4 {
  left: 470px;
  botttom: 20px;
  -webkit-transform: scale(0.75);
  -moz-transform: scale(0.75);
  transform: scale(0.75);
  opacity: 0.75;
  -webkit-animation: moveclouds 18s linear infinite;
  -moz-animation: moveclouds 18s linear infinite;
  -o-animation: moveclouds 18s linear infinite;
}
.page-error .x5 {
  left: 200px;
  top: 300px;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0.8;
  -webkit-animation: moveclouds 20s linear infinite;
  -moz-animation: moveclouds 20s linear infinite;
  -o-animation: moveclouds 20s linear infinite;
}
@-webkit-keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }
  100% {
    margin-left: -1000px;
  }
}
@-moz-keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }
  100% {
    margin-left: -1000px;
  }
}
@-o-keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }
  100% {
    margin-left: -1000px;
  }
}
.page-error #clouds {
  position: relative;
  z-index: 1000;
}

.sage ol li {
  font-weight: bold;
}
.sage ul {
  margin: 30px 0;
}
.sage ul li {
  font-weight: normal;
}
.sage ul li ul {
  margin: 15px;
  list-style-type: circle;
}
.sage__message--error {
  border: 2px solid indianred;
  color: indianred;
  padding: 15px;
  font-weight: 600;
  text-align: left;
  position: relative;
}
.sage__message--error .glyphicon {
  position: relative;
  transform: translateY(5px);
  font-size: 24px;
  margin-right: 8px;
}

.admin {
  box-sizing: border-box;
  background: #f1f1f1;
  min-height: 100vh;
}
.admin *, .admin *:before, .admin *:after {
  box-sizing: inherit;
}
.admin__container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 100px;
}
.admin__container:before, .admin__container:after {
  content: "";
  clear: both;
  display: table;
}
@media screen and (max-width: 1200px) {
  .admin__container {
    padding: 0 30px;
  }
}
@media screen and (max-width: 768px) {
  .admin__container {
    padding: 0 15px;
  }
}
.admin__nav {
  border-top: 30px solid #102542;
  background: #fff;
  border-bottom: 1px solid #eaeaea;
}
.admin__body {
  padding-top: 40px;
  padding-bottom: 40px;
}
.admin__body h1 {
  font-size: 28px;
}
.admin__body .cards {
  display: flex;
  justify-content: space-between;
  max-height: 1100px;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .admin__body .cards {
    flex-flow: row wrap;
  }
}
@media screen and (max-width: 768px) {
  .admin__body .cards {
    display: block;
  }
}
.admin__body .card {
  background: #fff;
  flex: 0 1 calc(25% - 15px);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
}
@media screen and (max-width: 1200px) {
  .admin__body .card {
    flex: 0 1 calc(50% - 15px);
  }
}
@media screen and (max-width: 768px) {
  .admin__body .card {
    width: 100%;
  }
}
.admin__body .card__content {
  padding: 20px;
}
.admin__body .card__content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.admin__body .card__content > h4 {
  margin-left: 10px;
}
.admin__body .card__img {
  background: #ededed;
  min-height: 140px;
  padding-left: 30px;
}
.admin__body .card__img img {
  max-width: 140px;
  max-height: 100%;
  opacity: 0.4;
}
.admin__body .card h4 {
  margin-top: 0;
  font-size: 18px;
  margin-bottom: 15px;
}
.admin__body .card a {
  transition: all 0.18s ease;
  padding: 8px;
}
.admin__body .card a:hover {
  color: black;
  background: #f4f5f6;
}
.admin__body table {
  background: #fff;
  border-collapse: collapse;
  border: 0;
  width: 100%;
  max-width: 100%;
}
.admin__body table th {
  background: #f4f5f6;
  color: black;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
}
.admin__body table td, .admin__body table th {
  border: 1px solid #ccc;
  padding: 8px 14px;
  max-width: 280px;
  overflow: hidden;
  vertical-align: middle;
}
.admin__body table td a, .admin__body table th a {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.admin__body table .admin__edit__actions {
  width: 140px;
}
.admin__body table .admin__edit__actions a {
  display: inline-block;
}
.admin__body table .admin__edit__actions img {
  width: 48px;
  height: auto;
}
.admin__body table .admin__actions {
  white-space: initial !important;
  vertical-align: middle;
}
.admin__body table .admin__actions a {
  display: inline-block;
  font-weight: bold;
  padding: 4px 8px;
  background: #f4f5f6;
  border: 1px solid #ddd;
  color: #5B6DCD;
  font-size: 13px;
  margin: 4px;
}
.admin__body table .admin__actions a:link, .admin__body table .admin__actions a:active, .admin__body table .admin__actions a:hover, .admin__body table .admin__actions a:visited {
  color: #102542;
}
.admin__body table .admin__actions a:hover {
  color: white;
  background: #005996;
}
.admin__body form label {
  font-size: 14px;
  font-weight: 500;
}
.admin__body form input[type=text], .admin__body form input[type=email], .admin__body form input[type=number] {
  padding: 12px 12px 10px;
  line-height: 1.5;
  color: black;
  border: 1px solid #dedede;
  min-width: 340px;
  margin-bottom: 30px;
}
.admin__body form input[type=file] {
  background: #f4f5f6;
  padding: 12px 12px 10px;
  min-width: 340px;
  border: 1px dashed #c1c1c1;
  margin-bottom: 30px;
}
.admin__body form input[type=submit] {
  padding: 8px 50px;
  cursor: pointer;
}
.admin__body__content {
  background: #fff;
  padding: 60px 30px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
}
.admin__body__content--fluid {
  float: left;
}
.admin__body__content h1:first-child, .admin__body__content h2:first-child, .admin__body__content h3:first-child, .admin__body__content h4:first-child {
  margin-top: 0;
}
.admin__body__table {
  margin-bottom: 15px;
}
.admin__body__table th {
  text-align: left;
  background: #f4f5f6;
}
.admin__body__table input {
  min-width: auto !important;
  max-width: 100%;
  margin-bottom: 0 !important;
}
.admin__body__table .totals-row {
  font-weight: bold;
}
.admin__search {
  margin-bottom: 15px;
}
.admin a {
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  display: block;
}
.admin a:active, .admin a:visited, .admin a:focus {
  text-decoration: none;
  color: #102542;
}
.admin a:hover {
  text-decoration: none;
  color: inherit;
}
.admin__leftbar {
  width: 40px;
  background: #102542;
}
@media screen and (max-width: 1200px) {
  .admin__navlinks, .admin__user {
    display: none !important;
  }
}
.admin__logo {
  text-align: center;
}
.admin__logo img {
  padding: 10px;
  width: 180px;
  height: auto;
}
.admin__navlinks {
  display: flex;
}
.admin__navlinks a {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 0;
  padding-right: 30px;
  padding-left: 30px;
  position: relative;
  font-size: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #3C4858 !important;
}
.admin__navlinks a:first-child {
  background: #f4f5f6;
}
.admin__navlinks a:hover {
  background: #f4f5f6;
}
.admin__user {
  text-align: right;
}
.admin a.admin__btn {
  padding: 8px 16px 8px 30px;
  display: inline-block;
  position: relative;
  transition: background 0.18s ease;
  border: 1px solid transparent;
}
.admin a.admin__btn:hover {
  background: #f4f5f6;
  border: 1px solid white;
}
.admin a.admin__btn:before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.admin a.admin__btn.admin__btn--new:before {
  background-image: url("/content/images/admin/add.svg");
}
.admin a.admin__btn.admin__btn--back:before {
  background-image: url("/content/images/admin/back.svg");
}
.admin a.admin__btn.admin__btn--delete:before {
  background-image: url("/content/images/icons/noun-delete.svg");
}
.admin__alert--error {
  margin-bottom: 15px;
  font-size: 12px;
  background-position: center left 10px;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  padding: 15px 15px 15px 50px;
  overflow: hidden;
  display: block;
  text-align: left;
  color: #de2e2e;
  border: 1px solid #de2e2e;
  background-color: #fff;
  background-image: url(/content/images/admin/remove.svg);
}
.admin__alert--warning {
  margin-bottom: 15px;
  font-size: 12px;
  background-position: center left 10px;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  padding: 15px 15px 15px 50px;
  overflow: hidden;
  display: block;
  text-align: left;
  color: #daa520;
  border: 1px solid #daa520;
  background-color: #fff;
  background-image: url(/content/images/admin/warning.svg);
}
.admin__alert--success {
  margin-bottom: 15px;
  font-size: 12px;
  background-position: center left 10px;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  padding: 15px 15px 15px 50px;
  overflow: hidden;
  display: block;
  text-align: left;
  color: #8bc34a;
  border: 1px solid #8bc34a;
  background-color: #fff;
  background-image: url(/content/images/admin/check.svg);
}
.admin__alert--lg {
  font-size: 1.2rem;
  padding-left: 60px;
  background-size: 50px;
}
.admin__alert--lg a {
  color: inherit !important;
  font-weight: bold;
  border-bottom: 1.5px solid transparent;
  text-decoration: none;
}
.admin__alert--lg a:hover, .admin__alert--lg a:active, .admin__alert--lg a:visited, .admin__alert--lg a:link {
  text-decoration: none;
}
.admin__alert--lg a:hover {
  border-color: inherit;
}
.admin .hint {
  display: block;
  transform: translateY(-20px);
  max-width: 340px;
}

.flex-grid-3 {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .flex-grid-3 {
    display: block;
  }
}
.flex-grid-3 > * {
  flex: 1 1 33.33%;
}
@media screen and (max-width: 1368px) {
  .flex-grid-3 > * {
    flex: 1 1 25%;
  }
}

.order__info {
  padding-top: 15px;
  padding-bottom: 15px;
}
.order__info h4 {
  color: #102542;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 30px;
}
.order__info-links {
  margin-bottom: 30px;
}
.order__info-link {
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: 400;
}
.order__info-link:active, .order__info-link:visited, .order__info-link:focus {
  text-decoration: none;
  color: #102542;
}
.order__info-link:hover {
  text-decoration: none;
  color: inherit;
}
.order__info-link:hover {
  border-bottom: 1px solid black;
}
.order-confirmation__form {
  padding: 50px 30px;
  border-radius: 2px;
  border: 1px solid #dedede;
  margin-left: 40px;
}
@media screen and (max-width: 992px) {
  .order-confirmation__form {
    margin-left: 0;
  }
}
.order-confirmation__form input, .order-confirmation__form select, .order-confirmation__form textarea {
  border: 1px solid #dedede;
  background-color: #fcfcfc;
  color: #000;
  display: inline-block;
  font-weight: 300;
  line-height: 1.5;
  padding: 12px 12px 10px;
  width: 100%;
}
.order-confirmation__form input[type=submit] {
  appearance: none;
  background: #005996;
  border: 0;
  color: #fff;
  float: none;
  font-weight: 500;
  padding: 18px 40px;
  margin-top: 5px;
  width: 100%;
  transition: all 0.2s ease-out;
  text-align: center;
  margin-bottom: 5px;
}
.order-confirmation__form input[type=submit]:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.order-confirmation__form input[type=button] {
  margin-bottom: 5px;
}
.order-confirmation__form .btn-danger {
  font-weight: 400;
  color: white;
  font-size: 16px;
}
.order-confirmation__form .form-group {
  margin-bottom: 0;
}
.order-confirmation__form h3 {
  margin-top: 30px;
}
.order__confirmed {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 280px;
  background: #eaeaea;
  color: black;
  font-weight: 400;
  width: 100%;
}
.order__confirmed p {
  margin: 10px;
}

#SignatureModal .modal-content, #M3HRejectModal .modal-content {
  border-radius: 3px;
}
#SignatureModal .btn, #M3HRejectModal .btn {
  text-align: center;
  border-radius: 2px;
  margin-bottom: 10px;
}
#SignatureModal .btn:hover, #M3HRejectModal .btn:hover {
  background: #e2e2e2;
}
#SignatureModal .btn-submit, #M3HRejectModal .btn-submit {
  padding-left: 30px;
  padding-right: 30px;
  background: #005996;
  color: white;
}
#SignatureModal .btn-submit:hover, #M3HRejectModal .btn-submit:hover {
  background: #0077c9;
}
#SignatureModal textarea, #M3HRejectModal textarea {
  width: 100%;
}

.signature-display {
  position: relative;
}
.signature-display img {
  max-width: 100%;
  border: 1px solid #dedede;
}
.signature-display input.edit {
  font-size: 14px;
  font-weight: 500;
  color: #89929e;
  padding: 10px;
  margin: 0;
  border: 0;
  background: transparent;
  text-align: right;
  position: absolute;
  top: 0px;
  right: 0px;
  width: auto;
}
.signature-display input.edit:hover {
  color: black;
}

.admin__body__content .admin__search__input {
  width: 100%;
  max-width: 100%;
}

.admin__search__input {
  border: 1px solid #dadada;
  background: url("/content/images/admin/search-icon.svg") no-repeat;
  background-size: 24px;
  background-position: right 6px top 15px;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 15px;
  line-height: 1;
}

.admin__table--fixed {
  table-layout: fixed;
}
.admin__table--fixed td, .admin__table--fixed th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.admin__table__action:hover {
  background: transparent !important;
}
.admin__table__action td {
  border: 0;
  padding-bottom: 0;
}
.admin__table__page {
  font-weight: bold;
  font-size: 16px;
  color: #bababa;
  display: inline-block;
  padding: 0 10px;
}
.admin__table__page:hover, .admin__table__page:active, .admin__table__page:focus {
  color: #330F63;
  text-decoration: none;
}
.admin__table .cansort {
  position: relative;
}
.admin__table .cansort:after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 5px;
  background-image: url("/content/images/admin/sort-active.svg");
  background-size: 24px;
  background-position: center right 5px;
  background-repeat: no-repeat;
  transform: translateY(-50%);
  opacity: 0.14;
}
.admin__table .cansort:hover {
  cursor: pointer;
}
.admin__table .cansort:hover:after {
  opacity: 1;
}
.admin__table__sort-row:hover .admin__table__sort-ico {
  background-image: url("/content/images/admin/sort-active.svg");
}
.admin__table__sort-ico {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url("/content/images/admin/sort-inactive.svg") center no-repeat;
  background-size: contain;
}
.admin__table__icon--lighten {
  opacity: 0.2;
}
.admin__table__icon--lighten:hover {
  opacity: 1;
}

.icon {
  width: 48px;
  height: auto;
}

.flex-btn {
  display: inline-flex !important;
  align-items: center;
}
.flex-btn img {
  margin-right: 5px;
}

input[name=emailfeedbacklinkto] {
  margin-bottom: 0 !important;
}

.career__form textarea {
  width: 100%;
  max-width: 100%;
}
.career__form label {
  font-weight: normal;
}
.career__form h2 {
  margin-bottom: 30px;
  margin-top: 30px;
}
.career__form hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

.about__content .career > h1:first-child {
  margin-bottom: 5px;
}
.about__content .career h1, .about__content .career h3, .about__content .career h4 {
  color: #3c4858;
}
.about__content .career h2 {
  color: #698f48;
  font-size: 24px;
}
.about__content .career h3 {
  font-size: 22px;
}
.about__content .career ul, .about__content .career ol {
  margin: 30px 0;
  padding: 0 0 0 30px;
}
.about__content .career ul li, .about__content .career ol li {
  margin: 0 0 4px 0;
  padding: 0;
}
.about__content .career ul li ul, .about__content .career ul li ol, .about__content .career ol li ul, .about__content .career ol li ol {
  padding: 0 0 15px 0;
  margin: 15px 0 30px 30px;
}
.about__content .career ul .li-header, .about__content .career ol .li-header {
  list-style-type: none;
  padding-left: 10px;
  font-weight: bold;
  margin: 25px 0 15px -20px;
  padding: 0;
}

.upcoming-trainings__heading {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 30px;
  margin-bottom: 40px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .upcoming-trainings__heading {
    font-size: 34px;
    line-height: 1.15;
  }
}
@media screen and (max-width: 768px) {
  .upcoming-trainings__heading {
    margin-bottom: 25px;
    text-align: left;
  }
}
.upcoming-trainings__heading a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.upcoming-trainings__heading a:active, .upcoming-trainings__heading a:visited, .upcoming-trainings__heading a:focus {
  text-decoration: none;
  color: inherit;
}
.upcoming-trainings__heading a:hover {
  text-decoration: none;
  color: inherit;
}
.upcoming-trainings__link {
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: bold;
  text-align: center;
  display: block;
  margin-top: 40px;
}
.upcoming-trainings__link:active, .upcoming-trainings__link:visited, .upcoming-trainings__link:focus {
  text-decoration: none;
  color: #102542;
}
.upcoming-trainings__link:hover {
  text-decoration: none;
  color: #295fa9;
}
.upcoming-trainings .upcoming-training {
  position: relative;
  background: #fafafa;
  margin-bottom: 30px;
  box-shadow: 0px 30px 50px 0px rgba(0, 46, 91, 0.22);
}
@media screen and (max-width: 768px) {
  .upcoming-trainings .upcoming-training {
    margin-bottom: 15px;
  }
}
.upcoming-trainings .upcoming-training__link {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.upcoming-trainings .upcoming-training__link:active, .upcoming-trainings .upcoming-training__link:visited, .upcoming-trainings .upcoming-training__link:focus {
  text-decoration: none;
  color: inherit;
}
.upcoming-trainings .upcoming-training__link:hover {
  text-decoration: none;
  color: inherit;
}
.upcoming-trainings .upcoming-training__image-container {
  position: relative;
  min-height: 250px;
  background-size: cover;
  background-position: center;
}
.upcoming-trainings .upcoming-training__image-container:after {
  content: "";
  display: block;
  background: #020024;
  background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(23, 43, 70, 0.4262079832) 0%, #020024 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.upcoming-trainings .upcoming-training__image-container:after {
  background: rgba(0, 0, 0, 0.06);
}
.upcoming-trainings .upcoming-training__image {
  width: 20%;
  height: auto;
  max-width: 100%;
  max-height: 250px;
}
.upcoming-trainings .upcoming-training__icons {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.upcoming-trainings .upcoming-training__details {
  padding: 30px;
}
.upcoming-trainings .upcoming-training__details a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  border-bottom: 1.5px solid black;
}
.upcoming-trainings .upcoming-training__details a:active, .upcoming-trainings .upcoming-training__details a:visited, .upcoming-trainings .upcoming-training__details a:focus {
  text-decoration: none;
  color: inherit;
}
.upcoming-trainings .upcoming-training__details a:hover {
  text-decoration: none;
  color: inherit;
}
.upcoming-trainings .upcoming-training__details__footer {
  margin-bottom: 30px;
}
.upcoming-trainings .upcoming-training__date, .upcoming-trainings .upcoming-training__location {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  margin-right: 40px;
  margin-bottom: 10px;
}
.upcoming-trainings .upcoming-training__location {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.upcoming-trainings .upcoming-training__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.15;
  margin-top: 0;
  font-size: 20px;
  margin-bottom: 15px;
}
.upcoming-trainings .upcoming-training__button {
  margin-top: 40px;
}
.upcoming-trainings .upcoming-training__button button {
  appearance: none;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 2px solid #005996;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}
.upcoming-trainings .upcoming-training:before {
  content: "";
  display: none;
  position: absolute;
  padding: 8px 20px;
  font-size: 15px;
  z-index: 100;
  top: 0;
  left: 0;
  background: #172B46;
  color: white;
  font-weight: 400;
}
.upcoming-trainings .upcoming-training.none:before {
  display: none;
}
.upcoming-trainings .upcoming-training.announcement:before {
  content: "Announcement";
  background: #172B46;
  display: block;
}
.upcoming-trainings .upcoming-training.news:before {
  content: "News";
  background: #172B46;
  display: block;
}
.upcoming-trainings .upcoming-training.nhmf:before {
  content: "NHMF";
  background: #fb0;
  display: block;
}
.upcoming-trainings .upcoming-training.awards:before {
  content: "NHMF Awards";
  background: #fb0;
  display: block;
}
.upcoming-trainings .upcoming-training.committee:before {
  content: "NHMF Committee";
  background: #fb0;
  display: block;
}
.upcoming-trainings .upcoming-training.conference:before {
  content: "NHMF Conference";
  background: #fb0;
  display: block;
}
.upcoming-trainings .upcoming-training.usergroup:before {
  content: "User Group";
  background: #005996;
  display: block;
}
.upcoming-trainings .upcoming-training.training:before {
  content: "Training";
  background: #0397D7;
  display: block;
}
.upcoming-trainings .upcoming-training.upcoming-trainings:before {
  content: "Upcoming training";
  background: #0397D7;
  display: block;
}
.upcoming-trainings .upcoming-training.exhibition:before {
  content: "Exhibition";
  background: #0397D7;
  display: block;
}

.upcoming-training__header {
  padding: 15px 30px;
}
.upcoming-training__header h4 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  color: white;
}
.upcoming-training__header.HATC {
  background-color: orange;
}
.upcoming-training__header.M3Central {
  background-color: #e50043;
}
.upcoming-training__header.M3PamwinPlus {
  background-color: #56AF31;
}
.upcoming-training__header.M3PamwinLite {
  background-color: #BCBE00;
}
.upcoming-training__header.SOR {
  background-color: #0095D6;
}
.upcoming-training__header .M3LocatorPlus {
  background-color: #00A6AA;
}

.iframe__container {
  max-width: 100%;
  margin-bottom: 60px;
}
@media screen and (max-width: 500px) {
  .iframe__container {
    margin-bottom: 80px;
  }
}
.iframe__title {
  margin-bottom: 20px;
}
.iframe__content {
  width: 900px;
  height: 580px;
  max-width: 100%;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 500px) {
  .iframe__content {
    width: 480px;
    max-width: none;
    transform: translateX(-40px);
  }
}

#navbar-primary {
  background: transparent;
}

.navbar-default {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/** Tendersafe **/
.no-gutter > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.tendersafe-login,
.tendersafe-home,
.tendersafe-register,
.tendersafe-register-complete {
  border-top: 1px solid #ededed;
  margin-left: -15px;
  margin-right: -15px;
}

.tendersafe-login__button,
.tendersafe-register-activated__button,
.tendersafe-home__form__button {
  background: #577bb2;
  padding: 10px 30px;
  color: #fff;
  min-width: 140px;
  text-decoration: none;
  transition: background 0.2s ease;
  max-width: 320px;
  display: block;
}

.tendersafe-login__button:hover,
.tendersafe-login__button:focus,
.tendersafe-login__button:visited,
.tendersafe-register-activated__button:hover,
.tendersafe-register-activated__button:focus,
.tendersafe-register-activated__button:visited,
.tendersafe-home__form__button:hover,
.tendersafe-home__form__button:focus,
.tendersafe-home__form__button:visited {
  background: #1a63a1;
  color: white;
  text-decoration: none;
}

.tendersafe-login__login,
.tendersafe-login__register,
.tendersafe-register-activated,
.tendersafe-register-complete {
  padding: 60px 40px;
}

.tendersafe-login__login {
  background: #ededed;
}

.tendersafe-login__heading,
.tendersafe-register-activated__heading,
.tendersafe-register-complete__heading,
.tendersafe-home__heading {
  font-size: 21px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 0;
}

.tendersafe-login__text,
.tendersafe-register-activated__text,
.tendersafe-register-complete__text,
.tendersafe-home__text {
  margin-bottom: 20px;
}

.tendersafe-login__form legend {
  display: none;
}

.tendersafe-login__form .error,
.tendersafe-register__form .error {
  border: 1px solid #BF5A58;
  color: #BF5A58;
  padding: 10px;
  margin-bottom: 20px;
}

.tendersafe-register__form .error {
  display: table;
  width: 100%;
  max-width: 545px;
  margin-bottom: 10px;
}

.tendersafe-login__form .form-control .tendersafe-register__form .form-control {
  border-radius: 0;
}

.tendersafe-login__form .form-control:focus,
.tendersafe-register__form .form-control:focus,
.tendersafe-home__form input:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.6);
}

.tendersafe-login__form input,
.tendersafe-register__form input,
.tendersafe-home__form input {
  background: white;
}

.tendersafe-login__form input:-webkit-autofill,
.tendersafe-register__form input:-webkit-autofill,
.tendersafe-home__form input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0px 9999px white;
}

/******************************/
/** TENDERSAFE REGISTER PAGE **/
/******************************/
.tendersafe-register .form-group:before,
.tendersafe-register .form-group:after {
  content: "";
  display: table;
}

.tendersafe-register .form-group:after {
  clear: both;
}

.tendersafe-register__fieldset {
  padding: 60px 40px;
}

.tendersafe-register__fieldset:nth-child(odd) {
  background: #fafafa;
}

.tendersafe-register .form-control {
  border-radius: 0;
}

.tendersafe-register__fieldset legend {
  font-size: 19px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 0;
  float: left;
  margin-bottom: 30px;
}

.tendersafe-register__fieldset label {
  float: left;
  margin-right: 30px;
  width: 14em;
  max-width: 100%;
}

.tendersafe-register__fieldset input[type=text],
.tendersafe-register__fieldset input[type=password],
.tendersafe-register__fieldset input[type=checkbox] {
  max-width: 320px;
  float: left;
}

.tendersafe-register__fieldset--password p {
  font-style: italic;
}

.tendersafe-register__fieldset--password .glyphicon-asterisk,
.tendersafe-home__error .glyphicon-asterisk {
  margin-right: 8px;
  color: #BF5A58;
  font-size: 8px;
  top: -2px;
}

.tendersafe-register__fieldset--terms label {
  width: 320px;
  max-width: 100%;
}

.tendersafe-register__fieldset--terms input[type=checkbox] {
  max-width: 22px;
}

.tendersafe-register__action {
  padding: 60px 40px;
  background: #f4f5f6;
  text-align: center;
}

.tendersafe-register__action__cancel,
.tendersafe-register__action__submit {
  padding: 15px 50px;
  border: 1px solid #bbb;
  transition: background 0.2s ease;
}

.tendersafe-register__action__cancel {
  background: #fff;
}

.tendersafe-register__action__submit {
  background: #577bb2;
  color: white;
}

.tendersafe-register__action__submit:hover,
.tendersafe-register__action__submit:active,
.tendersafe-register__action__submit:focus {
  background: #1a63a1;
  color: white;
}

/*********************************/
/* TENDERSAFE REGISTER COMPLETE */
/********************************/
.tendersafe-register-complete > *,
.tendersafe-register-activated > * {
  max-width: 700px;
}

.tendersafe-register-complete__heading,
.tendersafe-register-activated__heading,
.tendersafe-home__heading {
  margin-bottom: 30px;
}

.tendersafe-home__content {
  max-width: 760px;
  padding: 60px 40px;
}

.tendersafe-home__form {
  padding-top: 10px;
}

.tendersafe-home__form__label {
  font-weight: bold;
}

.tendersafe-home__form__label {
  padding-top: 8px;
}

.tendersafe-home__form__input {
  padding: 7px 20px;
  outline: 0;
}

.tendersafe-home__form__button {
  display: inline;
  padding: 8px 20px;
  min-width: auto;
  transform: translateY(-1px);
}

.tendersafe-home__documents__table {
  background: #f4f5f6;
  padding: 60px 40px;
  max-width: 100%;
}

.tendersafe-home__documents__table table {
  width: 100%;
}

.tendersafe-home__documents__table th {
  padding-bottom: 10px;
}

.tendersafe-home__documents__table .demoRow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid #eaeaea;
}

.tendersafe-home__documents__table .demoRow td {
  padding: 15px 40px 15px 0;
}

.tendersafe-home__documents__table .demoRow .statusCell {
  padding: 15px 0px 15px 0;
  text-align: right;
  width: 140px;
  text-align: right;
}

.tendersafe-home__documents__table .link {
  background: #fff;
  color: #4B72AD;
  position: relative;
  border: 1px solid #4B72AD;
  padding: 3px 5px 2px;
  border-radius: 2px;
  transition: all 0.15s;
  cursor: pointer;
  bottom: 0;
  width: auto;
  height: auto;
}

.tendersafe-home__documents__table .link:hover {
  background: #4b72ad;
  color: white;
}

.tendersafe-home__error {
  color: #333;
  margin-top: 30px;
}

@media screen and (max-width: 628px) {
  .tendersafe-login__login,
.tendersafe-login__register,
.tendersafe-register-activated,
.tendersafe-register-complete,
.tendersafe-home {
    padding: 40px 20px;
  }

  .tendersafe-register__fieldset legend {
    margin-bottom: 20px;
  }

  .tendersafe-register__fieldset--terms label {
    max-width: 70%;
  }

  .tendersafe-register__fieldset--terms input[type=checkbox] {
    text-align: center;
  }

  .tendersafe-register__fieldset input[type=text],
.tendersafe-register__fieldset input[type=password] {
    max-width: 100%;
  }

  .tendersafe-register__action__cancel,
.tendersafe-register__action__submit {
    display: block;
    max-width: 100%;
    width: 100%;
    margin-bottom: 10px;
  }

  .tendersafe-register__fieldset {
    padding: 40px 20px;
  }

  .tendersafe-home__documents__table .demoRow td {
    display: block;
    width: 100%;
  }
}
.recaptcha-container span.error {
  color: #de2e2e;
  font-size: 13px;
  text-align: center;
  display: block;
  width: 100%;
}

.section__notice {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.section__notice--success {
  background: #8BC34A;
  color: white;
}
.section__notice span {
  margin: 0 4px;
}

.btn--download-file {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  color: white;
  padding: 15px 60px;
}
.btn--download-file:active, .btn--download-file:visited, .btn--download-file:focus {
  text-decoration: none;
  color: inherit;
}
.btn--download-file:hover {
  text-decoration: none;
  color: inherit;
}
.btn--download-file:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.btn--download-file:hover {
  background: #0068b0 !important;
  color: white !important;
}
.btn--download-file:hover, .btn--download-file:active, .btn--download-file:visited {
  color: white;
}

.tendersafe-instructions {
  margin-top: 30px;
  margin-bottom: 30px;
}
.tendersafe-instructions h3 {
  font-weight: 700;
  margin-bottom: 30px;
}

.publications {
  line-height: 1.6;
}

.publications .publication {
  padding: 70px 50px;
}

@media screen and (max-width: 768px) {
  .publications .publication {
    padding: 30px 0;
  }
}
.publications .publication:nth-child(even) {
  background: #f4f5f6;
}

.publications .publication p {
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .publications .publication__image {
    margin-bottom: 40px;
  }
}
.publications .publication__image a {
  display: block;
  width: 100%;
  height: auto;
  max-width: 100%;
}

.publications .publication__image img {
  border: 0;
  max-width: 100%;
}

.publications .publication__title {
  font-size: 28px;
  margin-bottom: 0;
}

.publications .publication__author {
  margin-bottom: 20px;
  color: #2F7BBD;
  font-weight: normal;
  font-size: 17px;
}

.publications .publication__info:first-of-type {
  font-size: 17px;
  line-height: 1.5;
}

.publications .publication__info-list {
  margin: 30px 15px;
}

@media screen and (max-width: 968px) {
  .publications .publication__info-list {
    margin: 20px;
  }
}
@media screen and (max-width: 768px) {
  .publications .publication__info-list {
    margin: 20px 0;
  }
}
.publications .publication__info-list li {
  margin-bottom: 10px;
}

.publications .publication__order {
  display: inline-block;
  padding: 10px 40px;
  font-size: 16px;
  font-weight: 400;
  background: #2F7BBD;
  color: white;
  margin: 15px 0 0 0;
  text-decoration: none;
  -webkit-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

.publications .publication__order:hover, .publications .publication__order:active, .publications .publication__order:focus, .publications .publication__order:visited {
  text-decoration: none;
}

.publications .publication__order:hover {
  background: #256094;
}

/*************************************************/
/* USERGROUP */
/*************************************************/
.usergroup #mainContent {
  padding-left: 0;
  padding-right: 0;
}

.usergroup .no-gutter div > [class*=col-] > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.usergroup__sub-title {
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #3c4858;
}

.usergroup__main p {
  margin-bottom: 15px;
  color: #3c4858;
  font-weight: 300;
}

.usergroup__main table {
  width: 100%;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .usergroup__main table {
    table-layout: fixed;
  }
}
.usergroup__main table th, .usergroup__main table td {
  padding: 20px 20px 20px 0;
  border-bottom: 1px solid #f1f1f1;
}

@media screen and (max-width: 768px) {
  .usergroup__main table th, .usergroup__main table td {
    padding: 10px;
  }
}
.usergroup__main table th:nth-child(1), .usergroup__main table td:nth-child(1) {
  min-width: 20%;
}

.usergroup__main table th:nth-child(2):not(.item), .usergroup__main table td:nth-child(2):not(.item) {
  width: 60%;
}

.usergroup__main table th:last-child:not(.item), .usergroup__main table td:last-child:not(.item) {
  min-width: 20%;
  padding-right: 0;
}

.usergroup__main table tr:last-child td:not(.item) {
  border-bottom: 0;
}

.usergroup__main .usergroup__agenda--streams {
  table-layout: auto;
}

.usergroup__main .usergroup__agenda--streams th, .usergroup__main .usergroup__agenda--streams td {
  min-width: 0;
  width: auto;
  border: 1px solid #eaeaea;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .usergroup__main .usergroup__agenda--streams th, .usergroup__main .usergroup__agenda--streams td {
    display: block;
    width: 100% !important;
  }
}
.usergroup__main .usergroup__agenda--streams th {
  text-align: center;
  vertical-align: bottom;
}

@media screen and (max-width: 768px) {
  .usergroup__main .usergroup__agenda--streams th {
    display: none;
  }
}
.usergroup__main .usergroup__agenda--streams th span {
  vertical-align: middle;
}

.usergroup__main .usergroup__agenda--streams .item--time {
  text-align: center;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.8);
  background: #ffffff;
}

@media screen and (max-width: 768px) {
  .usergroup__main .usergroup__agenda--streams .item--time {
    display: none;
  }
}
.usergroup__main .usergroup__agenda--streams span {
  display: block;
}

.usergroup__main .usergroup__agenda--streams .item {
  position: relative;
  background: #fcfcfc;
}

.usergroup__main .usergroup__agenda--streams .time {
  font-weight: bold;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}

.usergroup__main .usergroup__agenda--streams .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 45px;
}

.usergroup__main .usergroup__agenda--streams .speaker {
  margin-top: 30px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  position: absolute;
  bottom: 20px;
}

.usergroup__main .usergroup__agenda--streams .item-heading {
  color: white;
}

.usergroup__main .usergroup__agenda--streams .item-heading--dev {
  background: #4FB847;
  width: 31%;
}

.usergroup__main .usergroup__agenda--streams .item-heading--repair {
  background: #ED174F;
  width: 31%;
}

.usergroup__main .usergroup__agenda--streams .item-heading--sor {
  background: #0397D7;
  width: 31%;
}

.usergroup__main .usergroup__agenda--streams .item-heading--time {
  color: black;
}

.usergroup__main .usergroup__agenda--streams .item--dev, .usergroup__main .usergroup__agenda--streams .item--repair, .usergroup__main .usergroup__agenda--streams .item--sor {
  vertical-align: top;
}

.usergroup__main .usergroup__agenda--streams .item-heading--time,
.usergroup__main .usergroup__agenda--streams .item--time {
  background: #ffffff;
}

.usergroup__main .usergroup__agenda--streams .item--dev {
  background: #d4d9de;
  overflow: hidden;
  position: relative;
  z-index: 100;
}

.usergroup__main .usergroup__agenda--streams .item--dev:before {
  content: "";
  display: block;
  position: absolute;
  background: #4FB847;
}

.usergroup__main .usergroup__agenda--streams .item--dev .time {
  color: #4FB847;
}

.usergroup__main .usergroup__agenda--streams .item--repair {
  background: #e3e6e9;
  overflow: hidden;
  position: relative;
  z-index: 100;
}

.usergroup__main .usergroup__agenda--streams .item--repair:before {
  content: "";
  display: block;
  position: absolute;
  background: #ED174F;
}

.usergroup__main .usergroup__agenda--streams .item--repair .time {
  color: #ED174F;
}

.usergroup__main .usergroup__agenda--streams .item--sor {
  background: #f1f3f4;
  overflow: hidden;
  position: relative;
  z-index: 100;
}

.usergroup__main .usergroup__agenda--streams .item--sor:before {
  content: "";
  display: block;
  position: absolute;
  background: #0397D7;
}

.usergroup__main .usergroup__agenda--streams .item--sor .time {
  color: #0397D7;
}

.usergroup__main .usergroup__agenda--streams .item--sor-blank {
  background: #f1f3f4;
}

.usergroup__main .usergroup__agenda--streams .item--sor-blank:before {
  display: none;
}

.usergroup__main .usergroup__agenda--streams .item--break {
  overflow: hidden;
  position: relative;
  z-index: 100;
}

.usergroup__main .usergroup__agenda--streams .item--break:before {
  content: "";
  display: block;
  position: absolute;
  background: goldenrod;
}

.usergroup__main .usergroup__agenda--streams .item--break .time {
  color: orange;
}

.usergroup__main .usergroup__agenda--streams .item--full {
  overflow: hidden;
  position: relative;
  z-index: 100;
}

.usergroup__main .usergroup__agenda--streams .item--full:before {
  content: "";
  display: block;
  position: absolute;
  background: #4FB847;
}

.usergroup__main .usergroup__agenda--streams .item--full .time {
  color: #4FB847;
}

.usergroup__main .usergroup__agenda--streams .item--exec {
  overflow: hidden;
  position: relative;
  z-index: 100;
}

.usergroup__main .usergroup__agenda--streams .item--exec:before {
  content: "";
  display: block;
  position: absolute;
  background: #4FB847;
}

.usergroup__main .usergroup__agenda--streams .item--doubleheight {
  position: relative;
  overflow: visible;
}

@media screen and (max-width: 768px) {
  .usergroup__main .usergroup__agenda--streams .item--doubleheight {
    overflow: auto;
  }
}
.usergroup__main .usergroup__agenda--streams .item--doubleheight .item-content {
  position: relative;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

@media screen and (max-width: 768px) {
  .usergroup__main .usergroup__agenda--streams .item--doubleheight .item-content {
    -webkit-transform: none;
    transform: none;
  }
}
.usergroup__main .usergroup__agenda--streams .item--doubleheight .speaker {
  position: relative;
}

.usergroup__main h3, .usergroup__main h4 {
  margin: 30px 0 15px;
}

.usergroup__main ul {
  padding-left: 20px;
  margin-bottom: 40px;
  max-width: 700px;
}

.usergroup__main li {
  margin-bottom: 5px;
}

@media screen and (max-width: 1200px) {
  .usergroup__main iframe {
    max-width: 620px;
  }
}
@media screen and (max-width: 992px) {
  .usergroup__main iframe {
    max-width: 500px;
  }
}
.usergroup__main .aside__heading {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 15px;
}

.usergroup__nav-homepage .drinks {
  display: none;
}

.usergroup--drinks .usergroup__nav-homepage .default {
  display: none;
}

.usergroup--drinks .usergroup__nav-homepage .drinks {
  display: inline-block;
}

.usergroup--drinks .usergroup__nav-timetable {
  display: none;
}

.usergroup #mainContent p {
  margin-bottom: 15px;
}

.usergroup #mainContent em.notice {
  margin: 30px 0;
  font-weight: 500;
  padding: 20px;
  display: block;
  background: #f4f5f5;
}

.usergroup .page-title {
  display: none;
}

.usergroup__title {
  margin-bottom: 30px;
  margin-top: 30px;
}

.usergroup__agenda--table {
  width: 100%;
  max-width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: relative;
}

.usergroup__agenda--table .item-heading--time, .usergroup__agenda--table .item--time {
  width: 95px;
}

@media screen and (max-width: 768px) {
  .usergroup__agenda--table .item-heading--time, .usergroup__agenda--table .item--time {
    display: none;
  }
}
.usergroup__agenda--table .item-details {
  color: #aaa;
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 0px;
  font-weight: 500;
}

.usergroup__agenda--table .item-speaker, .usergroup__agenda--table .item-facilitator, .usergroup__agenda--table .item-description {
  font-size: 13px;
}
.usergroup__agenda--table .item-description {
  padding-right: 40px;
}
@media screen and (max-width: 768px) {
  .usergroup__agenda--table .item-description {
    padding-right: 0;
  }
}

.usergroup__agenda--table .item--full {
  background: #fcfcfc;
}

.usergroup__agenda--table .item--break {
  background: #ffffff;
}

.usergroup__agenda--table .item--stream-1 {
  background: #e3e6e961;
}

@media screen and (max-width: 768px) {
  .usergroup__agenda--table .item--stream-1 .item-details {
    color: #8BC34A;
  }
}
.usergroup__agenda--table .item--stream-2 {
  background: #fafafa;
}

@media screen and (max-width: 768px) {
  .usergroup__agenda--table .item--stream-2 .item-details {
    color: #277dce;
  }
}
.usergroup__agenda--table .item-heading--time, .usergroup__agenda--table .item-heading--stream-1, .usergroup__agenda--table .item-heading--stream-2, .usergroup__agenda--table .item-heading--stream-3 {
  padding: 15px;
}

.usergroup__agenda--table .item-heading--stream-1 {
  background: #8BC34A;
  color: white;
}

.usergroup__agenda--table .item-heading--stream-2 {
  background: #277dce;
  color: white;
}

.usergroup__event-info {
  padding: 30px 20px;
  background: #fcfcfc;
  font-weight: 300;
  font-size: 14px;
}

.usergroup__event-info dt {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 4px;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #dedede;
}

.usergroup__event-info dt:first-child {
  padding-top: 0;
  margin-top: 0;
  border-top: 0;
}

.usergroup-nav-pills {
  padding: 0;
  background: #f4f5f6;
  width: 100%;
  max-width: 100%;
  color: black;
  margin-bottom: 30px !important;
  max-width: 100% !important;
  padding: 10px 15px;
}

.usergroup-nav-pills > li {
  margin-bottom: 0;
}

.usergroup-nav-pills > li > a {
  color: rgba(0, 0, 0, 0.4);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.usergroup-nav-pills > li.active > a, .usergroup-nav-pills > li.active > a:hover, .usergroup-nav-pills > li.active > a:focus {
  background-color: white;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.14);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.usergroup .section-help {
  margin-left: 15px;
  margin-right: 15px;
}

.usergroup__price-table {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  padding-top: 30px;
  margin-top: 30px;
  padding-bottom: 15px;
  margin-bottom: 0px;
}

.usergroup__price, .usergroup__vat, .usergroup__total {
  font-weight: bold;
}

.usergroup__vat div:first-child {
  font-weight: normal;
}

.usergroup .booking-form {
  padding: 50px;
  background: #fafafa;
  border-radius: 5px;
  border: 1px solid #efefef;
  background: #f4f5f6;
  padding: 30px;
}
@media screen and (max-width: 768px) {
  .usergroup .booking-form {
    padding: 30px 15px;
  }
}
.usergroup .booking-form input, .usergroup .booking-form select, .usergroup .booking-form textarea {
  border: 1px solid #efefef;
  background-color: #fff;
  color: black;
  display: inline-block;
  font-weight: 300;
  line-height: 1.5;
  padding: 12px 12px 10px;
  width: 100%;
  height: auto;
}
.usergroup .booking-form label {
  color: #3C4858;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  padding: 10px 0;
  margin: 0;
  width: 100%;
  vertical-align: top;
}
.usergroup .booking-form .select-wrapper {
  background: url(/content/images/icons/arrow_down.svg);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 20px;
  border: 1px solid #dedede;
  background-color: #fff;
  overflow: hidden;
  width: 100%;
}
.usergroup .booking-form .checkbox-wrapper label:before {
  background: #fff;
}
.usergroup .booking-form .checkbox-wrapper label {
  padding: 0 0 0 30px;
}
.usergroup .booking-form select {
  background: transparent;
  width: 100%;
  border: 0;
  margin: 0;
  appearance: none;
  padding: 12px 36px 10px 12px;
}
.usergroup .booking-form button, .usergroup .booking-form input[type=submit] {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  padding: 15px 20px;
  margin: 15px 0;
}
.usergroup .booking-form button:active, .usergroup .booking-form button:visited, .usergroup .booking-form button:focus, .usergroup .booking-form input[type=submit]:active, .usergroup .booking-form input[type=submit]:visited, .usergroup .booking-form input[type=submit]:focus {
  text-decoration: none;
  color: inherit;
}
.usergroup .booking-form button:hover, .usergroup .booking-form input[type=submit]:hover {
  text-decoration: none;
  color: inherit;
}
.usergroup .booking-form button:hover, .usergroup .booking-form input[type=submit]:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .usergroup .booking-form button, .usergroup .booking-form input[type=submit] {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .usergroup .booking-form button, .usergroup .booking-form input[type=submit] {
    width: 100%;
  }
}
.usergroup .booking-form button:hover, .usergroup .booking-form button:visited, .usergroup .booking-form button:active, .usergroup .booking-form input[type=submit]:hover, .usergroup .booking-form input[type=submit]:visited, .usergroup .booking-form input[type=submit]:active {
  color: #fff;
}
.usergroup .booking-form button:hover, .usergroup .booking-form input[type=submit]:hover {
  background: #0068b0;
}
.usergroup .booking-form .error {
  color: #de2e2e;
}
.usergroup .booking-form abbr {
  border: 0;
  color: rgba(0, 0, 0, 0.4);
  line-height: 1.1;
  text-decoration: none;
  vertical-align: center;
  margin-left: 5px;
}
.usergroup .booking-form .privacy-notice {
  padding: 0 0 45px;
  background: transparent;
}
.usergroup .booking-form ol, .usergroup .booking-form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.usergroup .booking-form ol li, .usergroup .booking-form ul li {
  margin-bottom: 15px;
}
.usergroup .booking-form legend {
  border-bottom: 1px solid #e5e5e5;
  margin: 15px 0;
  padding-bottom: 15px;
  padding-top: 15px;
}
.usergroup .booking-form label {
  width: 50%;
}
@media screen and (max-width: 768px) {
  .usergroup .booking-form label {
    width: 100%;
  }
}
.usergroup .booking-form label.full {
  width: 100%;
}
.usergroup .booking-form input {
  width: 50%;
}
@media screen and (max-width: 768px) {
  .usergroup .booking-form input {
    width: 100%;
  }
}
.usergroup .booking-form .btn--submit, .usergroup .booking-form .btn--reset {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  margin-bottom: 10px;
  font-size: 15px;
  padding: 10px;
  width: 100%;
}
.usergroup .booking-form .btn--submit:active, .usergroup .booking-form .btn--submit:visited, .usergroup .booking-form .btn--submit:focus, .usergroup .booking-form .btn--reset:active, .usergroup .booking-form .btn--reset:visited, .usergroup .booking-form .btn--reset:focus {
  text-decoration: none;
  color: inherit;
}
.usergroup .booking-form .btn--submit:hover, .usergroup .booking-form .btn--reset:hover {
  text-decoration: none;
  color: inherit;
}
.usergroup .booking-form .btn--submit:hover, .usergroup .booking-form .btn--reset:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .usergroup .booking-form .btn--submit, .usergroup .booking-form .btn--reset {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .usergroup .booking-form .btn--submit, .usergroup .booking-form .btn--reset {
    width: 100%;
  }
}
.usergroup .booking-form .btn--submit:hover, .usergroup .booking-form .btn--submit:visited, .usergroup .booking-form .btn--submit:active, .usergroup .booking-form .btn--reset:hover, .usergroup .booking-form .btn--reset:visited, .usergroup .booking-form .btn--reset:active {
  color: #fff;
}
.usergroup .booking-form .btn--submit:hover, .usergroup .booking-form .btn--reset:hover {
  background: #0068b0;
}
.usergroup .booking-form .btn--reset {
  background-color: #e1e1e1;
  color: black;
}
.usergroup .booking-form li:before, .usergroup .booking-form li:after {
  content: "";
  display: table;
}
.usergroup .booking-form li:after {
  clear: both;
}
.usergroup .booking-form label, .usergroup .booking-form input {
  float: left;
}
@media screen and (max-width: 768px) {
  .usergroup .booking-form .radio-container label {
    width: 66.6%;
  }
}
.usergroup .booking-form .radio-container input[type=radio] {
  margin-top: 14px;
}
@media screen and (max-width: 768px) {
  .usergroup .booking-form .radio-container input[type=radio] {
    width: 33.3%;
  }
}
.usergroup .booking-form .checkbox-wrapper label:before {
  top: 2px;
}
.usergroup .booking-form .checkbox-wrapper label:after {
  top: 6px;
  border-color: green;
}
.usergroup .booking-form--product {
  padding: 30px;
  background: #f4f5f6;
}
.usergroup .booking-form--product:before, .usergroup .booking-form--product:after {
  content: "";
  display: table;
}
.usergroup .booking-form--product:after {
  clear: both;
}
.usergroup .booking-form--product label {
  color: #3c4858;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  padding: 10px 0;
  vertical-align: top;
}
.usergroup .booking-form--product label.required:after {
  content: "*";
  color: red;
}
.usergroup .booking-form--product input, .usergroup .booking-form--product select, .usergroup .booking-form--product textarea {
  background-color: #fff;
  border: 1px solid #efefef;
  color: #000;
  display: inline-block;
  font-weight: 300;
  height: auto;
  line-height: 1.5;
  padding: 12px 12px 10px;
  width: 100%;
}
.usergroup .booking-form--product textarea {
  min-height: 150px;
}
.usergroup .booking-form--product input[type=radio] {
  margin-left: 5px;
  background: transparent;
}
.usergroup .booking-form--product .btn--submit, .usergroup .booking-form--product .btn--reset {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  margin-bottom: 10px;
  font-size: 15px;
  padding: 15px;
}
.usergroup .booking-form--product .btn--submit:active, .usergroup .booking-form--product .btn--submit:visited, .usergroup .booking-form--product .btn--submit:focus, .usergroup .booking-form--product .btn--reset:active, .usergroup .booking-form--product .btn--reset:visited, .usergroup .booking-form--product .btn--reset:focus {
  text-decoration: none;
  color: inherit;
}
.usergroup .booking-form--product .btn--submit:hover, .usergroup .booking-form--product .btn--reset:hover {
  text-decoration: none;
  color: inherit;
}
.usergroup .booking-form--product .btn--submit:hover, .usergroup .booking-form--product .btn--reset:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .usergroup .booking-form--product .btn--submit, .usergroup .booking-form--product .btn--reset {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .usergroup .booking-form--product .btn--submit, .usergroup .booking-form--product .btn--reset {
    width: 100%;
  }
}
.usergroup .booking-form--product .btn--submit:hover, .usergroup .booking-form--product .btn--submit:visited, .usergroup .booking-form--product .btn--submit:active, .usergroup .booking-form--product .btn--reset:hover, .usergroup .booking-form--product .btn--reset:visited, .usergroup .booking-form--product .btn--reset:active {
  color: #fff;
}
.usergroup .booking-form--product .btn--submit:hover, .usergroup .booking-form--product .btn--reset:hover {
  background: #0068b0;
}
.usergroup .booking-form--product .btn--reset {
  background-color: #e1e1e1;
  color: black;
}
.usergroup .booking-form--product .message.error {
  padding: 15px;
  color: indianred;
}
.usergroup .booking-form--product .checkbox-wrapper {
  margin-top: 15px;
  margin-bottom: 30px;
}
.usergroup .booking-form--product .checkbox-wrapper label {
  padding: 0 0 0 30px;
}
.usergroup .booking-form--product .checkbox-wrapper label span {
  display: block;
  transform: translateY(-4px);
}
.usergroup .booking-form--product .checkbox-wrapper label:before {
  background: #fff;
}
.usergroup .booking-form--product fieldset {
  margin-bottom: 30px;
}
.usergroup .booking-form--product .addresslink {
  position: relative;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.usergroup .booking-form--product .addresslink:active, .usergroup .booking-form--product .addresslink:visited, .usergroup .booking-form--product .addresslink:focus {
  text-decoration: none;
  color: inherit;
}
.usergroup .booking-form--product .addresslink:hover {
  text-decoration: none;
  color: inherit;
}
.usergroup .booking-form--product .addresslink:before {
  content: "";
  width: 20px;
  height: 20px;
  background: url("/content/images/icons/arrow_down.svg") center no-repeat;
  background-size: 20px;
  display: inline-block;
  transform: translateY(4px);
  margin-right: 5px;
}
.usergroup .booking-form--product .select-wrapper {
  background-color: #fff;
  border: 0;
}
.usergroup .booking-form--product .select-wrapper select {
  background: none;
}
.usergroup .form__message {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}
.usergroup .form__message--error {
  color: white;
}
.usergroup .internal img {
  width: 100% !important;
  height: auto !important;
  max-width: 100%;
}
.usergroup #usergroup__day1__pamwin .item-heading--stream-1 {
  background: #8bc34a;
}
.usergroup #usergroup__day1__repairs .item-heading--stream-1 {
  background: #C60D47;
}
.usergroup #usergroup__day1__sor .item-heading--stream-1 {
  background: #277dce;
}
.usergroup__agenda--table .item {
  border: 4px solid white;
  padding: 20px;
}
@media screen and (max-width: 768px) {
  .usergroup__agenda--table .item {
    display: block;
  }
}
.usergroup__agenda--table .item-title, .usergroup__agenda--table .item-title p {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
}
.usergroup__agenda--table .sublist, .usergroup__agenda--table .subitem {
  margin: 0;
  padding: 0;
}
.usergroup__agenda--table .sublist {
  list-style-type: none;
  margin: 0;
  counter-reset: list;
}
.usergroup__agenda--table .sublist__item {
  padding: 10px 30px 10px 70px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 0;
}
.usergroup__agenda--table .sublist__item:last-child {
  border-bottom: 0;
}
.usergroup__agenda--table .sublist__item:before {
  counter-increment: list;
  content: counter(list, lower-roman) ". ";
  padding-right: 15px;
  font-size: 14px;
  position: absolute;
  top: 10px;
  left: 30px;
  font-weight: 500;
}
.usergroup__agenda--table .sublist__title {
  font-weight: bold;
  font-weight: 500;
  font-size: 14px;
}
.usergroup__agenda--table .sublist__speaker {
  font-weight: 300;
  font-size: 13px;
}
.usergroup.section {
  padding-top: 0;
}
.usergroup__intro {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  min-height: 350px;
  padding: 60px 0;
  position: relative;
  margin-bottom: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #efefef;
}
.usergroup__intro:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #020024;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(2, 0, 36, 0.8295693277)), color-stop(70%, transparent));
  background: linear-gradient(0deg, rgba(2, 0, 36, 0.8295693277), transparent 70%);
}
@media screen and (max-width: 768px) {
  .usergroup__intro {
    margin-bottom: 30px;
  }
}
.usergroup__intro--custom {
  display: block;
  padding: 0;
}
.usergroup__intro__title {
  margin-bottom: 5px;
  color: white;
  font-size: 54px;
  font-weight: 900;
}
@media screen and (max-width: 768px) {
  .usergroup__intro__title {
    font-size: 44px;
  }
}
.usergroup__intro__subtitle {
  color: white;
  font-size: 28px;
  font-weight: 300;
}
@media screen and (max-width: 768px) {
  .usergroup__intro__subtitle {
    font-size: 22px;
  }
}
.usergroup__intro .container {
  z-index: 10;
  position: relative;
}
.usergroup__nav {
  transform: translateY(-20px);
  list-style: none;
  margin: 0;
  padding: 0;
}
.usergroup__nav li {
  border-bottom: 1px solid #eaeaea;
}
.usergroup__nav li:last-child {
  border-bottom: 0;
}
.usergroup__nav li a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: bold;
  color: #102542;
  display: block;
  padding: 20px 60px 20px 0px;
  background: url("/content/images/icons/caret-right-default.svg") no-repeat center right;
  background-size: 20px;
}
.usergroup__nav li a:active, .usergroup__nav li a:visited, .usergroup__nav li a:focus {
  text-decoration: none;
  color: inherit;
}
.usergroup__nav li a:hover {
  text-decoration: none;
  color: inherit;
}
.usergroup__nav li a.active, .usergroup__nav li a:hover {
  background: url("/content/images/icons/caret-right-active.svg") no-repeat center right;
  background-size: 20px;
}
.usergroup__main h1, .usergroup__main h2, .usergroup__main h3, .usergroup__main h4, .usergroup__main p {
  margin-top: 30px;
}
.usergroup__main h1:first-child, .usergroup__main h2:first-child, .usergroup__main h3:first-child, .usergroup__main h4:first-child, .usergroup__main p:first-child {
  margin-top: 0;
}
.usergroup__main .aside__heading:first-of-type {
  margin-top: 0;
}
.usergroup__main img, .usergroup__main iframe {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
}
.usergroup__main hr {
  border-top: 1px solid #eaeaea;
  margin-top: 30px;
  margin-bottom: 30px;
}
.usergroup__main__content {
  max-width: 760px;
}
.usergroup__main__content > p:first-child {
  font-size: 22px;
  font-weight: 400;
}
.usergroup__main__content a {
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  display: inline-block;
}
.usergroup__main__content a:active, .usergroup__main__content a:visited, .usergroup__main__content a:focus {
  text-decoration: none;
  color: #102542;
}
.usergroup__main__content a:hover {
  text-decoration: none;
  color: #102542;
  border-bottom: 2px solid #005996;
}
.usergroup__main__downloads {
  max-width: 760px;
}
.usergroup__main__downloads h1, .usergroup__main__downloads h2, .usergroup__main__downloads h3 {
  font-size: 24px;
  margin-bottom: 15px;
  margin-top: 0;
}
.usergroup__main__downloads a {
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  display: inline-block;
  margin-right: 40px;
  display: inline-block;
}
.usergroup__main__downloads a:active, .usergroup__main__downloads a:visited, .usergroup__main__downloads a:focus {
  text-decoration: none;
  color: #102542;
}
.usergroup__main__downloads a:hover {
  text-decoration: none;
  color: #102542;
  border-bottom: 2px solid #102542;
}
.usergroup__main__downloads ul {
  margin-top: 40px;
}
.usergroup__link {
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  display: inline-block;
}
.usergroup__link:active, .usergroup__link:visited, .usergroup__link:focus {
  text-decoration: none;
  color: #102542;
}
.usergroup__link:hover {
  text-decoration: none;
  color: #102542;
  border-bottom: 2px solid #0397D7;
}
.usergroup__quickview {
  background: #f4f5f6;
  padding: 30px;
  margin-bottom: 30px;
  margin-top: 10px;
}
.usergroup__quickview h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}
.usergroup__quickview dt {
  margin-bottom: 5px;
}
.usergroup__quickview dd {
  margin-bottom: 20px;
}
.usergroup__quickview a {
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  display: inline-block;
}
.usergroup__quickview a:active, .usergroup__quickview a:visited, .usergroup__quickview a:focus {
  text-decoration: none;
  color: #102542;
}
.usergroup__quickview a:hover {
  text-decoration: none;
  color: #102542;
  border-bottom: 2px solid #102542;
}
.usergroup__quickview .material-icons {
  transform: translateY(4px);
  font-weight: normal;
  margin-right: 8px;
  font-size: 22px;
  color: #bababa;
}
.usergroup__quickview hr {
  border-color: #dedede;
}

.usergroup__meeting {
  padding-bottom: 0;
}

.section__table__request {
  font-size: 14px;
  font-weight: medium;
}

.usergroup__main .highlight-list {
  margin: 50px 0;
  counter-reset: list;
  list-style: none;
  padding: 0;
}
.usergroup__main .highlight-list li {
  counter-increment: list;
  padding-left: 0;
  margin-bottom: 30px;
  position: relative;
  font-size: 18px;
  line-height: 1.4;
  display: flex;
  padding: 0 15px;
}
.usergroup__main .highlight-list li img {
  max-width: 100px;
  margin-right: 15px;
}

.pw4 .usergroup__intro--custom h1 {
  font-size: 74px;
  font-weight: 800;
  margin-bottom: 30px;
  margin-top: 100px;
  color: black;
}
@media screen and (max-width: 768px) {
  .pw4 .usergroup__intro--custom h1 {
    margin-top: 50px;
    font-size: 66px;
  }
}
.pw4 .usergroup__intro--custom h4, .pw4 .usergroup__intro--custom p {
  font-size: 24px;
}
.pw4 .usergroup__intro--custom h4 {
  font-weight: 800;
}
@media screen and (max-width: 768px) {
  .pw4 .usergroup__intro--custom {
    padding-left: 0;
  }
}
@media screen and (min-width: 1600px) {
  .pw4 .usergroup__intro--custom {
    padding-left: 60px;
  }
}
.pw4 .highlight-list {
  margin: 0 0 50px;
  counter-reset: list;
  list-style: none;
  padding: 0;
}
.pw4 .highlight-list li {
  counter-increment: list;
  padding-left: 0;
  position: relative;
  font-size: 18px;
  line-height: 1.4;
  display: flex;
  padding: 0 15px;
}
.pw4 .highlight-list li img {
  max-width: 40px;
  margin-right: 15px;
}
.pw4 .highlight-list p {
  margin-top: 15px;
}
.pw4 .highlight-list + h2 {
  font-size: 18px;
  font-weight: bold;
}
.pw4 .section__list {
  list-style-type: none;
  padding-left: 10px;
  margin-bottom: 50px;
  margin-top: 10px;
}
.pw4 .section__list img {
  float: left;
  width: 28px;
  margin-right: 10px;
  filter: grayscale(1);
}
.pw4 .section__list li {
  margin-bottom: 15px;
}
.pw4 .featured-image--right {
  transform: scale(1.08) translateX(30px);
  box-shadow: 0 30px 30px 0 rgba(0, 46, 91, 0.22);
}
.pw4 .section__subtitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

/**************************************
/* HEADER
***************************************/
.page .row.page-title {
  display: none;
}
.page #screenshotrulecontainer + .btn-large.hidden-xs {
  display: none;
}

.section-header {
  background: #005996;
  position: relative;
  text-align: center;
  overflow: hidden;
  color: #fff;
  border-bottom: 1px solid #efefef;
  display: block;
  max-height: 600px;
  padding: 50px 30px 0 30px;
}
@media screen and (max-width: 768px) {
  .section-header {
    padding: 50px 15px 0 15px;
  }
}
@media screen and (max-width: 480px) {
  .section-header {
    padding: 40px 10px 30px 10px;
  }
}
.section-header > * {
  box-sizing: border-box;
}
.section-header__image {
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  width: 980px;
  margin-bottom: -50px;
  display: block;
  margin: 0 auto;
}
@media screen and (max-width: 1200px) {
  .section-header__image {
    max-width: 90%;
  }
}
@media screen and (max-width: 480px) {
  .section-header__image {
    display: none;
  }
}
.section-header__title {
  font-size: 58px;
  font-weight: 700;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .section-header__title {
    font-size: 44px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .section-header__title {
    font-size: 38px;
    margin-bottom: 20px;
  }
}
.section-header__subtitle {
  font-size: 24px;
  font-weight: 300;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.4;
  margin-bottom: 40px;
}
@media screen and (max-width: 1200px) {
  .section-header__subtitle {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .section-header__subtitle {
    font-size: 20px;
  }
}
.section-header__button {
  padding: 18px 50px;
  background: white;
  margin-bottom: 50px;
  font-weight: 500;
  transition: all 0.2s ease;
  text-decoration: none;
  display: inline-block;
  color: #003b63;
}
@media screen and (max-width: 1200px) {
  .section-header__button {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 728px) {
  .section-header__button {
    margin-bottom: 30px;
  }
}
.section-header__button:hover, .section-header__button:visited, .section-header__button:active {
  text-decoration: none;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.development header {
  background-color: #50B84A;
}
.development header-section__button {
  color: #50B84A;
}

.section-header--alt, .section-header--alt-2 {
  display: none;
}

/**************************************
/* ANIMATION EFFECTS AND TIMING
***************************************/
@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.animate-pop-in {
  animation: pop-in 0.6s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
  opacity: 0;
}

.section-header__title {
  animation-delay: 0.6s;
}
.section-header__subtitle {
  animation-delay: 0.8s;
}
.section-header__button {
  animation-delay: 1s;
}
.section-header__image {
  animation-delay: 1.1s;
}

.link {
  display: inline-block;
  position: absolute;
  bottom: -30px;
  right: 15px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #3C4858;
  z-index: 100;
  text-align: center;
}
.link > i, .link > em {
  font-size: 34px;
  color: #fff;
  line-height: 60px;
}

/**************************************
/* SECTION
***************************************/
.section {
  padding: 70px 0;
  position: relative;
  font-weight: 300;
  border-bottom: 1px solid #f4f5f6;
  /**** Additional modifiers ***/
}
@media screen and (max-width: 768px) {
  .section {
    padding: 50px 0;
  }
}
.section:focus {
  outline: 0;
}
.section__title {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 30px;
  color: #3C4858;
  margin-top: 10px;
  margin-bottom: 30px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .section__title {
    font-size: 34px;
    line-height: 1.15;
  }
}
@media screen and (max-width: 768px) {
  .section__title {
    font-size: 30px;
    margin-bottom: 20px;
  }
}
.section__pre-title {
  font-weight: 300;
  font-size: 22px;
  line-height: 1.6;
  margin-bottom: 0;
}
.section__pre-title + h1.section__title {
  margin-top: 0;
}
.section__description {
  font-weight: 300;
  font-size: 22px;
  line-height: 1.6;
  position: relative;
}
@media screen and (max-width: 768px) {
  .section__description {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
.section__features, .section__items {
  padding: 40px 15px 0;
}
.section__features:before, .section__features:after, .section__items:before, .section__items:after {
  content: "";
  display: table;
}
.section__features:after, .section__items:after {
  clear: both;
}
@media screen and (max-width: 1200px) {
  .section__features {
    padding: 40px 0 0;
  }
}
@media screen and (max-width: 992px) {
  .section__features {
    padding: 20px 0;
  }
}
.section__image--raised {
  box-shadow: 1px 1px 100px rgba(0, 0, 0, 0.2);
  padding: 0;
  max-width: 840px;
}
@media screen and (max-width: 768px) {
  .section__items {
    padding: 30px 0 0;
  }
}
.section__brands {
  margin-top: 70px;
}
@media screen and (max-width: 768px) {
  .section__brands {
    margin-top: 20px;
  }
}
.section__brands ul {
  list-style-type: none;
  padding: 0;
}
.section__brands li {
  display: inline-block;
  max-width: 145px;
  margin: 0 20px;
}
.section__brands li img {
  width: auto;
  height: 100%;
  max-height: 100px;
  vertical-align: middle;
  filter: grayscale(100%);
  opacity: 0.7;
  transform: scale(0.9);
}
@media screen and (max-width: 768px) {
  .section__brands li img {
    max-height: 80px;
  }
}
.section__button {
  padding: 18px 50px;
  color: white;
  background: #005996;
  font-weight: 600;
  display: inline-block;
  margin: 40px 0;
  text-decoration: none;
  transition: all 0.2s ease;
  appearance: none !important;
  border: 0;
}
.section__button:hover, .section__button:active, .section__button:focus {
  text-decoration: none;
  color: white;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background: #0068b0;
  text-decoration: none;
}
.section__button:visited {
  color: white;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .section__button {
    width: 100%;
    text-align: center;
    margin: 15px 0;
  }
}
.section__button--full {
  width: 100%;
  margin: 0;
  text-align: center;
}
.section__table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  background-color: #fff;
}
.section__table th {
  font-weight: 400;
  color: #757575;
  vertical-align: bottom;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1.6rem;
}
.section__table tr {
  transition: all 0.3s ease;
}
.section__table tr:hover > td {
  background: #f5f5f5;
}
.section__table td {
  font-weight: 400;
  text-align: left;
  padding: 1.6rem;
  vertical-align: top;
  border-top: 0;
  transition: all 0.3s ease;
}
.section__table--4cols th, .section__table--4cols td {
  width: 25%;
}
@media screen and (max-width: 768px) {
  .section__table--4cols th, .section__table--4cols td {
    width: auto;
  }
}
.section__tabs {
  background: #8BC34A;
}
.section__tabs > li > a {
  border-radius: 0;
  color: white;
  line-height: 2;
  margin: 0;
}
.section__tabs > li > a:hover, .section__tabs > li > a:focus {
  color: #555;
}
.section__tab-content h3 {
  margin: 30px 15px;
}
.section--grey {
  background: #f9f9f9;
}
.section__nav-list {
  list-style: none;
  padding-left: 0px;
  margin-top: 15px;
  margin-bottom: 30px;
}
.section__nav-list li {
  border: 0;
}
.section__nav-list li a {
  color: #3C4858;
  display: block;
  padding: 10px 0;
  font-weight: 300;
  padding: 8px 0;
  font-weight: 400;
}
.section__nav-list li:last-child a {
  border: 0;
}
.section__nav-list-heading {
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}
.section__aside {
  list-style: none;
  padding-left: 0px;
  font-size: 14px;
  margin-top: 15px;
}
.section__aside li i {
  vertical-align: middle;
  margin-right: 15px;
}
.section__aside li a {
  color: #3C4858;
  display: block;
  padding: 15px;
  font-weight: bold;
  vertical-align: middle;
  transition: all 0.2s ease;
}
.section__aside li a:hover {
  background: #005996;
  color: white;
  text-decoration: none;
}
.section__cards {
  margin-top: 30px;
}
.section__content {
  font-weight: 300;
  margin-bottom: 30px;
}
.section__content p {
  margin-bottom: 15px;
}
.section__ol {
  margin: 40px 0;
}
@media screen and (max-width: 768px) {
  .section__ol {
    margin: 30px 0;
  }
}
.section__ol li {
  margin-bottom: 30px;
}
.section__ol li:last-child {
  margin-bottom: 0;
}
.section__list--default li {
  position: relative;
  padding: 8px 20px;
  margin: 0;
}
.section__list--default .material-icons {
  position: absolute;
  top: 10%;
  left: -15px;
}
.section__list--flex {
  display: flex;
}
.section__list--lg li {
  padding: 20px 20px 20px 38px;
}
.section__list--lg .section__list-heading {
  font-weight: bold;
  line-height: 1;
  margin-bottom: 5px;
  font-size: 20px;
}
.section__list--lg .section__list-description {
  margin-bottom: 0;
}
.section__list--lg .material-icons {
  font-size: 44px;
  top: 10px;
}
.section__list-container {
  overflow: hidden;
}
.section__list-heading {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0px;
}
.section__list-description {
  font-weight: 300;
  margin-bottom: 0;
}
.section__product-logo {
  max-width: 64px;
  height: auto;
  float: left !important;
  margin-right: 8px;
  transform: translateY(-12px);
}
@media screen and (max-width: 768px) {
  .section__product-image {
    display: none;
  }
}

/*************************************************/
/* SECTION MAIN FEATURES */
/*************************************************/
.section-main-features {
  overflow: hidden;
  padding-top: 90px;
  padding-bottom: 70px;
  background: #f9f9f9;
}
@media screen and (max-width: 768px) {
  .section-main-features {
    padding: 50px 0 70px;
  }
}
.section-main-features--alt {
  background: white;
}
.section-main-features--alt img {
  float: right;
}
.section-main-features img {
  max-height: 580px;
}
@media screen and (max-width: 768px) {
  .section-main-features img {
    max-height: 350px;
    max-width: 100%;
    margin: 30px 0;
  }
}
.section-main-features .section__title {
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .section-main-features .section__title {
    margin-top: 10;
  }
}
.section-main-features .section__description {
  margin-bottom: 20px;
  clear: both;
}
.section-main-features .section__product-image img {
  max-width: 780px;
}
.section-main-features ul {
  list-style-type: none;
  padding-left: 10px;
}
.section-main-features li {
  font-weight: 300;
  margin: 5px;
}
.section-main-features li em {
  margin-right: 15px;
  transform: translateY(4px);
}

/*************************************************/
/* HELP  */
/*************************************************/
.section-help--main {
  background: #f9f9f9;
}

.section-help {
  border-top: 1px solid #ececec;
}
.section-help .info {
  padding: 0;
}
.section-help--main .info__link {
  border-radius: 3px;
}
.section-help--main .info__link:hover {
  background: #fff;
}

.section-help--product .info__icon > em {
  font-size: 44px;
}
.section-help--product .info__link {
  display: block;
  padding: 15px;
}
.section-help--product .info__link:hover {
  background: white;
}

/*************************************************/
/* ACCORDION */
/*************************************************/
.section-glossary a,
.section-faq a {
  text-decoration: none;
  transition: all 0.2s ease;
}
.section-glossary a:visited, .section-glossary a:hover, .section-glossary a:focus,
.section-faq a:visited,
.section-faq a:hover,
.section-faq a:focus {
  background: #eaeaea;
  text-decoration: none;
  outline: 0;
}
.section-glossary p,
.section-faq p {
  max-width: 800px;
}
.section-glossary .panel,
.section-faq .panel {
  border-radius: 0;
  box-shadow: none;
}
.section-glossary .panel p,
.section-faq .panel p {
  margin: 0;
}
.section-glossary .panel-default,
.section-faq .panel-default {
  border: 0;
  margin-bottom: 5px;
}
.section-glossary .panel-default > .panel-heading + .panel-collapse > .panel-body,
.section-faq .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border: 0;
}
.section-glossary .panel-heading,
.section-faq .panel-heading {
  padding: 0;
  border-radius: 0;
  border: 0;
}
.section-glossary .panel-heading a,
.section-faq .panel-heading a {
  padding: 25px 60px 25px 25px;
  border: 0;
  display: block;
  position: relative;
}
.section-glossary .panel-heading a:after,
.section-faq .panel-heading a:after {
  font-family: "Material Icons";
  content: "expand_more";
  -webkit-font-feature-settings: "expand_more";
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  text-align: center;
  font-size: 28px;
  vertical-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s ease;
}
.section-glossary .panel-heading a[aria-expanded=true]:after,
.section-faq .panel-heading a[aria-expanded=true]:after {
  transform: translateY(-50%) rotate(-180deg);
}
.section-glossary .panel-body,
.section-faq .panel-body {
  padding: 40px 60px 40px 25px;
  border-radius: 0;
}
@media screen and (max-width: 768px) {
  .section-glossary .panel-body,
.section-faq .panel-body {
    padding: 30px 25px 30px 25px;
  }
}
.section-glossary .panel-footer,
.section-faq .panel-footer {
  padding: 25px 60px 25px 25px;
  background: transparent;
  border-top: 1px solid #f4f5f6;
}
.section-glossary .panel-group-heading,
.section-faq .panel-group-heading {
  margin: 70px 0 30px;
}
@media screen and (max-width: 768px) {
  .section-glossary .panel-group-heading,
.section-faq .panel-group-heading {
    margin: 40px 0 30px;
  }
}
.section-glossary .panel-group-heading:first-child,
.section-faq .panel-group-heading:first-child {
  margin-top: 0;
}
.section-glossary .panel-footer__location,
.section-faq .panel-footer__location {
  font-weight: 700;
}
.section-glossary .panel-footer__location:after,
.section-faq .panel-footer__location:after {
  content: "|";
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
}

.section-how-to .section__items .row {
  position: relative;
  padding: 20px 15px;
}
@media screen and (max-width: 768px) {
  .section-how-to .section__items .row {
    padding: 20px 0;
  }
}
@media screen and (max-width: 992px) {
  .section-how-to .section__items .info {
    text-align: center;
  }
}

.section-upload textarea {
  max-width: 100%;
  width: 100%;
}
.section-upload .section__button {
  margin-top: 0;
}
.section-upload .info:first-child {
  padding-top: 0;
}
.section-upload .info__icon {
  transform: translateY(-7px);
}
.section-upload .info__icon em {
  font-size: 30px;
}

.section-report {
  padding: 70px 15px;
}
.section-report > h2, .section-report > h5 {
  padding: 0 15px;
}
@media screen and (max-width: 768px) {
  .section-report > h2, .section-report > h5 {
    padding: 0;
  }
}
.section-report .row {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .section-report .row {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 768px) {
  .section-report .section__heading {
    padding: 0;
  }
}
@media screen and (max-width: 768px) {
  .section-report .section__item {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.section-report .info__title {
  margin-bottom: 5px;
  font-size: 22px;
  margin-right: 20px;
  font-weight: 500;
}
.section-report .info__date {
  font-weight: 300;
}
.section-report .info__text {
  margin-top: 15px;
}
.section-report .info__footer h4 {
  font-size: 16px;
}
.section-report .info__footer a {
  display: block;
  margin-bottom: 8px;
  transition: all 0.2s ease;
  position: relative;
  color: white;
  background: #8bc34a;
  padding: 6px 15px;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  margin-top: 30px;
  font-weight: 400;
}
.section-report .info__footer a:after {
  position: absolute;
  top: -6px;
  right: 0;
  font-family: "Material Icons";
  content: "expand_more";
  font-size: 20px;
  opacity: 0;
  transition: all 0.2s ease;
}
.section-report .info__footer a:hover, .section-report .info__footer a:active, .section-report .info__footer a:visited {
  background-color: #7cb33b;
  text-decoration: none;
}

/*************************************************/
/* SECTION TRAINING */
/*************************************************/
.section-training,
.section-demo {
  font-weight: 300;
  font-size: 16px;
  padding: 70px 15px;
}
.section-training .section__item,
.section-demo .section__item {
  margin-bottom: 45px;
  margin-top: 30px;
}
.section-training p,
.section-demo p {
  margin-bottom: 15px;
}
.section-training ul,
.section-demo ul {
  padding-left: 0;
}
.section-training ul li,
.section-demo ul li {
  display: inline-block;
  width: 49%;
  position: relative;
  padding-left: 40px;
}
@media screen and (max-width: 768px) {
  .section-training ul li,
.section-demo ul li {
    display: block;
    width: 100%;
    padding-left: 25px;
  }
}
.section-training ul li:before,
.section-demo ul li:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: slategray;
}
@media screen and (max-width: 768px) {
  .section-training ul li:before,
.section-demo ul li:before {
    left: 5px;
  }
}
.section-training .full-width li,
.section-demo .full-width li {
  width: 100%;
}
.section-training--light,
.section-demo--light {
  background: #f9f9f9;
}

.section-demo--light {
  background: #fff !important;
}

.section-training-guides .section__downloads {
  background: #fff;
  margin-left: -10px;
  padding-top: 15px;
}
.section-training-guides .section__downloads a {
  display: block;
  padding: 10px;
  position: relative;
  margin-bottom: 5px;
  transition: all 0.2s ease;
}
.section-training-guides .section__downloads a:hover {
  text-decoration: none;
  background: rgba(0, 0, 0, 0.015);
}
.section-training-guides .section__downloads a:before {
  content: "";
  font-family: "Material Icons";
  font-size: 18px;
  font-weight: 600;
  content: "expand_more";
  display: block;
  position: absolute;
  right: 10px;
  top: 45%;
  transform: translateY(-50%);
  display: none;
}
.section-training-guides .section__downloads a .date {
  float: right;
  color: rgba(0, 0, 0, 0.4);
}
@media screen and (max-width: 768px) {
  .section-training-guides .section__downloads a .date {
    float: none;
    display: block;
  }
}

.section-upper {
  padding: 70px 0 15px 0;
}

.section-lower {
  padding: 15px 0 70px 0;
  border-bottom: 0;
}

/*************************************************/
/* MISC */
/*************************************************/
.info {
  padding: 30px 0 30px;
  text-align: left;
  display: block;
}
@media screen and (max-width: 768px) {
  .info {
    padding: 20px 0;
  }
}
.info__content {
  overflow: hidden;
}
.info__icon {
  float: left;
  margin-right: 20px;
  color: #3C4858;
}
.info__icon--primary {
  color: #0288D1;
  color: #708090;
}
.info__icon--secondary {
  color: #8BC34A;
}
.info__icon--tertiary {
  color: #E91E63;
  color: #C2185B;
}
.info__icon > em {
  font-size: 60px;
  margin-bottom: 30px;
}
.info__title {
  color: #3C4858;
  font-size: 18px;
  font-weight: bold;
}
.info__text {
  font-weight: 300;
  color: #3C4858;
}
.info__link {
  display: block;
  text-decoration: none;
  transition: background 0.2s ease;
  padding: 30px 5px;
}
@media screen and (max-width: 768px) {
  .info__link {
    padding: 20px 0;
  }
}
.info__link:hover, .info__link:visited, .info__link:focus {
  text-decoration: none;
  background: #f4f5f6;
}
.info__link--inline {
  color: black;
  font-weight: 400;
  border-bottom: 1px solid;
  text-decoration: none;
  outline: none;
}
.info__link--inline:hover, .info__link--inline:active, .info__link--inline:focus {
  text-decoration: none;
  outline: none;
  color: black;
}
.info__button {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
}
.info__button:active, .info__button:visited, .info__button:focus {
  text-decoration: none;
  color: inherit;
}
.info__button:hover {
  text-decoration: none;
  color: inherit;
}
.info__button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .info__button {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .info__button {
    width: 100%;
  }
}
.info__button:hover, .info__button:visited, .info__button:active {
  color: #fff;
}
.info__button:hover {
  background: #0068b0;
}
.info__thumb {
  max-width: 120px;
}
@media screen and (max-width: 992px) {
  .info__thumb {
    max-width: 100px;
  }
}
@media screen and (max-width: 768px) {
  .info__thumb {
    max-width: 80px;
  }
}
.info__action {
  margin-top: 30px;
}
.info__price {
  padding-right: 15px;
}
@media screen and (max-width: 768px) {
  .info__price--alt {
    margin-top: 15px;
  }
}
.info--padded {
  padding-top: 40px;
  padding-bottom: 70px;
  padding-right: 20px;
}
@media screen and (max-width: 768px) {
  .info--padded {
    padding-bottom: 40px;
    padding-right: 0;
    border-bottom: 1px solid #ececec;
  }
}
.info iframe {
  margin-bottom: 20px;
  max-width: 100%;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

.row--padded {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .row--padded {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.card {
  margin-bottom: 30px;
}
.card-item {
  text-align: center;
}
.card-item ul {
  list-style: none;
  padding: 0;
  max-width: 240px;
  margin: 10px auto 15px !important;
}
.card-item ul li {
  font-weight: 300;
  color: #3C4858;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.card-item ul li:last-child {
  border: 0;
}
@media screen and (min-width: 992px) {
  .card-item--left {
    text-align: left;
  }
}
.card-raised {
  background: white;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.01), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card-body {
  margin: 0;
  padding: 30px;
}
@media screen and (max-width: 500px) {
  .card-body {
    padding: 20px;
  }
}
.card-category {
  color: #999;
}
.card-title {
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.card b {
  color: #3c4858;
}

.btn.btn-white {
  transition: all 0.2s;
  background-color: #fff;
  color: #999;
  padding: 18px 50px;
  margin: 0.3125rem 1px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.428571;
  border-radius: 0 !important;
}
.btn.btn-white:focus, .btn.btn-white:hover {
  color: black;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.13), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.btn.btn-white-raised {
  background: #005996;
  color: white;
}
.btn.btn-white-raised:hover, .btn.btn-white-raised:active {
  background: #0068b0;
  color: white;
}

.read-more:before {
  display: inline-block;
  content: "Read less";
}
.read-more.collapsed:before {
  content: "Read more";
}

.col-sm-offset--15 {
  transform: translateX(15px);
}
@media screen and (max-width: 768px) {
  .col-sm-offset--15 {
    transform: translateX(0);
  }
}

.btn--remove-inline {
  background: transparent;
  appearance: none !important;
  border: 0;
  padding: 10px 0;
}
.btn--remove-inline i {
  color: #de2e2e;
}

.btn--add {
  appearance: none;
  border: 0;
  padding: 10px 20px;
  background: #8BC34A;
  color: white;
  transition: all 0.2s ease;
  margin: 0;
}
.btn--add:disabled {
  background: #ccc;
}

.btn--add-block {
  appearance: none !important;
  border: 0;
  padding: 10px 20px;
  background: #8bc34a;
  color: white;
  transition: all 0.2s ease;
  display: block;
  margin: 0 auto;
  text-decoration: none;
}
.btn--add-block:disabled {
  background: #ccc;
}
.btn--add-block:hover, .btn--add-block:active, .btn--add-block:visited {
  color: white;
  text-decoration: none;
}
.btn--add-block span {
  transform: translateY(1px);
  display: inline-block;
  font-weight: 500;
}
.btn--add-block i {
  vertical-align: middle;
  margin-right: 5px;
}

.sor-module-list {
  margin-bottom: 60px;
}
.sor-module-list:before, .sor-module-list:after {
  content: "";
  display: table;
}
.sor-module-list:after {
  clear: both;
}

.section--legal {
  margin-bottom: 50px;
}

.document-list--link {
  list-style: none;
  margin-top: 15px;
  margin-bottom: 30px;
  padding-left: 20px;
  font-size: 14px;
}
.document-list--link li {
  display: block;
  padding: 0;
}
.document-list--link li a {
  display: block;
  padding: 15px 30px;
  position: relative;
  font-size: 16px;
  font-weight: 300;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.document-list--link li a:active, .document-list--link li a:visited, .document-list--link li a:focus {
  text-decoration: none;
  color: inherit;
}
.document-list--link li a:hover {
  text-decoration: none;
  color: inherit;
}
.document-list--link li a:before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  background-size: 20px;
  background-repeat: no-repeat;
  left: -18px;
  background-image: url("/content/images/icons/link-1.svg");
}
.document-list--link li a[href$=".pdf"]:before {
  background-image: url("/content/images/icons/pdf-1.svg");
}
.document-list--link li a[href*=".doc"]:before {
  background-image: url("/content/images/icons/word.svg");
}
.document-list--link li a[href*=".co"]:before {
  background-image: url("/content/images/icons/chrome.svg");
}
.document-list--link li a[href*=".cfm"]:before {
  background-image: url("/content/images/icons/chrome.svg");
}
.document-list--link li a:link, .document-list--link li a:hover, .document-list--link li a:active, .document-list--link li a:visited {
  border: 0;
}
.document-list--link li a:hover {
  font-weight: 400;
  color: black;
}

.aside {
  padding-right: 25%;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .aside {
    padding-right: 0;
  }
}
.aside h2 {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 20px;
}
.aside ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.aside li {
  border-top: 1px solid #e2e2e2;
  padding: 15px 0;
}
.aside li:first-child {
  border-top: none;
}
.aside li a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  display: block;
}
.aside li a:active, .aside li a:visited, .aside li a:focus {
  text-decoration: none;
  color: inherit;
}
.aside li a:hover {
  text-decoration: none;
  color: inherit;
}
.aside li a:hover {
  font-weight: 400;
  color: black;
}

.section--sm {
  padding-top: 50px;
  padding-bottom: 50px;
}
.section--lg {
  padding-bottom: 12vh;
  padding-top: 12vh;
}
.section--md {
  padding-top: 8vh;
  padding-bottom: 8vh;
}
.section--xl {
  padding-bottom: 18vh;
  padding-top: 18vh;
}
.section--break {
  position: relative;
}
.section--break:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1140px;
  background: #dedede;
  height: 1px;
}
.section__confirmation {
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 730px;
  text-align: center;
}
.section__confirmation h1 {
  line-height: 1.2;
}
.section__confirmation p {
  font-size: 18px;
}
.section__confirmation p a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  display: inline-block;
}
.section__confirmation p a:active, .section__confirmation p a:visited, .section__confirmation p a:focus {
  text-decoration: none;
  color: inherit;
}
.section__confirmation p a:hover {
  text-decoration: none;
  color: inherit;
  border-bottom: 2px solid #0397D7;
}
.section__confirmation strong {
  display: inline-block;
}
.section__confirmation img {
  max-width: 300px;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.usp--pw {
  background-image: url("/images/bg-pw-3.png");
  background-size: contain;
  background-position: right -180px bottom -260px;
  background-repeat: no-repeat;
}

/*************************************************/
/* Pamwin Support */
/*************************************************/
.pamwin h1.row > img {
  display: none;
}

.category {
  background: #efefef;
  position: relative;
}

.category__title {
  padding: 35px 40px 30px 30px;
  margin: 0 !important;
  display: inline-block;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .category__title {
    padding: 30px 15px;
    font-size: 18px;
  }
}
@media screen and (max-width: 550px) {
  .category__title {
    font-size: 16px;
  }
}
.category__subtitle {
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  background: #8BC34A;
  display: inline-block;
  position: absolute;
  padding-top: 38px;
  padding-left: 40px;
  padding-right: 40px;
  top: 0;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .category__subtitle {
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 14px;
  }
}
#mainContent .pamwin {
  padding-bottom: 0;
}

.support_pamwin h1.row {
  display: none;
}

.support_pamwin #mainContent {
  padding-bottom: 0;
}

.pamwin--training h1.row {
  display: none;
}

.pamwin--training .btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #005996;
  border: 0;
  border-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 20px 40px;
  color: white;
  font-weight: 600;
  letter-spacing: 0.5px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.pamwin--training .btn:hover {
  background: #0068b0;
  border-radius: 2px;
  padding: 20px 40px;
}

@media screen and (max-width: 768px) {
  .pamwin--training #inhouse_training_enquiry .modal-dialog {
    width: auto;
    max-width: 100%;
  }
}
.pamwin--training #inhouse_training_enquiry .modal-content {
  background: #fcfcfc;
  padding: 30px;
  border-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 300;
}

@media screen and (max-width: 768px) {
  .pamwin--training #inhouse_training_enquiry .modal-content {
    padding: 30px 15px;
  }
}
.pamwin--training #inhouse_training_enquiry .modal-content .m3field {
  padding: 0 0 5px 0;
}

@media screen and (max-width: 768px) {
  .pamwin--training #inhouse_training_enquiry .modal-content .m3field {
    padding-bottom: 15px;
  }
}
.pamwin--training #inhouse_training_enquiry .modal-content .m3field.alt {
  background: transparent;
}

.pamwin--training #inhouse_training_enquiry .modal-content .m3field > div {
  padding: 0;
}

.pamwin--training #inhouse_training_enquiry .modal-content .m3field .control-label {
  padding: 0;
}

.pamwin--training #inhouse_training_enquiry .modal-content input[type=text],
.pamwin--training #inhouse_training_enquiry .modal-content input[type=password],
.pamwin--training #inhouse_training_enquiry .modal-content .input-group-addon,
.pamwin--training #inhouse_training_enquiry .modal-content textarea {
  border: 1px solid #eaeaea;
  border-radius: 0;
}

.pamwin--training #inhouse_training_enquiry .modal-content p {
  margin-bottom: 20px;
}

.pamwin--uploads #mainContent {
  border-top: 1px solid #eaeaea;
  padding-bottom: 0;
}

.pamwin--uploads p {
  margin-bottom: 15px;
}

.pamwin--uploads .section--notice {
  padding-bottom: 0;
}

.pamwin--uploads .error {
  color: indianred;
}

.pamwin--uploads h2 {
  margin-top: 0;
}

.pamwin--uploads h2, .pamwin--uploads h3 {
  margin-bottom: 15px;
}

.pamwin--uploads form {
  padding: 40px;
  background: #fafafa;
}

@media screen and (max-width: 768px) {
  .pamwin--uploads form {
    padding: 30px 15px;
  }
}
.pamwin--uploads .btn-large {
  display: none;
}

.pamwin--uploads label {
  font-weight: 600;
}

.pamwin--uploads textarea {
  border: 1px solid #eee;
  width: 100%;
}

.pamwin--uploads input[type=file] {
  padding: 15px;
  border: 1px dashed #ccc;
  width: 100%;
}

.pamwin--uploads input[type=submit],
.pamwin--uploads input[type=button] {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  width: 100%;
}
.pamwin--uploads input[type=submit]:active, .pamwin--uploads input[type=submit]:visited, .pamwin--uploads input[type=submit]:focus,
.pamwin--uploads input[type=button]:active,
.pamwin--uploads input[type=button]:visited,
.pamwin--uploads input[type=button]:focus {
  text-decoration: none;
  color: inherit;
}
.pamwin--uploads input[type=submit]:hover,
.pamwin--uploads input[type=button]:hover {
  text-decoration: none;
  color: inherit;
}
.pamwin--uploads input[type=submit]:hover,
.pamwin--uploads input[type=button]:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .pamwin--uploads input[type=submit],
.pamwin--uploads input[type=button] {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .pamwin--uploads input[type=submit],
.pamwin--uploads input[type=button] {
    width: 100%;
  }
}
.pamwin--uploads input[type=submit]:hover, .pamwin--uploads input[type=submit]:visited, .pamwin--uploads input[type=submit]:active,
.pamwin--uploads input[type=button]:hover,
.pamwin--uploads input[type=button]:visited,
.pamwin--uploads input[type=button]:active {
  color: #fff;
}
.pamwin--uploads input[type=submit]:hover,
.pamwin--uploads input[type=button]:hover {
  background: #0068b0;
}

.pamwin--uploads #fixing, .pamwin--uploads #uploaded, .pamwin--uploads #finished {
  background: white;
  padding: 10px 30px 20px;
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .pamwin--uploads #fixing, .pamwin--uploads #uploaded, .pamwin--uploads #finished {
    padding: 10px 15px;
  }
}
.pamwin--uploads .section--files {
  background: #fafafa;
}

.pamwin--version h1.row, .pamwin--release h1.row {
  display: none;
}

.pamwin--version h2, .pamwin--release h2 {
  display: inline-block;
}

.pamwin--version .align-right, .pamwin--release .align-right {
  float: right;
  margin-top: 15px;
}

@media screen and (max-width: 768px) {
  .pamwin--version .align-right, .pamwin--release .align-right {
    display: block;
    float: none;
    margin-top: 0;
    width: 100%;
  }
}
.pamwin--version h2, .pamwin--version h3, .pamwin--release h2, .pamwin--release h3 {
  margin-bottom: 15px;
}

.pamwin--version > p, .pamwin--release > p {
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .pamwin--version > p, .pamwin--release > p {
    margin-bottom: 15px;
  }
}
.pamwin--version .notifications, .pamwin--release .notifications {
  padding-top: 50px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .pamwin--version .notifications, .pamwin--release .notifications {
    padding-top: 30px;
  }
}
.pamwin--version .notifications h4, .pamwin--release .notifications h4 {
  font-weight: 500;
  color: #3C4858;
  display: inline-block;
  margin-right: 15px;
}

.pamwin--version .notifications p button, .pamwin--release .notifications p button {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.pamwin--version .notifications .m3checkbox, .pamwin--release .notifications .m3checkbox {
  margin-right: 15px;
}

.pamwin--upgrade-guide {
  font-weight: 300;
  min-height: 70vh;
}

.pamwin--upgrade-guide h1.row {
  display: none;
}

.pamwin--upgrade-guide h2 {
  display: inline-block;
}

.pamwin--upgrade-guide h4 {
  margin-bottom: 15px;
}

.pamwin--upgrade-guide p {
  margin-bottom: 15px;
}

.pamwin--upgrade-guide .btn-group > .btn {
  margin: 0 5px;
}

.pamwin--usergroup {
  font-weight: 300;
}

.pamwin--usergroup h1.row {
  display: none;
}

.pamwin--usergroup p {
  margin-bottom: 15px;
}

.pamwin--usergroup h1, .pamwin--usergroup h2, .pamwin--usergroup h3 {
  margin-bottom: 15px;
}

.pamwin--usergroup .section__quote {
  margin-top: 30px;
  margin-bottom: 40px;
  padding: 40px;
  background: #f4f5f6;
}

.pamwin--usergroup .section__quote h2, .pamwin--usergroup .section__quote h3 {
  margin-top: 0;
}

.pamwin--usergroup .section__quote p:last-child {
  margin-bottom: 0;
}

.pamwin--usergroup .section__table {
  border-collapse: collapse;
  margin-bottom: 30px;
}

.pamwin--usergroup .section__table th {
  font-weight: 600;
  font-size: 12px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}

.pamwin--usergroup .section__table td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.pamwin--usergroup .section__table .ticket, .pamwin--usergroup .section__table .votes, .pamwin--usergroup .section__table .update, .pamwin--usergroup .section__table .schedule {
  text-align: center;
}

.pamwin--feature-details .page-title,
.pamwin--issue-details .page-title {
  display: none;
}

.pamwin--issue .page-title {
  display: none;
}

.pamwin--issue h2 {
  font-size: 30px;
  margin-bottom: 30px;
}

.pamwin--issue .list-unstyled {
  margin-bottom: 30px;
}

.pamwin--issue .list-unstyled li {
  display: inline-block;
  margin: 0 8px 5px 0;
  padding: 5px 12px;
  background: #f4f5f6;
  border-radius: 2px;
  font-weight: 400;
}

.pamwin--reports #mainContent,
.pamwin--faq #mainContent,
.pamwin--video #mainContent,
.pamwin--glossary #mainContent,
.pamwin--user-group #mainContent {
  padding-bottom: 0;
}

.pamwin--reports .btn-large.hidden-xs,
.pamwin--faq .btn-large.hidden-xs,
.pamwin--video .btn-large.hidden-xs,
.pamwin--glossary .btn-large.hidden-xs,
.pamwin--user-group .btn-large.hidden-xs {
  display: none;
}

.pamwin--reports .btn-large.hidden-xs + br,
.pamwin--faq .btn-large.hidden-xs + br,
.pamwin--video .btn-large.hidden-xs + br,
.pamwin--glossary .btn-large.hidden-xs + br,
.pamwin--user-group .btn-large.hidden-xs + br {
  display: none;
}

.section-training-guides .section__subtitle {
  padding: 0 10px;
}

.section-how-to--pamwin iframe {
  background: #f9f9f9;
  border: 0;
}

#section-pamwinsync {
  background: url("/images/bg-pc-full.png") no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}
@media screen and (max-width: 768px) {
  #section-pamwinsync {
    background: #fff;
    border-top: 1px solid #dedede;
  }
}
#section-pamwinsync em {
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
}
#section-pamwinsync .pamwinsync__header:before, #section-pamwinsync .pamwinsync__header:after {
  content: "";
  display: table;
}
#section-pamwinsync .pamwinsync__header:after {
  clear: both;
}
#section-pamwinsync .pamwinsync__header .img-logo {
  max-width: 72px;
  margin-right: 10px;
  margin-top: 5px;
  float: left;
}

.section-header--pw, .section-header--pwlite {
  max-height: 940px;
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
  position: relative;
}

.section-header--pw {
  background-image: url(/images/dmImage/StandardImage/bg-pamwin-promo.jpg);
}
.section-header--pw:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #020024;
  background: linear-gradient(0deg, rgba(2, 0, 36, 0.8295693277) 0%, rgba(0, 0, 0, 0) 70%);
}

.section-header--pwlite {
  background-image: url(/images/dmImage/StandardImage/bg-pw-lite.png);
}
.section-header--pwlite:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #020024;
  background: linear-gradient(0deg, rgba(2, 0, 36, 0.9976365546) 0%, rgba(2, 0, 36, 0.6222864146) 36%, rgba(2, 0, 36, 0.3477766106) 100%);
}
.section-header--pwlite .section__header__title {
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}

#section-powerbi {
  position: relative;
}
@media screen and (max-width: 1280px) {
  #section-powerbi {
    background-size: 1000px;
    background-position: right -580px center;
  }
}
@media screen and (max-width: 768px) {
  #section-powerbi {
    background: none;
  }
}
#section-powerbi .btn-play {
  margin-top: 180px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
}
#section-powerbi .btn-play img {
  align-self: flex-end;
}
@media screen and (max-width: 768px) {
  #section-powerbi .btn-play {
    display: none;
  }
}

.container-full {
  position: relative;
  width: 100%;
}

.container-half {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .container-half {
    display: block;
    position: static;
    width: 100%;
  }
}

.flex-container {
  display: flex;
  height: 100%;
}
.flex-container--center {
  align-items: center;
  justify-content: center;
}
.flex-container iframe {
  margin-left: 25px;
}

/************************************************************/
/** CENTRAL PAGE STYLES.                                   **/
/************************************************************/
.central .page-title {
  display: none;
}

.central a.btn.hidden-xs {
  display: none;
}

.central .section-header {
  max-height: 940px;
  background: url(/images/dmImage/StandardImage/central%2D3.png) left top -60px no-repeat;
  background-size: cover;
  position: relative;
}
.central .section-header:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #020024;
  background: linear-gradient(0deg, rgba(2, 0, 36, 0.9444152661) 0%, rgba(0, 0, 0, 0) 100%);
}
.central .section-header__subnav {
  position: relative;
  margin-top: -30px;
  display: flex;
  gap: 8px;
  justify-content: center;
}
@media screen and (max-width: 668px) {
  .central .section-header__subnav {
    flex-wrap: wrap;
    margin-top: -25px;
  }
}
.central .section-header__button-alt {
  align-items: center;
  justify-content: center;
  min-width: 200px;
  padding: 20px;
  height: 70px;
  border: 2px solid #fff;
  color: #fff;
  font-weight: 500;
  animation-delay: 1.2s;
  background: #fff;
}
.central .section-header__button-alt:link, .central .section-header__button-alt:visited, .central .section-header__button-alt:hover {
  color: #102542;
  text-decoration: none;
}
.central .section-header__button-alt:hover {
  box-shadow: 0 14px 28px rgba(253, 253, 253, 0.25), 0 10px 10px rgba(255, 255, 255, 0.2);
  color: #1f4880;
}
@media screen and (max-width: 668px) {
  .central .section-header__button-alt {
    display: block;
    width: 100%;
    max-width: 396px;
  }
}

@media screen and (max-width: 768px) {
  .central .section-header {
    max-height: 640px;
  }
}
.central .section-header__image {
  max-width: 100%;
  width: 100%;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
  position: relative;
  z-index: 0;
  margin-top: -70px;
}

@media screen and (max-width: 768px) {
  .central .section-header__image {
    display: none;
  }
}
.central .section-header__button {
  position: relative;
  z-index: 1000;
}

.central .section-header--alt {
  background-image: url("http://www.m3h.co.uk/images/temp/surveyor-0.png");
  background-size: 90%;
  background-position: top 30px right -380px;
}

.central .section__button {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .central .section__button {
    width: 100%;
  }
}
.central .section-main-features img {
  max-height: 540px;
  max-width: 920px;
  -webkit-box-shadow: 1px 1px 100px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 100px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 480px) {
  .central .section-main-features img {
    display: none;
  }
}
.central-demo .page-title {
  display: none;
}

.central-demo .category__subtitle {
  background: #8BC34A;
}

.central-demo video {
  max-width: 100%;
  -webkit-box-shadow: 1px 1px 80px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 1px 80px rgba(0, 0, 0, 0.05);
}

.central-demo .section-demo ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.central-demo .section-demo a:not([class]) {
  color: #005996;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  display: inline-block;
  font-weight: 300;
  border-bottom: 2px solid transparent;
  padding-bottom: 3px;
  line-height: 1;
}
.central-demo .section-demo a:not([class]):active, .central-demo .section-demo a:not([class]):visited, .central-demo .section-demo a:not([class]):focus {
  color: #005996;
  text-decoration: none;
}
.central-demo .section-demo a:not([class]):hover {
  text-decoration: none;
  color: #005996;
  border-bottom: 2px solid #005996;
}

.central-hms {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  line-height: 1.25;
  overflow-x: auto;
  display: block;
}
.central-hms thead {
  background-color: #f4f4f4;
  letter-spacing: 1px;
  text-transform: none;
}
.central-hms thead th {
  padding: 12px;
  text-align: left;
  color: #333;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
}
.central-hms tbody tr {
  transition: background-color 0.2s;
}
.central-hms tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
.central-hms tbody tr:hover {
  background-color: #f0f0f0;
}
.central-hms tbody tr td {
  padding: 10px;
  border-bottom: 1px solid #ffffff91;
  color: #555;
}
.central-hms tbody tr td:first-child {
  font-weight: bold;
  color: #222;
}
.central-hms .copy-paste {
  background-color: #fff38b;
  color: #5c5c00;
}
.central-hms .email {
  background-color: #fff;
  color: #102542;
}
.central-hms .na {
  background-color: #e0e0e0;
  color: #555;
}
.central-hms .xml-http {
  background-color: #bbdefb;
  background-color: #00B0F0;
  color: #0d47a1;
  color: #fff;
}
.central-hms .json-iframe {
  background-color: #abe5ad;
  color: #1b5e20;
}
.central-hms .both {
  background-color: #00B0F0;
  background-color: #172B46;
  color: #fff;
}
@media (max-width: 768px) {
  .central-hms {
    font-size: 14px;
  }
  .central-hms th, .central-hms td {
    padding: 8px;
  }
}

.flex-with-icon > li {
  display: flex;
  margin-bottom: 10px;
}

.faq-container {
  max-width: 800px;
  margin: 40px auto;
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  background-color: #f5f7fa;
}

.faq-header {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  transition: all 0.3s ease;
}
.faq-item:last-of-type {
  border-bottom: 0;
}
.faq-item .faq-question {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.faq-item .faq-question:hover {
  background-color: #eaeaea;
}
.faq-item .faq-question::after {
  content: "+";
  font-size: 20px;
  font-weight: bold;
  color: #666;
}
.faq-item.active .faq-question::after {
  content: "-";
}
.faq-item.active .faq-answer {
  display: block;
}
.faq-item .faq-answer {
  display: none;
  padding: 15px;
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  background-color: #ffffff;
  border-left: 3px solid #0078d4;
  border-radius: 5px;
  margin-bottom: 20;
}

@media (max-width: 600px) {
  .faq-container {
    padding: 15px;
  }

  .faq-question {
    font-size: 16px;
  }

  .faq-answer {
    font-size: 14px;
  }
}
#central-faq {
  border-top: 1px solid #ccc;
}

.hatc .section-header {
  max-height: 940px;
  background-position: top left;
  background-size: cover;
  position: relative;
}
.hatc .section-header:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #020024;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(2, 0, 36, 0.8295693277)), color-stop(70%, transparent));
  background: linear-gradient(0deg, rgba(2, 0, 36, 0.8295693277), transparent 70%);
}
.hatc .section-header .section-header__title {
  margin-top: 40px;
}
.hatc .teams {
  justify-content: center;
}
.hatc .teams .team {
  max-width: 300px;
}

.featured__trainers .flex-row {
  display: flex;
  justify-content: center;
}
.featured__trainers .flex-item {
  width: 33.33%;
}
@media screen and (max-width: 768px) {
  .featured__trainers .flex-item {
    width: 100%;
  }
}
.featured__trainers .section__title {
  text-align: center;
  margin-bottom: 40px;
}

.featured__trainer {
  text-align: center;
}
.featured__trainer__profileimg {
  min-height: 240px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  width: 340px;
  height: 340px;
  margin: 0 auto;
  margin-bottom: 40px;
  border: 15px solid #efefef;
}

.vision .section-header {
  background-position: left;
}
.vision .section-header:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #020024;
  background: linear-gradient(0deg, rgba(2, 0, 36, 0.8295693277), transparent 95%);
}
@media screen and (max-width: 768px) {
  .vision .section-main-features--right img {
    max-height: 100%;
    width: 100%;
  }
}

#stdLogin .new-registration, #stdLogin .main-login {
  padding: 50px;
}

@media screen and (max-width: 992px) {
  #stdLogin .new-registration, #stdLogin .main-login {
    padding: 40px;
  }
}
@media screen and (max-width: 768px) {
  #stdLogin .new-registration, #stdLogin .main-login {
    padding: 30px 15px;
  }
}
#stdLogin .new-registration a {
  margin-top: 15px;
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  padding: 15px 20px;
}
#stdLogin .new-registration a:active, #stdLogin .new-registration a:visited, #stdLogin .new-registration a:focus {
  text-decoration: none;
  color: inherit;
}
#stdLogin .new-registration a:hover {
  text-decoration: none;
  color: inherit;
}
#stdLogin .new-registration a:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  #stdLogin .new-registration a {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  #stdLogin .new-registration a {
    width: 100%;
  }
}
#stdLogin .new-registration a:hover, #stdLogin .new-registration a:visited, #stdLogin .new-registration a:active {
  color: #fff;
}
#stdLogin .new-registration a:hover {
  background: #0068b0;
}

@media screen and (max-width: 768px) {
  #stdLogin .new-registration a {
    max-width: 100%;
    text-align: center;
  }
}
#stdLogin .main-login {
  padding: 50px;
  background: #fafafa;
  border-radius: 5px;
  border: 1px solid #efefef;
}
@media screen and (max-width: 768px) {
  #stdLogin .main-login {
    padding: 30px 15px;
  }
}
#stdLogin .main-login input, #stdLogin .main-login select, #stdLogin .main-login textarea {
  border: 1px solid #efefef;
  background-color: #fff;
  color: black;
  display: inline-block;
  font-weight: 300;
  line-height: 1.5;
  padding: 12px 12px 10px;
  width: 100%;
  height: auto;
}
#stdLogin .main-login label {
  color: #3C4858;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  padding: 10px 0;
  margin: 0;
  width: 100%;
  vertical-align: top;
}
#stdLogin .main-login .select-wrapper {
  background: url(/content/images/icons/arrow_down.svg);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 20px;
  border: 1px solid #dedede;
  background-color: #fff;
  overflow: hidden;
  width: 100%;
}
#stdLogin .main-login .checkbox-wrapper label:before {
  background: #fff;
}
#stdLogin .main-login .checkbox-wrapper label {
  padding: 0 0 0 30px;
}
#stdLogin .main-login select {
  background: transparent;
  width: 100%;
  border: 0;
  margin: 0;
  appearance: none;
  padding: 12px 36px 10px 12px;
}
#stdLogin .main-login button, #stdLogin .main-login input[type=submit] {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  padding: 15px 20px;
  margin: 15px 0;
}
#stdLogin .main-login button:active, #stdLogin .main-login button:visited, #stdLogin .main-login button:focus, #stdLogin .main-login input[type=submit]:active, #stdLogin .main-login input[type=submit]:visited, #stdLogin .main-login input[type=submit]:focus {
  text-decoration: none;
  color: inherit;
}
#stdLogin .main-login button:hover, #stdLogin .main-login input[type=submit]:hover {
  text-decoration: none;
  color: inherit;
}
#stdLogin .main-login button:hover, #stdLogin .main-login input[type=submit]:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  #stdLogin .main-login button, #stdLogin .main-login input[type=submit] {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  #stdLogin .main-login button, #stdLogin .main-login input[type=submit] {
    width: 100%;
  }
}
#stdLogin .main-login button:hover, #stdLogin .main-login button:visited, #stdLogin .main-login button:active, #stdLogin .main-login input[type=submit]:hover, #stdLogin .main-login input[type=submit]:visited, #stdLogin .main-login input[type=submit]:active {
  color: #fff;
}
#stdLogin .main-login button:hover, #stdLogin .main-login input[type=submit]:hover {
  background: #0068b0;
}
#stdLogin .main-login .error {
  color: #de2e2e;
}
#stdLogin .main-login abbr {
  border: 0;
  color: rgba(0, 0, 0, 0.4);
  line-height: 1.1;
  text-decoration: none;
  vertical-align: center;
  margin-left: 5px;
}
#stdLogin .main-login .privacy-notice {
  padding: 0 0 45px;
  background: transparent;
}
#stdLogin .main-login ol, #stdLogin .main-login ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#stdLogin .main-login ol li, #stdLogin .main-login ul li {
  margin-bottom: 15px;
}
#stdLogin .main-login legend {
  border-bottom: 1px solid #e5e5e5;
  margin: 15px 0;
  padding-bottom: 15px;
  padding-top: 15px;
}

.forgotten-password {
  padding: 50px;
  background: #fafafa;
  border-radius: 5px;
  border: 1px solid #efefef;
}
@media screen and (max-width: 768px) {
  .forgotten-password {
    padding: 30px 15px;
  }
}
.forgotten-password input, .forgotten-password select, .forgotten-password textarea {
  border: 1px solid #efefef;
  background-color: #fff;
  color: black;
  display: inline-block;
  font-weight: 300;
  line-height: 1.5;
  padding: 12px 12px 10px;
  width: 100%;
  height: auto;
}
.forgotten-password label {
  color: #3C4858;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  padding: 10px 0;
  margin: 0;
  width: 100%;
  vertical-align: top;
}
.forgotten-password .select-wrapper {
  background: url(/content/images/icons/arrow_down.svg);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 20px;
  border: 1px solid #dedede;
  background-color: #fff;
  overflow: hidden;
  width: 100%;
}
.forgotten-password .checkbox-wrapper label:before {
  background: #fff;
}
.forgotten-password .checkbox-wrapper label {
  padding: 0 0 0 30px;
}
.forgotten-password select {
  background: transparent;
  width: 100%;
  border: 0;
  margin: 0;
  appearance: none;
  padding: 12px 36px 10px 12px;
}
.forgotten-password button, .forgotten-password input[type=submit] {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  padding: 15px 20px;
  margin: 15px 0;
}
.forgotten-password button:active, .forgotten-password button:visited, .forgotten-password button:focus, .forgotten-password input[type=submit]:active, .forgotten-password input[type=submit]:visited, .forgotten-password input[type=submit]:focus {
  text-decoration: none;
  color: inherit;
}
.forgotten-password button:hover, .forgotten-password input[type=submit]:hover {
  text-decoration: none;
  color: inherit;
}
.forgotten-password button:hover, .forgotten-password input[type=submit]:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .forgotten-password button, .forgotten-password input[type=submit] {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .forgotten-password button, .forgotten-password input[type=submit] {
    width: 100%;
  }
}
.forgotten-password button:hover, .forgotten-password button:visited, .forgotten-password button:active, .forgotten-password input[type=submit]:hover, .forgotten-password input[type=submit]:visited, .forgotten-password input[type=submit]:active {
  color: #fff;
}
.forgotten-password button:hover, .forgotten-password input[type=submit]:hover {
  background: #0068b0;
}
.forgotten-password .error {
  color: #de2e2e;
}
.forgotten-password abbr {
  border: 0;
  color: rgba(0, 0, 0, 0.4);
  line-height: 1.1;
  text-decoration: none;
  vertical-align: center;
  margin-left: 5px;
}
.forgotten-password .privacy-notice {
  padding: 0 0 45px;
  background: transparent;
}
.forgotten-password ol, .forgotten-password ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.forgotten-password ol li, .forgotten-password ul li {
  margin-bottom: 15px;
}
.forgotten-password legend {
  border-bottom: 1px solid #e5e5e5;
  margin: 15px 0;
  padding-bottom: 15px;
  padding-top: 15px;
}
.forgotten-password h2 {
  font-size: 28px;
  font-weight: 600;
  margin-top: 0;
  line-height: 1.15;
  margin-bottom: 30px;
}

.login-registration {
  padding: 50px;
  background: #fafafa;
  border-radius: 5px;
  border: 1px solid #efefef;
}
@media screen and (max-width: 768px) {
  .login-registration {
    padding: 30px 15px;
  }
}
.login-registration input, .login-registration select, .login-registration textarea {
  border: 1px solid #efefef;
  background-color: #fff;
  color: black;
  display: inline-block;
  font-weight: 300;
  line-height: 1.5;
  padding: 12px 12px 10px;
  width: 100%;
  height: auto;
}
.login-registration label {
  color: #3C4858;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  padding: 10px 0;
  margin: 0;
  width: 100%;
  vertical-align: top;
}
.login-registration .select-wrapper {
  background: url(/content/images/icons/arrow_down.svg);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 20px;
  border: 1px solid #dedede;
  background-color: #fff;
  overflow: hidden;
  width: 100%;
}
.login-registration .checkbox-wrapper label:before {
  background: #fff;
}
.login-registration .checkbox-wrapper label {
  padding: 0 0 0 30px;
}
.login-registration select {
  background: transparent;
  width: 100%;
  border: 0;
  margin: 0;
  appearance: none;
  padding: 12px 36px 10px 12px;
}
.login-registration button, .login-registration input[type=submit] {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  padding: 15px 20px;
  margin: 15px 0;
}
.login-registration button:active, .login-registration button:visited, .login-registration button:focus, .login-registration input[type=submit]:active, .login-registration input[type=submit]:visited, .login-registration input[type=submit]:focus {
  text-decoration: none;
  color: inherit;
}
.login-registration button:hover, .login-registration input[type=submit]:hover {
  text-decoration: none;
  color: inherit;
}
.login-registration button:hover, .login-registration input[type=submit]:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .login-registration button, .login-registration input[type=submit] {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .login-registration button, .login-registration input[type=submit] {
    width: 100%;
  }
}
.login-registration button:hover, .login-registration button:visited, .login-registration button:active, .login-registration input[type=submit]:hover, .login-registration input[type=submit]:visited, .login-registration input[type=submit]:active {
  color: #fff;
}
.login-registration button:hover, .login-registration input[type=submit]:hover {
  background: #0068b0;
}
.login-registration .error {
  color: #de2e2e;
}
.login-registration abbr {
  border: 0;
  color: rgba(0, 0, 0, 0.4);
  line-height: 1.1;
  text-decoration: none;
  vertical-align: center;
  margin-left: 5px;
}
.login-registration .privacy-notice {
  padding: 0 0 45px;
  background: transparent;
}
.login-registration ol, .login-registration ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.login-registration ol li, .login-registration ul li {
  margin-bottom: 15px;
}
.login-registration legend {
  border-bottom: 1px solid #e5e5e5;
  margin: 15px 0;
  padding-bottom: 15px;
  padding-top: 15px;
}
.login-registration h2 {
  font-size: 28px;
  font-weight: 600;
  margin-top: 0;
  line-height: 1.15;
  margin-bottom: 30px;
}
.login-registration ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.login-registration .invalid-password {
  color: #de2e2e;
}

.register-user p:first-of-type {
  font-size: 18px;
}
.register-user form {
  padding: 50px;
  background: #fafafa;
  border-radius: 5px;
  border: 1px solid #efefef;
  padding-bottom: 35px;
}
@media screen and (max-width: 768px) {
  .register-user form {
    padding: 30px 15px;
  }
}
.register-user form input, .register-user form select, .register-user form textarea {
  border: 1px solid #efefef;
  background-color: #fff;
  color: black;
  display: inline-block;
  font-weight: 300;
  line-height: 1.5;
  padding: 12px 12px 10px;
  width: 100%;
  height: auto;
}
.register-user form label {
  color: #3C4858;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  padding: 10px 0;
  margin: 0;
  width: 100%;
  vertical-align: top;
}
.register-user form .select-wrapper {
  background: url(/content/images/icons/arrow_down.svg);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 20px;
  border: 1px solid #dedede;
  background-color: #fff;
  overflow: hidden;
  width: 100%;
}
.register-user form .checkbox-wrapper label:before {
  background: #fff;
}
.register-user form .checkbox-wrapper label {
  padding: 0 0 0 30px;
}
.register-user form select {
  background: transparent;
  width: 100%;
  border: 0;
  margin: 0;
  appearance: none;
  padding: 12px 36px 10px 12px;
}
.register-user form button, .register-user form input[type=submit] {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  padding: 15px 20px;
  margin: 15px 0;
}
.register-user form button:active, .register-user form button:visited, .register-user form button:focus, .register-user form input[type=submit]:active, .register-user form input[type=submit]:visited, .register-user form input[type=submit]:focus {
  text-decoration: none;
  color: inherit;
}
.register-user form button:hover, .register-user form input[type=submit]:hover {
  text-decoration: none;
  color: inherit;
}
.register-user form button:hover, .register-user form input[type=submit]:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .register-user form button, .register-user form input[type=submit] {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .register-user form button, .register-user form input[type=submit] {
    width: 100%;
  }
}
.register-user form button:hover, .register-user form button:visited, .register-user form button:active, .register-user form input[type=submit]:hover, .register-user form input[type=submit]:visited, .register-user form input[type=submit]:active {
  color: #fff;
}
.register-user form button:hover, .register-user form input[type=submit]:hover {
  background: #0068b0;
}
.register-user form .error {
  color: #de2e2e;
}
.register-user form abbr {
  border: 0;
  color: rgba(0, 0, 0, 0.4);
  line-height: 1.1;
  text-decoration: none;
  vertical-align: center;
  margin-left: 5px;
}
.register-user form .privacy-notice {
  padding: 0 0 45px;
  background: transparent;
}
.register-user form ol, .register-user form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.register-user form ol li, .register-user form ul li {
  margin-bottom: 15px;
}
.register-user form legend {
  border-bottom: 1px solid #e5e5e5;
  margin: 15px 0;
  padding-bottom: 15px;
  padding-top: 15px;
}
.register-user form .form__group {
  margin-bottom: 10px;
}
.register-user form .form__group--actions {
  margin-bottom: 0;
  margin-top: 30px;
}
.register-user form .btn-submit {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  min-width: auto;
  padding: 12px;
}
.register-user form .btn-submit:active, .register-user form .btn-submit:visited, .register-user form .btn-submit:focus {
  text-decoration: none;
  color: inherit;
}
.register-user form .btn-submit:hover {
  text-decoration: none;
  color: inherit;
}
.register-user form .btn-submit:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .register-user form .btn-submit {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .register-user form .btn-submit {
    width: 100%;
  }
}
.register-user form .btn-submit:hover, .register-user form .btn-submit:visited, .register-user form .btn-submit:active {
  color: #fff;
}
.register-user form .btn-submit:hover {
  background: #0068b0;
}
.register-user form .btn-cancel {
  padding: 12px;
  font-weight: 500;
  color: black;
  display: block;
  text-align: center;
  margin-top: 15px;
}

.search .page-title {
  display: none;
}

.search .paginator .p-page {
  font-size: 14px;
}

.search .paginator span.p-page {
  color: #337ab7;
}

.search .paginator a.p-page {
  padding: 10px;
  border: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.search .paginator a.p-page:hover {
  background: #337ab7;
  color: white;
}

.search .paginator .p-previous, .search .paginator .p-next {
  border: 0;
  font-size: 14px;
  padding: 10px 15px;
  margin: 0 40px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

@media screen and (max-width: 768px) {
  .search .paginator .p-previous, .search .paginator .p-next {
    margin: 0;
    padding: 10px;
    font-size: 12px;
  }
}
.search .paginator a.p-previous:hover,
.search .paginator a.p-next:hover {
  background: #337ab7;
  color: white;
}

.search .paginator .p-first, .search .paginator .p-last {
  display: none;
}

.search-form {
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .search-form {
    text-align: left;
  }
}
.search-form + p {
  text-align: center;
  margin: 15px 0;
}

.search input[type=text], .search select {
  padding: 8px 16px;
  border: 1px solid #dedede;
  line-height: 1;
  height: 37px;
}

@media screen and (max-width: 768px) {
  .search input[type=text], .search select {
    width: 100%;
    margin-bottom: 15px;
  }
}
.search input[type=text] {
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.search-label {
  display: inline-block;
  padding: 8px 0;
  float: none;
}

@media screen and (max-width: 768px) {
  .search-label {
    width: 100%;
  }
}
.search-submit {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
}
.search-submit:active, .search-submit:visited, .search-submit:focus {
  text-decoration: none;
  color: inherit;
}
.search-submit:hover {
  text-decoration: none;
  color: inherit;
}
.search-submit:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .search-submit {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .search-submit {
    width: 100%;
  }
}
.search-submit:hover, .search-submit:visited, .search-submit:active {
  color: #fff;
}
.search-submit:hover {
  background: #0068b0;
}

.content-nav {
  display: inline-block;
  margin-bottom: 15px;
}

.content-nav span {
  display: inline-block;
  padding: 4px 10px 8px;
  background: #f4f5f6;
}

.content-nav span i {
  color: #708090;
  font-size: 22px;
  -webkit-transform: translateY(6px);
  transform: translateY(6px);
  margin-right: 5px;
}

.content-nav span a, .content-nav span a:link {
  color: #3c4858 !important;
  font-weight: 500;
}

.content-nav--right {
  float: right;
  margin-top: 15px;
}

.search__title {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 30px;
  margin-top: 70px;
  margin-bottom: 15px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .search__title {
    font-size: 34px;
    line-height: 1.15;
  }
}

#searchPage {
  padding-bottom: 70px;
  font-weight: 300;
}
#searchPage h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.15;
  margin-top: 0;
  margin-bottom: 15px;
}
#searchPage h4 a {
  display: block;
}

.searchResult {
  border-bottom: 1px solid #f4f5f6;
  padding: 35px 0 !important;
  margin: 0;
  max-width: 66.6%;
}
@media screen and (max-width: 992px) {
  .searchResult {
    max-width: 100%;
  }
}
.searchResult .searchResultContent {
  margin: 0;
  padding: 0;
}
.searchResult .searchResultContent p {
  margin: 0;
}

.handbooks .row.page-title {
  display: none;
}

.handbooks #screenshotrulecontainer + .btn-large.hidden-xs {
  display: none;
}

.usergroup-table {
  max-width: 100%;
  width: 100%;
  text-align: center;
  border: 1px solid #dedede;
  border-collapse: collapse;
  margin-bottom: 30px;
}

.usergroup-table tr {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.usergroup-table tr:hover {
  background: #f4f5f6;
}

.usergroup-table td, .usergroup-table th {
  padding: 10px;
  border: 1px solid #dedede;
}

.usergroup-table td.text-left, .usergroup-table th.text-left {
  text-align: left;
}

.usergroup-table th {
  font-weight: 600;
  font-size: 12px;
  border-bottom: 3px solid rgba(0, 0, 0, 0.12);
}

.usergroup-ticket, .usergroup-votes {
  width: 10%;
  min-width: 80px;
}

.usergroup-role, .usergroup-update, .usergroup-scheduled {
  width: 15%;
  min-width: 120px;
}

.usergroup-description {
  min-width: 200px;
}

.section__subtitle {
  margin-top: 30px;
}

.pamwin--user-group h1.row {
  display: none;
}

/******************************************
SOR PRODUCT PAGE
******************************************/
.schedules .info__icon img {
  max-width: 64px;
  max-height: auto;
  width: 64px;
  height: auto;
}
@media screen and (max-width: 520px) {
  .schedules .info__icon {
    display: none;
  }
}
.schedules .info__text a {
  font-weight: 400;
}
.schedules .section-header__button {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .schedules .section-header__button {
    margin-bottom: 15px;
  }
}

.schedules .row.page-title, .modules .row.page-title, .pppv .row.page-title {
  display: none;
}
.schedules #screenshotrulecontainer + .btn-large.hidden-xs, .modules #screenshotrulecontainer + .btn-large.hidden-xs, .pppv #screenshotrulecontainer + .btn-large.hidden-xs {
  display: none;
}
.schedules #mainContent, .modules #mainContent, .pppv #mainContent {
  padding-bottom: 0;
}

.modules #mainContent .container {
  background: transparent;
}
.modules .tab-content {
  font-weight: 300;
  background: #fff;
  padding: 30px 50px;
  border-left: 1px solid #f4f5f6;
}
@media screen and (max-width: 992px) {
  .modules .tab-content {
    padding: 30px 25px;
  }
}
@media screen and (max-width: 768px) {
  .modules .tab-content {
    padding: 25px 0;
    margin-bottom: 30px;
    border-left: 0;
  }
}
.modules .tab-content li {
  line-height: 1.6;
}
.modules .tab-pane h2 {
  font-size: 28px;
  font-weight: 600;
  color: #3C4858;
  margin-top: 0;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .modules .tab-pane h2 {
    font-size: 24px;
  }
}
.modules .tab-pane h3 {
  color: #3C4858;
  font-size: 18px;
  font-weight: bold;
  margin: 50px 0 15px;
}
@media screen and (max-width: 768px) {
  .modules .tab-pane h3 {
    margin: 30px 0 15px;
  }
}
.modules .tab-pane h3:first-child {
  margin-top: 0px !important;
}
.modules .tab-pane img {
  max-width: 100%;
}

/* Alternative section blue */
.section--blue {
  background: #005996;
  display: none;
}
.section--blue .section__title, .section--blue .section__description {
  color: white;
}
.section--blue .card-plain .card-category {
  color: white;
}
.section--blue .card-plain .card-title {
  color: white;
}
.section--blue .card-plain ul li, .section--blue .card-plain b {
  color: white;
}

.document-list {
  list-style: none;
  margin-top: 15px;
  padding-left: 25px;
  font-size: 14px;
}
.document-list li {
  margin-bottom: 15px;
  padding-left: 15px;
  position: relative;
}
.document-list li:before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  background: url("/content/images/icons/word.svg") no-repeat;
  background-size: 20px;
  left: -15px;
  top: 1px;
}

/******************************************
SOR Request, Approval, and Download Page
******************************************/
.form__heading {
  margin-top: 40px;
  margin-bottom: 15px;
}

.form__table label {
  display: inline-block;
  max-width: calc(100% - 30px);
  float: none;
}
.form__table-row {
  margin-top: 15px;
  margin-bottom: 15px;
}
.form__table input {
  display: inline-block;
}
.form__table-header {
  font-weight: 600;
}
.form__table .label-wrap {
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  .form__table .label-wrap {
    text-align: left;
  }
}
.form__table-row {
  border-bottom: 1px solid #eee;
  padding-top: 5px;
  padding-bottom: 5px;
}
.form__table-row:last-child {
  border-bottom: 0;
}
@media screen and (max-width: 768px) {
  .form__table-row {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.form__table-note {
  background: #f4f5f6;
  padding: 30px;
  border-radius: 2px;
  margin: 40px 0;
}
.form__table-note a:link, .form__table-note a:visited, .form__table-note a:focus {
  color: black;
}
@media screen and (max-width: 768px) {
  .form__table-note {
    padding: 15px;
    margin: 30px 0;
  }
}
.form__table .col-version {
  font-weight: 400;
  background: #f4f5f6;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .form__table .col-schedule {
    font-weight: 500;
  }
}
.form__table .btn__download--small {
  padding: 5px 18px;
  color: white;
  background: #8BC34A;
  font-weight: 500;
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s ease;
  appearance: none !important;
  border: 0;
  font-size: 12px;
}
.form__table .btn__download--small:hover {
  background: #97c95d;
}
.form__table .nolicence {
  color: #de2e2e;
}

.section-landing--schedules .flex-grid-4, .section-landing--schedules .flex-grid-3, .section-landing--schedules .flex-grid-2, .section-landing--schedules .flex-grid {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 0;
  margin-bottom: 0;
}
.section-landing--schedules .flex-grid {
  display: flex;
}
.section-landing--schedules .item {
  margin-bottom: 30px;
  padding: 30px;
  position: relative;
  background: #f9f9f9;
  min-height: 285px;
}
@media screen and (max-width: 768px) {
  .section-landing--schedules .item {
    min-height: 140px;
  }
}
.section-landing--schedules .item--highlight {
  background: #102542;
  color: white;
}
.section-landing--schedules .item--highlight a {
  color: white !important;
}
.section-landing--schedules .item--highlight .item__details, .section-landing--schedules .item--subhighlight .item__details {
  min-height: 290px;
}
@media screen and (max-width: 768px) {
  .section-landing--schedules .item--highlight .item__details, .section-landing--schedules .item--subhighlight .item__details {
    min-height: 140px;
  }
}
.section-landing--schedules .item__image-container {
  display: flex;
  align-items: flex-start;
  position: relative;
  min-width: 64px;
  margin-bottom: 15px;
}
.section-landing--schedules .item__image-container img {
  max-width: 100%;
  width: 64px;
  margin-right: 10px;
}
.section-landing--schedules .item.corporate:after, .section-landing--schedules .item.small-ha:after {
  display: inline-block;
  background: #102542;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  top: -10px;
  right: 0;
  line-height: 1;
  padding: 6px 12px;
}
.section-landing--schedules .item h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.15;
  margin-top: 0;
  margin-bottom: 15px;
}
.section-landing--schedules .item.corporate:after {
  content: "For corporate buildings";
}
.section-landing--schedules .item.small-ha:after {
  content: "For smaller HAs";
}
.section-landing--schedules .item__details {
  margin-bottom: 30px;
}
.section-landing--schedules .item__links {
  margin-top: auto;
  margin-bottom: 0;
  padding-top: 25px;
  position: absolute;
  bottom: 30px;
}
.section-landing--schedules .item__links a {
  font-size: 16px;
  color: #3c4858;
  color: initial;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: 400;
  display: inline-block;
  margin: 0 10px 0 0;
}
.section-landing--schedules .item__links a:active, .section-landing--schedules .item__links a:visited, .section-landing--schedules .item__links a:focus {
  text-decoration: none;
  color: initial;
}
.section-landing--schedules .item__links a:hover {
  text-decoration: none;
  color: inherit;
}
.section-landing--schedules .item__links a:first-child {
  margin-left: 0;
  padding-right: 10px;
  border-right: 1px solid;
}
.section-landing--schedules .col-1 {
  flex: 1;
}
.section-landing--schedules .col-2 {
  flex: 2;
}
.section-landing--schedules .col-3 {
  flex: 3;
}
.section-landing--schedules .col-4 {
  flex: 4;
}
.section-landing--schedules .col-1, .section-landing--schedules .col-2, .section-landing--schedules .col-3, .section-landing--schedules .col-4 {
  display: block;
}
.section-landing--schedules .col {
  display: block;
}
.section-landing--schedules h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.15;
  margin-top: 0;
  margin-bottom: 15px;
}

.section-header--sor {
  max-height: 940px;
  background: url(/images/dmImage/StandardImage/banner-sor.jpg) left top no-repeat;
  position: relative;
  background-size: cover;
}
.section-header--sor:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #020024;
  background: linear-gradient(0deg, rgba(2, 0, 36, 0.8295693277) 0%, rgba(0, 0, 0, 0) 70%);
}

#section-sorfeature {
  background: url(/images/bg-sor-1.png) no-repeat;
  background: url(/images/bg-sor.png) no-repeat;
  background-size: cover;
  background-position: 50%;
  position: relative;
}

/*************************************************/
/* LITE */
/*************************************************/
.lite .row.page-title {
  display: none;
}

.lite #screenshotrulecontainer + .btn-large.hidden-xs {
  display: none;
}

.lite #mainContent {
  padding-bottom: 0;
  margin-bottom: -25px;
}

@media screen and (max-width: 768px) {
  .lite #mainContent {
    margin-bottom: 0;
  }
}
.lite .section-header {
  max-height: 740px;
}

.lite .section-header__image {
  width: 880px;
  -webkit-box-shadow: -1px -1px 120px rgba(0, 0, 0, 0.2);
  box-shadow: -1px -1px 120px rgba(0, 0, 0, 0.2);
  padding: 0;
}

.lite .section-main-features img {
  -webkit-box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.01), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.01), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.lite .section-compare-features {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}

.demo-lite .row.page-title {
  display: none;
}

.demo-lite #screenshotrulecontainer + .btn-large.hidden-xs {
  display: none;
}

.demo-lite #mainContent {
  padding-bottom: 0;
  margin-bottom: -25px;
}

@media screen and (max-width: 768px) {
  .demo-lite #mainContent {
    margin-bottom: 0;
  }
}
.section__definition {
  background: #f4f5f6;
  padding: 30px 15px 15px;
  margin-top: 30px;
}
.section__definition:before, .section__definition:after {
  content: "";
  display: table;
}
.section__definition:after {
  clear: both;
}
@media screen and (max-width: 768px) {
  .section__definition {
    padding: 30px 15px 15px;
  }
}
.section__definition-item {
  margin-bottom: 15px;
}

.section__highlight {
  background: #f4f5f6;
  padding: 30px;
  margin-top: 50px;
}
@media screen and (max-width: 768px) {
  .section__highlight {
    padding: 30px 15px;
  }
}
.section__highlight-heading {
  margin: 0 0 15px 0;
}
.section__highlight-content {
  margin-bottom: 15px;
}
.section__highlight-content:last-of-type {
  margin-bottom: 0;
}

.link--inline {
  transition: all 0.2s ease;
  font-weight: 400;
}
.link--inline:link, .link--inline:visited, .link--inline:active {
  color: black;
}

.section__alert {
  padding: 5px;
  margin: 5px 0;
  display: inline-block;
  color: white;
  line-height: 1;
  font-size: 12px;
}
.section__alert--success {
  background: #8BC34A;
}
.section__alert--warning {
  background: #FF6969;
}

.contact__form--demo .contact__heading {
  padding-top: 40px;
  margin-top: 40px;
  border-top: 1px solid #ddd;
}
.contact__form--demo .contact__heading:first-child {
  border: 0;
  margin-top: 0;
}
.contact__form--demo .attendees .contact__field {
  margin-bottom: 0;
}
.contact__form--demo .attendees .remove {
  width: 26px;
  height: 26px;
  margin-top: 2px;
  display: inline-block;
}
.contact__form--demo .attendees .remove i {
  cursor: pointer;
}
.contact__form--demo input[type=submit] {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  margin: 0 auto;
  width: 100%;
  max-width: 200px;
  display: block;
  padding: 18px 40px;
  font-weight: 500;
}
.contact__form--demo input[type=submit]:active, .contact__form--demo input[type=submit]:visited, .contact__form--demo input[type=submit]:focus {
  text-decoration: none;
  color: inherit;
}
.contact__form--demo input[type=submit]:hover {
  text-decoration: none;
  color: inherit;
}
.contact__form--demo input[type=submit]:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .contact__form--demo input[type=submit] {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .contact__form--demo input[type=submit] {
    width: 100%;
  }
}
.contact__form--demo input[type=submit]:hover, .contact__form--demo input[type=submit]:visited, .contact__form--demo input[type=submit]:active {
  color: #fff;
}
.contact__form--demo input[type=submit]:hover {
  background: #0068b0;
}
.contact__form--demo .required:after {
  color: rgba(0, 0, 0, 0.4);
  line-height: 1.1;
  font-size: 14px;
}
.contact__form--demo .contact__field .checkbox-wrapper label:has(input[type=checkbox]:checked)::after {
  display: block;
}
.contact__form--demo .contact__field .checkbox-wrapper {
  float: right;
  width: 50%;
  margin-top: 10px;
}
.contact__form--demo .contact__field.mb0 {
  margin-bottom: 0;
}
.contact__form--demo .checkbox-wrapper {
  margin-bottom: 0;
}
.contact__form--demo .note-block {
  color: inherit;
  font-size: 13px;
  line-height: 1.2;
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
  font-style: italic;
  transform: translateY(-15px);
}
.contact__form--demo label[for=termsandconditions] {
  font-weight: 400;
  line-height: 1.5;
  padding-right: 24px;
}
.contact__form--demo label[for=cancellationterms] {
  font-weight: 400;
  line-height: 1.5;
}
.contact__form--demo label[for=cancellationterms] ul {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.4;
  margin-top: 10px;
}

/*************************************************/
/* DEMO FORM GENERAL */
/*************************************************/
.section-demo {
  background: #f9f9f9;
  font-family: "Roboto", "sans-serif";
  font-weight: 300;
  font-size: 16px;
}

.demo-form {
  padding: 50px;
  background: #fafafa;
  border-radius: 3px;
}

@media screen and (max-width: 768px) {
  .demo-form {
    padding: 30px 15px;
    margin-top: 30px;
  }
}
.demo-form__list {
  padding: 0;
  margin: 30px 0;
  list-style-type: none;
}

.demo-form__list li {
  margin-bottom: 10px;
}

.demo-form__list + .section__button {
  margin: 0;
}

.demo-form .form-group {
  font-size: 14px;
  height: 36px;
}

.demo-form .error {
  color: indianred;
  font-size: 12px;
}

.demo-form label {
  margin: 0;
  line-height: 1.4;
  color: black;
  font-weight: 500;
  margin-bottom: 5px;
}

.demo-form input[type=text] {
  width: 100%;
  display: block;
  border: 1px solid #d2d2d2;
  border: 1px solid #0000002b;
  height: 38px;
  padding: 8px 12px;
  font-size: 14px;
}

.demo-form input[type=checkbox] {
  margin-right: 10px;
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}

.demo-form input[type=checkbox] + span {
  font-weight: 500;
}

/*************************************************/
/* CONTACT US                                    */
/*************************************************/
.contact-us .page-title {
  display: none;
}
.contact-us #mainContent {
  padding-bottom: 0;
}

.section-contact--faq .info {
  padding: 10px 0;
}
.section-contact--faq .info__icon img {
  width: 80px;
  height: 80px;
  transform: translateY(-22px);
}

.info__anchor {
  font-weight: 700;
  font-size: 14px;
  color: #3C4858;
  display: block;
  padding-top: 40px;
}
.info__anchor i {
  vertical-align: bottom;
}

.honey-pot {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.contact__form .message {
  background: #de2e2e;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: white;
  padding: 4px;
  border-radius: 2px;
  display: none;
  float: left;
  margin-top: 2px;
}
.contact__form .message.error {
  display: inline-block;
}
.contact__form--padded {
  padding: 30px 0;
}
.contact__heading {
  color: #91969dd9;
  font-size: 14px;
  font-weight: 500;
  margin-top: 60px;
  margin-bottom: 20px;
}
.contact__field {
  margin-bottom: 20px;
  display: block;
  clear: both;
  position: relative;
}
@media screen and (max-width: 768px) {
  .contact__field {
    margin-bottom: 30px;
  }
}
.contact__field abbr {
  border: 0;
  color: rgba(0, 0, 0, 0.4);
  line-height: 1.1;
  text-decoration: none;
  vertical-align: bottom;
}
.contact__field label, .contact__field .contact__label {
  color: #3C4858;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  padding: 10px 0;
  margin: 0;
  width: 100%;
  vertical-align: top;
  line-height: 1.4;
}
@media screen and (max-width: 768px) {
  .contact__field label, .contact__field .contact__label {
    padding: 0;
    margin-bottom: 5px;
  }
}
.contact__field input, .contact__field select, .contact__field textarea {
  border: 1px solid #dedede;
  background-color: #fcfcfc;
  color: black;
  display: inline-block;
  font-weight: 300;
  line-height: 1.5;
  padding: 12px 12px 10px;
  width: 100%;
}
.contact__field textarea {
  min-height: 144px;
}
.contact__field select {
  background: transparent;
  width: 100%;
  border: 0;
  margin: 0;
  appearance: none;
  padding: 12px 36px 10px 12px;
}
.contact__field select::-ms-expand {
  display: none;
}
.contact__field .select-wrapper {
  background: url(/content/images/icons/arrow_down.svg);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 20px;
  border: 1px solid #dedede;
  background-color: #fcfcfc;
  overflow: hidden;
  width: 100%;
}
.contact__field input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
  z-index: 100;
}
.contact__field input[type=checkbox]:hover ~ label:before {
  background-color: #ccc;
}
.contact__field input[type=checkbox]:checked ~ label:after {
  display: block;
}
.contact__field .checkbox-wrapper {
  display: inline-block;
  position: relative;
  user-select: none;
  margin-top: 15px;
}
@media screen and (max-width: 768px) {
  .contact__field .checkbox-wrapper {
    margin-top: 15px;
  }
}
.contact__field .checkbox-wrapper label {
  padding-left: 30px;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 500;
  font-size: 14px;
}
.contact__field .checkbox-wrapper label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 2px;
  transition: all 0.2s ease;
}
.contact__field .checkbox-wrapper label:after {
  content: "";
  display: none;
  position: absolute;
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.contact__field .checkbox-label {
  margin-top: 5px;
}
.contact__field .checkbox-container {
  float: right;
  width: 50%;
}
@media screen and (max-width: 768px) {
  .contact__field .checkbox-container {
    width: 100%;
  }
}
.contact__field .help-block {
  padding: 15px;
  background: #f4f5f6;
  border: 1px solid #f1f1f1;
  line-height: 1.6;
}
.contact__field--inline label {
  width: 50%;
  float: left;
  padding-right: 15px;
}
@media screen and (max-width: 768px) {
  .contact__field--inline label {
    width: 100%;
    float: none;
  }
}
.contact__field--inline input, .contact__field--inline select, .contact__field--inline textarea {
  width: 50%;
  float: left;
  height: auto;
}
@media screen and (max-width: 768px) {
  .contact__field--inline input, .contact__field--inline select, .contact__field--inline textarea {
    width: 100%;
    float: none;
  }
}
.contact__field--inline input[type=radio] {
  width: 20px;
  margin-left: -5px;
}
.contact__field--inline input[type=radio] + span {
  padding-left: 20px;
}
.contact__field--inline .radio-inline {
  width: calc(25% - 1px);
  float: left;
  margin-left: 0;
}
.contact__field--inline .select-wrapper {
  float: left;
  width: auto;
  max-width: 50%;
}
@media screen and (max-width: 768px) {
  .contact__field--inline .select-wrapper {
    max-width: 100%;
  }
}
.contact__field--inline .select-wrapper select {
  width: 100%;
}
.contact__field--terms:before, .contact__field--terms:after {
  content: "";
  display: table;
}
.contact__field--terms:after {
  clear: both;
}
.contact__info {
  padding: 40px 30px;
  background: #f4f5f6;
  border-radius: 4px;
  margin-top: 38px;
}
@media screen and (max-width: 768px) {
  .contact__info {
    padding: 15px;
    margin-bottom: 15px;
  }
}
.contact__info-link:link, .contact__info-link:focus, .contact__info-link:visited {
  color: black;
  font-weight: 400;
  text-decoration: underline;
}
.contact__actions {
  padding-top: 15px;
}
.contact__actions:before, .contact__actions:after {
  content: "";
  display: table;
}
.contact__actions:after {
  clear: both;
}
@media screen and (max-width: 768px) {
  .contact__actions {
    padding-top: 0;
  }
}
.contact__actions .recaptcha-container {
  float: right;
}
@media screen and (max-width: 768px) {
  .contact__actions .recaptcha-container {
    float: none;
  }
}
.contact__actions .recaptcha-container .message {
  float: right;
}
@media screen and (max-width: 768px) {
  .contact__actions .recaptcha-container .message {
    display: inline-block;
    float: none;
    margin-left: 0px;
    margin-bottom: 10px;
  }
}
.contact__actions .g-recaptcha {
  transform: scale(0.84);
  margin-right: -25px;
  display: block;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .contact__actions .g-recaptcha {
    margin-left: -25px;
  }
}
.contact__actions--inline {
  text-align: center;
}
.contact__actions--inline .contact__submit {
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .contact__actions--inline .contact__submit {
    display: block;
  }
}
.contact__actions--inline .contact__submit input[type=submit] {
  min-width: 200px;
  max-width: 100%;
  margin: 15px 0;
}
.contact__submit input[type=submit] {
  appearance: none;
  background: #005996;
  border: 0;
  color: white;
  float: none;
  font-weight: 500;
  padding: 18px 40px;
  margin-top: 5px;
  width: 100%;
  max-width: 240px;
  transition: all 0.2s ease;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .contact__submit input[type=submit] {
    max-width: 100%;
  }
}
.contact__submit input[type=submit]:hover, .contact__submit input[type=submit]:active {
  background: #0068b0;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.contact__submit input[type=submit].full {
  max-width: 100%;
}
.contact__submit + p {
  margin-top: 30px;
}

.checkbox-container .checkbox-wrapper {
  display: block;
}

.checkbox-wrapper {
  position: relative;
  margin-bottom: 15px;
}
.checkbox-wrapper:before, .checkbox-wrapper:after {
  content: "";
  display: table;
}
.checkbox-wrapper:after {
  clear: both;
}
.checkbox-wrapper input[type=checkbox] {
  position: absolute;
  left: 0;
  opacity: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
  z-index: 100;
}
.checkbox-wrapper input[type=checkbox]:checked ~ label:after {
  display: block;
}
.checkbox-wrapper label {
  width: 100% !important;
  font-weight: 300 !important;
  font-size: 14px;
  margin-right: 0;
  margin-left: 0;
  padding-left: 30px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
}
.checkbox-wrapper label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border: 1px solid #cdcdcd;
  border-radius: 2px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.checkbox-wrapper label:after {
  content: "";
  display: none;
  position: absolute;
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.dropdown-wrapper {
  position: relative;
  margin-bottom: 15px;
  border: 1px solid #d1d1d1;
  overflow: hidden;
}
.dropdown-wrapper select {
  border: 0;
  color: #172b46;
  padding: 12px 12px 10px;
  font-weight: 300;
  outline: 0;
  width: calc(100% + 40px);
  appearance: none;
  background-color: #fff;
  background-image: url(/content/images/icons/ico-caret-down-thin.svg);
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: right 50px center;
}

.privacy-notice {
  margin-top: 15px;
  padding: 0;
  margin-bottom: 15px;
  padding: 40px;
  background: rgba(0, 0, 0, 0.03);
}
.privacy-notice:before, .privacy-notice:after {
  content: "";
  display: table;
}
.privacy-notice:after {
  clear: both;
}
.privacy-notice .checkbox-wrapper:last-of-type {
  margin-bottom: 0;
}
.privacy-note {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}

.form__message {
  padding: 20px;
  color: white;
  position: fixed;
  width: 100%;
  max-width: 1600px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 10000;
  animation: fadeInAndOut 8s forwards;
  font-size: 16px;
  font-weight: 400;
}
.form__message--success {
  background: #8BC34A;
}
.form__message--warning {
  background: goldenrod;
}
.form__message--error {
  background: #de2e2e;
}
.form__message .material-icons {
  margin-right: 6px;
  transform: translateY(4px);
}

@keyframes fadeInAndOut {
  0% {
    opacity: 0;
  }
  8% {
    opacity: 1;
  }
  94% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.form__table {
  margin-top: 30px;
  counter-reset: session;
}
.form__table .table td,
.form__table .table th {
  padding: 15px 5px 15px 0;
  line-height: 1.5;
}
.form__table .table .centre {
  font-size: 12px;
  width: 60px;
  text-align: center;
}
.form__table .table .centre input {
  float: none;
}
@media screen and (max-width: 768px) {
  .form__table.table-responsive {
    border: 0;
  }
}
.form__table .contact__heading {
  margin-top: 0;
}
.form__table input[type=text],
.form__table input[type=email] {
  background: #fcfcfc;
  border: 1px solid #dedede;
  padding: 4px 6px;
  font-weight: 400;
}
.form__table input[type=text]:focus, .form__table input[type=text]:active,
.form__table input[type=email]:focus,
.form__table input[type=email]:active {
  background: white;
}
.form__table input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #dedede;
}
.form__table input::-moz-placeholder {
  /* Firefox 19+ */
  color: #dedede;
}
.form__table input:-ms-input-placeholder {
  /* IE 10+ */
  color: #dedede;
}
.form__table input :-moz-placeholder {
  /* Firefox 18- */
  color: #dedede;
}
.form__table .trainingsessionnumber::before {
  counter-increment: session;
  content: "S#" counter(session) " ";
  padding: 4px;
  background: #eaeaea;
  margin-right: 4px;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
}

.link--add {
  display: inline-block;
  padding: 18px 50px;
}
.link--add:link, .link--add:hover, .link--add:active, .link--add:visited {
  text-decoration: none;
  color: #3C4858;
  font-weight: 500;
  transition: all 0.2s ease;
}
.link--add:hover {
  color: black;
}
.link--add i {
  vertical-align: text-bottom;
  transform: translateY(2px);
}

.checkout-table {
  margin-bottom: 60px;
  margin-top: 30px;
  table-layout: fixed;
  width: 100%;
  border: 1px solid #ccc;
}
@media screen and (max-width: 768px) {
  .checkout-table {
    table-layout: auto;
  }
}
.checkout-table th {
  background: #f4f5f6;
}
.checkout-table td, .checkout-table th {
  padding: 20px !important;
}
.checkout-table td:first-of-type, .checkout-table th:first-of-type {
  width: 50%;
}
@media screen and (max-width: 992px) {
  .checkout-table td:first-of-type, .checkout-table th:first-of-type {
    width: 40%;
  }
}
.checkout-table td:last-of-type, .checkout-table th:last-of-type {
  text-align: right;
}
.checkout-table tr {
  border-bottom: 1px solid #dedede;
}
.checkout-table__logo {
  max-width: 40px;
  width: 100%;
  height: auto;
  margin-right: 10px;
}

.form__terms {
  padding: 30px;
  background: #f4f5f6;
}
.form__terms label:before {
  background-color: #fff;
}
.form__terms .checkbox-wrapper {
  margin-bottom: 0px;
}
.form__terms .form__field {
  padding-bottom: 10px;
}
.form__terms .form__field a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: 500;
}
.form__terms .form__field a:active, .form__terms .form__field a:visited, .form__terms .form__field a:focus {
  text-decoration: none;
  color: inherit;
}
.form__terms .form__field a:hover {
  text-decoration: none;
  color: inherit;
}

.sagepay .baseform {
  margin-bottom: 15px;
}
.sagepay .baseform label {
  text-align: left !important;
}
.sagepay .addresslink {
  margin-top: 40px;
  margin-bottom: 40px;
}
.sagepay legend {
  margin-top: 0;
  padding-top: 0;
}
.sagepay__cards {
  margin-top: 40px;
}
.sagepay__cards img {
  margin-bottom: 15px;
  margin-left: 15px;
}
.sagepay #orderform table {
  border: 1px solid #dedede;
}
.sagepay #orderform td, .sagepay #orderform th {
  padding: 10px;
  border: 1px solid #dedede;
}
.sagepay .action {
  margin: 20px 0;
  text-align: right;
}
.sagepay .orderformbutton {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 5px;
  display: inline-block;
  font-size: 16px;
}
.sagepay .orderformbutton:active, .sagepay .orderformbutton:visited, .sagepay .orderformbutton:focus {
  text-decoration: none;
  color: inherit;
}
.sagepay .orderformbutton:hover {
  text-decoration: none;
  color: inherit;
}
.sagepay .orderformbutton:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .sagepay .orderformbutton {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .sagepay .orderformbutton {
    width: 100%;
  }
}
.sagepay .orderformbutton:hover, .sagepay .orderformbutton:visited, .sagepay .orderformbutton:active {
  color: #fff;
}
.sagepay .orderformbutton:hover {
  background: #0068b0;
}
@media screen and (max-width: 992px) {
  .sagepay .orderformbutton {
    margin-top: 0;
    margin-bottom: 15px;
  }
}
.sagepay .orderformbutton--light {
  background: #eee !important;
  color: #3C4858 !important;
}

.section__video {
  margin-top: 30px;
}
.section__video .col {
  background: #f9f9f9;
}

.video {
  background: #fafafa;
  margin-bottom: 30px;
}
.video__container {
  background: #eaeaea;
  display: flex;
  justify-content: center;
  padding: 30px;
}
.video__container iframe {
  max-width: 100%;
}
.video__details {
  padding: 30px;
}

.section__guides {
  margin-top: 30px;
  border: 5px solid red;
}

.guide {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #dedede;
  border-right: 1px solid #dedede;
  margin-top: 40px;
}
.guide__item {
  display: flex;
}
.guide__item a {
  display: flex;
  align-items: center;
  color: #3C4858;
  text-decoration: none;
  border-bottom: 1px solid #dedede;
  border-left: 1px solid #dedede;
  padding: 5px;
  padding: 15px;
}
.guide__item a:hover, .guide__item a:active, .guide__item a:focus {
  text-decoration: none;
  background: #f4f5f6;
  transition: background 0.2s ease;
}
.guide__link {
  flex: 1;
}
.guide__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3C4858;
  border-right: 2px solid #e7e7e7;
}
.guide__title {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
  flex: 1;
}
.guide__date {
  background: #f4f5f6;
  padding: 2px;
  margin: 0 10px;
  font-size: 12px;
  float: right;
}
@media screen and (max-width: 768px) {
  .guide__date {
    text-align: center;
    max-width: 90px;
  }
}

.section-report {
  border-bottom: 0;
}
.section-report .row {
  margin-bottom: 0;
}

.section__tools {
  padding-top: 0;
}

.privacy-message__overlay {
  background: rgba(0, 0, 0, 0.85);
  bottom: 0;
  left: 0;
  color: #fff;
  position: fixed;
  width: 100%;
  z-index: 10000000000;
}
@media screen and (max-width: 768px) {
  .privacy-message__overlay {
    background: rgba(0, 0, 0, 0.92);
  }
}
.privacy-message__content {
  display: block;
  max-width: 1280px;
  margin: 0 auto;
  border-radius: 0;
  padding-top: 16px;
  padding-bottom: 32px;
  font-weight: 300;
  font-size: 15px;
  position: relative;
}
.privacy-message__content h2 {
  font-size: 21px;
  line-height: 30px;
  font-weight: 300;
  color: white;
  font-family: "Open sans", sans-serif;
  margin-top: 16px;
}
.privacy-message__close {
  background-position-x: 0;
  background-position-y: 0;
  background-image: url(/content/images/icons/close-2.svg);
  background-repeat: no-repeat;
  cursor: pointer;
  display: block;
  height: 16px;
  position: absolute;
  text-indent: -9999px;
  top: 32px;
  right: 15px;
  width: 16px;
}
.privacy-message__link {
  color: white !important;
}
.privacy-message__link:link, .privacy-message__link:active, .privacy-message__link:hover, .privacy-message__link:focus {
  color: white;
  text-decoration: underline;
}

.brand__header {
  background: #005996;
  padding: 120px 40px;
}
.brand__title {
  color: #fff;
  text-align: center;
  font-size: 58px;
  margin-bottom: 10px;
}
.brand__subtitle {
  color: #fff;
  font-size: 24px;
  line-height: 1.25;
  text-align: center;
  margin-bottom: 0;
}
.brand .aside__heading {
  font-weight: bold;
  color: #102542;
}
.brand .aside__link {
  border-bottom: 0;
}
.brand .thumb__container {
  background: #f4f5f6;
  padding: 20px 20px 25px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.brand .thumb__container img {
  max-width: 100%;
  height: auto;
}
.brand .thumb__container:hover .brand__download {
  visibility: visible;
  opacity: 1;
  top: 66.6%;
}
.brand .thumb__label {
  position: absolute;
  bottom: 5;
  left: 0;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  display: block;
  color: rgba(0, 0, 0, 0.5);
}
.brand__download {
  position: absolute;
  top: 100%;
  bottom: 0;
  width: 100%;
  left: 0;
  transition: all 0.18s ease-out;
  background: #102542;
  background: rgba(23, 45, 70, 0.98);
  visibility: hidden;
  opacity: 0;
}
.brand__download-item {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: bold;
  font-size: 14px;
  color: #bbb !important;
  position: relative;
  display: inline-block;
  padding: 15px 5px 15px 30px;
  transition: background 0.18s ease;
}
.brand__download-item:active, .brand__download-item:visited, .brand__download-item:focus {
  text-decoration: none;
  color: inherit;
}
.brand__download-item:hover {
  text-decoration: none;
  color: inherit;
}
.brand__download-item:hover {
  color: white !important;
}
.brand__download-item:before {
  content: "";
  background: url(/content/images/icons/dl-3.svg) 50% no-repeat;
  background-size: contain;
  display: inline-block;
  height: 30px;
  left: 0px;
  position: absolute;
  top: 46%;
  transform: translateY(-50%);
  width: 30px;
}
.brand__content {
  padding: 40px 0;
}
.brand__content p {
  font-size: 18px;
}
.brand__content:first-child {
  padding-top: 0;
}
.brand__content h2 {
  margin-bottom: 30px;
}
.brand__content h3 {
  font-weight: bold;
  font-size: 22px;
}
.brand__content .extra-large {
  font-size: 58px;
}
.brand__content .large {
  font-size: 48px;
}
.brand__content .normal {
  font-size: 28px;
}
.brand__content .small {
  font-size: 22px;
}
.brand__content h1, .brand__content h2, .brand__content h3, .brand__content h4 {
  font-weight: bold;
  color: #102542;
  margin: 15px 0;
}
.brand__content .code {
  padding: 20px;
  background: #f4f5f6;
  margin-bottom: 30px;
}
.brand__content .code span {
  display: block;
}
.brand__content .margin {
  background: rgba(255, 0, 0, 0.1);
  list-style: none;
  padding: 15px 60px;
  margin: 0 0 0 -20px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
}
.brand__content .highlight {
  font-weight: bold;
}
.brand__content .inline-link {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: 400;
  color: #102542;
  border-bottom: 2px solid;
  transition: all 0.2s ease;
}
.brand__content .inline-link:active, .brand__content .inline-link:visited, .brand__content .inline-link:focus {
  text-decoration: none;
  color: inherit;
}
.brand__content .inline-link:hover {
  text-decoration: none;
  color: inherit;
}
.brand__content .inline-link:hover {
  border-bottom: 3px solid;
}
.brand .colour {
  box-shadow: 0 20px 30px 0 rgba(0, 46, 91, 0.22);
  margin-bottom: 30px;
}
.brand .colour .colour__container div {
  width: 100%;
  height: 140px;
}
.brand .colour .m3blue {
  background: #005996;
}
.brand .colour .m3midblue {
  background: #5B80B2;
}
.brand .colour .m3lightblue {
  background: #A2B2D2;
}
.brand .colour .white {
  background: #fff;
}
.brand .colour .lightgray {
  background: #f4f5f6;
  border-bottom: 1px solid #dedede;
}
.brand .colour .darkblue {
  background: #102542;
}
.brand .colour .pamwin-green {
  background: #54b948;
}
.brand .colour .pamwinlite-green {
  background: #b0bc22;
}
.brand .colour .central-red {
  background: #ed174f;
}
.brand .colour .sor-blue {
  background: #0096d6;
}
.brand .colour .hex, .brand .colour .rgb, .brand .colour .cmyk {
  position: relative;
  cursor: pointer;
}
.brand .colour .hex:after, .brand .colour .rgb:after, .brand .colour .cmyk:after {
  content: attr(class);
  background: #102542;
  font-size: 12px;
  position: absolute;
  left: 90%;
  padding: 3px 6px;
  border-radius: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  opacity: 0;
  transition: opacity 0.2s;
  text-transform: uppercase;
}
.brand .colour .hex:hover:after, .brand .colour .rgb:hover:after, .brand .colour .cmyk:hover:after {
  opacity: 1;
}
.brand .colour__description {
  text-align: center;
  padding: 10px;
  background: #f4f5f6;
}
.brand .colour__description h4 {
  font-weight: bold;
}
.brand .colour__description span {
  display: block;
}

.faqanswer table {
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
}
.faqanswer table td, .faqanswer table th {
  padding: 15px;
  border: 1px solid #ddd;
}

.faq h5 {
  margin: 0;
}
.faq__question {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  display: block;
  padding: 20px 60px 20px 20px;
  background-color: #f4f5f6;
  font-size: 16px;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 4px;
  background-image: url(/content/images/icons/ico-caret-down-thin.svg);
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: right 20px center;
  transition: background 0.2s ease;
}
.faq__question:active, .faq__question:visited, .faq__question:focus {
  text-decoration: none;
  color: inherit;
}
.faq__question:hover {
  text-decoration: none;
  color: inherit;
}
.faq__question:hover, .faq__question:active, .faq__question:focus {
  background-color: #e6e8eb;
}
.faq__answer {
  padding: 20px;
  border: 1px solid #eaeaea;
  margin-bottom: 30px;
}
.faq__answer a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  display: inline-block;
}
.faq__answer a:active, .faq__answer a:visited, .faq__answer a:focus {
  text-decoration: none;
  color: inherit;
}
.faq__answer a:hover {
  text-decoration: none;
  color: inherit;
  border-bottom: 2px solid #005996;
}
.faq__answer table {
  border-collapse: collapse;
  width: 100%;
  max-width: 80%;
}
.faq__answer table td {
  border: 1px solid #eaeaea;
  padding: 15px;
}
.faq__answer table td p {
  margin: 0;
}
.faq__answer table tr:nth-child(odd) {
  background: #f4f5f6;
}
.faq .rulecontent + .faq__title {
  margin-top: 90px;
}
.faq__title {
  margin-bottom: 20px;
  font-size: 24px;
}
.faq__sidebar {
  padding: 0;
  background: transparent;
}
.faq + .faq {
  margin-top: 80px;
}

.about__intro {
  background-color: #005996;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  text-align: center;
}
.about__intro__title {
  color: #fff;
  text-align: center;
  font-size: 58px;
  margin-bottom: 10px;
}
.about__intro__description {
  color: #fff;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.25;
  text-align: center;
  margin-bottom: 0;
}
.about__content {
  max-width: 740px;
}
.about__content h1:first-child,
.about__content h2:first-child,
.about__content h3:first-child,
.about__content h4:first-child,
.about__content p:first-child {
  margin-top: 0;
}
.about__content h1:first-child + p,
.about__content h2:first-child + p,
.about__content h3:first-child + p,
.about__content h4:first-child + p,
.about__content p:first-child + p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
}
.about__content h1, .about__content h2, .about__content h3, .about__content h4, .about__content h5 {
  font-weight: bold;
  line-height: 1.3;
}
.about__content h3 {
  font-size: 22px;
}
.about__content img {
  max-width: 100%;
  width: 100%;
}
.about__content table {
  padding: 0;
  margin: 50px 0;
  border: 0;
  width: 100%;
  max-width: 100%;
}
.about__content table th, .about__content table td {
  border-collapse: collapse;
  border: 1px solid #dedede;
}
.about__content table th {
  background: #f4f5f6;
  font-weight: bold;
  color: #102542;
  font-size: 14px;
  padding: 20px 15px;
}
.about__content table td {
  padding: 15px;
  font-weight: 300;
  font-size: 16px;
}
.about__content a {
  font-weight: 500;
}
.about__content hr {
  margin-top: 34px;
  margin-bottom: 34px;
}
.about__content .default-btn:link, .about__content .default-btn:active, .about__content .default-btn:visited, .about__content .default-btn:focus {
  color: #fff;
}
.about__aside strong {
  font-size: 14px;
  font-weight: bold;
}
.about__aside .social {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
}
.about__aside .social svg {
  max-width: 24px;
  max-height: 24px;
  margin-left: 10px;
  transition: all 0.2s ease;
}
.about__aside .social svg.linkedin {
  transform: translateY(-2px);
}
.about__aside .social svg:hover path {
  fill: #c1c1c1;
}
.about__aside.info {
  padding-top: 0;
}
.about__aside.info a {
  color: black;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.about__aside.info a:active, .about__aside.info a:visited, .about__aside.info a:focus {
  text-decoration: none;
  color: black;
}
.about__aside.info a:hover {
  text-decoration: none;
  color: inherit;
}
.about__aside .default-btn:link, .about__aside .default-btn:active, .about__aside .default-btn:visited, .about__aside .default-btn:focus {
  color: #fff;
}
.about__address span {
  display: block;
  margin-bottom: 5px;
}
.about__downloads {
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px solid #dedede;
}
.about__downloads ul {
  padding-left: 0;
}
.about__downloads ul li {
  list-style-type: none;
}
.about__downloads ul li a {
  position: relative;
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: 400;
  display: block;
  border-bottom: 1px solid #f1f1f1;
  padding: 12px 0;
}
.about__downloads ul li a:active, .about__downloads ul li a:visited, .about__downloads ul li a:focus {
  text-decoration: none;
  color: #102542;
}
.about__downloads ul li a:hover {
  text-decoration: none;
  color: black;
}
.about__downloads ul li a:before {
  content: "";
  position: absolute;
  right: 0px;
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("/content/images/icons/dl-8.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  transition: all 0.2s ease;
  opacity: 0.6;
}
.about__downloads ul li a:hover {
  background: #f4f5f6;
}
.about__downloads ul li a:hover:before {
  opacity: 1;
}

.tiles {
  display: flex;
  margin: 40px 0;
}
@media screen and (max-width: 768px) {
  .tiles {
    display: block;
  }
}
.tiles .tile {
  flex: 1 1 auto;
  padding: 20px;
  margin-right: 15px;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .tiles .tile {
    margin: 5px 0;
  }
}
.tiles .tile:nth-of-type(1n) {
  background: #f6f9fc;
}
.tiles .tile:nth-of-type(2n) {
  background: #102542;
  color: white;
}
.tiles .tile:nth-of-type(3n) {
  background: #005996;
  color: white;
}
.tiles .tile:last-of-type {
  margin-right: 0;
}
.tiles .tile img {
  margin: 0 auto;
}
.tiles .tile h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  line-height: 1.4;
}
.tiles .tile p {
  font-size: 16px;
  font-weight: 300;
}
.tiles .tile__icon img,
.tiles .tile__icon svg {
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  padding: 15px;
}

.about__content .content_frame {
  margin-top: 30px;
  margin-bottom: 30px;
}
.about__content .img-logo {
  width: 100%;
  height: auto;
  max-width: 180px;
  margin: 0 auto;
  border-radius: 4px;
  transform: translateY(-16px);
}
@media screen and (max-width: 768px) {
  .about__content .img-logo {
    transfrom: translateY(0);
    margin: 0 0 15px 0;
  }
}

.office-notice h2 {
  font-size: 30px;
}
@media screen and (max-width: 768px) {
  .office-notice h2 {
    font-size: 26px;
  }
}
.office-notice p {
  color: black;
  font-size: 17px !important;
  line-height: 1.4 !important;
}
.office-notice p a {
  display: inline-block !important;
}
.office-notice p a:hover {
  cursor: pointer;
}

noscript {
  background: #E6E6E6;
  color: black;
  display: block;
  font-size: 20px;
  font-weight: 500;
  padding: 40px 30px;
  text-align: center;
  width: 100%;
}

.message-block--warning {
  background: #FFF7D1;
  border-radius: 2px;
  border: 1px solid #e4c74a;
  padding: 30px;
  color: #3c2c00;
  font-size: 21px !important;
}
@media screen and (max-width: 768px) {
  .message-block--warning {
    padding: 15px;
  }
}

.warning-block {
  font-size: 22px !important;
  line-height: 1.6 !important;
  font-style: italic;
  font-weight: 400;
}

.article--inner img {
  max-width: 100%;
  height: auto;
  margin-bottom: 30px;
}
.article__title {
  font-weight: 700;
  margin-top: 0;
  font-size: 40px;
  color: #102542;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .article__title {
    font-size: 34px;
  }
}
.article__title__link {
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.article__title__link:active, .article__title__link:visited, .article__title__link:focus {
  text-decoration: none;
  color: #102542;
}
.article__title__link:hover {
  text-decoration: none;
  color: #102542;
}
.article__title__link:hover {
  text-decoration: underline;
}
.article__intro {
  font-size: 20px;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .article__intro {
    font-size: 18px;
  }
}
.article__footer {
  margin-bottom: 15px;
  font-size: 16px;
}
.article__social {
  display: block;
  float: right;
}
.article__date, .article__author {
  display: inline-block;
  position: relative;
  padding: 6px 20px 6px 24px;
  font-weight: 500;
}
.article__date:before, .article__author:before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.article__date:before {
  background: url("/content/images/icons/clock.svg") 50% no-repeat;
  background-size: cover;
}
.article__author:before {
  background: url("/content/images/icons/pen3.svg") 50% no-repeat;
  background-size: contain;
}
.article__author a:hover, .article__author a:active, .article__author a:visited, .article__author a:link {
  color: #102542;
}
.article__social a {
  display: inline-block;
}
.article__social a img {
  width: 32px;
  height: 32px;
  margin: 0;
}
.article__aside {
  margin-bottom: 50px;
}
.article__aside h4 {
  font-weight: 700;
  margin-top: 0;
  font-size: 22px;
  color: #102542;
  margin-bottom: 15px;
  font-size: 22px;
}
.article__aside ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  background: transparent;
  color: inherit;
}
.article__aside ul li {
  padding: 0;
  margin: 0;
  border: 0;
}
.article__aside ul li a, .article__aside ul li a:link, .article__aside ul li a:visited, .article__aside ul li a:active {
  color: #102542;
  font-size: 15px;
  transition: all 0.1s ease;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 5px;
  text-decoration: none;
}
.article__tag {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
}
.article__tag a, .article__tag a:hover, .article__tag a:active, .article__tag a:visited {
  text-decoration: none;
  color: #102542;
}
.article__search__button {
  appearance: none !important;
  border: 0;
  background: #eaeaea;
  padding: 6px 28px;
  transition: all 0.14s ease;
}
.article__search__button:hover {
  background: #005996;
  color: white;
}
.article__search__input {
  margin-bottom: 5px;
  padding: 4px 10px;
  border: 1px solid #dedede;
}
.article__image {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
  margin-bottom: 15px;
  background: #f4f5f6;
}
.article__downloads {
  margin: 50px 0;
}
@media screen and (max-width: 768px) {
  .article__downloads {
    margin: 30px 0;
  }
}
.article__downloads__heading {
  font-size: 22px;
  color: #102542;
  font-weight: 700;
}
.article__downloads a {
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: 400;
}
.article__downloads a:active, .article__downloads a:visited, .article__downloads a:focus {
  text-decoration: none;
  color: #102542;
}
.article__downloads a:hover {
  text-decoration: none;
  color: #102542;
}
.article__downloads a:hover {
  text-decoration: underline;
}
.article__more-link {
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  padding-right: 30px;
  display: inline-block;
  background: url("/content/images/icons/caret-right-active.svg") right center no-repeat;
  background-size: 14px;
  line-height: 1;
  font-weight: 500;
}
.article__more-link:active, .article__more-link:visited, .article__more-link:focus {
  text-decoration: none;
  color: #102542;
}
.article__more-link:hover {
  text-decoration: none;
  color: #102542;
}
.article__empty {
  padding-left: 10%;
  padding-right: 10%;
  min-height: 40vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.article__tag-list {
  padding: 0;
  margin: 0;
}
.article__tag-list:before, .article__tag-list:after {
  content: "";
  display: table;
}
.article__tag-list:after {
  clear: both;
}
.article__tag-list li {
  list-style-type: none;
  float: left;
  margin-right: 5px;
}
.article__tag-list li a:link, .article__tag-list li a:active, .article__tag-list li a:visited, .article__tag-list li a:hover {
  display: block;
  padding: 4px 16px;
  border: 1px solid #eee;
  background: #f4f5f6;
  border-radius: 10px;
  color: #102542;
  transition: all 0.2s ease;
  text-decoration: none;
}
.article__tag-list li a:hover {
  background-color: #005996;
  color: white;
}
.article__pagination {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
}
.article__pagination a {
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  transition: all 0.1s ease-in-out;
  display: inline-block;
  background-size: 14px;
  line-height: 1;
  font-weight: 500;
  background-size: contain;
  background-repeat: no-repeat;
}
.article__pagination a:active, .article__pagination a:visited, .article__pagination a:focus {
  text-decoration: none;
  color: #102542;
}
.article__pagination a:hover {
  text-decoration: none;
  color: £m3-darkblue;
}
.article__pagination a.next {
  padding-right: 30px;
  background-image: url("/content/images/icons/caret-right-active.svg");
  background-position: right center;
  margin-left: auto;
}
.article__pagination a.prev {
  padding-left: 30px;
  background-image: url("/content/images/icons/caret-left-active.svg");
  background-position: left center;
}
.article .paginator {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.article .paginator span.p-previous, .article .paginator span.p-next, .article .paginator a.p-previous, .article .paginator a.p-next {
  padding: 4px 32px;
  font-weight: 700;
  margin: 0px;
  background: transparent;
}
.article .paginator span.p-previous, .article .paginator span.p-next {
  color: #bcbcbc;
}
.article .paginator span.p-page {
  font-weight: 700;
  color: #102542;
  padding: 4px 16px;
  text-decoration: underline;
}
.article .paginator a.p-previous, .article .paginator a.p-next, .article .paginator a.p-page {
  color: #102542;
  border: 0;
  transition: all 0.14s ease;
}
.article .paginator a.p-previous:hover, .article .paginator a.p-next:hover, .article .paginator a.p-page:hover {
  background: #dedede;
}
.article .paginator a.p-page {
  font-weight: 700;
  padding: 4px 16px;
}
.article__heading {
  font-size: 28px;
  color: #102542;
  margin-bottom: 30px;
  font-weight: 600;
}
.article__profile-image {
  height: 200px;
  width: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
}
.article__content__intro {
  font-size: 24px;
  font-weight: 300;
}
.article__content h1, .article__content h2, .article__content h3, .article__content h4, .article__content h5, .article__content h6, .article__content strong {
  font-weight: 700;
  color: #102542;
  margin-top: 0;
}
.article__content p, .article__content span, .article__content li {
  line-height: 1.6;
  font-size: 18px;
  color: #3c4858;
}
.article__content p {
  margin-bottom: 30px;
}
.article__content ul, .article__content ol {
  list-style: none;
  padding: 0;
  margin-bottom: 50px;
}
.article__content ul li, .article__content ol li {
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
}
.article__content ul li:before, .article__content ol li:before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #172b46;
  z-index: 100;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 0;
}
.article__content img {
  max-width: 100%;
  height: auto;
  margin-bottom: 50px;
  display: block;
}
.article__content a {
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: 400;
  display: inline-block;
  border-bottom: 1.5px solid #005996;
}
.article__content a:active, .article__content a:visited, .article__content a:focus {
  text-decoration: none;
  color: #102542;
}
.article__content a:hover {
  text-decoration: none;
  color: #102542;
}
.article__content hr {
  margin: 50px 0;
  border-top: 1px solid #e1e1e1;
}
@media screen and (max-width: 768px) {
  .article__content hr {
    margin: 40px 0;
  }
}

.article--preview .article__heading {
  font-size: 24px;
  color: #102542;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 15px;
}
.article--preview .article__image {
  max-height: 250px;
}
.article--preview .article__title {
  font-weight: 700;
  margin-top: 0;
  font-size: 26px;
  color: #102542;
  margin-bottom: 15px;
  font-size: 24px;
}
.article--preview .article__title a {
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.article--preview .article__title a:active, .article--preview .article__title a:visited, .article--preview .article__title a:focus {
  text-decoration: none;
  color: #102542;
}
.article--preview .article__title a:hover {
  text-decoration: none;
  color: #102542;
}
.article--preview .article__title a:hover {
  text-decoration: underline;
}
.article--preview .article__intro {
  font-size: 16px;
}
.article--preview .article hr {
  border-color: #ccc;
}

.store__header {
  background-color: #005996;
  padding-top: 80px;
  padding-bottom: 0;
  color: white;
  margin-bottom: 60px;
  min-height: 272px;
}
.store__header h1 {
  font-size: 44px;
}
.store__header__tabs {
  border: 0;
  list-style-type: none;
  padding: 0;
  margin: 70px 0 0 0;
}
.store__header__tab {
  display: inline-block;
  min-width: 160px;
}
.store__header__tab.active {
  background-color: white;
  color: #102542;
}
.store__header__tab.active a:hover, .store__header__tab.active a:visited, .store__header__tab.active a:active, .store__header__tab.active a:link {
  color: #102542 !important;
  text-decoration: none !important;
}
.store__header__tab a {
  padding: 20px 16px 16px 40px;
  display: block;
  border: 0 !important;
  font-weight: bold;
  background-size: 32px;
  background-position: left 8px center;
  background-repeat: no-repeat;
  color: white;
  outline: none;
}
.store__header__tab a:hover, .store__header__tab a:visited, .store__header__tab a:active .store__header__tab a:link {
  color: rgba(255, 255, 255, 0.8) !important;
  text-decoration: none !important;
}
.store__header__tab a:hover {
  color: #ffffff !important;
}
.store__header__tab.organisation a {
  background-image: url("/content/images/icons/ico-org-white.svg");
}
.store__header__tab.organisation.active a {
  background-image: url("/content/images/icons/ico-org.svg");
}
.store__header__tab.contractors a {
  background-image: url("/content/images/icons/ico-eng-white.svg");
}
.store__header__tab.contractors.active a {
  background-image: url("/content/images/icons/ico-eng.svg");
}
.store__header__tab.upcoming-courses a {
  background-image: url("/content/images/icons/ico-calendar-add-fff.svg");
  background-size: 28px;
  background-position: center left 8px;
}
.store__header__tab.upcoming-courses.active a {
  background-image: url("/content/images/icons/ico-calendar-add.svg");
}
.store__header__tab.all-courses a {
  background-image: url("/content/images/icons/ico-courselist-fff.svg");
  background-size: 28px;
  background-position: center left 8px;
}
.store__header__tab.all-courses.active a {
  background-image: url("/content/images/icons/ico-courselist.svg");
}
.store__header__tab.package-courses a {
  background-image: url("/content/images/icons/ico-package-fff.svg");
  background-size: 28px;
  background-position: center left 8px;
}
.store__header__tab.package-courses.active a {
  background-image: url("/content/images/icons/ico-package.svg");
}
.store__header__img {
  width: 100%;
  max-width: 200px;
  height: auto;
  float: left;
  margin-bottom: 30px;
}
@media screen and (max-width: 992px) {
  .store__header__img {
    float: none;
    max-width: 140px;
  }
}
.store__header__inner {
  padding-left: 40px;
  padding-bottom: 40px;
  float: left;
  max-width: 820px;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .store__header__inner {
    padding-left: 10px;
    max-width: 740px;
  }
}
@media screen and (max-width: 992px) {
  .store__header__inner {
    float: none;
    padding-left: 0;
  }
}
.store__header__inner h1 {
  margin-bottom: 15px;
}
.store__header__fields {
  position: relative;
}
.store__header__field {
  margin-bottom: 20px;
  max-width: 300px;
  overflow: hidden;
}
.store__header__field select {
  color: #102542;
  outline: 0;
  padding: 8px;
  border: 1px solid #dedede;
  outline: 0;
  width: calc(100% + 40px);
  appearance: none;
  background-color: white;
  background-image: url(/content/images/icons/ico-caret-down-thin.svg);
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: right 50px center;
}
.store__header__field select:disabled {
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid #fff;
}
.store__header__field label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 6px;
}
.store__header__field input[type=text],
.store__header__field input[type=number] {
  color: #172b46;
  padding: 8px;
  border: 1px solid #dedede;
  outline: 0;
  width: 100%;
}
.store__header__field input[type=text]:disabled,
.store__header__field input[type=number]:disabled {
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid #dedede;
}
.store__header__field.dropdown {
  overflow: visible;
  position: relative;
}
.store__header__field.dropdown .dropdown-menu {
  width: 100%;
  left: 0;
  right: 0;
  color: #102542;
  padding: 10px;
  line-height: 1.8;
  border-radius: 0;
  background-clip: border-box;
  margin-top: 1px;
}
.store__header__field.dropdown .dropdown-menu li {
  margin: 10px 0;
}
.store__header__field.dropdown .dropdown-menu li:hover {
  cursor: pointer;
}
.store__header__field.has-error {
  box-shadow: 0 5px 0px 0px #de2e2e;
}
.store__header__field.has-error input[type=text],
.store__header__field.has-error input[type=number],
.store__header__field.has-error select {
  color: #de2e2e;
}
.store__header__teaser {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 30px;
}
.store__header__teaser a {
  color: #fff;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.store__header__teaser a:active, .store__header__teaser a:visited, .store__header__teaser a:focus {
  text-decoration: none;
  color: #fff;
}
.store__header__teaser a:hover {
  text-decoration: none;
  color: inherit;
}
.store__header .inline-message--success {
  margin-top: 10px;
}
.store__header__note {
  padding: 30px;
  background: rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 768px) {
  .store__header__note {
    margin-bottom: 15px;
  }
}
.store__header__note h3 {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.4);
}
.store__header__note ul {
  margin: 0;
  padding: 0;
  font-weight: 300;
  line-height: 1.4;
}
.store__header__note ul li {
  margin-bottom: 15px;
  margin-left: 20px;
}
.store__header__note a {
  color: #fff;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  display: inline-block;
}
.store__header__note a:active, .store__header__note a:visited, .store__header__note a:focus {
  text-decoration: none;
  color: #fff;
}
.store__header__note a:hover {
  text-decoration: none;
  color: #fff;
  border-bottom: 2px solid #0397D7;
}
.store__listing {
  display: flex;
  margin-bottom: 50px;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .store__listing {
    display: block;
  }
}
.store__listing__notice {
  font-weight: 300;
  font-size: 16px;
  font-style: italic;
  padding-right: 60px;
  max-width: 800px;
  position: relative;
  padding-left: 20px;
}
@media screen and (max-width: 768px) {
  .store__listing__notice {
    margin-bottom: 30px;
  }
}
.store__listing__notice:before {
  content: "*";
  display: block;
  width: 15px;
  height: 30px;
  position: absolute;
  left: 0;
  top: 5px;
}
.store__listing__notice.full {
  max-width: 100%;
  margin-bottom: 40px;
  color: inherit;
  font-style: normal;
  padding-left: 0;
}
.store__listing__notice.full:before {
  display: none;
}
.store__listing__filter span {
  display: inline-block;
  margin-right: 10px;
}
.store__listing__filter select {
  padding: 8px;
  border: 1px solid #dedede;
  outline: 0;
}
.store__category {
  margin-bottom: 60px;
}
.store__category__title {
  font-size: 18px;
  font-weight: bold;
}
.store__items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.store__item {
  background: #f4f5f6;
  margin: 15px;
  flex: 0 1 calc(33.33% - 30px);
  flex-basis: calc(33.33% - 30px);
  max-width: calc(33.33% - 30px);
  padding: 24px 24px 30px 24px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
@media screen and (max-width: 992px) {
  .store__item {
    flex: 0 1 calc(50% - 30px);
    flex-basis: calc(50% - 30px);
    max-width: calc(50% - 30px);
  }
}
@media screen and (max-width: 768px) {
  .store__item {
    flex: 1 1 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
.store__item__header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.store__item__name {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
}
.store__item__logo, .store__item__img {
  max-width: 68px;
  margin-right: 6px;
  height: auto;
}
.store__item__description {
  margin-bottom: 80px;
}
@media screen and (max-width: 768px) {
  .store__item__description {
    margin-bottom: 100px;
  }
}
.store__item__actions {
  margin-top: auto;
}
@media screen and (max-width: 768px) {
  .store__item__actions div {
    text-align: center;
  }
}
.store__item__actions div a {
  display: inline-block;
}
.store__item__actions div a img {
  margin-top: 5px;
}
@media screen and (max-width: 992px) {
  .store__item__actions div a img {
    height: 60px;
  }
}
.store__btn {
  appearance: none !important;
  border: 0;
  border-radius: 3px;
  padding: 10px 18px;
  font-size: 14px;
  font-weight: bold;
  color: #102542;
  transition: all 0.18s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: inline-block;
  text-align: center;
  min-width: 120px;
  outline: 0;
  cursor: pointer;
}
.store__btn > img {
  max-width: 28px;
  margin-right: 3px;
  transform: translateY(-2px);
}
@media screen and (max-width: 768px) {
  .store__btn {
    width: 100%;
  }
}
.store__btn:hover, .store__btn:active, .store__btn:visited, .store__btn:link {
  text-decoration: none;
  color: #102542;
}
.store__btn--quote {
  background: white;
  border: 1px solid #dedede;
}
.store__btn--quote:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.store__btn--buy {
  background: #005996;
  color: white;
}
.store__btn--buy:hover, .store__btn--buy:active, .store__btn--buy:visited, .store__btn--buy:link {
  color: white;
}
.store__btn--buy:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.store__btn--info:hover {
  text-decoration: underline;
}
.store__btn--add {
  background: #005996;
  color: white !important;
}
.store__btn--add:hover {
  background: #fff;
}
.store__btn--related {
  font-weight: bold;
  color: #102542;
  font-size: 16px;
  appearance: none;
  background: transparent;
  border: 0;
  padding: 0;
  margin-bottom: 15px;
}
.store__btn--related:hover, .store__btn--related:active, .store__btn--related:focus {
  outline: 0 !important;
}
.store__contact {
  text-align: center;
  background: #e6e8eb;
  font-size: 18px;
  font-weight: 300;
  color: #102542;
}
.store__contact img {
  width: 120px;
  max-width: 100%;
  height: auto;
}
.store__contact h2 {
  font-size: 32px;
}
.store__contact p {
  font-weight: 300;
}
.store__product {
  background: #F4F5F6;
  margin-bottom: 15px;
  border: 1px solid #eaeaea;
  padding: 20px 20px 20px 70px;
}
@media screen and (max-width: 768px) {
  .store__product {
    padding: 20px 20px 20px 50px;
  }
}
.store__product__desc {
  position: relative;
  margin-bottom: 15px;
}
.store__product__desc:before {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  left: -50px;
  width: 48px;
  height: 48px;
  background-image: url("/content/images/icons/ico-bookmark.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.store__product__action {
  text-align: center;
  float: right;
  min-width: 160px;
}
@media screen and (max-width: 992px) {
  .store__product__action {
    min-width: auto;
    text-align: left;
    float: none;
  }
}
.store__product__heading {
  font-weight: bold;
  font-size: 20px;
  line-height: 1.4;
  color: #102542;
  margin-bottom: 5px;
  margin-top: 0;
}
.store__product__teaser, .store__product__price {
  font-weight: 300;
  font-size: 16px;
  color: #102542;
  margin-bottom: 0px;
}
@media screen and (max-width: 768px) {
  .store__product__teaser, .store__product__price {
    margin-bottom: 15px;
  }
}
.store__product__price {
  text-align: right;
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .store__product__price {
    text-align: left;
    font-size: 22px;
    margin: 15px 0;
  }
}
.store__product__price label {
  font-size: 14px;
  font-weight: 500;
}
.store__product__price input[type=text],
.store__product__price input[type=number] {
  color: #172b46;
  padding: 8px;
  border: 1px solid #dedede;
  outline: 0;
  max-width: 100px;
  text-align: right;
  margin-left: 5px;
}
.store__product .store__btn--related {
  margin: 15px 0;
}
.store__product .inline-message--success {
  margin-bottom: 10px;
  display: inline-block;
}
@media screen and (min-width: 992px) {
  .store__product .inline-message--success {
    display: block;
    width: 160px;
  }
}
.store__related-product {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid #dedede;
  padding-left: 44px;
}
@media screen and (max-width: 992px) {
  .store__related-product {
    padding-left: 30px;
  }
}
.store__related-product .store__product__desc {
  margin-bottom: 0;
}
@media screen and (max-width: 992px) {
  .store__related-product .store__product__desc {
    margin-bottom: 15px;
  }
}
.store__related-product .store__product__desc:before {
  width: 26px;
  height: 26px;
  left: -32px;
  top: -4px;
  opacity: 1;
  background-image: url("/content/images/icons/ico-add.svg");
  background-size: 16px;
  background-position: center;
  border-radius: 50%;
}
.store__related-product .store__product__heading {
  font-weight: 500;
  font-size: 16px;
}
.store__related-product .store__product__img {
  padding-left: 40px;
}
.store__nav {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 60px;
}
.store__nav .btn--submit {
  max-width: 180px;
  margin: 0 15px;
}
.store__cart, .store__checkout {
  margin: 80px 0;
  color: #102542;
}
.store__cart--empty, .store__checkout--empty {
  text-align: center;
  color: #102542;
  margin-bottom: 60px;
}
.store__cart--empty h3, .store__checkout--empty h3 {
  font-weight: bold;
}
.store__cart--empty a, .store__checkout--empty a {
  font-weight: bold;
  font-size: 16px;
  color: #005996;
}
.store__cart .form__confirmation label a, .store__checkout .form__confirmation label a {
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  font-weight: 500;
}
.store__cart .form__confirmation label a:active, .store__cart .form__confirmation label a:visited, .store__cart .form__confirmation label a:focus, .store__checkout .form__confirmation label a:active, .store__checkout .form__confirmation label a:visited, .store__checkout .form__confirmation label a:focus {
  text-decoration: none;
  color: #102542;
}
.store__cart .form__confirmation label a:hover, .store__checkout .form__confirmation label a:hover {
  text-decoration: none;
  color: inherit;
}
.store__cart .form__confirmation label a:hover, .store__checkout .form__confirmation label a:hover {
  text-decoration: underline;
}
.store__cart .inline-message--success, .store__cart .inline-message--error, .store__checkout .inline-message--success, .store__checkout .inline-message--error {
  font-size: 14px;
}
.store__checkout h4 {
  color: #102542;
  margin-bottom: 40px;
  font-size: 22px;
  font-weight: bold;
}
.store__checkout label {
  font-weight: 400;
  color: #102542;
  font-size: 16px;
}
.store__checkout input:not([type=submit]), .store__checkout textarea {
  background-color: #fff;
  color: #102542;
  display: inline-block;
  font-weight: 300;
  line-height: 1.5;
  padding: 12px 12px 10px;
  width: 100%;
  height: auto;
  border: 1px solid #d1d1d1;
}
.store__checkout textarea {
  min-height: 120px;
}
@media screen and (max-width: 768px) {
  .store__checkout .form__field {
    margin-bottom: 20px;
  }
}
.store__checkout .form__field--flex {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .store__checkout .form__field--flex {
    display: block;
  }
}
.store__checkout .form__field--flex input {
  flex: 0 1 calc(50% - 10px);
}
@media screen and (max-width: 768px) {
  .store__checkout .form__field--flex input {
    margin-bottom: 5px;
  }
}
.store__price {
  padding-top: 30px;
  padding-bottom: 30px;
}
.store__price h3 {
  margin-bottom: 30px;
}
.store__price-table {
  table-layout: fixed;
  text-align: right;
}
.store__price-table th {
  background: #f4f5f6;
}
.store__price-table td, .store__price-table th {
  text-align: right;
  padding: 12px !important;
}
.store__price-table td:first-of-type, .store__price-table th:first-of-type {
  padding-left: 0;
  text-align: left;
}
.store__price-table tr:nth-child(even) {
  background: #f4f5f6;
}

.cart__nav {
  text-align: right;
  margin-bottom: 15px;
}
.cart__summary {
  background: #f4f5f6;
  border: 1px solid #eaeaea;
  padding: 25px;
  color: #102542;
  font-weight: 300;
  margin-left: auto;
  margin-bottom: 20px;
}
.cart__summary__title {
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 600;
}
.cart__summary__row {
  margin-bottom: 4px;
}
.cart__summary__row:before, .cart__summary__row:after {
  content: "";
  display: table;
}
.cart__summary__row:after {
  clear: both;
}
.cart__summary__label, .cart__summary__amount {
  width: 50%;
}
.cart__summary__label strong, .cart__summary__amount strong {
  font-weight: bold;
  font-size: 18px;
}
.cart__summary__label {
  float: left;
}
.cart__summary__amount {
  float: right;
  text-align: right;
}
.cart__summary .store__btn {
  width: 100%;
  margin: 25px 0;
}
.cart__summary .btn--submit {
  margin: 15px 0;
}
.basket__item {
  padding: 30px 0;
  border-top: 1px solid #dedede;
}
.basket__item__title {
  margin-bottom: 0px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .basket__item__title {
    max-width: 70%;
  }
}
.basket__item__price {
  text-align: right;
}
@media screen and (min-width: 992px) {
  .basket__item__qty {
    text-align: right;
  }
}
.basket__item__action {
  text-align: right;
}
@media screen and (max-width: 992px) {
  .basket__item__action {
    text-align: left;
  }
}
.basket__header {
  padding-bottom: 15px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: bold;
  margin-top: 10px;
  font-size: 13px;
  text-transform: uppercase;
}
.basket__header__price {
  text-align: right;
}
@media screen and (min-width: 992px) {
  .basket__header__qty {
    text-align: right;
  }
}
.basket__header__action {
  text-align: right;
}
@media screen and (max-width: 992px) {
  .basket__header__action {
    display: none;
  }
}

.order__complete {
  text-align: center;
  color: #102542;
  font-size: 20px;
}
.order__complete img {
  max-width: 120px;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}
.order__complete__heading {
  margin-bottom: 5px;
  font-weight: bold;
}
.order__complete__reference {
  font-weight: bold;
  margin-bottom: 30px;
}
.order__complete__description a {
  font-weight: bold;
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.order__complete__description a:active, .order__complete__description a:visited, .order__complete__description a:focus {
  text-decoration: none;
  color: #102542;
}
.order__complete__description a:hover {
  text-decoration: none;
  color: inherit;
}
.order__complete__description a:hover {
  border-bottom: 1px solid #102542;
}
.order__failed {
  color: #102542;
  font-size: 18px;
}
.order__failed .inline-message--error {
  font-size: 20px;
  margin-bottom: 40px;
}
.order__failed ol {
  margin-left: 0px;
  padding-left: 20px;
}
.order__failed ol li {
  margin-bottom: 10px;
}
.order__failed ol ul {
  margin: 30px;
}
.order__failed__heading {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #102542;
}
.order__failed a {
  font-weight: bold;
  color: #102542;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
}
.order__failed a:active, .order__failed a:visited, .order__failed a:focus {
  text-decoration: none;
  color: #102542;
}
.order__failed a:hover {
  text-decoration: none;
  color: inherit;
}
.order__failed a:hover {
  border-bottom: 1px solid #102542;
}

.store__btn--full {
  max-width: 100%;
}

.m-table {
  width: 99.9%;
  text-align: left;
  table-layout: fixed;
}
.m-table thead tr th {
  border-top: 1px solid #ccc;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  background: #eee;
  line-height: 1.15;
  vertical-align: middle;
  color: black;
  padding: 15px 20px 14px;
}
.m-table thead tr th:last-of-type {
  border-right: 0;
}
.m-table thead tr th.half {
  width: 50%;
}
.m-table thead tr th.quarter {
  width: 25%;
}
.m-table thead tr th.third {
  width: 33.33%;
}
.m-table thead tr th.forty {
  width: 40%;
}
.m-table tbody tr td, .m-table tbody tr th {
  border-bottom: 1px solid #eee;
  padding: 15px 20px 14px;
}
.m-table tbody tr th {
  border-right: 1px solid #ccc;
  font-weight: 600;
  border-bottom: 1px solid #eee;
}
.m-table tbody tr td {
  border-right: 1px solid #eee;
}
.m-table tbody tr td:last-of-type {
  border-right: 0;
}

.scripts__title {
  font-weight: bold;
}
.scripts__description {
  font-size: 18px;
  margin-bottom: 22px;
  max-width: 100ch;
}
.scripts__category {
  margin-bottom: 70px;
}
.scripts__table small {
  font-weight: 300;
  font-size: 14px;
}

#section-fm {
  background: url("/content/images/section/fm-bg-17.png") top left no-repeat;
  background: url("/content/images/section/fm-bg-16.png") top left no-repeat;
  background-size: cover;
}
@media screen and (max-width: 768px) {
  #section-fm {
    background-image: none;
  }
}

.col-main .info__link {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.col-main .info__link .info__icon {
  width: 80px;
}

.col-side {
  background: #fff;
  padding-right: 50px;
  border-right: 30px solid #f9f9f9;
}

.support h2 {
  display: block;
  margin-bottom: 15px;
}
.support__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.support__header h2 {
  max-width: 50%;
}
@media screen and (max-width: 992px) {
  .support__header h2 {
    max-width: 100%;
  }
}
.support__table {
  border-collapse: separate !important;
  border-radius: 4px;
  margin-top: 0;
  outline: 1px solid #dedede;
}
.support__table td, .support__table th {
  border-bottom: 1px solid #dedede;
  padding: 18px;
}
.support__table td.text-center, .support__table th.text-center {
  text-align: center !important;
}
.support__table th {
  font-weight: bold;
  color: #102542;
  background: #fff;
}
.support__table td {
  background: #fefefe;
}
.support__table a:link, .support__table a:hover, .support__table a:visited {
  color: #102542;
  font-weight: 500;
}
.support__table--borderless {
  border: none;
  margin-bottom: 30px;
  border-radius: 10px;
  outline: 4px solid #8bc34a;
  width: 100%;
}
.support__table--borderless td {
  padding: 15px;
  border: 1px solid #dedede;
}
.support__table--borderless td:first-of-type {
  font-weight: 500;
  max-width: 120px;
}
.support .btn__support {
  text-align: center;
  margin: 0;
  border-radius: 2px;
  background: #f4f5f6;
  font-weight: 500;
  padding: 0;
}
.support .btn__support a {
  padding: 14px 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.support .btn__support a:link, .support .btn__support a:hover, .support .btn__support a:visited {
  color: #333;
  text-decoration: none !important;
}
.support .btn__support a:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.support .section__tabs {
  font-weight: 500;
}
.support__features__notice {
  font-style: italic;
  margin: 30px 15px !important;
  max-width: 600px;
  position: relative;
}
.support__features__notice.note:before {
  content: "*";
  font-weight: normal;
  display: inline-block;
  margin-right: 5px;
  left: -15px;
  position: absolute;
  top: 3px;
}
.support__blocks .allSteps > h4 {
  margin-top: 50px;
  margin-bottom: 20px;
}
.support__block {
  padding: 20px;
  border: 1px solid #dedede;
  border-radius: 14px;
  margin-bottom: 20px;
}
.support__tag {
  background: #f4f5f6 !important;
  border-radius: 14px !important;
  font-size: 14px;
}
.support__link a {
  font-weight: 500;
  color: black;
  margin-right: 8px;
  font-size: 14px;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 10px;
  background: #f4f5f6;
}
.support__link a:link, .support__link a:active, .support__link a:visited {
  color: black !important;
}

.alert-warning {
  color: #5F4306;
  background-color: #FFF9E6;
  border-color: #F2E5B2;
}

code {
  padding: 2px 6px;
  border-radius: 4px;
  font-family: "Courier New", Courier, monospace;
  font-size: 0.9em;
}

.upgrade-selector {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  background: #8bc34a;
  color: #fff;
}
.upgrade-selector .versionFrom {
  margin-left: auto;
}

.warning-label {
  position: relative;
  padding-left: 20px;
  display: inline-flex;
}
.warning-label:before {
  content: "warning";
  font-family: "Material Icons";
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-51%);
  color: #bababa;
}
.warning-label.Minor:before {
  color: #fcbd11;
}
.warning-label.Major:before {
  color: #f75402;
}
.warning-label.Critical:before {
  color: #c92525;
}

.partners__heading {
  text-align: center;
}
.partners__subheading {
  text-align: center;
}
.partners .cards {
  margin: 30px 0;
}
.partners .card {
  display: inline-flex;
  flex-direction: column;
  position: relative;
}
.partners .card__overlay__info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #3C4858;
  font-size: 1.5em;
  text-align: center;
  opacity: 0;
  transition: opacity 0.2s ease;
  margin: 0;
  padding: 10px;
  width: 100%;
  background: #ffffffc7;
}
.partners .card__title {
  margin-bottom: 5px;
  line-height: 1;
  font-size: 18px;
}
.partners .card__category {
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  color: inherit;
}
.partners .card__img {
  width: 100%;
  height: auto;
  display: block;
  transition: all 0.3s ease;
  filter: grayscale(1);
  opacity: 0.8;
}
.partners .card__overlay {
  position: relative;
}
.partners .card__overlay:hover .card__overlay__info {
  opacity: 1;
}

.flex-center {
  justify-content: center !important;
}

html, body {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 16px;
  color: #3C4858;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

body {
  background: #f4f5f6;
}

p {
  margin-bottom: 15px;
}

/******************************
Page and containers
******************************/
.page {
  max-width: 1600px;
  margin: 0 auto;
  background-color: #fff;
  position: relative;
}

.wrapper {
  max-width: 1280px;
  width: 100%;
  padding: 0 70px;
  margin: 0 auto;
}
@media screen and (max-width: 992px) {
  .wrapper {
    padding: 0 15px;
  }
}

.container {
  background: transparent;
}

.no-header .page__header {
  display: none;
}

.js-is-hidden {
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.js-is-visible {
  height: auto;
  opacity: 1;
  visibility: visible;
}

#mainContent:before, #mainContent:after {
  content: "";
  display: table;
}
#mainContent:after {
  clear: both;
}

h1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 34px;
    line-height: 1.15;
  }
}

h2 {
  font-size: 28px;
  font-weight: 600;
  margin-top: 0;
  line-height: 1.15;
}

.baseform {
  padding: 50px;
  background: #fafafa;
  border-radius: 5px;
  border: 1px solid #efefef;
}
@media screen and (max-width: 768px) {
  .baseform {
    padding: 30px 15px;
  }
}
.baseform input, .baseform select, .baseform textarea {
  border: 1px solid #efefef;
  background-color: #fff;
  color: black;
  display: inline-block;
  font-weight: 300;
  line-height: 1.5;
  padding: 12px 12px 10px;
  width: 100%;
  height: auto;
}
.baseform label {
  color: #3C4858;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  padding: 10px 0;
  margin: 0;
  width: 100%;
  vertical-align: top;
}
.baseform .select-wrapper {
  background: url(/content/images/icons/arrow_down.svg);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 20px;
  border: 1px solid #dedede;
  background-color: #fff;
  overflow: hidden;
  width: 100%;
}
.baseform .checkbox-wrapper label:before {
  background: #fff;
}
.baseform .checkbox-wrapper label {
  padding: 0 0 0 30px;
}
.baseform select {
  background: transparent;
  width: 100%;
  border: 0;
  margin: 0;
  appearance: none;
  padding: 12px 36px 10px 12px;
}
.baseform button, .baseform input[type=submit] {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  padding: 15px;
  color: #fff;
  padding: 15px 20px;
  margin: 15px 0;
}
.baseform button:active, .baseform button:visited, .baseform button:focus, .baseform input[type=submit]:active, .baseform input[type=submit]:visited, .baseform input[type=submit]:focus {
  text-decoration: none;
  color: inherit;
}
.baseform button:hover, .baseform input[type=submit]:hover {
  text-decoration: none;
  color: inherit;
}
.baseform button:hover, .baseform input[type=submit]:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media screen and (max-width: 992px) {
  .baseform button, .baseform input[type=submit] {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .baseform button, .baseform input[type=submit] {
    width: 100%;
  }
}
.baseform button:hover, .baseform button:visited, .baseform button:active, .baseform input[type=submit]:hover, .baseform input[type=submit]:visited, .baseform input[type=submit]:active {
  color: #fff;
}
.baseform button:hover, .baseform input[type=submit]:hover {
  background: #0068b0;
}
.baseform .error {
  color: #de2e2e;
}
.baseform abbr {
  border: 0;
  color: rgba(0, 0, 0, 0.4);
  line-height: 1.1;
  text-decoration: none;
  vertical-align: center;
  margin-left: 5px;
}
.baseform .privacy-notice {
  padding: 0 0 45px;
  background: transparent;
}
.baseform ol, .baseform ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.baseform ol li, .baseform ul li {
  margin-bottom: 15px;
}
.baseform legend {
  border-bottom: 1px solid #e5e5e5;
  margin: 15px 0;
  padding-bottom: 15px;
  padding-top: 15px;
}

.default-btn {
  appearance: none;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  outline: 0 !important;
  background: #005996;
  color: white;
}
.default-btn:active, .default-btn:visited, .default-btn:focus {
  text-decoration: none;
  color: inherit;
}
.default-btn:hover {
  text-decoration: none;
  color: inherit;
}
.default-btn:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.default-btn:hover {
  background: #0068b0 !important;
  color: white !important;
}
.default-btn.full {
  width: 100%;
}

.offset-top-25 {
  margin-top: -25px;
}