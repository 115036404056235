.carousel__wrapper{
    position: relative;
}
#productcarousel {
    display: flex;
    width: 100% !important;
    position: relative;
    @media screen and (max-width: 520px) {
        display: block;
    } 
    & > div {
        padding: 20px 15px;
        > a {
            display: block;
        }
    }
}
.carousel__controls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); 
    width: 100%;
    a {
        @include unlink;
        i {
            font-size: 40px;
        }
        &.prev {
            float: left;
            margin-left: -40px;
        }
        &.next {
            float: right;
            margin-right: -40px;
        }
    }
}