.section--events {
   background:#fff;
}
.events {
    &__heading {
        @include heading-1;
        margin-bottom: 40px;
        text-align: center;
        @media screen and (max-width: 768px) {
            margin-bottom: 25px;
            text-align: left;
        }
        a {
            @include unlink;
        } 
    }
    &__link {
        @include unlink($dark-blue, lighten($dark-blue,25%));
        font-weight: bold;
        text-align: center;
        display: block;
        margin-top: 40px;
    }
    .event {
        position: relative;
        background: $formbackground;
        margin-bottom: 30px;
        box-shadow: 0px 30px 50px 0px rgba(0,46,91,0.22);
        @media screen and (max-width: 768px) {
            margin-bottom: 15px;
        }
        &__link {
            @include unlink;
        }
        &__image-container {
            @include darken-background;
            min-height: 250px;
            background-size: cover;
            background-position: center;
            &:after {
                background: rgba(0,0,0,0.06);
            }
        }
        &__image {
            width: 100%;
            height: auto;
            max-width: 100%; 
            max-height: 250px;        
        }
        &__icons {
            width: 18px;
            height: 18px;
            margin-right: 10px;
        }
        &__details {
            padding: 30px;
            a {
                @include unlink;
                border-bottom: 1.5px solid black;
            }
            &__footer {
                margin-bottom: 30px;
            }
        }
        &__date, &__location {
            font-size: 14px;
            font-weight: 500;
            display: flex;
            margin-right: 40px;
            margin-bottom: 10px;
        }
        &__location {
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
        &__title {
            @include heading-3; 
            font-size: 20px;
            margin-bottom: 15px;
        }
        &__button {
            margin-top: 40px;
            button {
                appearance: none;
                font-size: 16px;
                font-weight: bold;
                display: inline-block;
                padding-bottom: 2px;
                border-bottom: 2px solid $m3-blue;
                background: transparent;
                padding-left: 0;
                padding-right: 0;
                border-radius: 0;
            }
        }
        &:before {
            content: '';
            display: none;
            position: absolute;
            padding: 8px 20px;
            font-size: 15px;
            z-index: 100;
            top: 0;
            left: 0;
            background: #172B46;
            color: white;
            font-weight: 400;
        }
        &.none {
            &:before {
                display: none;
            }
        }

        &.announcement {
            &:before {
                content: 'Announcement';
                background: #172B46;
                display: block;
            }
        }
        &.news {
            &:before {
                content: 'News';
                background: #172B46;
                display: block;
            }
        }
        &.nhmf {
            &:before {
                content: 'NHMF';
                background: #fb0;
                display: block;
            }
        }
        &.awards {
            &:before {
                content: 'NHMF Awards';
                background: #fb0;
                display: block;
            }
        }
        &.committee {
            &:before {
                content: 'NHMF Committee';
                background: #fb0;
                display: block;
            }
        }
        &.conference {
            &:before {
                content: 'NHMF Conference';
                background: #fb0;
                display: block;
            }
        }
        &.studygroup {
            &:before {
                content: 'NHMF Study Group';
                background: #fb0;
                display: block;
            }
        }

        &.usergroup {
            &:before {
                content: 'User Group';
                background:#005996;
                display: block;
            }
        }
        &.training {
            &:before {
                content: 'Training';
                background:#0397D7;
                display: block;
            }
        }
        &.generic {
            &:before {
                content: 'Event';
                background:#0397D7;
                display: block;
            } 
        }
        &.exhibition {
            &:before {
                content: 'Exhibition';
                background:#0397D7;
                display: block;
            } 
        }
        &.showcase {
            &:before {
                content: 'Showcase';
                background:#0397D7;
                display: block;
            }
        }
        &.webinar {
            &:before {
                content: 'Webinar';
                background:#0397D7;
                display: block;
            }
        }
    }
} 

.internal__content {
    &.reset {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }
    .event {
        &__type {
            font-size: 18px !important;
            font-weight: 500 !important;
            margin-bottom: 10px;
            text-transform: Capitalize;
        }
        &__title {
            font-size: 46px;
            font-weight: 700;
            margin-bottom: 15px;
            margin-top: 0;
            line-height: 1;
        }
        &__description {
            font-size: 24px;
            font-weight: 400;
            line-height: 1.4;
            margin-bottom: 15px;
        }
        &__footer {
            display: flex;
            justify-content: space-between;
            font-weight: normal;
            &__authors {
                margin-right: 30px;
            }
            &__icon {
                display: inline-block;
                width: 18px;
                height: 18px;
                margin-top: 0;
                margin-bottom: 0;
                margin-right: 5px;
            }
        }

        &__image {
            padding: 0;
            max-height: 100%;
        }
        &__date {
            @include p-lg; 
            display: block;
            margin-bottom: 10px;
        }
        &__content {
            margin-bottom: 80px;
            p {
                font-size: 17px;
                font-weight: 400;
                line-height: 1.6;
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            strong {
                font-weight: 700;
            }
            ul {
                line-height: 1.5;
                margin-bottom: 50px;
                margin-top: 40px;
                padding-left: 0;
                list-style: none;
                li {
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 15px;
                    padding-left: 40px;
                    position: relative;
                    &:before {
                        background: #0397D7;
                        border-radius: 50%;
                        content: "";
                        display: block;
                        height: 8px;
                        left: 10px;
                        position: absolute;
                        top: 8px;
                        width: 8px;
                    }
                }
            }
        }
    }
}
.eventDate {
    display: block;
    text-align: center;
    margin-bottom: 30px;
    margin-top: -15px;
}
#eventDateFilter {
    appearance: none;
    border: 0;
    padding: 5px 10px;
    color: black;
    background-image: url('/content/images/icons/arrow_down.svg');
    background-color: #f4f5f6;
    background-repeat: no-repeat;
    background-position: 96%;
    background-size: 20px;
    border-radius: 2px;
}