
.section {
    &__video {
        margin-top: 30px;
        .col {
             background: $lighter-grey;
        }
    }
}
.video {
    background: $formbackground;
    margin-bottom: 30px;
    &__container {
        background: #eaeaea;
        display: flex;
        justify-content: center;
        padding: 30px;
        iframe {
            max-width: 100%;
        }
    }
    &__details {
        padding: 30px;
    }
}


.section {
    &__guides {
        margin-top: 30px;
        border: 5px solid red;
    }
}

.guide {
    @include unlist;
    border-top: 1px solid $inputborder;
    border-right: 1px solid $inputborder;
    margin-top: 40px;
    &__item {
        display: flex;
        a {
            display: flex;
            align-items: center;
            color: $body-text-color;
            text-decoration: none;
            border-bottom: 1px solid $inputborder;
            border-left: 1px solid $inputborder;
            padding: 5px;
            padding: 15px;
            &:hover, &:active, &:focus {
                text-decoration: none;
                background: $light-grey;
                transition: background 0.2s ease;
            }
        }
    }
    &__link {
        flex: 1;
    }
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $body-text-color;
        border-right: 2px solid #e7e7e7;
    }
    &__title{
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 0;
        flex: 1;
    }
    &__date {
        background: $light-grey;
        padding: 2px;
        margin: 0 10px;
        font-size: 12px;
        float: right;
        @media screen and (max-width: 768px) {
            text-align: center;
            max-width: 90px;
        }
    }
}