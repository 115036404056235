.admin {
    box-sizing: border-box;
    *, *:before, *:after {
        box-sizing: inherit;
    }
    background: #f1f1f1;
    min-height: 100vh;
    &__container {
        max-width: 1600px;
        margin: 0 auto;
        padding: 0 100px;
        &:before, &:after {
            content: "";
            clear:both;
            display: table;
        }
        @media screen and (max-width: 1200px) {
            padding: 0 30px;
        }
        @media screen and (max-width: 768px) {
             padding: 0 15px;
        }
    }
    &__nav {
        border-top: 30px solid $m3-darkblue;
        background: #fff;
        border-bottom: 1px solid #eaeaea;
    }
    &__body {
        padding-top: 40px;
        padding-bottom: 40px;
        h1 {
            font-size: 28px;
        }
        .cards {
            display: flex;
            justify-content: space-between;
            max-height: 1100px;
            width: 100%;
            @media screen and (max-width: 1200px) {
                flex-flow: row wrap;
            }
            @media screen and (max-width: 768px) {
                display: block;
            }
        }
        .card {
            background: #fff;
            flex: 0 1 calc(25% - 15px);
            @include box-shadow-lg;
            @media screen and (max-width: 1200px) {
                flex: 0 1 calc(50% - 15px);
            }
            @media screen and (max-width: 768px) {
                width:100%;
            }
  
            &__content {
                padding: 20px;
                ul {
                    @include unlist;
                }
                & > h4 {
                    margin-left: 10px;
                }
            }
    
            &__img {
                background:#ededed;
                min-height: 140px;
                padding-left: 30px;
                img {
                    max-width: 140px;
                    max-height: 100%;
                    opacity: 0.4;
                }
            }
            h4 {
                margin-top: 0;
                font-size: 18px;
                margin-bottom: 15px;
            }
            a {
                transition: all 0.18s ease;
                padding: 8px;
                &:hover { 
                    color: black;
                    background: #f4f5f6;
                }
            }
        }
        table {
            background: #fff;
            border-collapse: collapse;
            border:0;
            width: 100%;
            max-width: 100%;
            th {
                    background: #f4f5f6;
                    color: black;
                    font-weight: bold;
                    text-align: left;
                    vertical-align: middle;
            }
            td, th {
                border :1px solid #ccc;
                padding: 8px 14px;
                max-width: 280px;
                overflow: hidden;
                vertical-align: middle;
                a {
                    max-width: 200px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .admin__edit__actions {
                width: 140px;
                a {
                    display: inline-block;
                }
                img {
                    width: 48px;
                    height: auto;
                }
            }
            .admin__actions {
                white-space: initial !important;
                vertical-align: middle;
                a {
                    display: inline-block;
                    font-weight: bold;
                    padding: 4px 8px;
                    background: #f4f5f6;
                    border:1px solid #ddd;
                    color:#5B6DCD;
                    font-size: 13px;
                    margin: 4px;
                    &:link, &:active, &:hover, &:visited {
                        color: $m3-darkblue;
                    }
                    &:hover{
                        color: white;
                        background: $m3-blue;
                    }
                }
            }
        }
        form {
            label{
                 font-size: 14px;
                 font-weight: 500;
            }
         input{
             &[type='text'], &[type="email"], &[type="number"] {
                padding: 12px 12px 10px;
                line-height: 1.5;
                color: black;
                border: 1px solid #dedede;
                min-width: 340px;
                margin-bottom: 30px;
             }
             &[type='file'] {
                 background: #f4f5f6;
                 padding: 12px 12px 10px;
                 min-width: 340px;
                 border: 1px dashed #c1c1c1;
                 margin-bottom: 30px;
             }
            //  &[type='submit'] {
            //      appearance: none;
            //      background: #f4f5f6;
            //      color: black;
            //      font-weight: 500;
            //      border: 0;
            //      padding: 15px;
            //      min-width: 240px;
            //      background: $m3-blue;
            //      color: white;
            //      transition: all 0.18s ease;
            //      width: 100%;
            //      &:hover {
            //           background: lighten($m3-blue,5%);
            //      }
            //  }
            &[type="submit"] {
                padding: 8px 50px;
                cursor: pointer;
            }
         }
        }
        &__content {
            background: #fff;
            padding: 60px 30px;
            @include box-shadow-lg;
            &--fluid {
                float: left;
            }
            h1, h2, h3, h4 {
                &:first-child {
                    margin-top: 0;
                }
            }
        }
        &__table {
            margin-bottom: 15px;
            th {
                text-align: left;
                background: #f4f5f6;
            }
            input {
                min-width: auto !important;
                max-width: 100%;
                margin-bottom: 0 !important;
            }
            .totals-row {
                font-weight: bold;
            }
        }

    }
    &__search {
        margin-bottom: 15px;
    }
    a {
        @include unlink($m3-darkblue);
        display: block;
    }
    &__leftbar {
        width: 40px;
        background: $m3-darkblue;
    }
  
    &__navlinks, &__user {
        @media screen and (max-width: 1200px) {
            display: none !important;
        }
    }

    &__logo {
        text-align: center;
        img {
            padding: 10px;
            width: 180px;
            height: auto;
        }
    }
    &__navlinks {
        display: flex;
        a {
            display: inline-block;
            font-weight: bold;
            margin-bottom: 0;
            padding-right: 30px;
            padding-left: 30px;
            position: relative;
            font-size: 15px;
            padding-top: 30px;
            padding-bottom: 30px;
            color: #3C4858 !important;
            &:first-child {
                background: #f4f5f6;
            }
            &:hover {
                background: #f4f5f6;
            }
        }
    }
    &__user {
        text-align: right;
    }
    a.admin__btn {
        padding: 8px 16px 8px 30px;
        display: inline-block;
        position: relative;
        transition: background 0.18s ease;
        border: 1px solid transparent;
        &:hover {
            background: #f4f5f6;
            border: 1px solid white;
        }

        &:before {
            content: "";
            display: inline-block;
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: left center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }
        &.admin__btn--new {
            &:before {
                background-image: url('/content/images/admin/add.svg');
            }
        }
        &.admin__btn--back {
            &:before {
                background-image: url('/content/images/admin/back.svg');
            }
        }
        &.admin__btn--delete {
            &:before {
                background-image: url('/content/images/icons/noun-delete.svg');
            }
        }
    }
    &__alert {
        @include alerts;
    }
    .hint {
        display: block;
        transform: translateY(-20px);
        max-width:340px;
    }
}
.flex-grid-3 {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    @media screen and (max-width: 768px) {
        display: block;
    }
    & > * {
        flex: 1 1 33.33%;
        @media screen and (max-width: 1368px) {
            flex: 1 1 25%;
        }
    }
}
.order {
    &__info {
        padding-top: 15px;
        padding-bottom: 15px;
        h4{
            color: $m3-darkblue;
            font-weight: bold;
            margin-bottom: 15px;
            margin-top: 30px;
        }
        &-links {
            margin-bottom: 30px;
        }
        &-link {
            @include unlink($m3-darkblue);
            font-weight: 400;
            &:hover {
                border-bottom: 1px solid black;
            }
        }
    } 
    &-confirmation__form{
        padding: 50px 30px;
        border-radius: 2px;
        border: 1px solid #dedede;
        margin-left: 40px;
        @media screen and (max-width:992px) {
            margin-left: 0;
        }
        input, select, textarea {
            border: 1px solid #dedede;
            background-color: #fcfcfc;
            color: #000;
            display: inline-block;
            font-weight: 300;
            line-height: 1.5;
            padding: 12px 12px 10px;
            width: 100%;
        }
        input[type="submit"] {
            appearance: none;
            background: #005996;
            border: 0;
            color: #fff;
            float: none;
            font-weight: 500;
            padding: 18px 40px;
            margin-top: 5px;
            width: 100%;
            transition: all .2s ease-out;
            text-align: center;
            margin-bottom: 5px;
            &:hover {
                box-shadow: 0 14px 28px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.22);
            }
        }
        input[type="button"] {
            margin-bottom: 5px;
        }
        .btn-danger {
            font-weight: 400;
            color: white;
            font-size: 16px;
        }
        .form-group {
            margin-bottom: 0;
        }
        h3 {
            margin-top: 30px;
        }
    }
    &__confirmed {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 280px;
        background: #eaeaea;
        color: black;
        font-weight: 400;
        width: 100%;
        p {
           margin: 10px;
        }
    }
}

#SignatureModal,#M3HRejectModal {
    .modal-content {
        border-radius: 3px;
    }
    .btn {
        text-align: center;
        border-radius: 2px;
        margin-bottom: 10px;
        &:hover {
            background: darken(#efefef, 5%);
        }
    }
    .btn-submit {
        padding-left: 30px;
        padding-right: 30px;
        background: $m3-blue;
        color: white;
        &:hover {
            background: lighten($m3-blue, 10%);
        }
    }
    textarea {
        width: 100%;
    }
}

.signature-display {
    position: relative;
    img {
        max-width: 100%;
        border: 1px solid #dedede;
    }
    input.edit {
        font-size: 14px;
        font-weight: 500;
        color:#89929e;
        padding: 10px;
        margin: 0;
        border: 0;
        background: transparent;
        text-align: right;
        position: absolute;
        top: 0px;
        right: 0px;
        width: auto;
        &:hover {
            color: black;
        }
    }
}

.admin__body__content .admin__search__input {
    width: 100%;
    max-width: 100%;
}

.admin__search__input {
    border: 1px solid #dadada;
    background: url('/content/images/admin/search-icon.svg') no-repeat;
    background-size: 24px;
    background-position: right 6px top 15px;
    font-size: 14px;
    margin-bottom: 10px;
    padding: 15px;
    line-height: 1;
}

.admin__table {
    &--fixed {
        table-layout: fixed;
        td, th {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    &__action {
        &:hover {
            background: transparent !important;
        }
        td {
            border: 0;
            padding-bottom: 0;
        }
    }
    &__page {
        font-weight: bold;
        font-size: 16px;
        color: #bababa;
        display: inline-block;
        padding: 0 10px;
        &:hover, &:active, &:focus {
            color: $darkpurple;
            text-decoration: none;
        }
    }
    .cansort {
        position: relative;
        &:after {
            content: '';
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            right: 5px;
            background-image: url('/content/images/admin/sort-active.svg');
            background-size: 24px;
            background-position: center right 5px;
            background-repeat: no-repeat;
            transform: translateY(-50%);
            opacity: 0.14;
        }
        &:hover {
            cursor: pointer;
            &:after {
                opacity: 1;
            }
        }
    }
    &__sort-row {
        &:hover {
            .admin__table__sort-ico {
                background-image: url('/content/images/admin/sort-active.svg');
            }
        }
    }
    &__sort-ico {
        width: 24px;
        height: 24px;
        display: inline-block;
        background: url('/content/images/admin/sort-inactive.svg') center no-repeat;
        background-size: contain;
    }
    &__icon {
        &--lighten {
            opacity: 0.2;
            &:hover {
                opacity: 1;
            }
        }
    }
}

.icon {
    width: 48px;
    height: auto;
}
.flex-btn{
    display: inline-flex !important;
    align-items: center;
    img {
        margin-right: 5px;
    }
}
input[name="emailfeedbacklinkto"] {
    margin-bottom: 0 !important;
}