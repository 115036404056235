.brand {
    &__header {
        background: $m3-blue;
        padding: 120px 40px;
    }
    &__title {
        color: #fff;
        text-align: center;
        font-size: 58px;
        margin-bottom: 10px;

    }
    &__subtitle {
        color: #fff;
        font-size :24px;
        line-height: 1.25;
        text-align: center;
        margin-bottom: 0;
    }
    .aside {
        &__heading {
            font-weight: bold;
            color: $dark-blue;
        }
        &__link {
            border-bottom: 0;
        }
    }
    .thumb__container {
        background: #f4f5f6;
        padding: 20px 20px 25px 20px;
        text-align: center;
        position: relative;
        overflow:hidden;
        img {
            max-width: 100%;
            height: auto;
        }
        &:hover {
            .brand__download {
                visibility: visible;
                opacity: 1;
                top: 66.6%;
            }
        }
    }
    .thumb__label {
        position: absolute;
        bottom: 5;
        left: 0;
        font-size: 12px;
        font-weight: 500;
        width: 100%;
        text-align: center;
        display: block;
        color: rgba(0,0,0,0.5);
    }
    &__download {
        position: absolute;
        top: 100%;
        bottom: 0;
        width: 100%;
        left: 0;
        transition: all 0.18s ease-out;
        background: $m3-darkblue;
        background: rgba(23,45,70,0.98);
        visibility: hidden;
        opacity: 0;
        &-item {
            @include unlink;
            font-weight: bold;
            font-size: 14px;
            color: #bbb !important;
            position: relative;
            display: inline-block;
            padding: 15px 5px 15px 30px;
            transition: background 0.18s ease;
            &:hover {
                color: white !important;
            }
            &:before {
                content: "";
                background: url(/content/images/icons/dl-3.svg) 50% no-repeat;
                background-size: contain;
                display: inline-block;
                height: 30px;
                left: 0px;
                position: absolute;
                top: 46%;
                transform: translateY(-50%);
                width: 30px;
            }
        }
    }
    &__content {
        padding: 40px 0;
        p {
            font-size: 18px;
        }
        &:first-child {
            padding-top: 0;
        }
        h2 {
            margin-bottom: 30px;
        }
        h3 {
            font-weight: bold;
            font-size: 22px;
        }

        .extra-large {
            font-size: 58px;
        }
        .large {
            font-size: 48px;
        }
        .normal {
            font-size: 28px;
        }
        .small {
            font-size: 22px;
        }
        h1,h2,h3,h4 {
            font-weight: bold;
            color: $m3-darkblue;
            margin: 15px 0;
        }
        .code {
            padding: 20px;
            background: $light-grey;
            margin-bottom: 30px;
            span {
                display: block;
            }
        }
        .margin {
            background:rgba(255,0,0,.1);
            list-style:none;
            padding: 15px 60px;
            margin: 0 0 0 -20px;
            text-align: center;
            font-weight: bold;
            font-size: 12px;
        }
        .highlight {
            font-weight: bold;
            
        }
        .inline-link {
            @include unlink;
            font-weight: 400;
            color: $m3-darkblue;
            border-bottom: 2px solid;
            transition: all 0.2s ease;
            &:hover {
                border-bottom: 3px solid;
            }
        }
    }
    .colour {
        box-shadow: 0 20px 30px 0 rgba(0,46,91,.22);
        margin-bottom: 30px;
        .colour__container {
            div {
                width: 100%;
                height: 140px;
            }
        }
        .m3blue {
            background: $m3-blue;
        }
        .m3midblue {
            background: $m3-midblue;
        }
        .m3lightblue{
            background: $m3-lightblue;
        }
        .white{
            background: #fff;
        }
        .lightgray{
            background: $light-grey;
            border-bottom: 1px solid $inputborder;
        }
        .darkblue{
            background: $m3-darkblue;
        }
        .pamwin-green{
            background:$pamwin-green;
        }
        .pamwinlite-green{
            background:$pamwinlite-green;
        }
        .central-red{
            background:$central-red;
        }
        .sor-blue{
            background:$sor-blue;
        }
        .hex, .rgb, .cmyk {
            position: relative;
            cursor: pointer;
            &:after {
                content: attr(class);
                background: $m3-darkblue;
                font-size: 12px;
                position: absolute;
                left: 90%;
                padding: 3px 6px;
                border-radius: 5px;
                top: 50%;
                transform: translateY(-50%);
                color:#fff;
                opacity: 0;
                transition: opacity 0.2s;
                text-transform: uppercase;
            }
            &:hover:after {
                opacity: 1;
            }
        }
        &__description {
            text-align: center;
            padding: 10px;
            background: $light-grey;
            h4 {
                font-weight: bold;
            }
            span {
                display: block;
            }
        }
    }

}