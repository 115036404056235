/************************************************************/
/** CENTRAL PAGE STYLES.                                   **/
/************************************************************/
.central .page-title {
  display: none;
}
.central a.btn.hidden-xs {
  display: none;
}
.central .section-header {
  max-height: 940px;
  background: url(/images/dmImage/StandardImage/central%2D3.png) left top -60px no-repeat;
  background-size: cover;
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(2,0,36);
    background: linear-gradient(0deg, rgba(2,0,36,0.9444152661064426) 0%, rgba(0,0,0,0) 100%);
  }
  &__subnav {
    position: relative;
    margin-top: -30px;
    display: flex;
    gap: 8px;
    justify-content: center;
    @media screen and (max-width: 668px) {
        flex-wrap: wrap;
        margin-top: -25px;
    }
  }
  &__button {
    &-alt {
        align-items: center;
        justify-content: center;
        min-width: 200px;
        padding: 20px;
        height: 70px;
        border: 2px solid #fff;
        color: #fff;
        font-weight: 500;
        animation-delay: 1.2s;
        background: #fff;
        &:link, &:visited, &:hover {
            color: $dark-blue;
            text-decoration: none;
        }
        &:hover {
            box-shadow: 0 14px 28px rgba(253, 253, 253, 0.25), 0 10px 10px rgba(255, 255, 255, 0.20);
            color: lighten($dark-blue, 15%);
        }
        @media screen and (max-width: 668px) {
            display: block;
            width: 100%;
            max-width: 396px;
        }
    }
  }
}
@media screen and (max-width: 768px) {
  .central .section-header {
    max-height: 640px;
  }
}
.central .section-header__image {
  max-width: 100%;
  width: 100%;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
  position: relative;
  z-index: 0;
  margin-top: -70px;
}
@media screen and (max-width: 768px) {
  .central .section-header__image {
    display: none;
  }
}
.central .section-header__button {
  position: relative;
  z-index: 1000;
}
.central .section-header--alt {
  background-image: url("http://www.m3h.co.uk/images/temp/surveyor-0.png");
  background-size: 90%;
  background-position: top 30px right -380px;
}
.central .section__button {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .central .section__button {
    width: 100%;
  }
}
.central .section-main-features img {
  max-height: 540px;
  max-width: 920px;
  -webkit-box-shadow: 1px 1px 100px rgba(0, 0, 0, 0.2);
          box-shadow: 1px 1px 100px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 480px) {
  .central .section-main-features img {
    display: none;
  }
}

.central-demo .page-title {
  display: none;
}
.central-demo .category__subtitle {
  background: #8BC34A;
}
.central-demo video {
  max-width: 100%;
  -webkit-box-shadow: 1px 1px 80px rgba(0, 0, 0, 0.05);
          box-shadow: 1px 1px 80px rgba(0, 0, 0, 0.05);
}

.central-demo .section-demo {
    ul {
        @include unlist;
    }
    a:not([class]) {
        @include unlink--inline;
    }
}

.central-hms {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    line-height: 1.25;
    thead {
        background-color: #f4f4f4;
        letter-spacing: 1px;
        text-transform: none;
        th {
            padding: 12px;
            text-align: left;
            color: #333;
            font-weight: bold;
            border-bottom: 2px solid #ddd;
        }
    }

    tbody {
        tr {
            transition: background-color 0.2s;

            &:nth-child(even) {
                background-color: #f9f9f9;
            }

            &:hover {
                background-color: #f0f0f0;
            }

            td {
                padding: 10px;
                border-bottom: 1px solid #ffffff91;
                color: #555;

                // Add some space for the first column if needed
                &:first-child {
                    font-weight: bold;
                    color: #222;
                }
            }
        }
    }

    // Custom cell styles (flat colors for each type)
    .copy-paste {
        background-color: #fff38b; // Yellow
        color: #5c5c00;
    }

    .email {
        background-color:#fff; // Soft orange
        color: $m3-darkblue;
    }

    .na {
        background-color: #e0e0e0; // Light gray
        color: #555;
    }

    .xml-http {
        background-color: #bbdefb; // Soft blue
        background-color: #00B0F0; // Soft blue
        color: #0d47a1;
        color: #fff;
    }

    .json-iframe {
        background-color: #abe5ad; // Soft green
        color: #1b5e20;
    }

    .both {
        background-color: #00B0F0; // Soft pink
        background-color: #172B46; // Soft pink
        color: #fff;
    }

    // Optional — make it scrollable if needed
    overflow-x: auto;
    display: block;

    // Add responsive tweaks if required
    @media (max-width: 768px) {
        font-size: 14px;

        th, td {
            padding: 8px;
        }
    }
}


.flex-with-icon {
    & > li {
        display: flex;
        margin-bottom: 10px;
    }
}

.faq-container {
    max-width: 800px;
    margin: 40px auto;
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    background-color: #f5f7fa;
}

.faq-header {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.faq-item {
    border-bottom: 1px solid #ddd;
    transition: all 0.3s ease;
    &:last-of-type {
        border-bottom: 0;
    }
    .faq-question {
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f9f9f9;
        border-radius: 5px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #eaeaea;
        }

        &::after {
            content: "+";
            font-size: 20px;
            font-weight: bold;
            color: #666;
        }
    }

    &.active {
        .faq-question::after {
            content: "-";
        }

        .faq-answer {
            display: block;
        }
    }

    .faq-answer {
        display: none;
        padding: 15px;
        font-size: 16px;
        color: #555;
        line-height: 1.6;
        background-color: #ffffff;
        border-left: 3px solid #0078d4;
        border-radius: 5px;
        margin-bottom: 20;
    }
}

// Responsive tweaks
@media (max-width: 600px) {
    .faq-container {
        padding: 15px;
    }

    .faq-question {
        font-size: 16px;
    }

    .faq-answer {
        font-size: 14px;
    }
}
#central-faq {
    border-top: 1px solid #ccc;
}