

.page-header {
    padding: 0;
    margin: 0;
}
.top-nav {
	background-color: $dark-blue;
	&__list {
		@include unlist;
		display: flex;
	}
	&__item {
        margin-left: 20px;
        line-height: 1;
		@media screen and (max-width: 540px) {
			flex: 1 1 auto;	
			margin: 0;
		}
		&:first-of-type {
			margin-left: 0;
		}
		&--search {
            margin-right: auto;
            i {
                @media screen and (min-width: 768px) {
                    order: 1;
                    margin-left: 5px;
                }
            }

			@media screen and (max-width: 540px) {
				margin-right: 0;	
			}
        }
        &--user {
            a {
                padding: 0 !important;
                &:hover {
                    span {
                        color: white;
                    }
                }
            }
            span {
                display: inline-block;
                font-size: 14px;
                padding: 10px;
                color: $text-over-dark-blue;
                line-height: 1.6;
            }
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
	}
	a {
		@include unlink(#fff,#fff);
		font-weight: 500;
		font-size: 14px;
		padding: 10px;
        display: flex;
        align-items: center;
		i {
			margin-right: 5px;
			color: rgba(255,255,255,0.2);
		}
		&:hover {
			background: lighten($dark-blue, 10%);
			background: #005996;
		}
		@media screen and (max-width: 992px) {
			font-weight: 400;
			font-size: 13px;
		}
	}
}
.main-search {
    background: #f4f5f6;
    border-bottom: 1px solid #f4f5f6;
    transition: height 0.2s ease-out;
    position: absolute;
    top: 130px;
    z-index: 9999;
    width: 100%; 
}
.main-search__container {
    padding-top: 30px;
    padding-bottom: 30px;
    @media screen and (max-width: 768px) {
        padding: 30px 15px;
    }
    #main-search__input {
        @include input-lg;
        height: 50px;
        margin-bottom: 0;
    }
    .main-search__button {
        width: 100%;
        height: 50px;
        @include infobutton;
        margin-top: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            font-weight: bold;
            color: rgba(255,255,255,0.8);
            font-size: 30px;
            margin-top: 0px;
            @media screen and (max-width: 768px) {
                font-size: 28px;
            }
        }
    }
}
.nav {
    position: relative;
    font-size: 15px;
    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
	.container {
		@media screen and (min-width: 992px) {
			display: flex;
            justify-content: space-between;
		}
	}
	&__brand {
		width: 190px;
		margin: 10px 0;
        display: inline-block;
        padding-bottom: 10px;
		@media screen and (min-width:992px) {
            display: flex;
			margin: 0 ;
			padding: 0;
            order: 1;
            margin-left: auto;
            margin-right: auto;
		}

		&-link {
			@include unlink;
			font-weight: bold;
			line-height: 1.1;
			font-size: 16px;
			display: flex;
			align-items: center;
		}
		&-image {
			width: 64px;
			height: 64px;
            margin-right: 5px;
            min-width:64px;
        } 
        &-tag {
            width: 126px;
        }
 
	}

	&__menu {
		display: none;
		padding: 10px;
		font-size: 28px;
		right: 10px;
        font-weight: bold;
        float: right;
        cursor: pointer; 
        margin-top: 15px;
		@media screen and (max-width: 992px) {
			display: inline-block;
		}
	}

	&__list {
		@include unlist;
        display: flex;
        transition: opacity 0.2s ease-out;
		@media screen and (max-width: 992px) {
            border-top: 1px solid #ececec;
			display: block;
			@include is-hidden;
		}
		&-item {
			margin-right: 28px;
			flex: 1 1 auto;
			&:last-of-type {
				margin-right: 0;
			}
			@media screen and (max-width: 1200px) {
				margin: 0;
			}
		}
		&-link {
			@include unlink;
			font-weight: 500;
			padding: 30px 10px 34px;
			display: block;
			position: relative;
			@media screen and (max-width:992px) {
				padding: 15px 10px;
			}
			&:hover {
				background: #f4f5f6;
			}
			i {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-62%);
            }
            &.js-active {
                i {
                    transform: rotate(182deg) translateY(62%);
                }
            }
			&.js-has-children {
				padding-right: 30px;
			}
		}
		&--right {
			order: 2;
			@media screen and (max-width: 992px) {
				order: 1;
			}
		}
		&--show {
			@include is-visible;
		}
	}
	&__dropdown {
        z-index: 1001;
        transition: opacity 0.1s ease;
        border-bottom: 4px solid transparent;
        @media screen and (min-width: 992px) {
            border-top: 1px solid #eaeaea;
            border-bottom: 4px solid $dark-blue;
            padding: 70px 0 50px;
			position: absolute;
            background: #fff;
			left: 0;
			right: 0;
        }
        .container {
            display: block;
        }
        &-list{
            @include unlist;
            &-item {
                /** inner list within the dropdown menu **/
                ul {
                    @media screen and (min-width: 992px) {
                        @include unlist;
                        margin-bottom: 40px;
                    }
                }
            }
            &-label {
                font-weight: 500;
            }
        }
		&-link{
            @include unlink;
            transition: none;
            display: block;
            padding: 15px 0 15px;
			@media screen and (max-width: 992px) {
                padding: 10px;
			}
			&:hover {
				background: #f4f5f6;
            }
        }
        &-logos {
            max-width: 56px;
            height: auto;
            display: inline-block;
            margin-right: 10px;
            @media screen and (max-width:1200px) {
               margin-right: 0;
            }
            @media screen and (max-width: 992px) {
                display: none;
            }
        }
    }
}