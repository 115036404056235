/*************************************************/
/* DEMO FORM GENERAL */
/*************************************************/
.section-demo {
  background: #f9f9f9;
  font-family: "Roboto", "sans-serif";
  font-weight: 300;
  font-size: 16px;
}

.demo-form {
  padding: 50px;
  background: #fafafa;
  border-radius: 3px;
}
@media screen and (max-width: 768px) {
  .demo-form {
    padding: 30px 15px;
    margin-top: 30px;
  }
}
.demo-form__list {
  padding: 0;
  margin: 30px 0;
  list-style-type: none;
}
.demo-form__list li {
  margin-bottom: 10px;
}
.demo-form__list + .section__button {
  margin: 0;
}
.demo-form .form-group {
  font-size: 14px;
  height: 36px;
}
.demo-form .error {
  color: indianred;
  font-size: 12px;
}
.demo-form label {
  margin: 0;
  line-height: 1.4;
  color: black;
  font-weight: 500;
  margin-bottom: 5px;
}
.demo-form input[type="text"] {
  width: 100%;
  display: block;
  border: 1px solid #d2d2d2;
  border: 1px solid #0000002b;
  height: 38px;
  padding: 8px 12px;
  font-size: 14px;
}
.demo-form input[type="checkbox"] {
  margin-right: 10px;
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}
.demo-form input[type="checkbox"] + span {
  font-weight: 500;
}
