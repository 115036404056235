.sagepay{
    .baseform {
        margin-bottom: 15px;
        label {
            text-align: left !important;
        }
    }
    .addresslink {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    legend {
        margin-top: 0;
        padding-top: 0;
    }
    &__cards{
        margin-top: 40px;
        img {
            margin-bottom: 15px;
            margin-left: 15px;
            &:first-of-type {
     
            }
        }
    }
    #orderform {
        table {
            border: 1px solid #dedede;
        }
        td, th {
            padding: 10px;
            border: 1px solid #dedede;
        }
    }
    .action {
        margin: 20px 0;
        text-align: right;
    }
    .orderformbutton {
        @include infobutton;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 5px;
        display: inline-block;
        font-size: 16px;
        @media screen and (max-width: 992px) {
            margin-top: 0;
            margin-bottom: 15px;
        }
        &--light {
            background: #eee !important;
            color: $body-text-color !important;
        }
    }
}