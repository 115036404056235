.col-main {
    .info__link {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        .info__icon {
            width: 80px;
        }
    }
}
.col-side {
    background: #fff;
    padding-right:50px;
    border-right: 30px solid #f9f9f9;
}

.support {
    h2 {
        display: block;
        margin-bottom: 15px;
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 15px;
        h2 {
            max-width: 50%;
            @media screen and (max-width:992px) {
                max-width: 100%;
            }
        }
    }
    &__table {
        border-collapse:separate !important;
        border-radius: 4px;
        margin-top: 0;
        outline: 1px solid #dedede;
        td, th {
            border-bottom: 1px solid #dedede;
            padding: 18px;
            &.text-center {
                text-align: center !important;
            }
        }
        th {
            font-weight: bold;
            color: $m3-darkblue;
            background: #fff;
        }
        td {
            background: #fefefe;
        }
        a:link, a:hover, a:visited {
            color: $m3-darkblue;
            font-weight: 500;
        }
    }
    &__table--borderless {
        border: none;
        margin-bottom: 30px;
        border-radius: 10px;
        outline: 4px solid #8bc34a;
        width: 100%;
        td {
            padding: 15px;
            border: 1px solid #dedede;
            &:first-of-type {
                font-weight: 500;
                max-width: 120px;
            }
        }
    }
    .btn__support {
        text-align: center;
        margin: 0;
        border-radius: 2px;
        background: #f4f5f6;
        font-weight: 500;
        padding: 0;
        a {
            padding: 14px 18px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            &:link, &:hover, &:visited {
                color: #333;
                text-decoration: none !important;
            }
            &:hover {
                box-shadow: $shadow-lvl2;
            }
        }
    }
    .section__tabs {
        font-weight: 500;
    }
    &__features {
        &__notice {
            font-style: italic;
            margin: 30px 15px !important;
            max-width: 600px;
            position: relative;
            &.note {
                &:before {
                    content:"*";
                    font-weight: normal;
                    display: inline-block;
                    margin-right: 5px;
                    left: -15px;
                    position: absolute;
                    top: 3px;
                }
            }
        }
    }
    &__blocks {
        .allSteps {
            & > h4 {
                margin-top: 50px;
                margin-bottom: 20px;
            }
        }
    }
    &__block {
        padding: 20px;
        border: 1px solid #dedede;
        border-radius: 14px;
        margin-bottom: 20px;
    }
    &__tags {
       
    }
    &__tag {
        background: #f4f5f6 !important;
        border-radius: 14px !important;
        font-size: 14px;
    }
    &__link {
        a {
            font-weight: 500;
            color: black;
            margin-right: 8px;
            font-size: 14px;
            display: inline-block;
            padding: 4px 8px;
            border-radius: 10px;
            background: #f4f5f6;
            &:link, &:active, &:visited {
                color: black !important;
            }
        }
    }
}
.alert-warning {
    color: #5F4306;
    background-color: #FFF9E6;
    border-color: #F2E5B2;
}
code {
    padding: 2px 6px;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 0.9em;
}
.upgrade-selector {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    background: #8bc34a;
    color: #fff;
    .versionFrom {
        margin-left: auto;
    }
}
.warning-label{
    position: relative;
    padding-left: 20px;
    display: inline-flex;
    &:before {
        content: "warning";
        font-family: "Material Icons";
        font-weight: 400;
        font-style: normal;
        font-size: 18px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-51%);
        color: #bababa;
    }
    &.Minor {
        &:before{
            color: #fcbd11;
        }
    }
    &.Major {
        &:before{
            color: #f75402;
        }
    }
    &.Critical {
        &:before{
            color: #c92525;
        }
    }
}