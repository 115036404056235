.section{
    &-report {
        border-bottom: 0;
       .row {
        margin-bottom: 0;
       }
    }
}

.section {
    &__tools {
        padding-top: 0;
    }
}