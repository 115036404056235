/** TO UPDATE THE CAROUSEL STYLES  **/
#myCarousel {
    height: 100%;
    width: 100%;
    @media screen and (max-width: 992px) {
        height: 480px;
    }
    @media screen and (max-width: 520px) {
        height: 540px;
    }
}
#quoteCarousel{
    min-height: 474px;
}
 
.carousel-fade {
    .carousel-inner {
        height: 100%;
        .item {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            a {
                @include unlink;
                display: flex;
                flex-direction: column; 
                height: 100%;
                justify-content: center;
                @media screen and (max-width: 992px) {
                    padding: 0;
                }
                .carousel-image {
                    order: 1;
                    height: auto;
                    max-width: 100%;
                    height: 100%;
                    max-height: 100%;
                    background-position: center center;
                    @media screen and (max-width: 520px) {
                        // height: 220px;
                        background-position: left center;
                    }
                }
                .carousel-pre-header {
                    color: rgba(255, 255, 255, 0.5);
                    margin-bottom: 10px;
                    font-size: 18px;
                    z-index: 1;
                }
                .carousel-header {
                    @include heading-1;
                    color: white;
                    line-height: 1.05;
                    margin-bottom: 30px;
                    & + .carousel-image {
                        height: 260px;
                        margin: 0 30px;
                    }
                } 
                .carousel-text {
                    @include p-lg;
                    max-width: 100%;
                }
                .carousel-pre-header,
                .carousel-header,
                .carouse-text,
                > p {
                    margin-left: 30px;
                    margin-right: 30px;
                }
            }
        }
        .active {
            opacity: 1;
            &.left, &.right {
                left: 0;
                opacity: 0;
                z-index: 1;
            }
        } 
        .next.left,
        .prev.right {
            opacity: 1;
        }
        p {
            max-width: 100%;
        }

        .custom-template-content {
            display:flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            padding: 30px;
            @media screen and (min-width: 1280px) {
                padding: 60px;
            }
            h1 {
                font-size: 55px;
                line-height: 1;
                margin-bottom: 15px;
                @media screen and (max-width: 1280px) {
                    font-size: 42px;
                }
            }
            p {
                font-size: 22px;
                line-height: 1.2; 
            }
            img {
                max-width: 100%;
                height: auto;
                max-height: 200px;
            }
            .heading--underline {
                padding-bottom: 15px;
                margin-bottom: 15px;
                font-size: 40px;
            }
            table {
                text-align: left;
                table-layout: fixed;
                th {
                    font-weight: bold;
                }
                th, td {
                    padding: 10px 10px 10px 0;
                    font-size: 16px;	
                    min-width: 130px;
                }
            }
        }
    }
    .carousel-control {
        z-index: 2;
        &.left, &.right {
            opacity: 0;
            width: 40px;
            height: 100%;
            position: absolute;
            margin: 0;
            top: 0;
        }
        &.left {
            left: -20px;
        }
        &.right {
            right: -20px;
        }     
    }
    .carousel-indicators {
        width: auto;
        left: 50%;
        bottom: 10px;
        transform: translateX(-50%);
        margin: 0;
        li {
            border: 2px solid rgba(255,255,255,0.4);
            width: 14px;
            height: 14px;
            margin: 2px;
            &.active {
                background: rgba(255,255,255,0.8);
            }
        }
    }
    .carousel-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}  


.carouselWrapper {
    overflow:hidden;
}

.hero {
    .masonry__item-1 {
        padding: 0;
    }
}
// .carousel {
//     .item {
//         a {
//             > * {
//                 border: 5px solid #fb0;
//             }
//         }
//     }
// }

.carousel{
    &-block {
        padding: 80px 40px;
        background-color: #f6f6f9;
        background-image: url(/content/images/icons/ico-at.svg);
        background-repeat: no-repeat;
        background-size: 40%;
        background-position: left -180px top 0;
        background-size: 550px;
        background-position: right -90px top 30px;
        @media screen and (max-width: 768px) {
            padding: 60px 20px;
            background-image: none;
        }
        .carousel-inner {
            max-width: 1200px;
            margin: 0 auto;
        }
    }
}
.quote {
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 260px;
    @media screen and (max-width: 768px) {
        max-width: 100%;
        padding-left: 35px;
    }
    blockquote {
        border-left: 0;
        padding: 0;
        font-size: 30px;
        line-height: 1.4;
        font-weight: lighter;
        margin-bottom: 40px;
        position: relative;
        @media screen and (max-width: 768px) {
            font-size: 28px;
        }
        &:before {
            content: "\201C";
            display: block;
            position: absolute;
            top: -14px;
            left: -54px;
            font-size: 120px;
            font-weight: bold;
            line-height: 1;
            opacity: 0.2;
            transform: rotate(-5deg);
            @media screen and (max-width: 768px) {
                left: -40px;
            }
        }
    }
    figcaption {
        font-weight: bold;
        font-size: 20px;
        small {
            font-weight: 300;
            font-size: 20px;
            color: inherit;
            @media screen and (max-width: 768px) {
                font-size: 18px;
            }
        }
    }
}


#myCarousel {
    .advert {
        display: flex;
        height: 100%;
        flex-direction: column;
        color: black;
        h4 {
            color: rgba(0,0,0,0.7);
            margin-bottom: 15px;
        }
        &__heading {
            font-size: 48px;
            margin-bottom: 15px;
        }
        &__subheading {
            font-size: 30px;
            margin-bottom: 50px;
            &.sm {
                font-size: 26px;
            }
            &.sm-b {
                font-size: 28px;
                font-weight: bold;
                margin-bottom: 30px;
            }
        }
        &__bullet{
            font-size: 28px;
            max-width: 90%;
            position: relative;
            padding-left: 48px;
            &:before {
                content: "";
                display: inline-block;
                position: absolute;
                top: 5px;
                left: 5px;
                width: 32px;
                height: 32px;
                background: url('/content/images/icons/ico-check--gray.svg') center no-repeat;
                background-size: contain;
            }
        }
        &__link {
            font-size: 24px;
            margin-top: auto;
            display: block;
            height: auto;
            z-index: 999;
            strong {
                &:after {
                    content: '';
                    display: inline-block;
                    margin-left: 20px;
                    background: url('/content/images/icons/ico-caret-right.svg') center right no-repeat;
                    background-size: contain;
                    width: 20px;
                    height: 20px;
                    transform: translateY(2px);
                }
            }
        }
        &-training {
            color: black; 
            padding-right: 20px;
            img {
                position: absolute; 
                bottom: 0; 
                right: 0; 
                max-height: 100%; 
                width: 90%; 
                height: auto; 
                z-index: 0;
            }
            .material-icons {
                color: #686868; 
                font-weight: medium;
                transform: translateY(2px);
            }
            .advert__title {
                font-size: 28px;
                margin-bottom: 30px;
                font-weight: bold;
            }
            .advert__subheading {
                font-size: 26px;
                margin-bottom: 5px;
            }
        }
    }
}

