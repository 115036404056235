.vision {
    .section-header {
        background-position: left;
        &:before {
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: #020024;
            background: linear-gradient(0deg,rgba(2,0,36,.8295693277),transparent 95%);
        
        }
    }
    .section-main-features--right {
        @media screen and (max-width:768px) {
            img {
                max-height:100%;
                width: 100%;
            }
        }
    }
}