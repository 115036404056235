
.m-table {
	width: 99.9%;
	text-align: left;
	table-layout: fixed;
	thead {
        tr {
            th {
                border-top: 1px solid #ccc;
                font-weight: 700;
                border-bottom: 1px solid #ccc;
                background: #eee;
                line-height: 1.15;
                vertical-align: middle;
                color: black;
                padding: 15px 20px 14px;
                &:last-of-type {
                    border-right: 0;
                }
                &.half{
                    width: 50%;
                }
                &.quarter {
                    width: 25%;
                }
                &.third {
                    width: 33.33%;
                }
                &.forty {
                    width: 40%;
                }
            }
        }
	}
	tbody {
		tr {
			td, th {
    			border-bottom: 1px solid #eee;
                padding: 15px 20px 14px;
			}
			th {
				border-right: 1px solid #ccc;
                font-weight: 600;
				border-bottom: 1px solid #eee
			}
			td {
				border-right: 1px solid #eee;
                &:last-of-type {
                    border-right: 0;
                }
			}
		}
	}
}
