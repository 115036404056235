
.publications {
  line-height: 1.6;
}
.publications .publication {
  padding: 70px 50px;
}
@media screen and (max-width: 768px) {
  .publications .publication {
    padding: 30px 0;
  }
}
.publications .publication:nth-child(even) {
  background: #f4f5f6;
}
.publications .publication p {
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .publications .publication__image {
    margin-bottom: 40px;
  }
}
.publications .publication__image a {
  display: block;
  width: 100%;
  height: auto;
  max-width: 100%;
}
.publications .publication__image img {
  border: 0;
  max-width: 100%;
}
.publications .publication__title {
  font-size: 28px;
  margin-bottom: 0;
}
.publications .publication__author {
  margin-bottom: 20px;
  color: #2F7BBD;
  font-weight: normal;
  font-size: 17px;
}
.publications .publication__info:first-of-type {
  font-size: 17px;
  line-height: 1.5;
}
.publications .publication__info-list {
  margin: 30px 15px;
}
@media screen and (max-width: 968px) {
  .publications .publication__info-list {
    margin: 20px;
  }
}
@media screen and (max-width: 768px) {
  .publications .publication__info-list {
    margin: 20px 0;
  }
}
.publications .publication__info-list li {
  margin-bottom: 10px;
}
.publications .publication__order {
  display: inline-block;
  padding: 10px 40px;
  font-size: 16px;
  font-weight: 400;
  background: #2F7BBD;
  color: white;
  margin: 15px 0 0 0;
  text-decoration: none;
  -webkit-transition: background 0.2s ease;
  transition: background 0.2s ease;
}
.publications .publication__order:hover, .publications .publication__order:active, .publications .publication__order:focus, .publications .publication__order:visited {
  text-decoration: none;
}
.publications .publication__order:hover {
  background: #256094;
}
