.section__definition {
    @include clearfix;
    background: #f4f5f6;
    padding: 30px 15px 15px;
    margin-top: 30px;
    @media screen and (max-width: 768px) {
        padding: 30px 15px 15px;
    }
    &-item {
        margin-bottom: 15px;
    }
}
.section__highlight {
    background: #f4f5f6;
    padding: 30px;
    margin-top: 50px;
    @media screen and (max-width:768px) {
        padding: 30px 15px;
    }
    &-heading {
        margin: 0 0 15px 0;
    }
    &-content {
        margin-bottom: 15px;
        &:last-of-type {
            margin-bottom: 0;
        }
    } 
}
.link--inline {
    transition: all 0.2s ease;
    font-weight: 400;
    &:link, &:visited, &:active {
        color: black;
    }


}
.section__alert{
    padding: 5px;
    margin: 5px 0;
    display: inline-block;
    color: white;
    line-height: 1;
    font-size: 12px;
    @media screen and (max-width: 768px) {
        
    }
    &--success {
        background: #8BC34A;
    }
    &--warning {
        background: #FF6969;
    }
}
.contact__form--demo {
    .contact__heading {
        padding-top: 40px;
        margin-top: 40px;
        border-top: 1px solid #ddd;
        &:first-child {
            border: 0;
            margin-top: 0;
        }
    }
    .attendees {
        .contact__field {
            margin-bottom: 0;
        }
        .remove {
            width: 26px;
            height: 26px;
            margin-top: 2px;
            display: inline-block;
            i {
                cursor: pointer;
            }

        }
    }
    input[type="submit"] {
      @include infobutton;
      margin: 0 auto;
      width: 100%;
      max-width: 200px;
      display: block;
      padding: 18px 40px;
      font-weight: 500;
    }
    .required:after {
        color: rgba(0,0,0,0.4);
        line-height: 1.1;
        font-size: 14px;
    }
    .contact__field .checkbox-wrapper label:has(input[type='checkbox']:checked)::after {
        display: block;
    }
    .contact__field .checkbox-wrapper {
        float: right;
        width: 50%;
        margin-top: 10px;
    }
    .contact__field.mb0 {
        margin-bottom: 0;
    }
    .checkbox-wrapper {
        margin-bottom: 0;
    }
    .note-block {
        color: inherit;
        font-size: 13px;
        line-height: 1.2;
        background: transparent !important;
        border: 0 !important;
        padding: 0 !important;
        font-style: italic;
        transform: translateY(-15px);
    }
    label[for="termsandconditions"] {
        font-weight: 400;
        line-height: 1.5;
        padding-right: 24px;
    }
    label[for="cancellationterms"] {
        font-weight: 400;
        line-height: 1.5;
        ul {
            font-size: 13px;
            font-weight: 300;
            line-height: 1.4;
            margin-top: 10px;
        }
    }
}
 