.career {
    &__form {
        textarea {
            width: 100%;
            max-width: 100%;
        }
        label {
            font-weight: normal;
        }
        h2 {
            margin-bottom: 30px;
            margin-top: 30px;
        }
        hr {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
}
.about__content{
    .career {
        > h1 {
            &:first-child {
                margin-bottom: 5px;
            }
        }
        h1,h3,h4 {
            color:#3c4858 ;
        }
        h2 {
            color:  #698f48;
            font-size: 24px;
        }
        h3 {
            font-size: 22px;
        }
        ul, ol {
            margin: 30px 0;
            padding: 0 0 0 30px;
            li {
                margin: 0 0 4px 0;
                padding: 0;
                ul, ol {
                    padding: 0 0 15px 0;
                    margin: 15px 0 30px 30px;
                }
            }
            .li-header {
                list-style-type: none;
                padding-left: 10px;
                font-weight: bold;
                margin: 25px 0 15px -20px;
                padding: 0;
            }
      
        }
    }
}

