#stdLogin .new-registration, #stdLogin .main-login {
  padding: 50px;
}
@media screen and (max-width: 992px) {
  #stdLogin .new-registration, #stdLogin .main-login {
    padding: 40px;
  }
}
@media screen and (max-width: 768px) {
  #stdLogin .new-registration, #stdLogin .main-login {
    padding: 30px 15px;
  }
}
#stdLogin .new-registration a {
  margin-top: 15px;
  @include infobutton;
  padding: 15px 20px;
}
@media screen and (max-width: 768px) {
  #stdLogin .new-registration a {
    max-width: 100%;
    text-align: center;
  }
}

#stdLogin .main-login {
    @include baseform;
}

.forgotten-password {
  @include baseform;
  h2 {
      @include heading-2;
      margin-bottom: 30px;
  }
}


.login-registration {
  @include baseform;
  h2 {
      @include heading-2;
      margin-bottom: 30px;
  }
  ul {
      @include unlist;
  }
  .invalid-password {
      color: $error;
  }
}

.register-user {
    p {
        &:first-of-type {
            font-size: 18px;
        }
    }
    form {
      @include baseform;
      padding-bottom: 35px;
      .form__group {
          margin-bottom: 10px;
          &--actions {
              margin-bottom: 0;
              margin-top: 30px; 
          }
      }
      .btn-submit {
          @include infobutton;
          font-size: 15px;
          font-weight: 500;
          width: 100%;
          min-width: auto;
          padding: 12px;
      }
      .btn-cancel {
          padding: 12px;
          font-weight: 500;
          color: black;
          display: block;
          text-align: center;
          margin-top: 15px;
      }
    }
}