.store {
    &__header {
        background-color: $m3-blue;
        padding-top: 80px;
        padding-bottom: 0;
        color: white;
        margin-bottom: 60px;
        min-height: 272px;
        h1 {
            font-size: 44px;
        }
        &__tabs {
            border: 0;
            list-style-type: none;
            padding: 0;
            margin: 70px 0 0 0;
        }
        &__tab {
            display: inline-block;
            min-width: 160px;
            &.active {
                background-color: white;
                color: $m3-darkblue;
                a:hover,a:visited,a:active,a:link {
                    color: $m3-darkblue !important;
                    text-decoration: none !important;
                }
            }
            a {
                padding: 20px 16px 16px 40px;
                display: block;
                border: 0 !important;
                font-weight: bold;
                background-size: 32px;
                background-position: left 8px center;
                background-repeat: no-repeat;
                color: white;
                outline: none;
                &:hover, &:visited, &:active &:link {
                    color: rgba(255,255,255,0.8) !important;
                    text-decoration: none !important;
                }
                &:hover {
                    color: #ffffff !important;
                }
            }
            &.organisation {
                a {
                    background-image: url('/content/images/icons/ico-org-white.svg');
                }
                &.active {
                    a {
                        background-image: url('/content/images/icons/ico-org.svg'); 
                    }
                }
            }
            &.contractors {
                a {
                    background-image: url('/content/images/icons/ico-eng-white.svg');
                }
                &.active {
                    a {
                        background-image: url('/content/images/icons/ico-eng.svg');
                    }
                }
            }
            &.upcoming-courses {
                a {
                    background-image: url('/content/images/icons/ico-calendar-add-fff.svg');
                    background-size: 28px;
                    background-position: center left 8px;
                }
                &.active {
                    a {
                        background-image: url('/content/images/icons/ico-calendar-add.svg');
                    }
                }
            }
            &.all-courses {
                a {
                    background-image: url('/content/images/icons/ico-courselist-fff.svg');
                    background-size: 28px;
                    background-position: center left 8px;
                }
                &.active {
                    a {
                        background-image: url('/content/images/icons/ico-courselist.svg');
                    }
                }
            }
            &.package-courses {
                a {
                    background-image: url('/content/images/icons/ico-package-fff.svg');
                    background-size: 28px;
                    background-position: center left 8px;
                }
                &.active {
                    a {
                        background-image: url('/content/images/icons/ico-package.svg');
                    }
                }
            }
        }
        &__img {
            width: 100%;
            max-width: 200px;
            height: auto;
            float: left;
            margin-bottom: 30px;
            @media screen and (max-width: 992px) {
                float: none;
                max-width: 140px;
            }
        }
        &__inner {
            padding-left: 40px;
            padding-bottom: 40px;
            float: left;
            max-width: 820px;
            width: 100%;
            @media screen and (max-width: 1200px) {
                padding-left: 10px;
                max-width: 740px;
            }
            @media screen and (max-width: 992px) {
                float: none;
                padding-left: 0;
            }
            h1 {
                margin-bottom: 15px;
            }
        }
        &__fields {
            position: relative;
        }
        &__field {
            margin-bottom: 20px;
            max-width: 300px;
            overflow: hidden;
            select {
                color: $m3-darkblue;
                outline: 0;
                padding: 8px;
                border: 1px solid #dedede;
                outline: 0;
                width: calc(100% + 40px);
                appearance: none;
                background-color: white;
                background-image: url(/content/images/icons/ico-caret-down-thin.svg);
                background-repeat: no-repeat;
                background-size: 14px;
                background-position: right 50px center;
                &:disabled {
                    background-color: rgba(255,255,255,0.3);
                    border: 1px solid #fff;
                }
            }
            label {
                display: block;
                margin-bottom: 10px;
                font-size: 14px;
                font-weight: 500;
                color: rgba(255,255,255,0.6);
                margin-bottom: 6px;
            }
            input[type="text"], 
            input[type="number"] {
                color: #172b46;
                padding: 8px;
                border: 1px solid #dedede;
                outline: 0;
                width: 100%;
                &:disabled {
                    background-color: rgba(255,255,255,0.3);
                    border: 1px solid #dedede;
                }
            }
            &.dropdown {
               overflow: visible;
               position: relative;
               .dropdown-menu {
                   width: 100%;
                   left: 0;
                   right: 0;
                   color: $m3-darkblue;
                   padding: 10px;
                   line-height: 1.8;
                   border-radius: 0;
                   background-clip: border-box;
                   margin-top: 1px;
                   li {
                       margin: 10px 0;
                       &:hover {
                           cursor: pointer;
                       }
                   }
               }
            }
            &.has-success {
                input[type="text"], 
                input[type="number"],
                select {
    
                }
            }
            &.has-error {
                box-shadow: 0 5px 0px 0px $error;
                input[type="text"], 
                input[type="number"],
                select {
                    color: $error;
                }
            }
        }
        &__teaser {
            font-size: 24px;
            font-weight: 300;
            line-height: 1.4;
            margin-bottom: 30px;
            a {
                @include unlink(#fff);
                font-size: 16px;
                font-weight: 600;
            }
        }
        .inline-message--success {
            margin-top: 10px;
        }
        &__note {
            padding: 30px;
            background: rgba(0,0,0,0.2);
            @media screen and (max-width: 768px) {
                margin-bottom: 15px;
            }
            h3 {
                margin-bottom: 20px;
                font-weight: 600;
                font-size: 20px;
                color: rgba(255,255,255,0.4);
            }
            ul {
                margin: 0;
                padding: 0;
                font-weight: 300;
                line-height: 1.4;
                li {
                    margin-bottom: 15px;
                    margin-left: 20px;
                }
            }
            a {
                @include unlinkunderline(#fff,#fff);
            }
        }
    }
    &__listing {
        display: flex;
        margin-bottom: 50px;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
           display: block;
        }
        &__notice {
            font-weight: 300;
            font-size: 16px;
            font-style: italic;
            padding-right: 60px;
            max-width: 800px;
            position: relative;
            padding-left: 20px;
            @media screen and (max-width: 768px) {
                margin-bottom: 30px;
             }
            &:before {
                content: '*';
                display: block;
                width: 15px;
                height: 30px;
                position: absolute;
                left: 0;
                top: 5px;
            } 
            &.full {
                max-width: 100%;
                margin-bottom: 40px;
                color: inherit;
                font-style: normal;
                padding-left: 0;
                &:before {
                    display: none;
                }
            }   
        }
        &__filter {
            span {
                display: inline-block;
                margin-right: 10px;
            }
            select {
                padding: 8px;
                border: 1px solid #dedede;
                outline: 0;
            }
        }

    }
    &__category{
        margin-bottom: 60px;
        &__title {
            font-size: 18px;
            font-weight: bold;
        }
    }
    &__items {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
    }
    &__item {
        background: #f4f5f6;
        margin: 15px;
        flex: 0 1 calc(33.33% - 30px);
        flex-basis: calc(33.33% - 30px);
        max-width: calc(33.33% - 30px);
        padding: 24px 24px 30px 24px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        @media screen and (max-width: 992px) {
            flex: 0 1 calc(50% - 30px);
            flex-basis: calc(50% - 30px);
            max-width: calc(50% - 30px);
        }
        @media screen and (max-width: 768px) {
            flex: 1 1 100%;
            flex-basis: 100%;
            max-width: 100%;
        }
        &__header {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
        }
        &__name {
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 600;
        }
        &__logo, &__img {
            max-width: 68px;
            margin-right: 6px;
            height: auto;
        }
        &__description {
            margin-bottom: 80px;
            @media screen and (max-width:768px) {
                margin-bottom: 100px;
            }
        }
        &__actions {
            margin-top: auto;
            div {
                @media screen and (max-width: 768px) {
                    text-align: center;
                }
                a {
                    display: inline-block;
                    img {
                        margin-top: 5px;
                        @media screen and (max-width: 992px) {
                            height: 60px;
                        }
                    }
                }
            }
        }
    }
    &__btn {
        appearance: none !important;
        border: 0;
        border-radius: 3px;
        padding: 10px 18px;
        font-size: 14px;
        font-weight: bold;
        color: $m3-darkblue;
        transition: all 0.18s cubic-bezier(.25,.8,.25,1);
        display: inline-block;
        text-align: center;
        min-width: 120px;
        outline: 0;
        cursor: pointer;
        > img{
            max-width: 28px;
            margin-right: 3px;
            transform: translateY(-2px);
        }
        @media screen and (max-width: 768px) {
            width: 100%;
        }
        &:hover, &:active, &:visited, &:link {
            text-decoration: none;
            color: $m3-darkblue;
        }

        &--quote {
            background: white;
            border: 1px solid #dedede;
            &:hover {
                @include box-shadow-4;
            }
        }
        &--buy {
            background: $m3-blue;
            color: white;

            &:hover, &:active, &:visited, &:link {
                color: white;
            }
            &:hover {
                @include box-shadow-4;
            }
        }
        &--info {
            &:hover {
                text-decoration: underline;
            }
        }
        &--add {
            background: $m3-blue;
            color: white !important;
            &:hover {
                background: #fff;
            }
        }
        &--related {
            font-weight: bold;
            color: $m3-darkblue;
            font-size: 16px;
            appearance: none;
            background: transparent;
            border: 0;
            padding: 0;
            margin-bottom: 15px;
            &:hover, &:active, &:focus {
                outline: 0 !important;
            }
        }
    }
    &__contact {
        text-align: center;
        background: #e6e8eb;
        font-size: 18px;
        font-weight: 300;
        color: $m3-darkblue;
        img {
            width: 120px;
            max-width: 100%;
            height: auto;
        }
        h2 {
            font-size: 32px;
        }
        p {
            font-weight: 300;
        }
    }
    &__product {
        background: #F4F5F6;
        margin-bottom: 15px;
        border: 1px solid #eaeaea;
        padding: 20px 20px 20px 70px;
        @media screen and (max-width: 768px) {
            padding: 20px 20px 20px 50px;
        }
        &__img {
      
        }
        &__desc{
            position: relative;
            margin-bottom: 15px;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: -2px;
                left: -50px;
                width: 48px;
                height: 48px;
                background-image: url('/content/images/icons/ico-bookmark.svg');
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
        &__action {
            text-align: center;
            float: right;
            min-width: 160px;
            @media screen and (max-width:992px) {
                min-width: auto;
                text-align: left;
                float: none;
            }
        }
        &__heading {
            font-weight: bold;
            font-size: 20px;
            line-height: 1.4;
            color: $m3-darkblue;
            margin-bottom: 5px;
            margin-top: 0;
        }
        &__teaser, &__price {
            font-weight: 300;
            font-size: 16px;
            color: $m3-darkblue;
            margin-bottom: 0px;
            @media screen and (max-width: 768px) {
                margin-bottom: 15px;
            }
        }
        &__price {
            text-align: right;
            font-size: 18px;
            @media screen and (max-width: 768px) {
                text-align: left;
                font-size: 22px;
                margin: 15px 0;
            }
            label {
                font-size: 14px;
                font-weight: 500;
            }
            input[type="text"],
            input[type="number"] {
                color: #172b46;
                padding: 8px;
                border: 1px solid #dedede;
                outline: 0;
                max-width: 100px;
                text-align: right;
                margin-left: 5px;
            }
        }
        .store__btn--related {
            margin: 15px 0;
        }
        .inline-message--success {
            margin-bottom: 10px;
            display: inline-block;
            @media screen and (min-width: 992px) {
                display: block;
                width: 160px;
            }
        }
    }
    &__related-product {
        padding-top: 25px;
        padding-bottom: 25px;
        border-top: 1px solid #dedede;
        padding-left: 44px;
        @media screen and (max-width: 992px) {
            padding-left: 30px;
        }
        .store__product__desc {
            margin-bottom: 0;
            @media screen and (max-width: 992px) {
                margin-bottom: 15px;
            }
            &:before {
                width: 26px;
                height: 26px;
                left: -32px;
                top: -4px;
                opacity: 1;
                background-image: url('/content/images/icons/ico-add.svg');
                background-size: 16px;
                background-position: center;
                border-radius: 50%;
            }
        }
        .store__product__heading {
            font-weight: 500;
            font-size: 16px;
        }
        .store__product__img {
            padding-left: 40px;
        }
 
    }
    &__nav {
        text-align: center;
        padding-top: 30px;
        padding-bottom: 60px;
        .btn--submit {
            max-width: 180px;
            margin: 0 15px;
        }
    }
    &__cart, &__checkout {
        margin: 80px 0;
        color: $m3-darkblue;
        &--empty {
            text-align: center;
            color: $m3-darkblue;
            margin-bottom: 60px;
            h3 {
                font-weight: bold;
            }
            a {
                font-weight: bold;
                font-size: 16px;
                color: $m3-blue;
            }
        }
        .form__confirmation {
            label {
                a {
                    @include unlink($m3-darkblue);
                    font-weight: 500;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .inline-message--success, .inline-message--error {
            font-size: 14px;
        }
    }
    &__checkout {
        @include checkoutform;
    }
    &__price {
        padding-top: 30px;
        padding-bottom: 30px;
        h3 {
            margin-bottom: 30px;
        }
        &-table {
            table-layout:fixed;
            text-align: right;
            th {
                background: #f4f5f6;
            }
            td, th {
                text-align: right;
                padding: 12px !important;
            }
            td, th {
                &:first-of-type {
                    padding-left: 0;
                    text-align: left;
                }
            }
            tr{
                &:nth-child(even) {
                    background:#f4f5f6;
                }
            }
        }
    }
}
.cart {
    &__nav {
        text-align: right;
        margin-bottom: 15px;
    }
    &__summary {
        background: #f4f5f6;
        border:1px solid #eaeaea;
        padding: 25px;
        color: $m3-darkblue;
        font-weight: 300;
        margin-left: auto;
        margin-bottom: 20px;
        &__title {
            margin-bottom: 25px;
            font-size: 18px;
            font-weight: 600;
        }
        
        &__row {
            @include clearfix;
            margin-bottom: 4px;
        }
        &__label, &__amount {
            width: 50%;
            strong {
                font-weight: bold;
                font-size: 18px;
            }
        }
        &__label {
            float: left;
        }
        &__amount {
            float: right;
            text-align: right;
        }
        .store__btn {
            width: 100%;
            margin: 25px 0;
        }
        .btn--submit {
            margin:15px 0;
        }
    }
    &__message {

    }
}
.basket {
    &__item {
        padding: 30px 0;
        border-top: 1px solid #dedede;
        &__title {
            margin-bottom: 0px;
            font-weight: 600;
            @media screen and (max-width: 768px) {
                max-width: 70%;
            }
        }
        &__price {
            text-align: right;
        }
        &__qty {
            @media screen and (min-width:992px) {
                text-align: right;
            }
        }
        &__btn-remove {
        }
        &__action {
            text-align: right;
            @media screen and(max-width:992px) {
                text-align: left;
            }
        }
    }
    &__header {
        padding-bottom: 15px;
        color: rgba(0,0,0,0.4);
        font-weight: bold;
        margin-top: 10px;
        font-size:13px;
        text-transform: uppercase;
        &__price {
            text-align: right;
        }
        &__qty {
            @media screen and (min-width:992px) {
                text-align: right;
            }
        }
        &__action {
            text-align: right;
            @media screen and(max-width:992px) {
               display: none;
            }
        }
    }
}
.order {
    &__complete {
        text-align: center;
        color: $m3-darkblue;
        font-size: 20px;
        img {
            max-width: 120px;
            width: 100%;
            height: auto;
            margin-bottom: 30px;
        }
        &__heading {
            margin-bottom: 5px;
            font-weight: bold;
        }
        &__reference {
            font-weight: bold;
            margin-bottom: 30px;
        }
        &__description {
            a {
                 font-weight: bold;
                 @include unlink($m3-darkblue);
                 &:hover {
                     border-bottom: 1px solid $m3-darkblue;
                 }
            }
        }
    }
    &__failed {
        color: $m3-darkblue;
        font-size: 18px;
        .inline-message--error {
            font-size: 20px;
            margin-bottom: 40px;
        }
        ol {
            margin-left: 0px;
            padding-left: 20px;
            li {
                margin-bottom: 10px;
            }
            ul {
                margin: 30px;
            }
        }
        &__heading {
            margin-top: 30px;
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: bold;
            color: $m3-darkblue;
        }
        a {
            font-weight: bold;
            @include unlink($m3-darkblue);
            &:hover {
                border-bottom: 1px solid $m3-darkblue;
            }
        }
    }
}
.store__btn--full {
    max-width: 100%;
}