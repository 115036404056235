@mixin flex-grid($colslg:4, $colsmd:2, $colssm:2, $gutter:15px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -$gutter;
    @media screen and (max-width: 520px) {
        display: block;
    }
    > .col {
        margin: $gutter;
        flex: 0 1 calc( (100% / #{$colslg}) - (#{$gutter} * 2));
        flex-basis: calc( (100% / #{$colslg}) - (#{$gutter} * 2));
        @media screen and (max-width: 992px) {
            flex: 0 1 calc( (100% / #{$colsmd}) - (#{$gutter} * 2));
            flex-basis: calc( (100% / #{$colsmd}) - (#{$gutter} * 2));
        }
        @media screen and (max-width: 768px) {
            flex: 0 1 calc( (100% / #{$colssm}) - (#{$gutter} * 2));
            flex-basis: calc( (100% / #{$colssm}) - (#{$gutter} * 2));
        }
        @media screen and (max-width: 520px) {
            flex: 1; 
        }
    }
 
}

.flex-grid-auto {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    @media screen and (max-width: 992px) {
        justify-content: flex-start;
    }
    @media screen and (max-width: 520px) {
        display: block;
    }
    .col {
        flex: 1;
        @media screen and (max-width: 992px) {
            flex-basis: 50%;
            flex-grow: 0;
        }
        @media screen and (max-width: 768px) {
            flex-basis: 100%;
        }
    }
}

 
.flex-grid-2 {
    @include flex-grid(2, 2, 1, 15px);
}
.flex-grid-3 {
    @include flex-grid(3, 2, 1, 15px);
} 
.flex-grid-4 {
    @include flex-grid(4, 2, 2, 15px);   
} 
.flex-grid-5 {
    @include flex-grid(5, 3, 2, 15px);
}
.flex-grid-6 {
    @include flex-grid(6, 3, 2, 15px);
}