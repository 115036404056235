.section--news {
    background: #fff;
    border-top: 1px solid $light-grey;
}
.news {
    &__heading {
        @include heading-1;
        margin-bottom: 40px;
        text-align: center;
        @media screen and (max-width: 768px) {
            margin-bottom: 25px;
            text-align: left;
        }
        a {
            @include unlink;
        } 
    }
    &__item {
        background: #fff;
        @media screen and (max-width: 992px) {
            margin-bottom: 30px;
        }
        @media screen and (max-width: 768px) {
            margin-bottom: 15px;
        }
        &:nth-child(1n) {
            background-color: #F6F9FC;
            a {
                @include unlink(inherit,#0397D7);
            }
        }
        &:nth-child(2n) {
            background-color: $dark-blue;
            color: white;
            a {
                @include unlink(#fff,#0397D7);
            }
        }
        &:nth-child(3n) {
            background: #0397D7; 
            background: #0b3875;
            background: $m3-blue;
            color: white;
            a {
                @include unlink(inherit,$dark-blue);
            }
        }
    }
    &__image {
        height: auto; 
        max-width: 100%;
        width: 100%;
    }
    &__details {
        padding: 30px;
        position: relative;
        &:before {
            content:"";
            display: inline-block;
            width: 30px;
            height: 30px;
            background-image: url('/content/images/icons/twitter.svg');
            background-size: cover;
            z-index: 100;
            position: absolute;
            right: 20px;
        }
    }
    &__date, &__handle {
        font-size: 14px;
        font-weight: 500;
        display: block;
    }
    &__handle {
        font-weight: 600;
        @include unlink;
        display: inline-block;
    }
    &__description {
        margin-top: 30px;
        a {
            @include unlink;
            font-weight: 600;
            word-break: break-word;
        }
    }
} 

