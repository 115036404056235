
.privacy-message {
    &__overlay {
        background: rgba(0,0,0,.85);
        bottom: 0;
        left: 0;
        color: #fff;
        position: fixed;
        width: 100%;
        z-index: 10000000000;
        @media screen and (max-width: 768px) {
            background: rgba(0,0,0,.92);
        }
    }
    &__content {
        display: block;
        max-width: 1280px;
        margin: 0 auto;
        border-radius: 0;
        padding-top: 16px;
        padding-bottom: 32px;
        font-weight: 300;
        font-size: 15px;
        position: relative;
        h2 {
            font-size: 21px;
            line-height: 30px;
            font-weight: 300;
            color: white;
            font-family: 'Open sans', sans-serif;
            margin-top: 16px;
        }
    }
    &__close {
        background-position-x: 0;
        background-position-y: 0;
        background-image: url(/content/images/icons/close-2.svg);
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;
        height: 16px;
        position: absolute;
        text-indent: -9999px;
        top: 32px;
        right: 15px;
        width: 16px;
    }
    &__link {
        color: white !important;
        &:link, &:active, &:hover, &:focus {
            color: white;
            text-decoration: underline;
        }
    }
}