.clients {
    &__heading {
        @include heading-1;
        text-align: center;
        margin-bottom: 40px;
    }
    &__list {
        @include unlist;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        li {
            max-width: 240px;
            padding: 0px 20px;
            img {
                max-width: 100%;
                max-height: 100px;
                height: auto;
                filter: grayscale(1);
                opacity: 0.7;
            }
            @media screen and (max-width: 768px) {
                max-width: 50%;
            }
        }
    }
}


