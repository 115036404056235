.partners {
    &__heading {
        text-align: center;
    }
    &__subheading {
        text-align: center;
    }
    .cards {
        margin: 30px 0;
    }
    .card {
        display: inline-flex;
        flex-direction: column;
        position: relative;
        &__overlay__info{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $heading-text-color;
            font-size: 1.5em;
            text-align: center;
            opacity: 0;
            transition: opacity 0.2s ease;
            margin: 0;
            padding: 10px;
            width: 100%;
            background: #ffffffc7;
        }
        &__title {
            margin-bottom: 5px;
            line-height: 1;
            font-size: 18px;
        }
        &__category {
            font-size: 13px;
            font-weight: 500;
            text-transform: capitalize;
            color: inherit;
        }
        &__img {
            width: 100%;
            height: auto;
            display: block;
            transition: all 0.3s ease;
            filter: grayscale(1);
            opacity: .8;
        }
    }
    .card__overlay {
        position: relative;
        &:hover {
            .card__overlay__info {
                opacity: 1;
            }
        }
    }

}
.flex-center {
    justify-content: center !important;
}