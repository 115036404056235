@import 'colors';
@import 'mixins';
@import 'flex-grid';
@import 'buttons-and-links';

@import 'components/header';
@import 'components/masonry';
@import 'components/homepage';
@import 'components/clients';
@import 'components/newsletter';
@import 'components/news';
@import 'components/events';
@import 'components/teams';
@import 'components/footer';
@import 'components/carousel';
@import 'components/products';
@import 'components/crm-renewal';
@import 'components/internal-feedback';
@import 'components/training';
@import 'components/error404'; 
@import 'components/store';
@import 'components/admin';
@import 'components/careers';
@import 'components/training-upcoming';
@import 'components/iframe';
 

@import 'components/navbar';
@import 'components/tendersafe';
@import 'components/publication';
@import 'components/usergroup';
@import 'components/section';
@import 'components/pamwin';
@import 'components/central';
@import 'components/hatc';
@import 'components/vision';
@import 'components/login';
@import 'components/search';
@import 'components/repairhandbooks'; 
@import 'components/usergroup-table';
@import 'components/schedules';
@import 'components/lite';
@import 'components/demoform';
@import 'components/demo';
@import 'components/contact';
@import 'components/forms';
@import 'components/sagepay';
@import 'components/videos';
@import 'components/tools';
@import 'components/cookies';
@import 'components/brand';
@import 'components/faq';
@import 'components/about';
@import 'components/notices';
@import 'components/article';
@import 'components/store-update';
@import 'components/custom-form-elements';
@import 'components/tables';
@import 'components/scripts';
@import 'components/fm';
@import 'components/support';
@import 'components/partners';

html, body {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: 16px; 
    color: $body-text-color;
    line-height: 1.6;
	margin: 0; 
	padding: 0;
}
body {
    background: #f4f5f6;
}

p {
    margin-bottom: 15px;
}
/******************************
Page and containers
******************************/
.page {
	max-width: 1600px;
	margin: 0 auto;
	background-color: #fff;
	position: relative;
}
.wrapper {
	max-width: 1280px;
	width: 100%;
	padding: 0 70px;
	margin: 0 auto;
	@media screen and (max-width: 992px) {
		padding: 0 15px;
	}
}
.container {
    background: transparent;
}

.no-header {
    .page__header {
        display: none;
    }
}
.js-is-hidden {
    @include is-hidden;
}
.js-is-visible {
    @include is-visible;
}
#mainContent{
    @include clearfix;
}
h1 {
    @include heading-1;
}
h2 {
    @include heading-2;
}
.baseform {
    @include baseform;
}

.default-btn{
    @include defaultbutton;
    &.full {
        width: 100%;
    }
}
.offset-top-25 {
    margin-top: -25px;
}
