.article {
    &--inner {
        img {
            max-width: 100%;
            height: auto;
            margin-bottom: 30px;
        }
    }
    &__title {
        font-weight: 700;
        margin-top: 0;
        font-size: 40px;
        color: $m3-darkblue;
        margin-bottom: 15px;
        @media screen and (max-width: 768px) {
            font-size: 34px;
        }
        &__link {
            @include unlink($m3-darkblue, $m3-darkblue);
            // border-bottom: 1px solid transparent;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__intro {
        font-size: 20px;
        margin-bottom: 15px;
        @media screen and (max-width: 768px) {
            font-size: 18px;
        }
    }
    &__footer {
        margin-bottom: 15px;
        font-size: 16px;
    }
    &__social {
        display: block;
        float: right;
    }  
    &__date, &__author {
        display: inline-block;
        position: relative;
        padding: 6px 20px 6px 24px;
        font-weight: 500;
        &:before {
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          
        }
    }
    &__date {
        &:before {
            background: url('/content/images/icons/clock.svg') 50% no-repeat;
            background-size: cover;
        }
    }
    &__author {
        &:before {
            background: url('/content/images/icons/pen3.svg') 50% no-repeat;
            background-size: contain;
        }
        a {
            &:hover, &:active, &:visited, &:link {
                color: $m3-darkblue;
            }
        }
    }
    &__social {
        a {
            display: inline-block;
            img {
                width: 32px;
                height: 32px;
                margin: 0;
            }
        }
    }
    &__aside {
        margin-bottom: 50px;
        h4 {
            font-weight: 700;
            margin-top: 0;
            font-size: 22px;
            color: $m3-darkblue;
            margin-bottom: 15px;
            font-size: 22px;
        }
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            background: transparent;
            color: inherit;
            li {
                padding: 0;
                margin: 0;
                border: 0;
                a, a:link, a:visited, a:active {
                    color: $m3-darkblue;
                    font-size: 15px;
                    transition: all .1s ease;
                    font-weight: 400;
                    display: inline-block;
                    margin-bottom: 5px;
                    text-decoration: none;
                }
            }
        }
    }
    &__tag {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 1;
        font-weight: 500;
        a, a:hover, a:active, a:visited {
            text-decoration: none;
            color: $m3-darkblue;
        }
    }
    &__search {
        &__button {
            appearance: none!important;
            border: 0;
            background: #eaeaea;
            padding: 6px 28px;
            transition: all 0.14s ease;
            &:hover {
                background: $m3-blue;
                color: white;
            }
        }
        &__input {
            margin-bottom: 5px;
            padding: 4px 10px;
            border: 1px solid #dedede;
        }
    }
    &__image {
        display: block;
        height: auto;
        max-width: 100%;
        width: 100%;
        margin-bottom: 15px;
        background: #f4f5f6;
    }
    &__downloads{
        margin: 50px 0;
        @media screen and (max-width:768px) {
            margin: 30px 0;
        }
        &__heading {
            font-size: 22px;
            color: $m3-darkblue;
            font-weight: 700;
        }
        a {
            @include unlink($m3-darkblue, $m3-darkblue);
            font-weight: 400;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__more-link {
        @include unlink($m3-darkblue, $m3-darkblue);
        padding-right: 30px;
        display: inline-block;
        background: url('/content/images/icons/caret-right-active.svg') right center no-repeat;
        background-size: 14px;
        line-height: 1;
        font-weight: 500;
    }
    &__empty {
        padding-left: 10%;
        padding-right: 10%;
        min-height: 40vh;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    &__tag-list{
        padding: 0;
        margin: 0;
        @include clearfix;
        li {
            list-style-type: none;
            float: left;
            margin-right: 5px;
            a {
                &:link, &:active, &:visited, &:hover {
                    display: block;
                    padding: 4px 16px;
                    border: 1px solid #eee;
                    background: #f4f5f6;
                    border-radius: 10px;
                    color: $m3-darkblue;
                    transition: all 0.2s ease;
                    text-decoration: none;
                }
                &:hover {
                    background-color: $m3-blue;
                    color: white;
                }
            }
        }
    }
    &__pagination {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 50px;
        margin-bottom: 20px;
        a {
            @include unlink($m3-darkblue, £m3-darkblue);
            transition: all .1s ease-in-out;
            display: inline-block;
            background-size: 14px;
            line-height: 1;
            font-weight: 500;
            background-size: contain;
            background-repeat: no-repeat;
            &.next {
                padding-right: 30px;
                background-image: url('/content/images/icons/caret-right-active.svg');
                background-position: right center;
                margin-left: auto;
     
            }
            &.prev {
                padding-left: 30px;
                background-image: url('/content/images/icons/caret-left-active.svg');
                background-position: left center;
            }
        }
    }
    .paginator {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        span, a {
            &.p-previous, &.p-next {
                padding: 4px 32px;
                font-weight: 700;
                margin: 0px;         
                background: transparent;
            }
        }
        span {
            &.p-previous, &.p-next {
                color: #bcbcbc;
            }
            &.p-page {
                font-weight: 700;
                color: $m3-darkblue;
                padding: 4px 16px;
                text-decoration: underline;
            }

        }
        a {
            &.p-previous, &.p-next, &.p-page {
                color: $m3-darkblue;
                border: 0;
                transition: all 0.14s ease;
                &:hover {
                    background: #dedede;
                }
            }
            &.p-page  {
                font-weight: 700;
                padding: 4px 16px;
            }
        }
    }
    &__heading {
        font-size: 28px;
        color: $m3-darkblue;
        margin-bottom: 30px;
        font-weight: 600;
    }
    &__profile-image {
        height: 200px;
        width: 200px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
    }
    &__content {
        &__intro {
            font-size: 24px;
            font-weight: 300;
        }
        h1, h2, h3, h4, h5, h6, strong {
            font-weight: 700;
            color: $m3-darkblue;
            margin-top: 0;
        }
        p, span, li {
            line-height: 1.6;
            font-size: 18px;
            color: #3c4858;
        }
        p {
            margin-bottom: 30px;
        }
        ul, ol {
            list-style: none;
            padding: 0;
            margin-bottom: 50px;
            li {
                margin-bottom: 15px;
                padding-left: 30px;
                position: relative;
                &:before {
                    content: "";
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    background: #172b46;
                    z-index: 100;
                    border-radius: 50%;
                    position: absolute;
                    top: 10px;
                    left: 0;       
                }
            }
        }
        img {
            max-width: 100%;
            height: auto;
            margin-bottom: 50px;
            display: block;
        }
        a {
            @include unlink($m3-darkblue, $m3-darkblue);
           font-weight: 400;
           display: inline-block;
           border-bottom:1.5px solid $m3-blue;
        }
        hr {
            margin: 50px 0;
            border-top: 1px solid #e1e1e1;
            @media screen and (max-width:768px) {
                margin: 40px 0;
            }
        }
    }
}
.article--preview {
    .article {
        &__heading {
            font-size: 24px;
            color: $m3-darkblue;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 15px;
        }
        &__image {
            max-height: 250px;
        }
        &__title {
            font-weight: 700;
            margin-top: 0;
            font-size: 26px;
            color: $m3-darkblue;
            margin-bottom: 15px;
            font-size: 24px;
            a {
                @include unlink($m3-darkblue, $m3-darkblue);
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        &__intro {
            font-size: 16px;
        }
        hr {
            border-color:#ccc;
        }
    }
}
