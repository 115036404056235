.usergroup-table {
  max-width: 100%;
  width: 100%;
  text-align: center;
  border: 1px solid #dedede;
  border-collapse: collapse;
  margin-bottom: 30px;
}
.usergroup-table tr {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.usergroup-table tr:hover {
  background: #f4f5f6;
}
.usergroup-table td, .usergroup-table th {
  padding: 10px;
  border: 1px solid #dedede;
}
.usergroup-table td.text-left, .usergroup-table th.text-left {
  text-align: left;
}
.usergroup-table th {
  font-weight: 600;
  font-size: 12px;
  border-bottom: 3px solid rgba(0, 0, 0, 0.12);
}
.usergroup-ticket, .usergroup-votes {
  width: 10%;
  min-width: 80px;
}
.usergroup-role, .usergroup-update, .usergroup-scheduled {
  width: 15%;
  min-width: 120px;
}
.usergroup-description {
  min-width: 200px;
}

.section__subtitle {
  margin-top: 30px;
}

.pamwin--user-group h1.row {
  display: none;
}

