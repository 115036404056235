@mixin base-btn($bg:$m3-blue,$color:white,$maxwidth:100%) {
    appearance: none !important;
    border: 0;
    border-radius: 2px;
    background-color: $bg;
    display: inline-block;
    text-align: center;
    transition: all .2s ease;
    color: $color;
    text-decoration: none;
    cursor: pointer;
    transition: all .15s ease-in-out;
    outline: 0!important;
    width: 100%;
    max-width: $maxwidth;
    font-weight: 500;
    padding: 15px;
    position: relative;
    text-decoration: none;
    &:hover, &:visited, &:active, &:link {
        color: $color;
    }
    &:not([disabled]):hover {
        box-shadow: 0 4px 8px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.22);
        text-decoration: none !important;
        background: lighten($bg, 5%);
        color: $color;
    }   
    &:disabled {
        background: #ddd!important;
        color: rgba(0,0,0,.2)!important;
        cursor: default;
        img {
            opacity: 0.5;
        }
    }
    img {
        max-width: 22px;
        transform: translateY(-3px);
        margin-right: 4px;
    }
}
@mixin base-link-btn($color:$m3-darkblue, $display:inline-block) {
    color: $color;
    font-weight: 600;
    text-decoration: none;
    display: $display;
    font-size: 14px;
    margin-left: 15px;
    margin-right: 15px;
    appearance: none;
    border: 0;
    outline: 0;
    position: relative;
    &:hover, &:active, &:visited, &:link {
        color: lighten($color, 5%);
    }
    &:hover {
        text-decoration: underline;
    }    
    &[hidden] {
        display: none;
    }
    img {
        width: 18px;
        height: auto;
        display: inline-block;
        margin-right: 10px;
    }
}

// Apply mixins to default button classes
.btn--submit {
    @include base-btn;
}
.btn--cart {
    @include base-btn($m3-blue,white,220px);
    padding: 10px 15px;
}
.btn--light {
    @include base-btn;
    background: white;
    border: 1px solid $m3-darkblue;
}
.btn--remove {
    font-weight: 500;
    font-size: 14px;
    color: $m3-blue;
    padding: 0 6px;
    display: inline-block;
    opacity: 0.3;
    transition: opacity 0.18s ease-out;
    appearance: none;
    border: 0;
    background-color: transparent;
    background-size: 18px;
    background-position: center;
    background-image: url('/content/images/icons/ico-remove-1.svg');
    background-repeat: no-repeat;
    color: transparent;
    width: 24px;
    height: 24px;
    transform: translateY(-3px);
    &:hover {
        opacity: 1;
    }
}
.btn--remove-ico {
    font-weight: 500;
    font-size: 14px;
    color: $m3-blue;
    padding: 0 6px;
    display: inline-block;
    opacity: 0.3;
    transition: opacity 0.18s ease-out;
    appearance: none;
    border: 0;
    background-color: transparent;
    background-size: 18px;
    background-position: center;
    background-image: url('/content/images/icons/ico-remove-1.svg');
    background-repeat: no-repeat;
    color: transparent;
    width: 24px;
    height: 24px;
    transform: translateY(-3px);
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    text-indent: -9999px;
    &:hover {
        opacity: 1;
    }
}
.btn--link {
    @include base-link-btn;
}
.btn--related {
    @include base-link-btn($m3-blue);
    background-image:url("/content/images/icons/ico-caret-down-blue.svg");
    background-position: left 5px center;
    background-repeat: no-repeat;
    background-size: 17px;
    background-color: transparent;
    padding-left: 28px;
    line-height: 1.4;
    margin-bottom: 15px;
    margin-left: 0;
    @media screen and (max-width: 992px) {
        margin: 15px 0;
    }
}
.btn--back {
    @include base-link-btn;
    background-image:url("/content/images/icons/ico-caret-left-thick.svg");
    background-position: left 5px center;
    background-repeat: no-repeat;
    background-size: 17px;
    padding-left: 28px;
    line-height: 1.4;
}
.unlink--inline {
    @include unlink--inline;
}

.link-ico {

	font-weight: 700;
	padding-bottom: 1px;
	text-decoration: none;
	display: inline-block;
	position: relative;
	padding-left: 28px;
    border-bottom: 1px dotted #bbb;
    &:active, 
    &:visited,
    &:link {
		text-decoration: none;
        color:#004fb6;
        color: #3c4858;
	}
    &:hover {
        text-decoration: none;
        border-bottom: 1px solid #004fb6;
        color:lighten(#004fb6, 5%);
        color: black;
    }
	&--before {
		&:before {
			height: 24px;
			font-family: 'Material Icons';
			left: 0px;
            top: -2px;
			margin-right: 5px;
			position: absolute;
			text-align: center;
			width: 24px;
			-webkit-font-feature-settings: 'liga';
		}
	}
	&--document {
		&:before {
			content: "receipt_long";
			content: "article";
			content: "copy_all";
            color:#004fb6;
		}
	}
}
