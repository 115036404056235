.masonry {
    background: $m3-blue;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
    height: 600px;
    @media screen and (max-width: 1200px) {
        height: 750px;
    } 
    @media screen and (max-width: 992px) {
        display: block;
        height: auto;
    }
    @media screen and (max-width: 768px) {
        height: auto;
    }
    &__grid {
        display: flex;
        width: 66.6%; 
        flex-direction: column;
        height: 100%;
        align-items: stretch;
        @media screen and (max-width: 992px) {
            width: 100%;
        }
        @media screen and (max-width: 768px) {
            display: block;
            height: auto;
        }
    }
    &__row { 
        display: flex;
        height: 50%;
        @media screen and (max-width: 768px) {
            display: block;
        }
        .masonry__item {
            @media screen and (max-width: 768px) {
                width: 100%;
                display: block;
            }
        }
    }

    &__item {
        color: white; 
        display: flex;
        flex-direction: column;
        padding: 20px;
        width: 33.3%;
        flex: 1; 
        align-items: stretch;
        @media screen and (max-width: 768px) {
            padding: 40px 20px;
        }
        /* Carousel column*/
        &-1 {
            background: $m3-blue;
            height: 100%;
            justify-content: center;
            flex-basis: 33.3%;
            @media screen and (max-width: 992px) {
                width: 100%;
            }
        }
        /* Products column */
        &-2 {
            background-image: url('/content/images/home/layers.svg');
            background-color: $dark-blue;
            background-repeat: no-repeat;
            background-size: 260px;
            background-position: bottom right;
            @media screen and (max-width: 992px) {
                background-size: 200px;
                background-position: bottom right;
            } 
        }
        &-3 {
            background: #dedede;
            background-image: url('/content/images/home/support.webp');
            background-repeat: no-repeat;
            background-size:cover;
            background-position:bottom center;
            @include darken-background;
            p {
                background-color: rgba(0,0,0,0.1);
            }
            h1 {
                background-color:rgba(0,0,0,0.05);
            }
        }

        &-4 {
            background: #dedede;
            background-image: url('/content/images/home/consultancy.webp');
            background-repeat: no-repeat;
            background-size:cover;
            @include darken-background;
            p {
                background-color: rgba(0,0,0,0.1);
            }
            h2 {
                background-color:rgba(0,0,0,0.05);
            }
        }
        &-5 {
            background-color: #0397D7;
            background-color: #0b3875;
            background-image: url('/content/images/home/training-white.svg');
            background-position: right -50px bottom 20px;
            background-repeat: no-repeat;
            background-size:180px;
        }
        &-6 {
            background: $m3-blue;
            background-image: url('/content/images/home/book.svg');
            background-position: right -50px bottom 20px;
            background-repeat: no-repeat;
            background-size:180px;
        }
        &__link {
            @include unlink;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: flex-end;
            z-index: 1000;
            position: relative;
        }
        &-2 {
            flex: 2 1 auto;
            @media screen and (max-width: 992px) {
                flex: 1 1 auto;
            }
        }
        &-3 {
            flex: 0 1 auto;
            @media screen and (max-width: 992px) {
                flex: 1 1 auto;
            }
        }
    }
    h1 {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 35px;
        margin-top: 0;
        z-index: 1;
        @media screen and (max-width: 768px) {
            font-size: 34px;
            margin-bottom: 30px;
        }
    }
    h2 {
        z-index: 1;
        font-weight: bold;
    }
    h4 {
        color: rgba(255,255,255,0.8);
        margin-bottom: 10px;
        font-size: 18px; 
        z-index: 1;
        text-shadow: none;
    }
    p {
        @include p-lg;
        margin-bottom: 15px;
        max-width: 86%;
        width: auto;
        z-index: 1; 
        @media screen and (max-width: 1200px) {
            width: 100%;
            max-width: 100%;
        }
        @media screen and (max-width: 992px) {
            width: 100%;
            max-width: 92%;
        }
        @media screen and (max-width: 768px) {
            width: 100%;
            max-width: 85%;
        }
    } 
}
 