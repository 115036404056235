/**************************************
/* HEADER
***************************************/
.page {
	@include removetitlerow;
}
.section-header {
	background: $blue;
	position: relative;
	text-align: center;
	overflow: hidden;
	color: #fff;
	border-bottom: 1px solid $grey;
	display: block;
	max-height: 600px;
	padding: 50px 30px 0 30px;
	@media screen and (max-width: 768px) {
		padding: 50px 15px 0 15px;
	}
	@media screen and (max-width: 480px) {
		padding: 40px 10px 30px 10px;
	}
	> * {
		box-sizing: border-box;
	}
	&__image {
		max-width: 100%;
		padding-left: 20px;
		padding-right: 20px;
		width: 980px;
		margin-bottom: -50px;
		display: block;
		margin: 0 auto;
		@media screen and (max-width: 1200px) {
			max-width: 90%;
		}
		@media screen and (max-width: 480px) {
			display: none;
		}
	}
	&__title {
		font-size: 58px;
		font-weight: 700;
		margin-bottom: 30px;
		@media screen and (max-width: 768px) {
			font-size: 44px;
			margin-bottom: 20px;
		}
		@media screen and (max-width: 480px) {
			font-size: 38px;
			margin-bottom: 20px;
		}
	}
	&__subtitle {
		font-size: 24px;
		font-weight: 300;
		max-width: 800px;
		margin: 0 auto;
		line-height: 1.4;
		margin-bottom: 40px;
		@media screen and (max-width: 1200px) {
			margin-bottom: 30px;
		}
		@media screen and (max-width: 768px) {
			font-size: 20px;
		}
	}
	&__button {
		padding: 18px 50px;
		background: white;
		margin-bottom: 50px;
		font-weight: 500;
		transition: all 0.2s ease;
		text-decoration: none;
		display: inline-block;
		color: darken($blue, 10%);
		@media screen and (max-width: 1200px) {
			margin-bottom: 40px;
		}
		@media screen and (max-width: 728px) {
			margin-bottom: 30px;
		}
		&:hover, &:visited, &:active {
			text-decoration: none;
			box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
		}
	}
}
.development header {
	background-color: #50B84A;
	&-section__button {
		color: #50B84A;
	}
}
.section-header--alt, .section-header--alt-2 {
	display: none;
}
/**************************************
/* ANIMATION EFFECTS AND TIMING
***************************************/
@keyframes pop-in {
	0% {
		opacity: 0;
		transform: scale(0.9);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}
.animate-pop-in {
	animation: pop-in .6s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
	opacity: 0;
}
.section-header {
	&__title {
		animation-delay: .6s;
	}
	&__subtitle {
		animation-delay: .8s;
	}
	&__button {
		animation-delay: 1s;
	}
	&__image {
		animation-delay: 1.1s;
	}
}
.link {
	display: inline-block;
	position: absolute;
	bottom: -30px;
	right: 15px;
	width: 60px;
	height: 60px;
	border-radius: 100%;
	background: $body-text-color;
	z-index: 100;
	text-align: center;
	> i, > em {
		font-size: 34px;
		color: $body-text-color-light;
		line-height: 60px;
	}
}

/**************************************
/* SECTION
***************************************/
.section {
	padding: 70px 0;
	position: relative;
    font-weight: 300;
    border-bottom: 1px solid $light-grey;
	@media screen and (max-width: 768px) {
		padding: 50px 0;
	}
	&:focus {
		outline: 0;
	}
	&__title {
        @include heading-1;
        color: #3C4858;
		margin-top: 10px;
		margin-bottom: 30px;
		position: relative;
		@media screen and (max-width: 768px) {
			font-size: 30px;
			margin-bottom: 20px;
		}
    }
    &__pre-title {
        font-weight: 300;
        font-size: 22px;
        line-height: 1.6;
        margin-bottom: 0;
        & + {
            h1.section__title {
                margin-top: 0;
            }
        }
    }
	&__description {
		font-weight: 300;
		font-size: 22px;
		line-height: 1.6;
		position: relative;
		@media screen and (max-width: 768px) {
			font-size: 20px;
			margin-bottom: 20px;
		}
	}
	&__features, 
	&__items {
		padding: 40px 15px 0;
		&:before, &:after {
			content: "";
			display: table;
		}
		&:after {
			clear: both;
		}
	}
	&__features {
		@media screen and (max-width: 1200px) {
			padding: 40px 0 0;
		}
		@media screen and (max-width: 992px) {
			padding: 20px 0;
		}
	}
	&__image {
		&--raised {
			box-shadow: 1px 1px 100px rgba(0,0,0,0.2);
			padding: 0;
			max-width: 840px;
		}
	}
	&__items {
		@media screen and (max-width: 768px) {
			padding: 30px 0 0;
		}
	}
	&__brands {
		margin-top: 70px;
		@media screen and (max-width: 768px) {
			margin-top: 20px;
		}
		ul {
			list-style-type: none;
			padding: 0;
		}
		li {
			display: inline-block;
			max-width: 145px;
			margin: 0 20px;
			img {
				width: auto;
				height: 100%;
				max-height: 100px;
				vertical-align: middle;
				filter: grayscale(100%);
				opacity: 0.7;
				transform: scale(0.9);
				@media screen and (max-width: 768px) {
					max-height: 80px;
				}
			}
		}
	}
	&__button {
		padding: 18px 50px;
		color: white;
		background: $blue;
		font-weight: 600;
		display: inline-block;
		margin: 40px 0;
		text-decoration: none;
        transition: all 0.2s ease;
        appearance: none !important;
        border: 0;
		&:hover, &:active, &:focus {
			text-decoration: none;
			color: white;
			box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
            background: lighten($blue, 5%);
            text-decoration: none;
        }
        &:visited {
            color: white;
            text-decoration: none;
        }
		@media screen and (max-width:768px) {
            width: 100%;
            text-align: center;
            margin: 15px 0;
		}
		&--full {
			width: 100%;
			margin: 0;
			text-align: center;
		}
	}
	&__table {
		width: 100%;
		max-width: 100%;
		margin-bottom: 30px;
		margin-top: 30px;
		background-color: #fff;
		// box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
		th {
			font-weight: 400;
			color: #757575;
			vertical-align: bottom;
			border-bottom: 1px solid rgba(0, 0, 0, 0.12);
			padding: 1.6rem;
		}
		tr {
			transition: all 0.3s ease;
		}
		tr:hover > td {
			background: #f5f5f5;
		}
		td {
			font-weight: 400;
			text-align: left;
			padding: 1.6rem;
			vertical-align: top;
			border-top: 0;
			transition: all 0.3s ease;
		}
		&--4cols{
			th, td {
				width: 25%;
				@media screen and (max-width: 768px) {
					width: auto;
				}
			}
		}
	}
	&__tabs {
		background: $green;
		> li {
			> a {
				border-radius: 0;
				color: white;
				line-height: 2;
				margin: 0;
				&:hover, &:focus {
					color: #555;
				}
			}
		}
	}
	&__tab-content {
		h3 {
			margin: 30px 15px;
		}
	}
	/**** Additional modifiers ***/
	&--grey {
		background: #f9f9f9;
	}
	&__nav-list {
		list-style: none;
		padding-left: 0px;
		margin-top: 15px;
		margin-bottom: 30px;
		li {
			border: 0;
			a {
				color: #3C4858;
				display: block;
				padding: 10px 0;
				font-weight: 300;
				padding: 8px 0;
				// border-bottom: 1px solid rgba(153, 153, 153, 0.3);
				font-weight: 400;
			}
			&:last-child {
				a {
					border: 0;
				}
			}
		}
	}
	&__nav-list-heading {
		margin-top: 30px;
		margin-bottom: 15px;
		font-size: 18px;
		font-weight: bold;
	}
	&__aside {
		list-style: none;
		padding-left: 0px;
		font-size: 14px;
		margin-top: 15px;
		li {
			i {
				vertical-align: middle;
				margin-right: 15px;
			}
			a {
				color: #3C4858;
				display: block;
				padding: 15px;
				font-weight: bold;
				vertical-align: middle;
				transition: all 0.2s ease;
				&:hover {
					background: $blue;
					color: white;
					text-decoration: none;
				}
			}
		}
	}
	&__cards {
		margin-top: 30px;
	}
	&__content {
		font-weight: 300;
		margin-bottom: 30px;
		p {
			margin-bottom: 15px;
		}
	}
	&__ol {
		margin: 40px 0;
		@media screen and (max-width:768px) {
			margin: 30px 0;
		}
		li {
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0;
			}
		}
    }
    &__list {
        &--default {
            li {
                position: relative;
                padding: 8px 20px;
                margin: 0;
            }
            .material-icons {
                position: absolute;
                top: 10%;
                left: -15px;
            }
        }
        &--flex {
            display: flex;
        }
        &--lg {
            li {
                padding: 20px 20px 20px 38px;
            }
            .section__list-heading {
                font-weight: bold;
                line-height: 1;
                margin-bottom: 5px;
                font-size: 20px;
            }
            .section__list-description {
                margin-bottom: 0;
            }
            .material-icons {
                font-size: 44px;
                top: 10px;
            }
        }
        &-container {
            overflow: hidden;
        }
        &-heading {
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 0px;
        }
        &-description {
            font-weight: 300;
            margin-bottom: 0;
        }
    }
    &__product {
        &-logo {
            max-width: 64px;
            height: auto;
            float: left !important;
            margin-right: 8px;
            transform: translateY(-12px);
        }
        &-image {
            @media screen and (max-width:768px) {
                display: none;
            }
        }
    }
}

/*************************************************/
/* SECTION MAIN FEATURES */
/*************************************************/
.section-main-features {
	overflow: hidden;
	padding-top: 90px;
	padding-bottom: 70px;
	background: $lighter-grey;
	@media screen and (max-width: 768px) {
		padding: 50px 0 70px;
    }
    &--alt {
        background: white;
        img {
            float: right;
        }
    }
	img {
		max-height: 580px;
		@media screen and (max-width: 768px) {
            max-height: 350px;
            max-width: 100%;
            margin: 30px 0;
		}
	}
	.section__title {
		margin-top: 20px;
		@media screen and (max-width: 768px) {
			margin-top: 10;
		}
	}
	.section__description {
        margin-bottom: 20px;
        clear: both;
    }
    .section__product-image {
        img {
            max-width: 780px;
        }
    }
	ul {
		list-style-type: none;
		padding-left: 10px;
	}
	li {
		font-weight: 300;
		margin: 5px;
		em {
			margin-right: 15px;
			transform: translateY(4px);
		}
    }
}

/*************************************************/
/* HELP  */
/*************************************************/
.section-help {
	&--main {
        background: $lighter-grey;
    }
}
.section-help {
    border-top: 1px solid #ececec;
	.info {
		padding: 0;
	}
	&--main {
		.info__link {
			border-radius: 3px;
			&:hover {
				background: #fff;
			}
		}
	}
} 

.section-help--product {
	.info__icon > em {
		font-size: 44px;
	}
	.info__link {
		display: block;
		padding: 15px;
		&:hover {
			background: white;
		}
	}
}

/*************************************************/
/* ACCORDION */
/*************************************************/
.section-glossary, 
.section-faq {
	a {
		text-decoration: none;
		transition: all 0.2s ease;
	}
	a:visited, a:hover, a:focus {
		background: #eaeaea;
		text-decoration: none;
		outline: 0;
	}
	p {
		max-width: 800px;
	}
	.panel {
		border-radius: 0;
		box-shadow: none;
		p {
			margin: 0;
		}
	}
	.panel-default {
		border: 0;
		margin-bottom: 5px;
		> .panel-heading + .panel-collapse > .panel-body {
			border: 0;
		}
	}
	.panel-heading {
		padding: 0;
		border-radius: 0;
		border: 0;
		a {
			padding: 25px 60px 25px 25px;
			border: 0;
			display: block;
			position: relative;
			&:after {
				font-family: 'Material Icons';
				content: "expand_more";
				-webkit-font-feature-settings: 'expand_more';
				width: 30px;
				height: 30px;
				position: absolute;
				right: 20px;
				text-align: center;
				font-size: 28px;
				vertical-align: center;
				top: 50%;
				transform: translateY(-50%);
				transition: all 0.2s ease;
			}
			&[aria-expanded="true"]:after {
				transform: translateY(-50%) rotate(-180deg);
			}
		}
	}
	.panel-body {
		padding: 40px 60px 40px 25px;
		border-radius: 0;
		@media screen and (max-width: 768px) {
			padding: 30px 25px 30px 25px;
		}
	}
	.panel-footer {
		padding: 25px 60px 25px 25px;
		background: transparent;
		border-top: 1px solid $light-grey;
	}
	.panel-group-heading {
		margin: 70px 0 30px;
		@media screen and (max-width: 768px) {
			margin: 40px 0 30px;
		}
		&:first-child {
			margin-top: 0;
		}
	}
	.panel-footer__location {
		font-weight: 700;
		&:after {
			content: "|";
			position: relative;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}
.section-how-to {
	.section__items {
		.row{
			position: relative;
			padding: 20px 15px;
			@media screen and (max-width: 768px){
				padding: 20px 0;
			}
		}
		.info {
			@media screen and (max-width: 992px) {
				text-align: center;
			}
		}
	}
}
.section-upload {
	textarea {
		max-width: 100%;
		width: 100%;
	}
	.section__button {
		margin-top: 0;
	}
	.info:first-child {
		padding-top: 0;
	}
	.info__icon {
		transform: translateY(-7px);
		em {
			font-size: 30px;
		}
	}
}
.section-report {
	padding: 70px 15px;
	> h2, > h5 {
		padding: 0 15px;
		@media screen and (max-width: 768px) {
			padding: 0;
		}
	}
	.row {
		margin-bottom: 40px;
		@media screen and (max-width: 768px) {
			margin-bottom: 0;
		}
	}
	.section__heading {
		@media screen and (max-width: 768px) {
			padding: 0;
		}
	}
	.section__item {
		@media screen and (max-width: 768px) {
			margin-bottom: 30px;
			padding-bottom: 30px;
			border-bottom: 1px solid rgba(0,0,0,0.1);
		}
	}
	.info__title {
		margin-bottom: 5px;
		font-size: 22px;
		margin-right: 20px;
		font-weight: 500;
	}
	.info__date {
		font-weight: 300;

	}
	.info__text { 
		margin-top: 15px;
	}
	.info__footer { 
		h4 {
			font-size: 16px;
		}
		a {
			display: block;
			margin-bottom: 8px;
			transition: all .2s ease;
			position: relative;
			color: white;
			background: #8bc34a;
			padding: 6px 15px;
			border-radius: 2px;
			display: inline-block;
			font-weight: 500;
			margin-top: 30px;
			font-weight: 400;
			&:after {
				position: absolute;
				top: -6px;
				right: 0;
				font-family: 'Material Icons';
				content: "expand_more";
				font-size: 20px;
				opacity: 0;
				transition: all 0.2s ease;
			}
			&:hover, &:active, &:visited {
				background-color: darken(#8bc34a, 6%);
				text-decoration: none;
				// &:after {
				// 	opacity: 1;
				// }
			}
		}
	}
}

/*************************************************/
/* SECTION TRAINING */
/*************************************************/
.section-training, 
.section-demo {
	font-weight: 300;
	font-size: 16px;
	padding: 70px 15px;
	.section__item {
		margin-bottom: 45px;
		margin-top: 30px;
	}
	p {
		margin-bottom: 15px;
	}
	ul {
		padding-left: 0;
		li {
			display: inline-block;
			width: 49%;
			position: relative;
			padding-left: 40px;
			@media screen and (max-width: 768px) {
				display: block;
				width: 100%;
				padding-left: 25px;
			}
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				left: 10px;
				transform: translateY(-50%);
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: rgba(112,128,144,1);
				@media screen and (max-width: 768px) {
					left: 5px;
				}
			}
		}
	}
	.full-width {
		li {
			width: 100%;
		}
	}
	&--light {
		background: #f9f9f9;
	}
}
.section-demo--light {
    background: #fff !important;
}
.section-training-guides {
	.section__downloads {
		background: #fff;
        margin-left: -10px;
        padding-top: 15px;
		a {
			display: block;
			padding: 10px;
			position: relative;
			margin-bottom: 5px;
			transition: all 0.2s ease;
			&:hover {
				text-decoration: none;
				background: rgba(0,0,0,0.015);
			}
			&:before {
				content: "";
				font-family: 'Material Icons';
				font-size: 18px;
				font-weight: 600;
				content: "expand_more";
				display: block;
				position: absolute;
				right: 10px;
				top: 45%;
				transform: translateY(-50%);
				display: none;
			}
			.date {
				float: right;
				color: rgba(0,0,0,0.4);
				@media screen and (max-width: 768px) {
					float: none;
					display: block;
				}
			}
		}
	}
}
.section-upper {
    padding: 70px 0 15px 0;
}
.section-lower {
    padding: 15px 0 70px 0;
    border-bottom: 0;
}


/*************************************************/
/* MISC */
/*************************************************/
.info {
	padding: 30px 0 30px;
	text-align: left;
	display: block;
	@media screen and (max-width: 768px) {
		padding: 20px 0;
	}
	&__content {
		overflow: hidden;
	}
	&__icon {
		float: left;
		margin-right: 20px;
		color: #3C4858;
		&--primary {
			color: #0288D1;
			color: #708090;
		}
		&--secondary {
			color: $green;
		}
		&--tertiary {
			color: #E91E63;
			color: #C2185B;
		}
		& > em {
			font-size: 60px;
			margin-bottom: 30px;
		}	
	}
	&__title {
		color: $heading-text-color;
		font-size: 18px;
		font-weight: bold;
	}
	&__text {
		font-weight: 300;
		color: $body-text-color;
	}
	&__link {
		display: block;
		text-decoration: none;
		transition: background 0.2s ease;
		padding: 30px 5px;
		@media screen and (max-width: 768px) {
			padding: 20px 0;
		}
		&:hover, &:visited, &:focus {
			text-decoration: none;
			background: $light-grey;
		}
		&--inline {
			color:black;
			font-weight: 400;
			border-bottom: 1px solid;
			text-decoration: none;
			outline:none;
			&:hover, &:active, &:focus {
				text-decoration: none;
				outline:none;
				color: black;
			}
		}
	}
	&__button {
		@include infobutton;
	}
	&__thumb {
		max-width: 120px;
		@media screen and (max-width:992px) {
			max-width: 100px;	
		}
		@media screen and (max-width:768px) {
			max-width: 80px;	
		}
	}
	&__action {
		margin-top: 30px;
	}
	&__price {
		padding-right: 15px;
		&--alt {
			@media screen and (max-width:768px) {
				margin-top: 15px;
			}
		}
	}
	&--padded {
		padding-top: 40px;
		padding-bottom: 70px;
		padding-right: 20px;
		@media screen and (max-width:768px) {
			padding-bottom: 40px;
			padding-right: 0;
			border-bottom: 1px solid #ececec;	
		}
	}
	iframe {
		margin-bottom: 20px;
		max-width: 100%;
	}
}
.image-container img {
	max-width: 100%;
	height: auto;
}
.row {
	&--padded {
		padding-top: 30px;
		padding-bottom: 30px;
		@media screen and (max-width:768px) {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}
.card {
	margin-bottom: 30px;
	&-item {
		text-align: center;
		ul {
			list-style: none;
			padding: 0;
			max-width: 240px;
			margin: 10px auto 15px !important;
			li {
				font-weight: 300;
				color: #3C4858;
				text-align: center;
				padding: 15px 0;
				border-bottom: 1px solid hsla(0, 0%, 60%, .3);
				&:last-child {
					border: 0;
				}
			}
		}
	}
	&-item--left {
		@media screen and (min-width:992px) {
			text-align: left;
		}
	}
	&-raised {
		background: white;
		box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.01), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)
	}
	&-body { 
		margin: 0;
		padding: 30px;
		@media screen and (max-width: 500px) {
			padding: 20px;
		}
	}
	&-category {
		color: #999;
	}
	&-title {
		font-size: 24px;
		margin-top: 30px;
		margin-bottom: 20px;
	}
	b {
		color: #3c4858;
	}
}
.btn.btn-white {
	transition: all 0.2s;
	background-color: #fff;
	color: #999;
	padding: 18px 50px;
	margin: .3125rem 1px;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.428571;
	border-radius: 0 !important;
	&:focus, &:hover {
		color: black;
		box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.13), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
	}
}
.btn.btn-white-raised {
	background: $blue;
	color: white;
	&:hover, &:active  {
		background: lighten($blue, 5%);
		color: white;
	}
}
.read-more {
	&:before {
		display: inline-block;
		content: "Read less"
	}
	&.collapsed:before {
		content: "Read more";
	}
}
.col-sm-offset--15 {
    transform: translateX(15px);
    @media screen and (max-width: 768px) {
        transform: translateX(0);
    }
}
.btn--remove-inline {
    background: transparent;
    appearance: none !important;
    border: 0;
    padding: 10px 0;
    i {
        color: $error;
    }
}
.btn--add {
    appearance: none;
    border: 0;
    padding: 10px 20px;
    background: $green;
    color: white;
    transition: all 0.2s ease;
    margin: 0;
    &:disabled {
        background:#ccc;
    }
}
.btn--add-block {
    appearance: none !important;
    border: 0;
    padding: 10px 20px;
    background: #8bc34a;
    color: white;
    transition: all 0.2s ease;
    display: block;
    margin:0 auto;
    text-decoration: none;
    &:disabled {
        background: #ccc;
    }
    &:hover, &:active, &:visited {
        color: white;
        text-decoration: none;
    }
    span{
        transform:translateY(1px);
        display: inline-block;
        font-weight: 500;
    }
    i {
        vertical-align: middle;
        margin-right: 5px;
    }
}
.sor-module-list{
    @include clearfix;
    margin-bottom: 60px;
}
.section--legal {
    margin-bottom: 50px;
}
.document-list--link {
    list-style: none;
    margin-top: 15px;
    margin-bottom: 30px;
    padding-left: 20px;
    font-size: 14px;
    li {
        display: block;
       padding: 0;
        a {
            display: block;
            padding: 15px 30px;
            position: relative;
            font-size: 16px;
            font-weight: 300;
            @include unlink;
            &:before {
                content: "";
                display: block;
                width: 24px;
                height: 24px;
                position: absolute;
                background-size: 20px;
                background-repeat: no-repeat;
                left: -18px;
                background-image: url("/content/images/icons/link-1.svg");
            }
            &[href$=".pdf"]:before {
                background-image: url("/content/images/icons/pdf-1.svg");
            }
            &[href*=".doc"]:before {
                background-image: url("/content/images/icons/word.svg");
            }
            &[href*=".co"]:before {
                background-image: url("/content/images/icons/chrome.svg");
            }
            &[href*=".cfm"]:before {
                background-image: url("/content/images/icons/chrome.svg");
            }
            &:link, &:hover, &:active, &:visited {
                border: 0;
            }
            &:hover {
                font-weight: 400;
                color: black;
            }
        }
    }
}
.aside {
    @include aside;
}
.section {
    &--sm {
        padding-top: 50px;
        padding-bottom: 50px;
    }
	&--lg {
		padding-bottom: 12vh;
		padding-top: 12vh;
    }
    &--md{
        padding-top: 8vh;
        padding-bottom: 8vh;
    }
    
    &--xl {
        padding-bottom: 18vh;
        padding-top: 18vh;
    }
    &--break {
        position: relative;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            max-width: 1140px;
            background: #dedede;
            height: 1px;
        }
    }
	&__confirmation {
		border-radius: 10px;
		margin-left: auto;
		margin-right: auto;
		max-width: 730px;
			text-align: center;
		h1 {
			line-height: 1.2;
		}
		p {
			font-size: 18px;
			a {
				@include unlinkunderline;
			}
		}
		strong {
			display: inline-block;
		}
		img {
			max-width: 300px;
			width: 100%;
			height: auto;
			margin-bottom: 30px;
		}
    }
    
}
.usp{
    &--pw{
        background-image: url('/images/bg-pw-3.png');
        background-size: contain;
        background-position: right -180px bottom -260px;
        background-repeat: no-repeat;
    }
}