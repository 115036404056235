.footer {
	@include clearfix;
	background: $dark-blue;
	color: $text-over-dark-blue;
	font-size: 16px;
	font-weight: 300;
	padding-top: 70px;
    padding-bottom: 30px;
    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
	&__nav{
        @include unlist;
        margin-bottom: 30px;
		a {
			@include unlink;
		}
	}
	&__heading {
		color: white;
		font-size: 18px;
		font-weight: bold;
        margin-bottom: 15px;
        margin-top: 0;
	}
	p {
		margin-bottom: 20px;
	}
	a {
		@include unlink;
		transition: color 0.2s ease;
		display: inline-block;
		&:hover {
			color: white;
		}
    }
    &__info {
        @media screen and (max-width: 768px) {
            margin-bottom: 30px;
        }
    }
	&__address {
		span {
            display: block;
            color: $text-over-dark-blue;
            font-size: inherit;
			&.adr {
				margin-bottom: 15px;
			}
		}
    }
    &__social {
        &__icon {
            display: inline-block;
            margin-right: 10px;
            span {
                display: none;
            }
            svg {
                max-width: 24px;
                path {
                    fill: #fff;
                    transition: fill 0.2s ease;
                }
                &.linkedin {
                    transform: translateY(-2px);
                }
            }
            &:hover {
                svg {
                    path {
                        fill: $m3-blue;
                    }
                }
            }
        }
    }
	&__legal {
        padding: 30px 0 0;
        margin-top: 30px;
        text-align: center;
        border-top: 1px solid rgba(132,144,161,0.25);
		p {
			margin-bottom: 0;
		}
		a {
			margin: 0 10px;
		}
    }
    &__cert {
        @include unlist;
        @include clearfix;
        display: flex;
        @media screen and (min-width:768px) and (max-width:992px) {
            flex-wrap: wrap;
        }
        @media screen and (max-width: 768px) {
            justify-content: center;
        }
        &__item {
            width: 50%;
            @media screen and (min-width:768px) and (max-width:992px) {
                width: 100%;
                text-align: center;
                margin-bottom: 15px;
            }
            @media screen and (max-width: 768px) {
                max-width:140px;
            }
            span {
                text-align: center;
                display: block;
                font-size: 12px;
                line-height: 1.4;
            }
            a {
                display: block;
            }
        }

        &__img{
            width: 100%;
            max-width: 130px;
            max-height: 80px;
            height: auto;
            border: 1px solid $dark-blue;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
            0 3px 6px rgba(0, 0, 0, 0.23);        
        }
    }

}