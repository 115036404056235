/*************************************************/
/* USERGROUP */
/*************************************************/
.usergroup #mainContent {
  padding-left: 0;
  padding-right: 0;
}
.usergroup .no-gutter div > [class*='col-'] > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
// .usergroup__nav {
//   margin: 0;
//   padding: 0;
//   list-style: none;
//   padding: 15px;
//   margin-bottom: 30px;
// }
// @media screen and (max-width: 768px) {
//   .usergroup__nav {
//     padding: 0;
//   }
// }
// .usergroup__nav li {
//   border-bottom: 1px solid #dedede;
// }
// .usergroup__nav li.label {
//   font-weight: 600;
//   color: #656a70;
//   padding: 0;
//   font-size: 14px;
//   margin-bottom: 15px;
//   display: inline-block;
//   display: none;
// }
// .usergroup__nav li.active a {
//   font-weight: 700;
// }
// .usergroup__nav li a {
//   display: block;
//   padding: 20px 0;
//   color: #3c4858;
// }
// .usergroup__nav li a:visited, .usergroup__nav li a:hover {
//   color: #3c4858;
//   text-decoration: none;
// }
// .usergroup__nav li i.material-icons {
//   margin-right: 10px;
//   font-size: 16px;
//   font-weight: bold;
//   -webkit-transform: translateY(3px);
//           transform: translateY(3px);
// }
.usergroup__sub-title {
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #3c4858;
}
.usergroup__main p {
  margin-bottom: 15px;
  color: #3c4858;
  font-weight: 300;
}
.usergroup__main table {
  width: 100%;
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .usergroup__main table {
    table-layout: fixed;
  }
}
.usergroup__main table th, .usergroup__main table td {
  padding: 20px 20px 20px 0;
  border-bottom: 1px solid #f1f1f1;
}
@media screen and (max-width: 768px) {
  .usergroup__main table th, .usergroup__main table td {
    padding: 10px;
  }
}
.usergroup__main table th:nth-child(1), .usergroup__main table td:nth-child(1) {
  min-width: 20%;
}
.usergroup__main table th:nth-child(2):not(.item), .usergroup__main table td:nth-child(2):not(.item) {
  width: 60%;
}
.usergroup__main table th:last-child:not(.item), .usergroup__main table td:last-child:not(.item) {
  min-width: 20%;
  padding-right: 0;
}
.usergroup__main table tr:last-child td:not(.item) {
  border-bottom: 0;
}
.usergroup__main .usergroup__agenda--streams {
  table-layout: auto;
}
.usergroup__main .usergroup__agenda--streams th, .usergroup__main .usergroup__agenda--streams td {
  min-width: 0;
  width: auto;
  border: 1px solid #eaeaea;
  padding: 20px;
}
@media screen and (max-width: 768px) {
  .usergroup__main .usergroup__agenda--streams th, .usergroup__main .usergroup__agenda--streams td {
    display: block;
    width: 100% !important;
  }
}
.usergroup__main .usergroup__agenda--streams th {
  text-align: center;
  vertical-align: bottom;
}
@media screen and (max-width: 768px) {
  .usergroup__main .usergroup__agenda--streams th {
    display: none;
  }
}
.usergroup__main .usergroup__agenda--streams th span {
  vertical-align: middle;
}
.usergroup__main .usergroup__agenda--streams .item--time {
  text-align: center;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.8);
  background: #ffffff;
}
@media screen and (max-width: 768px) {
  .usergroup__main .usergroup__agenda--streams .item--time {
    display: none;
  }
}
.usergroup__main .usergroup__agenda--streams span {
  display: block;
}
.usergroup__main .usergroup__agenda--streams .item {
  position: relative;
  background: #fcfcfc;
}
.usergroup__main .usergroup__agenda--streams .time {
  font-weight: bold;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}
.usergroup__main .usergroup__agenda--streams .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 45px;
}
.usergroup__main .usergroup__agenda--streams .speaker {
  margin-top: 30px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  position: absolute;
  bottom: 20px;
}
.usergroup__main .usergroup__agenda--streams .item-heading {
  color: white;
}
.usergroup__main .usergroup__agenda--streams .item-heading--dev {
  background: #4FB847;
  width: 31%;
}
.usergroup__main .usergroup__agenda--streams .item-heading--repair {
  background: #ED174F;
  width: 31%;
}
.usergroup__main .usergroup__agenda--streams .item-heading--sor {
  background: #0397D7;
  width: 31%;
}
.usergroup__main .usergroup__agenda--streams .item-heading--time {
  color: black;
}
.usergroup__main .usergroup__agenda--streams .item--dev, .usergroup__main .usergroup__agenda--streams .item--repair, .usergroup__main .usergroup__agenda--streams .item--sor {
  vertical-align: top;
}
.usergroup__main .usergroup__agenda--streams .item-heading--time,
.usergroup__main .usergroup__agenda--streams .item--time {
  background: #ffffff;
}
.usergroup__main .usergroup__agenda--streams .item--dev {
  background: #d4d9de;
  overflow: hidden;
  position: relative;
  z-index: 100;
}
.usergroup__main .usergroup__agenda--streams .item--dev:before {
  content: "";
  display: block;
  position: absolute;
  background: #4FB847;
}
.usergroup__main .usergroup__agenda--streams .item--dev .time {
  color: #4FB847;
}
.usergroup__main .usergroup__agenda--streams .item--repair {
  background: #e3e6e9;
  overflow: hidden;
  position: relative;
  z-index: 100;
}
.usergroup__main .usergroup__agenda--streams .item--repair:before {
  content: "";
  display: block;
  position: absolute;
  background: #ED174F;
}
.usergroup__main .usergroup__agenda--streams .item--repair .time {
  color: #ED174F;
}
.usergroup__main .usergroup__agenda--streams .item--sor {
  background: #f1f3f4;
  overflow: hidden;
  position: relative;
  z-index: 100;
}
.usergroup__main .usergroup__agenda--streams .item--sor:before {
  content: "";
  display: block;
  position: absolute;
  background: #0397D7;
}
.usergroup__main .usergroup__agenda--streams .item--sor .time {
  color: #0397D7;
}
.usergroup__main .usergroup__agenda--streams .item--sor-blank {
  background: #f1f3f4;
}
.usergroup__main .usergroup__agenda--streams .item--sor-blank:before {
  display: none;
}
.usergroup__main .usergroup__agenda--streams .item--break {
  overflow: hidden;
  position: relative;
  z-index: 100;
}
.usergroup__main .usergroup__agenda--streams .item--break:before {
  content: "";
  display: block;
  position: absolute;
  background: goldenrod;
}
.usergroup__main .usergroup__agenda--streams .item--break .time {
  color: orange;
}
.usergroup__main .usergroup__agenda--streams .item--full {
  overflow: hidden;
  position: relative;
  z-index: 100;
}
.usergroup__main .usergroup__agenda--streams .item--full:before {
  content: "";
  display: block;
  position: absolute;
  background: #4FB847;
}
.usergroup__main .usergroup__agenda--streams .item--full .time {
  color: #4FB847;
}
.usergroup__main .usergroup__agenda--streams .item--exec {
  overflow: hidden;
  position: relative;
  z-index: 100;
}
.usergroup__main .usergroup__agenda--streams .item--exec:before {
  content: "";
  display: block;
  position: absolute;
  background: #4FB847;
}
.usergroup__main .usergroup__agenda--streams .item--doubleheight {
  position: relative;
  overflow: visible;
}
@media screen and (max-width: 768px) {
  .usergroup__main .usergroup__agenda--streams .item--doubleheight {
    overflow: auto;
  }
}
.usergroup__main .usergroup__agenda--streams .item--doubleheight .item-content {
  position: relative;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}
@media screen and (max-width: 768px) {
  .usergroup__main .usergroup__agenda--streams .item--doubleheight .item-content {
    -webkit-transform: none;
            transform: none;
  }
}
.usergroup__main .usergroup__agenda--streams .item--doubleheight .speaker {
  position: relative;
}
.usergroup__main h3, .usergroup__main h4 {
  margin: 30px 0 15px;
}
.usergroup__main ul {
  padding-left: 20px;
  margin-bottom: 40px;
  max-width: 700px;
}
.usergroup__main li {
  margin-bottom: 5px;
}

@media screen and (max-width: 1200px) {
  .usergroup__main iframe {
    max-width: 620px;
  }
}
@media screen and (max-width: 992px) {
  .usergroup__main iframe {
    max-width: 500px;
  }
}
.usergroup__main .aside__heading {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 15px;
}
.usergroup__nav-homepage .drinks {
  display: none;
}
.usergroup--drinks .usergroup__nav-homepage .default {
  display: none;
}
.usergroup--drinks .usergroup__nav-homepage .drinks {
  display: inline-block;
}
.usergroup--drinks .usergroup__nav-timetable {
  display: none;
}

.usergroup #mainContent p {
  margin-bottom: 15px;
}
.usergroup #mainContent em.notice {
  margin: 30px 0;
  font-weight: 500;
  padding: 20px;
  display: block;
  background: #f4f5f5; 
}
.usergroup .page-title {
  display: none;
}
.usergroup__title { 
  margin-bottom: 30px;
  margin-top: 30px;
}
.usergroup__agenda--table {
  width: 100%;
  max-width: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  position: relative;
}

.usergroup__agenda--table .item-heading--time, .usergroup__agenda--table .item--time {
  width: 95px;
}
@media screen and (max-width: 768px) {
  .usergroup__agenda--table .item-heading--time, .usergroup__agenda--table .item--time {
    display: none;
  }
}
.usergroup__agenda--table .item-details {
  color: #aaa;
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 0px;
  font-weight: 500;
}

.usergroup__agenda--table {
  .item-speaker, .item-facilitator, .item-description {
    font-size:13px;
  }
  .item-description {
    padding-right: 40px;
    @media screen and (max-width: 768px) {
        padding-right: 0;
    }
  }
} 
.usergroup__agenda--table .item--full {
  background: #fcfcfc;
}
.usergroup__agenda--table .item--break {
  background: #ffffff;
}
.usergroup__agenda--table .item--stream-1 {
  background: #e3e6e961;
}
@media screen and (max-width: 768px) {
  .usergroup__agenda--table .item--stream-1 .item-details {
    color: #8BC34A;
  }
}
.usergroup__agenda--table .item--stream-2 {
  background: #fafafa;
}
@media screen and (max-width: 768px) {
  .usergroup__agenda--table .item--stream-2 .item-details {
    color: #277dce;
  }
}
.usergroup__agenda--table .item-heading--time, .usergroup__agenda--table .item-heading--stream-1, .usergroup__agenda--table .item-heading--stream-2, .usergroup__agenda--table .item-heading--stream-3 {
  padding: 15px;
}
.usergroup__agenda--table .item-heading--stream-1 {
  background: #8BC34A;
  color: white;
}
.usergroup__agenda--table .item-heading--stream-2 {
  background: #277dce;
  color: white;
}
.usergroup__event-info {
  padding: 30px 20px;
  background: #fcfcfc;
  font-weight: 300;
  font-size: 14px;
}
.usergroup__event-info dt {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 4px;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #dedede;
}
.usergroup__event-info dt:first-child {
  padding-top: 0;
  margin-top: 0;
  border-top: 0;
}
.usergroup-nav-pills {
  padding: 0;
  background: #f4f5f6;
  width: 100%;
  max-width: 100%;
  color: black;
  margin-bottom: 30px !important;
  max-width: 100% !important;
  padding: 10px 15px;
}
.usergroup-nav-pills > li {
  margin-bottom: 0;
}
.usergroup-nav-pills > li > a {
  color: rgba(0, 0, 0, 0.4);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.usergroup-nav-pills > li.active > a, .usergroup-nav-pills > li.active > a:hover, .usergroup-nav-pills > li.active > a:focus {
  background-color: white;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.14);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.14);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.usergroup .section-help {
  margin-left: 15px;
  margin-right: 15px;
}
.usergroup__price-table {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  padding-top: 30px;
  margin-top: 30px;
  padding-bottom: 15px;
  margin-bottom: 0px;
}
.usergroup__price, .usergroup__vat, .usergroup__total {
  font-weight: bold;
}
.usergroup__vat div:first-child {
  font-weight: normal;
}



@mixin usergroupform {

}
.usergroup {
    .booking-form {
        @include baseform;
        background: $light-grey;
        padding: 30px;
        label {
            width: 50%;
            @media screen and (max-width: 768px) {
                width :100%;
            } 
            &.full {
                width: 100%;
            }
        }
        input {
            width: 50%;
            @media screen and (max-width: 768px) {
                width :100%;
            } 
        }
        .btn--submit, .btn--reset {
            @include infobutton;
            margin-bottom: 10px;
            font-size: 15px;
            padding: 10px;
            width: 100%;
        } 
        .btn--reset {
            background-color: #e1e1e1;
            color: black;
        }
        li {
            @include clearfix;
        }
        label, input {
            float: left;
        }
        .radio-container {
            label {
                @media screen and (max-width: 768px) {
                    width: 66.6%;
                }
            }
            input[type="radio"] {
                margin-top: 14px;
                @media screen and (max-width: 768px) {
                    width: 33.3%;
                }
            }
        }
        .checkbox-wrapper {
            label {
                &:before {
                   top: 2px;
                }
                &:after {
                    top: 6px;
                    border-color: green;
                }
            }
        }
    }
    .booking-form--product {
        @include clearfix;
        padding: 30px;
        background: $light-grey;
        label {
            color: #3c4858;
            display: inline-block;
            font-size: 15px;
            font-weight: 500;
            margin: 0;
            padding: 10px 0;
            vertical-align: top;
            &.required {
              &:after {
                content: "*";
                color: red;
              }
            }
        }

        input, select, textarea {
            background-color: #fff;
            border: 1px solid #efefef;
            color: #000;
            display: inline-block;
            font-weight: 300;
            height: auto;
            line-height: 1.5;
            padding: 12px 12px 10px;
            width: 100%;
        }
        textarea {
            min-height: 150px;
        }
        input[type="radio"] {
            margin-left: 5px;
            background: transparent;
        }
        .btn--submit, .btn--reset {
            @include infobutton;
            margin-bottom: 10px;
            font-size: 15px;
            padding: 15px;
        } 
        .btn--reset {
            background-color: #e1e1e1;
            color: black;
        }
        .message.error {
            padding: 15px;
            color: indianred;
        }
        .checkbox-wrapper {
            margin-top: 15px;
            margin-bottom: 30px;
            label {
                padding: 0 0 0 30px;
                span {
                    display:block;
                    transform: translateY(-4px);
                }
                &:before {
                    background: #fff;
                }
            }
        }
        fieldset {
          margin-bottom: 30px;
        }
        .addresslink {
            position: relative;
            @include unlink;
            &:before {
                content: "";
                width: 20px;
                height: 20px;
                background: url('/content/images/icons/arrow_down.svg') center no-repeat;
                background-size: 20px;
                display: inline-block;
                transform: translateY(4px);
                margin-right: 5px;
            }
        }
        .select-wrapper {
            background-color: #fff;
            border: 0;
            select {
                background: none;
            }
        }
      }
    .form__message {
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
        &--error {
            color: white;
        }
    }
    .internal {
      img {
        width: 100% !important;
        height: auto !important;
        max-width: 100%;
      }
    }
    #usergroup__day1{
      &__pamwin {
        .item-heading--stream-1 {
          background: #8bc34a;
        }
      }
      &__repairs {
        .item-heading--stream-1 {
          background:  #C60D47;
        }
      }
      &__sor {
        .item-heading--stream-1 {
          background:  #277dce;
        }
      }
    }
    &__agenda--table{
      .item {
        border: 4px solid white;
        padding: 20px;
        @media screen and (max-width: 768px) {
          display: block;
        }
      }
      .item-title, .item-title p {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 0;
      }
      .sublist, .subitem {
        margin: 0;
        padding: 0;
      }
      .sublist {
         list-style-type: none; 
         margin: 0;
         counter-reset: list;
         &__item {
          padding: 10px 30px 10px 70px;
          border-bottom: 1px solid rgba(0,0,0,0.1);
          position: relative;
          margin-bottom: 0;
          &:last-child {
            border-bottom: 0;
          }
          &:before {
            counter-increment: list;
            content: counter(list, lower-roman) ". ";
            padding-right: 15px;
            font-size: 14px;
            position: absolute;
            top: 10px;
            left: 30px;
            font-weight: 500;
          }
         }
         &__title {
           font-weight: bold;
           font-weight: 500;
           font-size: 14px;
         }
         &__speaker {
           font-weight: 300;
           font-size: 13px;
         }
      }
    }
    &.section {
        padding-top: 0;
    }
    &__intro {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        min-height: 350px;
        padding: 60px 0;
        position: relative;
        margin-bottom: 50px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #efefef;
        &:before {
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: #020024;
            background: -webkit-gradient(linear,left bottom,left top,from(rgba(2,0,36,.8295693277)),color-stop(70%,transparent));
            background: linear-gradient(0deg,rgba(2,0,36,.8295693277),transparent 70%);
        }
        @media screen and (max-width: 768px) {
            margin-bottom: 30px;
        }
        &--custom {
            display: block;
            padding: 0;
        }
        // &:before {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     bottom: 0;
        //     left: 0;
        //     right: 0;
        //     background: linear-gradient(0deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,.2) 48%, rgba(255,255,255,0) 60%);
        //     z-index: 1;
        // }
        &__title {
            margin-bottom: 5px;
            color: white;
            font-size: 54px;
            font-weight: 900;
            @media screen and (max-width: 768px) {
                font-size: 44px;
            }
        }
        &__subtitle {
            color: white;
            font-size: 28px;
            font-weight: 300;
            @media screen and (max-width: 768px) {
                font-size: 22px;
            }
        }
        .container {
            z-index: 10;
            position: relative;
        }
    }
    &__nav {
        transform:translateY(-20px);
        @include unlist;
        li {
            border-bottom: 1px solid #eaeaea;
            &:last-child {
                border-bottom: 0;
            }
            a {
                @include unlink;
                font-weight: bold;
                color: $dark-blue;
                display: block;
                padding: 20px 60px 20px 0px;
                background: url('/content/images/icons/caret-right-default.svg') no-repeat center right;
                background-size: 20px;
                &.active, &:hover {
                    background: url('/content/images/icons/caret-right-active.svg') no-repeat center right;
                    background-size: 20px;
                }
            }
        }
    }
    &__main {
        h1, h2, h3, h4, p {
            margin-top: 30px;
            &:first-child {
                margin-top: 0;
            }
        }
        .aside__heading {
            &:first-of-type {
                margin-top: 0;
            }
        }
        img, iframe {
            width: 100%;
            max-width: 100%;
            margin-bottom: 15px;
        }
        hr {
            border-top: 1px solid #eaeaea;
            margin-top: 30px;
            margin-bottom: 30px;
        }
        &__content {
            max-width: 760px;
            > p:first-child {
                font-size: 22px;
                font-weight: 400;
            }
            a {
               @include unlinkunderline($dark-blue, $dark-blue, $m3-blue);
            }
        }
        &__downloads {
            max-width: 760px;
            h1,h2, h3 {
                font-size: 24px;
                margin-bottom: 15px;
                margin-top: 0;
            }
            a {
                @include unlinkunderline($dark-blue, $dark-blue, $dark-blue);
                margin-right: 40px;
                display: inline-block;
            }
            ul {
                margin-top: 40px;
            }
        }
    } 
    &__link {
        @include unlinkunderline($dark-blue, $dark-blue);
    }
    &__quickview {
        background: #f4f5f6;
        padding: 30px;
        margin-bottom: 30px;
        margin-top: 10px;
        h3 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 15px;
        }
        dt {
            margin-bottom: 5px;
        }
        dd {
            margin-bottom: 20px;
        }
        a {
            @include unlinkunderline($dark-blue, $dark-blue, $dark-blue);
        }
        .material-icons {
            transform: translateY(4px);
            font-weight: normal;
            margin-right: 8px;
            font-size: 22px;
            color: #bababa;
        }
        hr {
            border-color: #dedede;
        }
    }
}

.usergroup__meeting{
  padding-bottom: 0;
}
.section__table__request {
  font-size: 14px;
  font-weight: medium;
}
.usergroup__main{
    .highlight-list {
        margin: 50px 0;
        counter-reset: list;
        list-style: none;
        padding: 0;
        li {
            counter-increment: list;
            padding-left: 0;
            margin-bottom: 30px;
            position: relative;
            font-size: 18px;
            line-height: 1.4;
            display: flex;
            padding: 0 15px;
            img {
                max-width: 100px;
                margin-right: 15px;
            }
        }
    }
}
.pw4 {
    .usergroup__intro--custom {
        h1 {
            font-size: 74px;
            font-weight: 800;
            margin-bottom: 30px;
            margin-top: 100px;
            color: black;
            @media screen and (max-width: 768px) {
                margin-top: 50px;
                font-size: 66px;
            }
        }
        h4, p {
            font-size: 24px;
        }
        h4 {
            font-weight: 800;
        }
        @media screen and (max-width: 768px) {
            padding-left: 0;
        }
        @media screen and (min-width: 1600px) {
            padding-left: 60px;
        }
    }
    .highlight-list {
        margin: 0 0 50px;
        counter-reset: list;
        list-style: none;
        padding: 0;
        li {
            counter-increment: list;
            padding-left: 0;
            position: relative;
            font-size: 18px;
            line-height: 1.4;
            display: flex;
            padding: 0 15px;
            img {
                max-width: 40px;
                margin-right: 15px;
            }
        }
        p { 
            margin-top: 15px
        }
        & + h2 {
            font-size: 18px;
            font-weight: bold;
        }
    }
    .section__list {
        list-style-type: none;
        padding-left: 10px;
        margin-bottom: 50px;
        margin-top: 10px;
        img {
            float: left;
            width: 28px;
            margin-right: 10px;
            filter: grayscale(1);
        }
        li {
            margin-bottom: 15px;
        }
    }
    .featured-image--right {
        transform: scale(1.08) translateX(30px);
        box-shadow: 0 30px 30px 0 rgba(0,46,91,.22)

    }
    .section__subtitle {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
    }
}
