$button-color: #577bb2;
$button-color-hover: darken($button-color, 5%);
$primary-color: yellow;
$blue: #005996;
$green: #8BC34A;
$red: red;
$highlight: orange;
$heading-text-color: #3C4858;
$body-text-color: #3C4858;;
$body-text-color-light: #fff;
$lighter-grey: #f9f9f9;
$light-grey: #f4f5f6;
$medium-grey: #eaeaea;
$dark-grey: #ccc;
$grey: #efefef;
$error:#de2e2e; 
$success: #8BC34A; 
$warning: goldenrod;
$yellow-notice-bar:#f6e58d;


$inputborder: #dedede;
$inputbackground: #fcfcfc;
$formbackground: #fafafa;
$light-grey: #f4f5f6;


/* 2018 Update */
$m3-blue: #005996;
$dark-blue:#172B46;
$dark-blue: #102542;
$text-over-dark-blue:#8490A1;
$med-blue:#3C4858;
$darkpurple: #330F63;


/** M3 Icon colours **/
$m3-blue: #005996;
$m3-midblue: #5B80B2;
$m3-lightblue: #A2B2D2;
$m3-darkblue: $dark-blue;


$pamwin-green:#54b948;
$pamwinlite-green:#b0bc22;
$central-red:#ed174f;
$sor-blue:#0096d6;

$shadow-lvl2:0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);