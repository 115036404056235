.faqanswer {
    table {
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        td, th {
            padding: 15px;
            border: 1px solid #ddd;
        }
    }
}

.faq {
    h5 {
        margin: 0;
    }
    &__question {
        @include unlink;
        display: block;
        padding: 20px 60px 20px 20px;
        background-color: #f4f5f6;
        font-size: 16px;
        border-bottom: 1px solid #f1f1f1;
        margin-bottom: 4px;
        background-image: url(/content/images/icons/ico-caret-down-thin.svg);
        background-repeat: no-repeat;
        background-size: 14px;
        background-position: right 20px center;
        transition: background 0.2s ease;
        &:hover, &:active, &:focus {
            background-color: darken(#f4f5f6, 5%);
        }
    } 
    &__answer {
        padding: 20px;
        border: 1px solid #eaeaea;
        margin-bottom: 30px;
        a {
            @include unlinkunderline(inherit,inherit, $m3-blue);
        }
        table {
            border-collapse: collapse;
            width: 100%;
            max-width: 80%;
            td {
                border: 1px solid #eaeaea;
                padding: 15px;
                p {
                    margin: 0;
                }
            }
            tr:nth-child(odd) {
                background: #f4f5f6;
            }
        }
    }
    .rulecontent + .faq__title {
        margin-top: 90px;
    }
    &__title {
        margin-bottom: 20px;
        font-size: 24px;
    }
    &__sidebar {
        padding: 0;
        background: transparent;
    }
    & + .faq {
        margin-top: 80px;
    }
}
