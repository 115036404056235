/*************************************************/
/* Pamwin Support */
/*************************************************/
.pamwin h1.row > img {
  display: none;
}

.category {
  background: #efefef;
  position: relative;
}
.category__title {
  padding: 35px 40px 30px 30px;
  margin: 0 !important;
  display: inline-block;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .category__title {
    padding: 30px 15px;
    font-size: 18px;
  }
}
@media screen and (max-width: 550px) {
  .category__title {
    font-size: 16px;
  }
}
.category__subtitle {
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  background: #8BC34A;
  display: inline-block;
  position: absolute;
  padding-top: 38px;
  padding-left: 40px;
  padding-right: 40px;
  top: 0;
  bottom: 0;
  right: 0;
}
@media screen and (max-width: 768px) {
  .category__subtitle {
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 14px;
  }
}

#mainContent .pamwin {
  padding-bottom: 0;
}

.support_pamwin h1.row {
  display: none;
}

.support_pamwin #mainContent {
  padding-bottom: 0;
}

.pamwin--training h1.row {
  display: none;
}
.pamwin--training .btn {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: $m3-blue;
  border: 0;
  border-radius: 2px;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 20px 40px;
  color: white;
  font-weight: 600;
  letter-spacing: 0.5px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.pamwin--training .btn:hover {
  background: lighten($m3-blue, 5%);
  border-radius: 2px;
  padding: 20px 40px;
}
@media screen and (max-width: 768px) {
  .pamwin--training #inhouse_training_enquiry .modal-dialog {
    width: auto;
    max-width: 100%;
  }
}
.pamwin--training #inhouse_training_enquiry .modal-content {
  background: #fcfcfc;
  padding: 30px;
  border-radius: 2px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 300;
}
@media screen and (max-width: 768px) {
  .pamwin--training #inhouse_training_enquiry .modal-content {
    padding: 30px 15px;
  }
}
.pamwin--training #inhouse_training_enquiry .modal-content .m3field {
  padding: 0 0 5px 0;
}
@media screen and (max-width: 768px) {
  .pamwin--training #inhouse_training_enquiry .modal-content .m3field {
    padding-bottom: 15px;
  }
}
.pamwin--training #inhouse_training_enquiry .modal-content .m3field.alt {
  background: transparent;
}
.pamwin--training #inhouse_training_enquiry .modal-content .m3field > div {
  padding: 0;
}
.pamwin--training #inhouse_training_enquiry .modal-content .m3field .control-label {
  padding: 0;
}
.pamwin--training #inhouse_training_enquiry .modal-content input[type="text"],
.pamwin--training #inhouse_training_enquiry .modal-content input[type="password"],
.pamwin--training #inhouse_training_enquiry .modal-content .input-group-addon,
.pamwin--training #inhouse_training_enquiry .modal-content textarea {
  border: 1px solid #eaeaea;
  border-radius: 0;
}
.pamwin--training #inhouse_training_enquiry .modal-content p {
  margin-bottom: 20px;
}

.pamwin--uploads #mainContent {
  border-top: 1px solid #eaeaea;
  padding-bottom: 0;
}
.pamwin--uploads p {
  margin-bottom: 15px;
}
.pamwin--uploads .section--notice {
  padding-bottom: 0;
}
.pamwin--uploads .error {
  color: indianred;
}
.pamwin--uploads h2 {
  margin-top: 0;
}
.pamwin--uploads h2, .pamwin--uploads h3 {
  margin-bottom: 15px;
}
.pamwin--uploads form {
  padding: 40px;
  background: #fafafa;
}
@media screen and (max-width: 768px) {
  .pamwin--uploads form {
    padding: 30px 15px;
  }
}
.pamwin--uploads .btn-large {
  display: none;
}
.pamwin--uploads label {
  font-weight: 600;
}
.pamwin--uploads textarea {
  border: 1px solid #eee;
  width: 100%;
}
.pamwin--uploads input[type="file"] {
  padding: 15px;
  border: 1px dashed #ccc;
  width: 100%;
}
.pamwin--uploads input[type="submit"],
.pamwin--uploads input[type="button"] {
@include infobutton;
width: 100%;
}

.pamwin--uploads #fixing, .pamwin--uploads #uploaded, .pamwin--uploads #finished {
  background: white;
  padding: 10px 30px 20px;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .pamwin--uploads #fixing, .pamwin--uploads #uploaded, .pamwin--uploads #finished {
    padding: 10px 15px;
  }
}
.pamwin--uploads .section--files {
  background: #fafafa;
}

.pamwin--version h1.row, .pamwin--release h1.row {
  display: none;
}
.pamwin--version h2, .pamwin--release h2 {
  display: inline-block;
}
.pamwin--version .align-right, .pamwin--release .align-right {
  float: right;
  margin-top: 15px;
}
@media screen and (max-width: 768px) {
  .pamwin--version .align-right, .pamwin--release .align-right {
    display: block;
    float: none;
    margin-top: 0;
    width: 100%;
  }
}
.pamwin--version h2, .pamwin--version h3, .pamwin--release h2, .pamwin--release h3 {
  margin-bottom: 15px;
}
.pamwin--version > p, .pamwin--release > p {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .pamwin--version > p, .pamwin--release > p {
    margin-bottom: 15px;
  }
}
.pamwin--version .notifications, .pamwin--release .notifications {
  padding-top: 50px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .pamwin--version .notifications, .pamwin--release .notifications {
    padding-top: 30px;
  }
}
.pamwin--version .notifications h4, .pamwin--release .notifications h4 {
  font-weight: 500;
  color: #3C4858;
  display: inline-block;
  margin-right: 15px;
}
.pamwin--version .notifications p button, .pamwin--release .notifications p button {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}
.pamwin--version .notifications .m3checkbox, .pamwin--release .notifications .m3checkbox {
  margin-right: 15px;
}

.pamwin--upgrade-guide {
  font-weight: 300;
  min-height: 70vh;
}
.pamwin--upgrade-guide h1.row {
  display: none;
}
.pamwin--upgrade-guide h2 {
  display: inline-block;
}
.pamwin--upgrade-guide h4 {
  margin-bottom: 15px;
}
.pamwin--upgrade-guide p {
  margin-bottom: 15px;
}
.pamwin--upgrade-guide .btn-group > .btn {
  margin: 0 5px;
}

.pamwin--usergroup {
  font-weight: 300;
}
.pamwin--usergroup h1.row {
  display: none;
}
.pamwin--usergroup p {
  margin-bottom: 15px;
}
.pamwin--usergroup h1, .pamwin--usergroup h2, .pamwin--usergroup h3 {
  margin-bottom: 15px;
}
.pamwin--usergroup .section__quote {
  margin-top: 30px;
  margin-bottom: 40px;
  padding: 40px;
  background: #f4f5f6;
}
.pamwin--usergroup .section__quote h2, .pamwin--usergroup .section__quote h3 {
  margin-top: 0;
}
.pamwin--usergroup .section__quote p:last-child {
  margin-bottom: 0;
}
.pamwin--usergroup .section__table {
  border-collapse: collapse;
  margin-bottom: 30px;
}
.pamwin--usergroup .section__table th {
  font-weight: 600;
  font-size: 12px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}
.pamwin--usergroup .section__table td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pamwin--usergroup .section__table .ticket, .pamwin--usergroup .section__table .votes, .pamwin--usergroup .section__table .update, .pamwin--usergroup .section__table .schedule {
  text-align: center;
}

.pamwin--feature-details .page-title,
.pamwin--issue-details .page-title {
  display: none;
}

.pamwin--issue .page-title {
  display: none;
}
.pamwin--issue h2 {
  font-size: 30px;
  margin-bottom: 30px;
}
.pamwin--issue .list-unstyled {
  margin-bottom: 30px;
}
.pamwin--issue .list-unstyled li {
  display: inline-block;
  margin: 0 8px 5px 0;
  padding: 5px 12px;
  background: #f4f5f6;
  border-radius: 2px;
  font-weight: 400;
}

.pamwin--reports #mainContent,
.pamwin--faq #mainContent,
.pamwin--video #mainContent,
.pamwin--glossary #mainContent,
.pamwin--user-group #mainContent {
  padding-bottom: 0;
}
.pamwin--reports .btn-large.hidden-xs,
.pamwin--faq .btn-large.hidden-xs,
.pamwin--video .btn-large.hidden-xs,
.pamwin--glossary .btn-large.hidden-xs,
.pamwin--user-group .btn-large.hidden-xs {
  display: none;
}
.pamwin--reports .btn-large.hidden-xs + br,
.pamwin--faq .btn-large.hidden-xs + br,
.pamwin--video .btn-large.hidden-xs + br,
.pamwin--glossary .btn-large.hidden-xs + br,
.pamwin--user-group .btn-large.hidden-xs + br {
  display: none;
}

.section-training-guides {
    .section__subtitle {
        padding: 0 10px;
    }

}
.section-how-to--pamwin {
    iframe {
        background: #f9f9f9;
        border: 0;
    }
}


#section-pamwinsync {
    background: url('/images/bg-pc-full.png') no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    @media screen and (max-width: 768px) {
        background: #fff;
        border-top: 1px solid #dedede;
    }
    em {
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
    }
    .pamwinsync__header {
        @include clearfix;
        .img-logo {
            max-width: 72px;
            margin-right: 10px;
            margin-top: 5px;
            float: left;
        }
    }
    // &:before {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     bottom: 0;
    //     right: 0;
    //     background: linear-gradient(95deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,1) 100%);
    //     width: 100%;
    //     height: 100%;
    //     display: block;
    //     @media screen and (max-width: 768px) {
    //         display: none;
    //     }
    // }
}

.section-header--pw, .section-header--pwlite {
    max-height: 940px;
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
    position: relative;
}
.section-header--pw {
    background-image: url(/images/dmImage/StandardImage/bg-pamwin-promo.jpg);
    &:before {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgb(2,0,36);
        background: linear-gradient(0deg, rgba(2,0,36,0.8295693277310925) 0%, rgba(0,0,0,0) 70%);
    }
}
.section-header--pwlite {
    background-image: url(/images/dmImage/StandardImage/bg-pw-lite.png); 
    &:before {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgb(2,0,36);
        background: linear-gradient(0deg, rgba(2,0,36,0.9976365546218487) 0%, rgba(2,0,36,0.6222864145658263) 36%, rgba(2,0,36,0.3477766106442577) 100%);
    }
    .section__header__title {
        text-shadow: 0 1px rgba(0,0,0,0.2);
    }
}
#section-powerbi {
    // background-image: url(/images/dmImage/StandardImage/PowerBI.png);
    // background-size: 1200px;
    // background-position: right -510px center;
    // background-repeat: no-repeat;
    position: relative;
    @media screen and (max-width: 1280px) {
        background-size: 1000px;
        background-position: right -580px center;
    }
    @media screen and (max-width: 768px) {
        background: none;
    }
    .btn-play{
        margin-top: 180px;
        margin-bottom:100px;
        display: flex;
        flex-direction: column;
        img {
            align-self: flex-end;
        }
        @media screen and (max-width:768px) {
            display: none;
        }
    }
}
.container-full {
    position: relative;
    width: 100%;
}
.container-half {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    @media screen and (max-width: 768px) {
        display: block;
        position: static;
        width: 100%;
    }
}
.flex-container {
    display: flex;
    height: 100%;
    &--center {
        align-items: center;
        justify-content: center;
    }
    iframe {
        margin-left: 25px;
    }
}