/*************************************************/
/* CONTACT US                                    */
/*************************************************/
.contact-us {
	.page-title {
		display: none;
	}
	#mainContent {
		padding-bottom: 0;
    }
}
.section-contact--faq {
    .info {
        padding: 10px 0;
    }
    .info__icon {
        img {
            width: 80px;
            height: 80px;
            transform: translateY(-22px);
        }
    }
}
.info__anchor {
    font-weight: 700;
    font-size: 14px;
    color: #3C4858;
    display: block;
    padding-top: 40px;
    i {
        vertical-align: bottom;
    }
}
.honey-pot{
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	height: 0;
	width: 0;
	z-index: -1;
}
.contact {
	&__form {
		.message {
            background:$error;
			font-size: 12px;
            font-weight: 400;
            line-height: 1;
			color: white;
			padding: 4px;
			border-radius: 2px;
            display: none;
            float:left;
            margin-top: 2px;
		}
		.message.error {
			display:inline-block;
        }
        &--padded {
            padding: 30px 0;
        }
	}
	&__heading {
		color: #91969dd9;
		font-size: 14px;
		font-weight: 500;
		margin-top: 60px;
		margin-bottom:20px;
	}
	&__field {
		margin-bottom: 20px;
		display: block;
        clear: both;
        position: relative;
        @media screen and (max-width:768px) {
            margin-bottom: 30px;
        }
		abbr {
			border: 0;
			color: rgba(0,0,0,0.4);
			line-height: 1.1;
			text-decoration: none;
			vertical-align: bottom;
		}
		label, .contact__label {
			color: #3C4858;
			display: inline-block;
			font-weight: 500;
			font-size: 16px;
			padding: 10px 0;
			margin: 0;
			width: 100%;
			vertical-align: top;
            line-height: 1.4;
			@media screen and (max-width: 768px) {
				padding: 0;
				margin-bottom: 5px;
			}
		}
		input, select, textarea {
			border: 1px solid #dedede;
			background-color: #fcfcfc;
			color: black;
			display: inline-block;
			font-weight: 300;
			line-height: 1.5;
			padding: 12px 12px 10px;
			width: 100%;

		}	
		textarea {
			min-height: 144px;
		}
		select {
			background: transparent;
			width: 100%;
			border: 0;
			margin: 0;
            appearance: none;
            padding: 12px 36px 10px 12px;
            &::-ms-expand {
                display: none;
            }
                
		}
		.select-wrapper {
			background: url(/content/images/icons/arrow_down.svg);
			background-color: #fff;
			background-repeat: no-repeat;
			background-position: 98%;	
			background-size: 20px;
			border:1px solid #dedede;
			background-color: #fcfcfc;
			overflow: hidden;
			width: 100%;
		}
		input[type="checkbox"] {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			width: 20px;
			height: 20px;
			z-index: 100;
			&:hover ~ label:before {
				background-color:#ccc;
			}
			&:checked ~ label:after {
				display: block;
			}
		}
		.checkbox-wrapper {
			display: inline-block;
			position: relative;
			user-select: none;
			margin-top: 15px;
			@media screen and (max-width: 768px) {
				margin-top: 15px;
			}
			label {
				padding-left: 30px;
				padding-top: 0;
                padding-bottom: 0;
                font-weight: 500;
                font-size: 14px;
				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					height: 20px;
					width: 20px;
					background-color: #eee;
					border: 1px solid #ddd;
					border-radius: 2px;
					transition: all 0.2s ease;
				}
				&:after {
					content: "";
					display:none;
					position: absolute;
					left: 7px;
					top: 4px;
					width: 5px;
					height: 10px;
					border: solid black;
					border-width: 0 3px 3px 0;
					transform: rotate(45deg);
				}
			}
        }
        .checkbox-label {
            margin-top: 5px;
        }
        .checkbox-container {
            float: right;
            width: 50%;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
        .help-block {
            padding: 15px;
            background: #f4f5f6;
            border: 1px solid #f1f1f1;
            line-height: 1.6;
        }
    }
    &__field--inline {
        label {
            width: 50%;
            float: left;
            padding-right: 15px;
            @media screen and (max-width:768px) {
                width: 100%;
                float: none;
            }
        }
        input, select, textarea {
            width: 50%;
            float: left;
            height: auto;
            @media screen and (max-width:768px) {
                width: 100%;
                float: none;
            }
        }
        input[type="radio"] {
            width: 20px;
            margin-left: -5px;
            & + span {
                padding-left: 20px;
            }
        }
        .radio-inline {
            width: calc(25% - 1px);
            float: left;
            margin-left: 0;
        }
        .select-wrapper {
            float: left;
            width: auto;
            max-width: 50%;
            @media screen and (max-width:768px) {
                max-width: 100%;
            }
            select {
                width: 100%;
            }
        }
    }
    &__field--terms {
        @include clearfix;
    }
    &__info {
        padding: 40px 30px;
        background: #f4f5f6;
        border-radius: 4px;
        margin-top: 38px;
        @media screen and (max-width: 768px) {
            padding: 15px;
            margin-bottom: 15px;
        }
        &-link {
            &:link, &:focus, &:visited{
                color: black;
                font-weight: 400;
                text-decoration: underline;
            }
        }
    }
	&__actions {
		@include clearfix;
		padding-top: 15px;
		@media screen and (max-width:768px) {
			padding-top: 0;
		}
		.recaptcha-container {
			float: right;
			@media screen and (max-width:768px) {
				float: none;
			}
			.message {
				float: right;
				@media screen and (max-width: 768px) {
					display: inline-block;
					float: none;
					margin-left: 0px;
					margin-bottom: 10px;
				}
			}
		}
		.g-recaptcha {
			transform: scale(0.84);
			margin-right: -25px;
			display: block;
			@media screen and (max-width:768px) {
				margin-left: -25px;
			}
			line-height:1;	
		}
    }
    &__actions--inline {
        text-align: center;
        .contact__submit {
            display: inline-block;
            @media screen and (max-width: 768px) {
                display: block;
            }
            input[type="submit"] {
                min-width: 200px;
                max-width: 100%;
                margin: 15px 0;
            }
        }
    }
    &__submit {
        input[type="submit"] {
			appearance: none;
			background: $blue;
			border: 0;
			color: white;
			float: none;
			font-weight: 500;
			padding: 18px 40px;
			margin-top: 5px;
			width: 100%;
			max-width: 240px;
            transition: all 0.2s ease;
            text-align: center;
			@media screen and (max-width: 768px) {
				max-width: 100%;
			}
			&:hover, &:active {
                background: lighten($blue, 5%);
                box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
            }
            &.full {
                max-width: 100%;
            }	
        }
        & + p {
            margin-top: 30px;
        }
    }
}
