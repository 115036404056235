.sage {
    ol {
        li {
            font-weight: bold;
        }
    }
    ul {
        margin: 30px 0;
        li {
            font-weight: normal;
            ul {
                margin: 15px;
                list-style-type:circle;
            }
        }
    }
    &__message--error {
        border: 2px solid indianred;
        color: indianred;
        padding: 15px;
        font-weight: 600;
        text-align: left;
        position: relative;
        .glyphicon {
            position: relative;
            transform: translateY(5px);
            font-size: 24px;
            margin-right: 8px;
        }
    }     
}