.crm-renewal {
    &__table {
        width: 100%;
       td, th {
            padding: 15px 30px 15px 15px;
            border: 1px solid $light-grey;
       }
       margin-bottom: 30px;
    }
    form {
        @include baseform;
        ol {
            @include unlist;
        }
    }
} 