.upcoming-trainings {
    &__heading {
        @include heading-1;
        margin-bottom: 40px;
        text-align: center;
        @media screen and (max-width: 768px) {
            margin-bottom: 25px;
            text-align: left;
        }
        a {
            @include unlink;
        } 
    }
    &__link {
        @include unlink($dark-blue, lighten($dark-blue,25%));
        font-weight: bold;
        text-align: center;
        display: block;
        margin-top: 40px;
    }
    .upcoming-training {
        position: relative;
        background: $formbackground;
        margin-bottom: 30px;
        box-shadow: 0px 30px 50px 0px rgba(0,46,91,0.22);
        @media screen and (max-width: 768px) {
            margin-bottom: 15px;
        }
        &__link {
            @include unlink;
        }
        &__image-container {
            @include darken-background;
            min-height: 250px;
            background-size: cover;
            background-position: center;
            &:after {
                background: rgba(0,0,0,0.06);
            }
        }
        &__image {
            width: 20%;
            height: auto;
            max-width: 100%; 
            max-height: 250px;        
        }
        &__icons {
            width: 18px;
            height: 18px;
            margin-right: 10px;
        }
        &__details {
            padding: 30px;
            a {
                @include unlink;
                border-bottom: 1.5px solid black;
            }
            &__footer {
                margin-bottom: 30px;
            }    
        
        }
        &__date, &__location {
            font-size: 14px;
            font-weight: 500;
            display: flex;
            margin-right: 40px;
            margin-bottom: 10px;
        }
        &__location {
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
        &__title {
            @include heading-3; 
            font-size: 20px;
            margin-bottom: 15px;
        }
        &__button {
            margin-top: 40px;
            button {
                appearance: none;
                font-size: 16px;
                font-weight: bold;
                display: inline-block;
                padding-bottom: 2px;
                border-bottom: 2px solid $m3-blue;
                background: transparent;
                padding-left: 0;
                padding-right: 0;
                border-radius: 0;
            }
        }
        &:before {
            content: '';
            display: none;
            position: absolute;
            padding: 8px 20px;
            font-size: 15px;
            z-index: 100;
            top: 0;
            left: 0;
            background: #172B46;
            color: white;
            font-weight: 400;
        }
        &.none {
            &:before {
                display: none;
            }
        }

        &.announcement {
            &:before {
                content: 'Announcement';
                background: #172B46;
                display: block;
            }
        }
        &.news {
            &:before {
                content: 'News';
                background: #172B46;
                display: block;
            }
        }
        &.nhmf {
            &:before {
                content: 'NHMF';
                background: #fb0;
                display: block;
            }
        }
        &.awards {
            &:before {
                content: 'NHMF Awards';
                background: #fb0;
                display: block;
            }
        }
        &.committee {
            &:before {
                content: 'NHMF Committee';
                background: #fb0;
                display: block;
            }
        }
        &.conference {
            &:before {
                content: 'NHMF Conference';
                background: #fb0;
                display: block;
            }
        }

        &.usergroup {
            &:before {
                content: 'User Group';
                background:#005996;
                display: block;
            }
        }
        &.training {
            &:before {
                content: 'Training';
                background:#0397D7;
                display: block;
            }
        }
        &.upcoming-trainings {
            &:before {
                content: 'Upcoming training';
                background:#0397D7;
                display: block;
            } 
        }
        &.exhibition {
            &:before {
                content: 'Exhibition';
                background:#0397D7;
                display: block;
            } 
        }
    }
} 
.upcoming-training__header {
    padding: 15px 30px;
    h4 {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        color: white;
    }
    &.HATC {
        background-color: orange;          
    }
    &.M3Central {
        background-color: #e50043; 
        
    }
    &.M3PamwinPlus {
        background-color: #56AF31; 
        
    }
    &.M3PamwinLite {
        background-color: #BCBE00; 
        
    }
    &.SOR {
        background-color: #0095D6; 
        
    }
    .M3LocatorPlus {
        background-color: #00A6AA; 
        
    }
}