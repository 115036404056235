.internal-feedback {
    form {
        @include baseform;
        ol {
            @include unlist;
            label {
                text-align: left;
            }
        }
        .radio-inline {
            margin-left: 20px;
            span {
                display: inline-block;
                padding-left: 5px;
            }
        }
    }
} 