/******************************************
SOR PRODUCT PAGE
******************************************/
.schedules {
	.info {
		&__icon {
			img {
				max-width: 64px;
				max-height: auto;
				width: 64px;
				height: auto;
			}
			@media screen and (max-width:520px) {
				display: none;
			}
		}
		&__text {
			a {
				font-weight: 400;
			}
		}
	}
	.section {
		&-header__button {
			margin-bottom: 0;
			@media screen and (max-width: 768px) {
				margin-bottom: 15px;
			}
		}
	}
}
.schedules, .modules, .pppv {
	@include removetitlerow;
	#mainContent {
		padding-bottom: 0;
	}
}
.modules {
	#mainContent {    
		.container {
			background: transparent;
		}
	} 
	.tab-content {
		font-weight: 300;
		background: #fff;
        padding: 30px 50px;
        border-left: 1px solid $light-grey;
        @media screen and (max-width: 992px) { 
            padding: 30px 25px;
        }
		@media screen and (max-width: 768px) {
			padding: 25px 0;
            margin-bottom: 30px;
            border-left:0;
		}
		li {
			line-height: 1.6;
		}
	}
	.tab-pane {
		h2 {
			font-size: 28px;
			font-weight: 600;
			color: #3C4858;
			margin-top: 0;
			margin-bottom: 30px;
			@media screen and (max-width: 768px) {
				font-size: 24px;
			}
		}
		h3 {
			color: #3C4858;
			font-size: 18px;
			font-weight: bold;
            margin: 50px 0 15px;
			@media screen and (max-width: 768px) {
				margin: 30px 0 15px;	
            }
            &:first-child {
                margin-top: 0px !important;
            }
		}
		img {
			max-width: 100%;
		}
	}
}



/* Alternative section blue */
.section--blue {
	background: $blue;
	display: none;
	.section__title, .section__description {
		color: white;
	}
	.card-plain {
		.card-category {
			color: white;
		}
		.card-title {
			color: white;
		}
		ul li, b {
			color: white;
		}
	}
}

.document-list {
    list-style: none;
    margin-top: 15px;
    padding-left: 25px;
    font-size: 14px;
    li {
        margin-bottom: 15px;
        padding-left: 15px;
        position: relative;
        &:before {
            content : "";
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            background: url('/content/images/icons/word.svg') no-repeat;
            background-size: 20px;
            left: -15px;
            top: 1px;

        }
    }
}

/******************************************
SOR Request, Approval, and Download Page
******************************************/

.form__heading {
    margin-top: 40px;
    margin-bottom: 15px;
}
.form__table { 
    label {
        display: inline-block;
        max-width: calc(100% - 30px);
        float: none;
    }
    &-row{ 
        margin-top: 15px;
        margin-bottom: 15px;
    }
    input {
        display: inline-block;
    }
    &-header {
        font-weight:600;
    }
    .label-wrap {
        max-width: 100%;
        @media screen and (max-width:768px) {
            text-align: left;
        }
    }
    &-row {
        border-bottom: 1px solid #eee;
        padding-top: 5px;
        padding-bottom: 5px;
        &:last-child{
            border-bottom: 0;
        }
        @media screen and (max-width: 768px) {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    &-note {
        background: #f4f5f6;
        padding: 30px;
        border-radius: 2px;
        margin: 40px 0;
        a:link, a:visited, a:focus {
            color: black;
        }
        @media screen and (max-width: 768px) {
            padding: 15px;
            margin: 30px 0;
        }
    }
    .col-version {
        font-weight: 400;
        background: #f4f5f6;
        text-align: center;
    }
    .col-schedule {
        @media screen and (max-width: 768px) {
            font-weight: 500;
        }
    }
    .btn__download--small{
        padding: 5px 18px;
        color: white;
        background: $green;
        font-weight: 500;
        display: inline-block;
        text-decoration: none;
        transition: all 0.2s ease;
        appearance: none !important;
        border: 0;
        font-size: 12px;
        &:hover {
            background:lighten($green,5%);
        }
    }
    .licenced {

    }
    .nolicence {
        color: $error;
    }
}

.section-landing--schedules {
    .flex-grid-4, .flex-grid-3, .flex-grid-2, .flex-grid {
       margin-left: -15px;
       margin-right: -15px;
       margin-top: 0;
       margin-bottom: 0;

    }
    .flex-grid {
        display: flex;
    }
    .item {
        margin-bottom: 30px;
        padding: 30px;
        position: relative;
        background: $lighter-grey;
        min-height: 285px; 
        @media screen and (max-width: 768px) {
            min-height: 140px;
        }
        &--highlight {
            background: $dark-blue;
            color: white;
            a {
                color: white !important;
            }
        }
        &--highlight, &--subhighlight {
            .item__details {
                min-height: 290px;
                @media screen and (max-width: 768px) {
                    min-height: 140px;
                }
            }
        }

        &__image-container{
            display: flex;
            align-items: flex-start;
            position: relative;
            min-width: 64px;
            margin-bottom: 15px;
            img {
                max-width: 100%;
                width: 64px;
                margin-right: 10px;
            } 
        }
        &.corporate, &.small-ha {
            &:after {
                display: inline-block;
                background: $dark-blue;
                color: #fff;
                font-size: 12px;
                font-weight: 500;
                position: absolute;
                top: -10px;
                right: 0;
                line-height: 1;
                padding: 6px 12px;
            }
        }
        h3 {
            @include heading-3;
            margin-bottom: 15px;
        }
        &.corporate {
            &:after {
                content: "For corporate buildings";
            }
        }
        &.small-ha {
            &:after {
                content: "For smaller HAs";
            }
        }
        &__details {
            margin-bottom: 30px;
        }
        &__links {
            margin-top: auto;
            margin-bottom: 0;
            padding-top: 25px;
            position: absolute;
            bottom: 30px;
            a {
                font-size: 16px;
                color: #3c4858;
                @include unlink(initial);
                font-weight: 400;
                display: inline-block;
                margin: 0 10px 0 0;

                &:first-child {
                    margin-left: 0;
                    padding-right: 10px;
                    border-right: 1px solid;
                }
            }
 
        }
           
    }
    .col-1 {
        flex: 1;
    }
    .col-2 {
        flex: 2;
    }
    .col-3 {
        flex: 3;
    }
    .col-4 {
        flex: 4;
    }
    .col-1, .col-2, .col-3, .col-4 {
        display: block; 
    }
    .col {
        display: block;
    } 
    h4 {
        @include heading-4;
        margin-bottom: 15px;
    }
}

.section-header--sor{
    max-height: 940px;
    background: url(/images/dmImage/StandardImage/banner-sor.jpg) left top no-repeat;
    position: relative;
    background-size:cover;
    &:before {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgb(2,0,36);
        background: linear-gradient(0deg, rgba(2,0,36,0.8295693277310925) 0%, rgba(0,0,0,0) 70%);
    }
}


#section-sorfeature {
    background: url(/images/bg-sor-1.png) no-repeat;
    background: url(/images/bg-sor.png) no-repeat;
    background-size: cover;
    background-position: 50%;
    position: relative;
}