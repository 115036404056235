.about {
    &__intro {
        background-color: $m3-blue;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        color: white;
        text-align: center;

        &__title {
            color: #fff;
            text-align: center;
            font-size: 58px;
            margin-bottom: 10px;
        }

        &__description {
            color: #fff;
            font-size: 24px;
            font-weight: 300;
            line-height: 1.25;
            text-align: center;
            margin-bottom: 0;
        }
    }

    &__content {
        // padding-right: 100px;
        max-width: 740px;
        h1,
        h2,
        h3,
        h4,
        p {
            &:first-child {
                margin-top: 0;

                +p {
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 1.6;
                }
            }
        }
        h1, h2, h3, h4, h5 {
            font-weight: bold;
            line-height: 1.3;
        }
        h3 {
            font-size: 22px;
        }
        img {
            max-width: 100%;
            width: 100%;
        }

        table {
            @include table;
        }

        a {
            font-weight: 500;
        }
        hr {
            margin-top: 34px;
            margin-bottom: 34px;
        }
        .default-btn {
            &:link, &:active, &:visited, &:focus {
                color: #fff;
            }
        }
    }

    &__aside {
        strong {
            font-size: 14px;
            font-weight: bold;
        }

        .social {
            display: flex;
            font-size: 14px;
            font-weight: bold;
            margin-top: 20px;

            svg {
                max-width: 24px;
                max-height: 24px;
                margin-left: 10px;
                transition: all 0.2s ease;

                &.linkedin {
                    transform: translateY(-2px);
                }

                &:hover {
                    path {
                        fill: #c1c1c1;
                    }
                }
            }
        }

        &.info {
            // padding: 15px;
            // padding-top: 15px;
            padding-top: 0;

            a {
                @include unlink(black)
            }
        }
        .default-btn {
            &:link, &:active, &:visited, &:focus {
                color: #fff;
            }
        }
    }

    &__address {
        span {
            display: block;
            margin-bottom: 5px;

        }
    }

    &__downloads {
        margin-top: 50px;
        padding-top: 50px;
        border-top: 1px solid #dedede;

        ul {
            padding-left: 0;

            li {
                list-style-type: none;

                a {
                    position: relative;
                    @include unlink($dark-blue, black);
                    font-weight: 400;
                    display: block;
                    border-bottom: 1px solid #f1f1f1;
                    padding: 12px 0;

                    &:before {
                        content: "";
                        position: absolute;
                        right: 0px;
                        width: 24px;
                        height: 24px;
                        top: 50%;
                        transform: translateY(-50%);
                        background-image: url('/content/images/icons/dl-8.svg');
                        background-size: 24px;
                        background-repeat: no-repeat;
                        transition: all 0.2s ease;
                        opacity: 0.6;
                    }

                    &:hover {
                        background: #f4f5f6;

                        &:before {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.tiles {
    display: flex;
    margin: 40px 0;

    @media screen and (max-width:768px) {
        display: block;
    }

    .tile {
        flex: 1 1 auto;
        padding: 20px;
        margin-right: 15px;
        margin-bottom: 15px;

        @media screen and (max-width:768px) {
            margin: 5px 0;
        }

        &:nth-of-type(1n) {
            background: #f6f9fc;
        }

        &:nth-of-type(2n) {
            background: $m3-darkblue;
            color: white;
        }

        &:nth-of-type(3n) {
            background: $m3-blue;
            color: white;
        }

        &:last-of-type {
            margin-right: 0;
        }

        img {
            margin: 0 auto;
        }

        h4 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 15px;
            line-height: 1.4;
        }

        p {
            font-size: 16px;
            font-weight: 300;
        }

        &__icon {

            img,
            svg {
                width: 100%;
                max-width: 200px;
                margin: 0 auto;
                padding: 15px;
            }
        }
    }
}

.about__content {
    .content_frame {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .img-logo {
        width: 100%;
        height: auto;
        max-width: 180px;
        margin: 0 auto;
        // box-shadow: 4px 4px 30px rgba(0,0,0,0.2);
        // padding: 10px;
        border-radius: 4px;
        transform: translateY(-16px);
        @media screen and (max-width:768px) {
            transfrom: translateY(0);
            margin: 0 0 15px 0;
        }
    }
}