.checkbox-container {
    .checkbox-wrapper {
        display: block;
    }
}

.checkbox-wrapper {
    @include clearfix;
    position: relative;
    margin-bottom: 15px;
    input[type="checkbox"] {
        position: absolute;
        left: 0;
        opacity: 0;
        cursor: pointer;
        width: 20px;
        height: 20px;
        z-index: 100;
        &:checked~label:after {
            display: block;
        }
    }
    label {
        width: calc(100%) !important;
        font-weight: 300 !important;
        font-size: 14px;
        margin-right: 0;
        margin-left: 0;
        padding-left: 30px;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 14px;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #eee;
            border: 1px solid #cdcdcd;
            border-radius: 2px;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }
        &:after {
            content: "";
            display: none;
            position: absolute;
            left: 7px;
            top: 4px;
            width: 5px;
            height: 10px;
            border: solid black;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}
.dropdown-wrapper {
    position: relative;
    margin-bottom: 15px;
    border: 1px solid #d1d1d1;
    overflow: hidden;
    select {
        border: 0;
        color: #172b46;
        padding: 12px 12px 10px;
        font-weight: 300;
        outline: 0;
        width: calc(100% + 40px);
        appearance: none;
        background-color: #fff;
        background-image: url(/content/images/icons/ico-caret-down-thin.svg);
        background-repeat: no-repeat;
        background-size: 14px;
        background-position: right 50px center;
    }
}
.privacy {
    &-notice {
        @include clearfix;
        margin-top: 15px;
        padding: 0;
        margin-bottom: 15px;
        padding: 40px;
        background: rgba(0, 0, 0, 0.03);
        .checkbox-wrapper {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &-note {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 15px;
    }
}

.form__message {
    padding: 20px;
    color: white;
    position: fixed;
    width: 100%;
    max-width: 1600px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 10000;
    animation: fadeInAndOut 8s forwards;
    font-size: 16px;
    font-weight: 400;
    &--success {
        background: $green;
    }
    &--warning {
        background: $warning;
    }
    &--error {
        background: $error;
    }
    .material-icons {
        margin-right: 6px;
        transform: translateY(4px);
    }
}

@keyframes fadeInAndOut {
    0% {
        opacity: 0;
    }
    8% {
        opacity: 1;
    }
    94% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}



.form__table {
    margin-top: 30px;
    .table {
        td,
        th {
            padding: 15px 5px 15px 0;
            line-height: 1.5;
        }
        .centre {
            font-size: 12px;
            width: 60px;
            text-align: center;
            input {
                float: none;
            }
        }
    }
    &.table-responsive {
        @media screen and (max-width:768px){
            border: 0;
        }
    }   
    .contact__heading {
        margin-top: 0;
    }
    input[type="text"],
    input[type="email"] {
        background: $inputbackground;
        border: 1px solid $inputborder;
        padding: 4px 6px;
        font-weight:400;
        &:focus,
        &:active {
            background: white;
        }
    }
    input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #dedede;
    }
    input::-moz-placeholder {
        /* Firefox 19+ */
        color: #dedede;
    }
    input:-ms-input-placeholder {
        /* IE 10+ */
        color: #dedede;
    }
    input :-moz-placeholder {
        /* Firefox 18- */
        color: #dedede;
    }
    counter-reset: session;
    .trainingsessionnumber::before {
        counter-increment: session;
        content: "S#" counter(session) " ";
        padding: 4px;
        background: #eaeaea;
        margin-right: 4px;
        text-align: center;
        font-size: 11px;
        font-weight: 500;
    }
}
.link--add{
    display: inline-block;
    padding: 18px 50px;
    &:link, &:hover, &:active, &:visited {
        text-decoration: none;
        color: #3C4858;
        font-weight: 500;
        transition: all 0.2s ease;
    }
    &:hover {
        color: black;
    }
    i {
        vertical-align: text-bottom;
        transform: translateY(2px);
    }
}

.checkout-table {
    margin-bottom: 60px;
    margin-top: 30px;
    table-layout: fixed;
    width: 100%;
    border: 1px solid #ccc;
    @media screen and (max-width:768px) {
        table-layout: auto;
    }
    th {
        background: #f4f5f6;
    }
    td, th {
        padding: 20px !important;
        &:first-of-type {
            width: 50%;
            @media screen and (max-width:992px) {
                width: 40%;
            }
        }
        &:last-of-type {
            text-align:right;
        }
    }
    tr {
        border-bottom: 1px solid #dedede;
    }
    &__logo {
        max-width: 40px;
        width: 100%;
        height: auto;
        margin-right: 10px;
    }
}
.form__terms {
    padding: 30px;
    background: #f4f5f6;
    label {
        &:before {
            background-color: #fff;
        }
    }
    .checkbox-wrapper {
        margin-bottom: 0px;
    }
    .form__field {
        padding-bottom: 10px;
        a {
            @include unlink();
            font-weight: 500;
        }
    }
}