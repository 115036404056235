.newsletter {
    &__heading {
        @include heading-2;
        margin-bottom: 15px;
    }
    &__input {
        @include input-lg;
        height: 55px;
    }
    &__button {
        margin: 0 !important;
        padding: 10px !important;
        height: 55px;
        width: 100%;
   
    }
    .checkbox-wrapper {
        margin-bottom: 0;
        @media screen and (max-width: 768px) {
            margin-bottom: 15px;
        }
    }
}