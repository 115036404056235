.office-notice {
    h2 {
        font-size: 30px;
        @media screen and (max-width: 768px) {
            font-size: 26px;
        }
    }
    p {
        color: black;
        font-size: 17px !important;
        line-height: 1.4 !important;
        a {
            display: inline-block !important;
            &:hover {
                cursor: pointer;
            }
        }
    }

}
noscript {
    background: #E6E6E6;
    color: black;
    display: block;
    font-size: 20px;
    font-weight: 500;
    padding: 40px 30px;
    text-align: center;
    width: 100%;
 }

 .message-block {
     &--warning {
        background: #FFF7D1;
        border-radius: 2px;
        border: 1px solid #e4c74a;
        padding: 30px;
        color: #3c2c00;
        font-size: 21px !important;
         @media screen and (max-width: 768px) {
             padding: 15px;
         }
     }
 }
 .warning-block {
     font-size: 22px !important;
     line-height: 1.6 !important;
     font-style: italic;
     font-weight: 400;
 }