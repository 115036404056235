@mixin box-shadow-lg() {
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.12);
}

@mixin box-shadow-1() {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
@mixin box-shadow-2() {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

@mixin box-shadow-3() {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
@mixin box-shadow-4() {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

@mixin checkoutform {
    h4 {
        color: $m3-darkblue;
        margin-bottom: 40px;
        font-size: 22px;
        font-weight: bold;
    }
    label {
        font-weight: 400;
        color: $m3-darkblue;
        font-size: 16px;
    }
    input:not([type=submit]), textarea {
        background-color: #fff;
        color: $m3-darkblue;
        display: inline-block;
        font-weight: 300;
        line-height: 1.5;
        padding: 12px 12px 10px;
        width: 100%;
        height: auto;
        border: 1px solid #d1d1d1
    }
    textarea {
        min-height: 120px;
    }

    .form__field {
        @media screen and (max-width: 768px) {
            margin-bottom: 20px;
        }
        &--flex {
            display: flex;
            justify-content: space-between;
            @media screen and (max-width:768px) {
                display: block;
            }
            input {
                flex: 0 1 calc(50% - 10px);
                @media screen and (max-width:768px) {
                  margin-bottom: 5px;
                }
            }
        }
    }
}
@mixin removetitlerow {
	.row.page-title {
		display: none;
	}
	#screenshotrulecontainer {
		& + .btn-large.hidden-xs {
			display: none;
		}
	}
}

@mixin adjusted-border($bg:$green,$height:1px) {
	position:relative;
	&:before{
		content: "";
		position: absolute;
		display:block;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		height: $height;
		background: $bg;
		margin-left: -15px;
		margin-right: -15px;
	}
}
@mixin category($text, $subtitlebg:$green) {
	background: $grey;
	position: relative;
	&__title {
		padding: 35px 40px 30px 30px;
		margin:0 !important;
		display: inline-block;
		font-weight: 600;
		@media screen and (max-width:768px) {
			padding: 30px 15px;
			font-size: 18px;
		}
		@media screen and (max-width:550px) {
			font-size: 16px;
		}
	}
	&__subtitle {
		text-transform: uppercase;
		color: white;
		font-weight: 600;
		background: $subtitlebg;
		display: inline-block;
		position: absolute;
		padding-top: 38px;
		padding-left: 40px;
		padding-right: 40px;
		top: 0;
		bottom: 0;
		right: 0;
		@media screen and (max-width: 768px) {
			padding-top: 30px;
			padding-left: 25px;
			padding-right: 25px;
			font-size: 14px;
		}	
	}
}
@mixin clearfix {
	&:before, &:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}
@mixin submitbutton {
    appearance: none;
	background: $m3-blue;
	border: 0;
	padding: 10px 30px;
	color: #fff;
	min-width: 140px;
	text-decoration: none;
	transition: background .2s ease;
	max-width: 320px;
	display: inline-block;
	@media screen and (max-width: 768px) {
		width: 100%;
		max-width: 100%;
	}
	&:hover { 
        background: lighten($m3-blue, 5%);
        color: #fff;
	}
}
@mixin generalbuttonstyle {
    appearance: none;
	border:0;
    border-radius: 2px;
	display: inline-block;
	font-weight: 500;
	padding: 8px 18px;
    text-align: center;
	text-decoration: none;
	transition: all 0.2s ease;
    @include unlink;
    &:hover {
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
}
@mixin defaultbutton {
    @include generalbuttonstyle;
    background: $m3-blue;
    color: white;
    &:hover {
        background: lighten($m3-blue, 5%) !important;
        color: white !important;
    }
}
@mixin altbutton{
    @include generalbuttonstyle;
	background: #fff;
	color: #333;
}
@mixin linkbutton {
    appearance: none;
    border: 1px solid #dedede;
    color: black;
    border-radius: 30px;
    padding: 8px 16px;
    background: white;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    transition: all 0.15s ease;
    &:hover { 
        background: #eaeaea;
    }
}
@mixin addbutton {
    appearance: none;
    color: #44bd32;
    background: none;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 0px;
    transition: all 0.15 ease;
    border-bottom: 2px solid transparent;
    &:hover {
        color: #4cd137;
    }
}
@mixin infobutton {
    @include generalbuttonstyle;
	background: $m3-blue;
	padding: 15px;
	color: #fff;
	@media screen and (max-width: 992px) {
		margin-top: 15px;
	}
	@media screen and (max-width: 768px) {
		width: 100%;
	}
	&:hover, &:visited, &:active {
		color: #fff;
    }
    &:hover {
        background: lighten($m3-blue, 5%);
    }
}


/* 2018 */
@mixin unlink ($color:inherit,$hovercolor:inherit){
	color: $color;
	text-decoration: none;
    transition: all 0.1s ease-in-out;
    outline: 0 !important;
	&:active, &:visited, &:focus {
		text-decoration: none;
		color: $color;
    }
    &:hover {
        text-decoration: none;
        color: $hovercolor;
    }
}

@mixin unlinkunderline ($color:inherit, $hovercolor:inherit, $underline:#0397D7) {
    color: $color;
	text-decoration: none;
    transition: all 0.1s ease-in-out;
    outline: 0 !important;
    font-weight: bold;
    border-bottom: 2px solid transparent;
    display: inline-block;
	&:active, &:visited, &:focus {
		text-decoration: none;
		color: $color;
    }
    &:hover {
        text-decoration: none;
        color: $hovercolor;
        border-bottom: 2px solid $underline;
    } 
}


@mixin unlink--inline {
    color: $m3-blue;
	text-decoration: none;
    transition: all 0.1s ease-in-out;
    outline: 0 !important;
    display: inline-block;
    font-weight: 300;
    border-bottom: 2px solid transparent;
    padding-bottom: 3px;
    line-height: 1;
	&:active, &:visited, &:focus {
        color: $m3-blue;
		text-decoration: none;
    }
    &:hover {
        text-decoration: none; 
        color: $m3-blue;
        border-bottom: 2px solid $m3-blue;
    }
}
@mixin unlist ($margin:0, $padding:0) {
	list-style: none;
	margin: $margin;
	padding: $padding;
}
@mixin clearfix {
	&:before, &:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}
@mixin is-hidden {
	height: 0;
	opacity: 0;
	visibility: hidden;
}
@mixin is-visible {
	height: auto;
	opacity: 1;
	visibility: visible;
}

@mixin darken-background {
    position: relative;
    &:after {
        content: "";
        display: block;
        background: rgb(2,0,36);
        background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(23,43,70,0.42620798319327735) 0%, rgba(2,0,36,1) 100%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
    }
}
@mixin heading-4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.15;
    margin-top: 0;
}
@mixin heading-3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.15;
    margin-top: 0;
}
@mixin heading-2 {
    font-size: 28px;
    font-weight: 600;
    margin-top: 0;
    line-height: 1.15;
}
@mixin heading-1 {
    font-size: 40px;
    font-weight: 600;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
        font-size: 34px;
        line-height: 1.15;
    }
}

@mixin input-lg {
    background: white;
    line-height: 30px;
    font-size: 16px;
    border-radius: 0;
    outline: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    appearance: none;
    padding: 10px;
    width: 100%;
    margin-bottom: 15px;
    @media screen and (max-width: 768px) {
        padding: 5px;
        font-size: 16px;
        line-height: 15px;
    }
}
@mixin p-lg {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.6;
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
}


@mixin baseform ($background:$formbackground,$padding:50px){
    padding: $padding;
    background: $background;
    border-radius: 5px;
    border:1px solid $grey;
    @media screen and (max-width: 768px) {
        padding :30px 15px; 
    }   
    input,select,textarea {
        border: 1px solid $grey;
        background-color: #fff;
        color: black;
        display: inline-block;
        font-weight: 300;
        line-height: 1.5;
        padding: 12px 12px 10px;
        width: 100%;
        height: auto;
    }
    label {
        color: #3C4858;
        display: inline-block;
        font-weight: 500;
        font-size: 15px;
        padding: 10px 0;
        margin: 0;
        width: 100%;
        vertical-align: top;
    }
    .select-wrapper {
        background: url(/content/images/icons/arrow_down.svg);
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: 98%;
        background-size: 20px;
        border: 1px solid #dedede;
        background-color: #fff;
        overflow: hidden;
        width: 100%;
    }
    .checkbox-wrapper label:before {
        background: #fff;
    }
    .checkbox-wrapper label {
        padding: 0 0 0 30px; 
    }
    select {
        background: transparent;
        width: 100%;
        border: 0;
        margin: 0;
        appearance: none;
        padding: 12px 36px 10px 12px;
    }
    button, input[type="submit"] {
        @include infobutton;
        padding: 15px 20px;
        margin: 15px 0;
    }
    .error {
        color: $error;
    }
    abbr{
        border: 0;
        color: rgba(0, 0, 0, 0.4);
        line-height: 1.1;
        text-decoration: none;
        vertical-align: center;
        margin-left: 5px;
    }
    .privacy-notice {
        padding: 0 0 45px;
        background: transparent;

    }
    ol, ul {
        @include unlist;
        li {
            margin-bottom: 15px;
        }

    }
    legend {
        border-bottom: 1px solid #e5e5e5;
        margin: 15px 0;
        padding-bottom: 15px;
        padding-top: 15px;
    }
}

@mixin aside {
    padding-right: 25%;
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
        padding-right: 0;
    }
    h2 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;
    }
    ul {
        @include unlist;
    }
    li {
        border-top: 1px solid #e2e2e2;
        padding: 15px 0;
        &:first-child {
            border-top: none;
        }
        a {
            @include unlink;
            display: block;
            &:hover {
                font-weight: 400;
                color: black;
            }
        }
    }
}

.internal {
    &__content {
        @include p-lg;
        padding-right: 40px;
        @media screen and (max-width: 768px) {
            padding-right: 0;
        }
        h1,h2,h3,h4,ul,p {
            margin-bottom: 22px;
        }
        h1 {
            @include heading-1;
        }
        h2 {
            @include heading-3;
            margin-top: 44px;
            &:first-child {
                margin-top: 0;
            }
        }
        & > p:first-of-type {
            font-size: 24px;
            font-weight: 400;
            line-height: 1.4;
            @media screen and (max-width:768px) {
                font-size: 20px;
            } 
        }
        p {
            a:not(.default-btn) {
                @include unlink--inline;
            }
        }
        a:not(.default-btn) {
           @include unlink;
        }
        img { 
            display: block; 
            margin-top: 44px;
            margin-bottom: 22px;
            max-width: 100%;
            height: auto;
        } 
        &__list {
            @include unlist;
            margin-left: 30px;
            li {
                margin-bottom: 15px;
                padding-left: 30px;
                position: relative;
                &:before {
                    content: "";
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    background: $dark-blue;
                    z-index: 100;
                    border-radius: 50%;
                    position: absolute;
                    top: 10px;
                    left: 0;
                }
            }
            margin-bottom: 30px;
        }
    }
    &__aside {
        @include p-lg;
        @include aside;
    }
}

.profile {
    @include p-lg;
    &__img {
        padding-right: 25%;
        img {
            width: 100%;
            height: auto;
            max-width: 100%;
            border-radius: 50%;
            margin-bottom: 30px;
            @media screen and (max-width:992px) {
                max-width: 250px;
            }
        }
    }
    &__title {
        @include heading-1;
        margin-bottom: 5px !important;
    }  
}

@mixin banner-notice($noticebg:#f4f5f6,$noticetext:$body-text-color) {
    text-align: center;
    padding: 30px;
    background: $noticebg;
    color: $noticetext;
    p {
        margin-bottom: 0;
    }
    a {
        display: inline-block;
        font-weight: bold;
        @include unlink;
        border-bottom: 2px solid;
    }
}

@mixin table {
    padding: 0;
    margin: 50px 0;
    border: 0;
    width: 100%;
    max-width: 100%;
    th, td {
        border-collapse: collapse;
        border: 1px solid #dedede;
    }
    th {
        background: #f4f5f6;
        font-weight: bold;
        color: $m3-darkblue;
        font-size: 14px;
        padding: 20px 15px;
    }
    td {
        padding: 15px;
        font-weight: 300;
        font-size: 16px;
    }
}

@mixin alert {
    margin-bottom: 15px;
    font-size: 12px;
    background-position: center left 10px;
    background-repeat: no-repeat;
    background-size: 40px 40px;
    padding: 15px 15px 15px 50px;
    overflow: hidden;
    display: block;
    text-align: left;
}
@mixin alerts {
    &--error {
        @include alert;
        color: #de2e2e;
        border: 1px solid #de2e2e;
        background-color: #fff;
        background-image: url(/content/images/admin/remove.svg);
    }
    &--warning {
        @include alert;
        color: #daa520; 
        border: 1px solid #daa520;
        background-color: #fff;
        background-image: url(/content/images/admin/warning.svg);
    }
    &--success {
        @include alert;
        color: #8bc34a;
        border: 1px solid #8bc34a;
        background-color: #fff;
        background-image: url(/content/images/admin/check.svg);
    }
    &--lg {
        font-size: 1.2rem;
        padding-left:60px;
        background-size:50px;
        a {
            color: inherit !important;
            font-weight: bold;
            border-bottom: 1.5px solid transparent;
            text-decoration: none;
            &:hover, &:active, &:visited, &:link {
                text-decoration: none;
            }
            &:hover {
                border-color: inherit;
            }
        }
    }
}
.inline-message {
    @include alerts; 
}

