/*************************************************/
/* LITE */
/*************************************************/
.lite .row.page-title {
  display: none;
}
.lite #screenshotrulecontainer + .btn-large.hidden-xs {
  display: none;
}
.lite #mainContent {
  padding-bottom: 0;
  margin-bottom: -25px;
}
@media screen and (max-width: 768px) {
  .lite #mainContent {
    margin-bottom: 0;
  }
}
.lite .section-header {
  max-height: 740px;
}
.lite .section-header__image {
  width: 880px;
  -webkit-box-shadow: -1px -1px 120px rgba(0, 0, 0, 0.2);
          box-shadow: -1px -1px 120px rgba(0, 0, 0, 0.2);
  padding: 0;
}
.lite .section-main-features img {
  -webkit-box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.01), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.01), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.lite .section-compare-features {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}

.demo-lite .row.page-title {
  display: none;
}
.demo-lite #screenshotrulecontainer + .btn-large.hidden-xs {
  display: none;
}
.demo-lite #mainContent {
  padding-bottom: 0;
  margin-bottom: -25px;
}
@media screen and (max-width: 768px) {
  .demo-lite #mainContent {
    margin-bottom: 0;
  }
}