.scripts {
    &__title{
        font-weight: bold;
    }
    &__description {
        font-size: 18px;
        margin-bottom: 22px;
        max-width: 100ch;
    }
    &__category {
        margin-bottom: 70px;
    }
    &__table {
        small {
            font-weight: 300;
            font-size: 14px;
        }
    }
}