.training {
    &__aside {
        margin-bottom: 30px;
        a {
            @include unlink;
            font-weight: 400;
            display: block;
            margin-bottom: 10px;
        }
    }
    &__accordion {
        &__title {
            @include heading-3;
            border-top: 1px solid #dedede;
            margin-bottom: 30px;
            margin-top: 50px;
            padding-top: 50px;
            &:first-child {
                margin-top: 0;
                padding-top: 0;
                border-top: 0;
            }
        }
        h5 {
            a {
                @include unlink;
                font-size: 16px;
                font-weight: 400;
                display: block;
                position: relative;
                padding-right: 30px;
                padding-top: 10px;
                padding-bottom: 10px;
                &:hover {
                    background:$lighter-grey;
                }
                &:after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    width: 10px;
                    height: 20px;
                    top: 0;
                    right: 15px;
                    background-image:url('/content/images/icons/arrow_down.svg');
                    background-repeat: no-repeat;
                    background-position: center 5px;
                }
            }
        }
        .rulecontent {
            h5 {
                margin: 0;
            }
            li {
                margin-bottom: 15px;
            }
            .faqanswer {
                padding: 30px;
                background: #f9f9f9;
                border-radius: 5px;
                margin-bottom: 25px;
                p {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

    } 
    &__description {
        margin-bottom: 30px;
    }
} 

.info.panel-body {
    background: #fff;
    padding: 30px;
    .training__button {
        @include infobutton;
        margin: 30px auto 0;
        padding: 10px;
        display: inline-block;
        min-width: 300px;
        max-width: 100%;
    }
}

.training {
    .section-header {
        background-image : url('/content/images/training/training-01.png');
        background-size: cover;
        background-position: top left;
    }
    .pageheader {
        background-image : url('/content/images/training/training-01.png');
        background-size: cover;
        background-position: top left;
        &__content {
            max-width: 70%;
            margin-top: 120px;
            margin-bottom: 120px;
            @media screen and (max-width: 786px) {
                max-width: 100%;
                margin-top: 40px;
                margin-bottom: 40px;
            }
            h1, p {
                color: white;
                margin-bottom: 15px;
            }
            h1 {
                font-size: 38px;
            }
            p {
                font-size: 24px;
                font-weight: 300;
            }
        }
    }
    &__aside {
        padding: 30px;
        background: #f4f5f6;
        .btn {
            @include infobutton;
            width: 100%;
            &[disabled] {
                background: #ddd!important;
                color: rgba(0,0,0,.4)!important;
            }
        }
        .btn--invert {
            @include altbutton;
            width: 100%;
            padding: 15px;
        }
        &__maplink {
            display: inline-block;
            margin: 8px 0;
            font-size: 14px;
        }
    }
    &__cpd {
        display:flex;
        align-items:center;
        h4 {
            display: inline-block;
            margin-right: 40px;
        }
        img.training__cpd__img{
            max-height: 190px;
            display: inline-block;
            margin-top: 15px;
        }
        iframe {
            transform: translateY(15px);
        }

    }
    &__title {
        margin-bottom: 30px;
        + p {
            font-size: 18px;
            margin-bottom: 30px;
        }
    }
    &__item {
        text-align: center;
        @include unlink;
        font-weight: 600;
        display: block;
        padding: 15px;
        border-radius: 10px;
        img {
            width: 100%;
            max-width: 100%;
            max-height: 240px;
            margin-bottom: 15px;
        }
        &:hover {
            background: #f4f5f6;
        }
    }
}

.pageheader {
    background-repeat: no-repeat;
    background-size: 1120px;
    background-position: right -60px top -280px;
    background-color: #0397D7;
    &__panel {
        // background-color: #0397D7;
        width: 50%;
        min-height: 350px;
    }
    &__title {
        color: white;
        float: right;
        width: 100%;
        max-width: 585px;
        padding: 15px;
        height: 100%;
        display: flex;
        align-items: center;
        min-height: 350px;
        h1 {
            margin-bottom: 0;
            font-size: 60px;
        }
    }
    &.training__header {
        background: url('/content/images/training/training-01.png');
        p {
            margin-top: -15px;
            max-width: 900px;
            margin-bottom: 35px;
            font-size: 22px;
            font-weight: 300;
            line-height: 1.4;
        }
    }
}

.form {
    background: #f4f5f6;
    padding: 30px;
    &.flex {
        .form__group  {
            display: flex;  
            align-items: center;   
            position: relative;
            > *  {
                flex: 1;
            }
        }
    }
    &__group {
        label {
            font-weight: 400;
            font-size: 15px;
            small {
                display: block;
                font-weight: 300;
                padding-right: 10px;
            }
        }
        .g-recaptcha{
            margin-bottom: 15px;
        }
    }
    &__field {
        padding: 0 0 15px 0;
        &__discount {
            display: flex;
            .btn--link {
                border-radius: 4px !important;
                margin: 4px !important;
            }
            & + span {
                font-size: smaller;
            }
        }

        input:not([type='submit']), textarea {
            font-size: 14px;
            background-color: #fff;
            border: 1px solid #dedede;
            color: #000;
            font-weight: 300;
            line-height: 1.5;
            padding: 10px;
            width: 100%;
        }
        textarea {
            resize: none;
        }
        // input[type='submit'] {
        //     @include submitbutton;
        //     border: 5px solid red;
        //     appearance: none;
        // }
    }
    &__error {
        background: $error;
        border-radius: 2px;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
        margin-top: 2px;
        padding: 4px;
        &--block {
            display: block;
            width: 100%;
            margin-bottom: 8px;
            padding: 10px;
        }
    }
    &__radio {
        @include clearfix;
        margin-bottom: 15px;
        .radio-inline + .radio-inline {
            margin-left: 20px;
        }
    }
    &__checkbox {
        display: flex;
        margin-top: 10px;
        margin-bottom: 15px;
        position: relative;
        input[type="checkbox"] {
            cursor: pointer;
            height: 20px;
            left: 0;
            opacity: 0;
            position: absolute;
            width: 20px;
            z-index: 100;
            &:checked~label:after {
                display: block;
            }
        }
        label {
            font-size: 14px;
            font-weight: 300!important;
            margin-left: 0;
            margin-right: 0;
            padding-bottom: 0;
            padding-left: 30px;
            padding-top: 0;
            width: 100%!important;
            &:before {
                content: "";
                border: 1px solid #cdcdcd;
                border-radius: 2px;
                height: 20px;
                left: 0;
                position: absolute;
                top: 0;
                transition: all .2s ease;
                width: 20px;
                background: #fff;
            }
            &:after{
                content: "";
                border: solid #000;
                border-width: 0 3px 3px 0;
                display: none;
                height: 10px;
                left: 7px;
                position: absolute;
                top: 4px;
                transform: rotate(45deg);
                width: 5px;
            }
        }

    }
    &__select {
        background: url('/content/images/icons/arrow_down.svg');
        background-color: #fff;
        background-position: 98%;
        background-repeat: no-repeat;
        background-size: 20px;
        border: 1px solid #dedede;
        overflow: hidden;
        margin-bottom: 15px;
        select {
            appearance: none;
            background: transparent;
            border: 0;
            margin: 0;
            padding: 10px 36px 10px 10px;
            width: 100%;
            font-size: 14px;
            &::-ms-expand {
                display: none;
            }
        }
    }
    &__confirmation {
        h4 {
            margin-bottom: 30px;
        }
        .form__field{
            padding: 0;
        }
    }
    &__information {
        margin-top: 40px;
    }
    a.form__btn {
        @include unlink;
    }
    .form__btn {
        &--submit {
            @include infobutton;
            width: 100%;
            max-width: 100%;
            font-weight: 500;
            padding: 15px;
            &:disabled {
                background: #ddd !important;;
                color: rgba(0,0,0,0.2) !important;
                &:hover {
                    box-shadow: none;
                }
            }
        }
    }
    .btn--edit, .btn--remove {
        @include unlink;
        border: 0;
        border-radius: 3px;
        padding: 4px;
        font-weight: 400;
        font-size: 13px;
        padding: 4px 12px;
        &:hover {
           background: #eaeaea;
        }
    }
    .btn--add {
        @include addbutton;
    }
    .btn--cancel {
        @include altbutton;
    }
    .btn--update {
        @include defaultbutton;
    }
    .btn--link {
        @include linkbutton;
    }
}

.attendee {
    &__list {
        @include unlist;
        margin: 0;
    }
    &__item {
        display: flex;
        align-items:center;
        margin-bottom: 5px;
        padding: 4px 8px;
        background: #fff;
        font-size: 14px;
        p {
            flex: 1;
            margin: 0;
        }
    }
    &__form__actions {
        display: flex;
        justify-content: flex-end;
        button {
            margin-left: 5px;
        }
    } 
    &__edit {
        background: #ededed;
        padding: 30px 30px 30px 50px;
        border-radius: 5px;
    } 
}




.cart {
    margin-bottom: 30px;
    margin-top: 30px;
    font-weight: 400;
    dl {
        background: white;
        display: flex;
        margin: 0;
    }
    dt, dd {
        display: inline-block;
        border: 1px solid #f4f5f6;
        margin: 0;
        padding: 8px;
        font-size: 14px;
    }
    dt {
        flex: 1;
        font-weight: 500;
    }
    dd {
        flex: 2;

    }
    &__total {
        font-weight: bold;
    }
    &__quantity {
        font-weight: 500;
        font-size: 12px;
        color: #bfc4c9;
    }
}

#purchasebooks,
#purchasebooksqty {
    display: none;
    &.displayflex {
        display: flex;
    }
    label {
        padding-right: 10px;
    }
}
/* JS RELATED CSS. DO NOT MODIFY */
.js-attendee-template {
    display: none;
}

.course {
    margin-bottom: 80px;
    position: relative;
    h2 {
        margin-bottom: 15px;
    }
    > img {
        float: left;
        width: 120px;
        height: 120px;
        margin: 4px 25px 25px 0;
        background:#f4f5f6;
    }
    &__desc {
        overflow: hidden;
        &.remote {
            &:before {
                content: "Remote Training";
                display: block;
                position: absolute;
                top: 10px;
                right: 20px;
                padding: 8px;
                border: 1px solid;
                font-weight: bold;
                font-size: 12px;
                @media screen and (max-width:768px) {
                    position: relative;
                    margin-bottom: 15px;
                    right: 0;
                    left: 0;
                }
            }
        }
    }
    &__table {
        width: 100%;
        text-align: left;
        th, td {
            padding: 8px 12px;
            border: 1px solid #eaeaea;
        }
        td {
            background: #fff;
        }
        th {
            background: #f4f5f6;
        }
        small {
            display: block;
        }
    }
}
.payment-option {
    border: 2px solid $m3-darkblue;
    padding: 20px;
    margin-bottom: 15px;
    & + .form__field{
        margin-top: 30px;
    }
    .checkbox-container {
        margin-bottom: 5px;
    }
    input[type=radio] {
        border: 1px solid #efefef;
        display: inline-block;
        margin-right: 10px;
        margin-top: 3px;
        padding: 3px;
        vertical-align: top;
        width: 16px;
        height: 16px;
        float: none;
        padding: 6px 12px;
        border: 1px solid #efefef;
    }
}

.training__grid {
    margin-bottom: 0;
    .col {
        border: 1px solid #f4f5f6;
        a {
            display: block;
            min-height: 232px;
        }
    }
}

.training {
    .course {
        padding-right: 30px;
    }
    .events {
        justify-content: flex-start;
    }
    .btn--enquire {
        appearance: none;
        border: 0;
        border-radius: 2px;
        display: inline-block;
        font-weight: 500;
        text-align: center;
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
        color: inherit;
        text-decoration: none;
        transition: all .1s ease-in-out;
        outline: 0!important;
        background: #005996;
        padding: 15px;
        color: #fff;
        width: 100%;
        &:hover {
            box-shadow: 0 4px 8px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
        }
    }
}
.course {
    &__content {
        display: flex;
        margin-bottom: 50px;
        margin-top: 50px;
        &__img {
            flex: 0 0 80px;
            img {
                max-width: 100%;
            }
            margin-right: 20px;
        }
        &__description {
            padding-top: 10px;
            h2 {
                font-size: 22px;
            }
            p {
                font-size: 16px;
            }
            ol {
                margin-left: 0px;
                padding-left: 20px;
                > li {
                    margin-bottom: 10px;
                    > ul {
                        padding-left: 24px;
                    }
                }  
            }
            ol, ul {
                margin-bottom: 30px;
            }
        }
    }
}
.training {
    &__dates {
        padding-top:30px;
        margin-top:30px;
        border-top: 1px solid rgba(0,0,0,0.05);
        h4 {
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 20px;
        }
        a {
            display: block;
            margin-bottom: 5px;
            @include unlink;
            font-size: 14px;
            i {
                float: right;
            }
        }
    }
}