.iframe {
    &__container {
        max-width: 100%;
        margin-bottom: 60px;
        @media screen and (max-width:500px) {
            margin-bottom: 80px;
        }
    }
    &__title {
        margin-bottom: 20px;
    }
    &__content {
        width: 900px;
        height: 580px;
        max-width: 100%;
        box-shadow: 2px 2px 30px rgba(0,0,0,0.2);
        @media screen and (max-width:500px) {
            width: 480px;
            max-width: none;
            transform: translateX(-40px);
        }
    }
}