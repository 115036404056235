.section--teams {
   background:#fff;
}
.teams {
    &__heading {
        @include heading-1;
        margin-bottom: 40px !important;
        text-align: center;
        @media screen and (max-width: 768px) {
            margin-bottom: 25px;
            text-align: left;
        }
        a {
            @include unlink;
        } 
    }
    &__link {
        @include unlink($dark-blue, lighten($dark-blue,25%));
        font-weight: bold;
        text-align: center;
        display: block;
        margin-top: 40px;
    }
    .team {
        background: $formbackground;
        margin-bottom: 30px;
        @media screen and (max-width: 768px) {
            margin-bottom: 15px;
        }
        &__link {
            @include unlink;
            &:hover {
                .team__image {
                    filter: grayscale(0);
                }
                .team__details {
                    background: $dark-blue;
                    color: white;
                }
            }
        }
        &__image-container {
            @include darken-background;
            &:after {
                background: rgba(0,0,0,0.01);
            }
        }
        &__image {
            height: auto;
            max-width: 100%;  
            width: 100%;
            filter: grayscale(0.2);
            transition: all 0.2s ease;
        }
        &__details {
            padding: 30px;
            transition: all 0.2s ease;
        }
        &__date {
            font-size: 14px;
            font-weight: 500;
        }
        &__title {
            @include heading-4;
            margin-bottom: 5px;
        }
        &__description {
            margin-bottom: 0;
        }
    }
} 
