.homepage {
    .news__heading, .events__heading, .clients__heading {
        @include heading-2;
    }
    .clients__heading {
        margin-bottom: 30px;
    }
    .important-notice {
        @include banner-notice($yellow-notice-bar);
    }
    .cta {
        &__link {
            @include clearfix;
            @include unlink;
            display: block;
            padding: 30px;
            background: #fb0;
            color: black;
            // background: #172b46;
            // background-color: #bf843a;
            // background-color: #cf3288;
            background: #5a2165;
            font-weight: 400;
            color: white;
            font-size: 24px;
            text-align: center;
            line-height: 1.4;
            transition: all 0.14s ease;
            border-top: 1px solid transparent;
            @media screen and (max-width: 800px) {
                text-align: left;
            }
            span {
                color: white;
                padding-right: 130px;
                position: relative;
                @media screen and (max-width: 800px) {
                    display: block;
                    padding-right: 100px;
                }
                &:after {
                    content: "";
                    display: inline-block;
                    background-image: url('/content/images/icons/arrow-right.svg');
                    background-repeat: no-repeat;
                    background-size: auto;
                    background-position: center;
                    width: 80px;
                    height: 80px;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    transition: all 0.2s ease;
     
                }
            }
            &:hover {
                border-top: 1px solid rgba(255,255,255,0.2);
                background-color: lighten(#5a2165,5%);
                span {
                    &:after {
                        right: -15px;
                    }
                }
            }
            .container { 
    
            }
        }
    }
} 
