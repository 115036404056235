.hatc {
    .section-header {
        max-height: 940px;
        background-position: top left;
        background-size: cover;
        position: relative;
        &:before {
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: #020024;
            background: -webkit-gradient(linear,left bottom,left top,from(rgba(2,0,36,.8295693277)),color-stop(70%,transparent));
            background: linear-gradient(0deg,rgba(2,0,36,.8295693277),transparent 70%);
        }
        .section-header__title {
            margin-top: 40px;
        }
    }
    .teams {
        justify-content: center;
        .team {
            max-width: 300px
        }
    }
}

.featured__trainers{
    .flex-row {
        display: flex;
        justify-content: center;
    }
    .flex-item {
        width: 33.33%;
        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
    .section__title {
        text-align: center;
        margin-bottom: 40px;
    }
}
.featured__trainer {
    text-align: center;
    &__profileimg {
        min-height: 240px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        width: 340px;
        height: 340px;
        margin: 0 auto;
        margin-bottom: 40px;
        border: 15px solid #efefef;
    }
}

