/** Tendersafe **/
.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

.tendersafe-login,
.tendersafe-home,
.tendersafe-register,
.tendersafe-register-complete {
	border-top: 1px solid #ededed;
	margin-left: -15px;
	margin-right: -15px;
}

.tendersafe-login__button,
.tendersafe-register-activated__button,
.tendersafe-home__form__button {
	background: #577bb2;
	padding: 10px 30px;
	color: #fff;
	min-width: 140px;
	text-decoration: none;
	transition: background 0.2s ease;
	max-width: 320px;
	display: block;
}
.tendersafe-login__button:hover,
.tendersafe-login__button:focus,
.tendersafe-login__button:visited,
.tendersafe-register-activated__button:hover,
.tendersafe-register-activated__button:focus,
.tendersafe-register-activated__button:visited,
.tendersafe-home__form__button:hover,
.tendersafe-home__form__button:focus,
.tendersafe-home__form__button:visited{
	background: #1a63a1;
	color: white;
	text-decoration: none;
}

.tendersafe-login__login,
.tendersafe-login__register,
.tendersafe-register-activated,
.tendersafe-register-complete {
	padding: 60px 40px;
}
.tendersafe-login__login {
	background: #ededed;
}
.tendersafe-login__heading,
.tendersafe-register-activated__heading,
.tendersafe-register-complete__heading,
.tendersafe-home__heading {
	font-size: 21px;
	font-weight: bold;
	text-transform: uppercase;
	margin-top: 0;
}
.tendersafe-login__text,
.tendersafe-register-activated__text,
.tendersafe-register-complete__text,
.tendersafe-home__text{
	margin-bottom: 20px;
}
.tendersafe-login__form legend{
	display: none;
}
.tendersafe-login__form .error,
.tendersafe-register__form .error  {
	border: 1px solid #BF5A58;
	color: #BF5A58;
	padding: 10px;
	margin-bottom:20px;
}

.tendersafe-register__form .error {
	display: table
;	width: 100%;
	max-width: 545px;
	margin-bottom: 10px;
}

.tendersafe-login__form .form-control
.tendersafe-register__form .form-control {
	border-radius: 0;
}
.tendersafe-login__form .form-control:focus,
.tendersafe-register__form .form-control:focus,
.tendersafe-home__form input:focus {
	box-shadow: none;
	-webkit-box-shadow: none;
	border: 1px solid rgba(0,0,0,0.6);
}
.tendersafe-login__form input,
.tendersafe-register__form input,
.tendersafe-home__form input {
   background: white;
}
.tendersafe-login__form input:-webkit-autofill,
.tendersafe-register__form input:-webkit-autofill,
.tendersafe-home__form input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0px 9999px white;
}
/******************************/
/** TENDERSAFE REGISTER PAGE **/
/******************************/
.tendersafe-register .form-group:before,
.tendersafe-register .form-group:after {
	content: "";
	display: table;
}
.tendersafe-register .form-group:after {
	clear: both;
}

.tendersafe-register__fieldset {
	padding: 60px 40px;
}
.tendersafe-register__fieldset:nth-child(odd) {
	background: #fafafa;
}
.tendersafe-register .form-control {
	border-radius: 0;
}
.tendersafe-register__fieldset legend {
	font-size: 19px;
	font-weight: bold;
	text-transform: uppercase;
	border-bottom: 0;
	float:left;
	margin-bottom: 30px;
}

.tendersafe-register__fieldset label {
	float: left;
	margin-right: 30px;
	width: 14em;
	max-width: 100%;
}
.tendersafe-register__fieldset input[type="text"],
.tendersafe-register__fieldset input[type="password"],
.tendersafe-register__fieldset input[type="checkbox"] {
	max-width:320px;
	float: left;
}

.tendersafe-register__fieldset--password p{
	font-style: italic;
}
.tendersafe-register__fieldset--password .glyphicon-asterisk,
.tendersafe-home__error .glyphicon-asterisk {
	margin-right: 8px;
	color: #BF5A58 ;
	font-size: 8px;
	top: -2px;
}
.tendersafe-register__fieldset--terms label{
	width: 320px;
	max-width: 100%;
}
.tendersafe-register__fieldset--terms input[type="checkbox"] {
	max-width: 22px;
}

.tendersafe-register__action {
	padding: 60px 40px;
	background: #f4f5f6;
	text-align: center;
}
.tendersafe-register__action__cancel,
.tendersafe-register__action__submit {
	padding: 15px 50px;
	border:1px solid #bbb;
	transition: background 0.2s ease;
}
.tendersafe-register__action__cancel {
	background: #fff;
}
.tendersafe-register__action__submit {
	background: #577bb2;
	color: white;
}
.tendersafe-register__action__submit:hover,
.tendersafe-register__action__submit:active,
.tendersafe-register__action__submit:focus {
	background: #1a63a1 ;
	color: white;
}

/*********************************/
/* TENDERSAFE REGISTER COMPLETE */
/********************************/
.tendersafe-register-complete > *,
.tendersafe-register-activated > * {
	max-width: 700px;
}
.tendersafe-register-complete__heading,
.tendersafe-register-activated__heading,
.tendersafe-home__heading  {
	margin-bottom: 30px;
}

.tendersafe-home__content {
	max-width: 760px;
	padding:60px 40px;
}
.tendersafe-home__form{
	padding-top: 10px;
}
.tendersafe-home__form__label {
	font-weight: bold;
}

.tendersafe-home__form__label {
	padding-top: 8px;
}
.tendersafe-home__form__input {
	padding: 7px 20px;
	outline: 0;
}
.tendersafe-home__form__button {
	display: inline;
	padding: 8px 20px;
	min-width: auto;
	transform: translateY(-1px);
}
.tendersafe-home__documents__table{
	background: #f4f5f6;
    padding: 60px 40px;
    max-width: 100%
}
.tendersafe-home__documents__table table {
	width: 100%;
}
.tendersafe-home__documents__table th {
	padding-bottom: 10px;
}
.tendersafe-home__documents__table .demoRow {
	border-bottom: 1px solid rgba(0,0,0,0.08);
	border-bottom: 1px solid #eaeaea;
}
.tendersafe-home__documents__table .demoRow td {
	padding: 15px 40px 15px 0;
}
.tendersafe-home__documents__table .demoRow .statusCell {
	padding: 15px 0px 15px 0;
	text-align: right;
	width: 140px;
	text-align: right;
}
.tendersafe-home__documents__table .link {
    background: #fff;
    color: #4B72AD;
    position: relative;
    border: 1px solid #4B72AD;
    padding: 3px 5px 2px;
    border-radius: 2px;
    transition: all .15s;
    cursor: pointer;
    bottom: 0;
    width: auto;
    height: auto;
}
.tendersafe-home__documents__table .link:hover {
	background: #4b72ad;
	color: white;
}
.tendersafe-home__error {
	color: #333;
	margin-top: 30px;
}
@media screen and (max-width:628px) {
	.tendersafe-login__login,
	.tendersafe-login__register,
	.tendersafe-register-activated,
	.tendersafe-register-complete,
	.tendersafe-home {
		padding: 40px 20px;
	}
	.tendersafe-register__fieldset legend {
		margin-bottom: 20px;
	}
	.tendersafe-register__fieldset--terms label {
		max-width: 70%;
	}
	.tendersafe-register__fieldset--terms input[type="checkbox"] {
		text-align: center;
	}
	.tendersafe-register__fieldset input[type="text"],
	.tendersafe-register__fieldset input[type="password"] {
		max-width:100%;
	}
	.tendersafe-register__action__cancel,
	.tendersafe-register__action__submit {
		display: block;
		max-width: 100%;
		width: 100%;
		margin-bottom: 10px;
	}
	.tendersafe-register__fieldset {
		padding: 40px 20px;
	}
	.tendersafe-home__documents__table .demoRow td {
		display: block;
		width: 100%;
	}
}
.recaptcha-container{
    span.error {
        color: $error;
        font-size: 13px;
        text-align: center;
        display: block;
        width: 100%;
    }
}

.section__notice {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    &--success {
        background: $success;
        color: white;
    }
    span {
        margin: 0 4px;
    }
}
.btn--download-file {
    @include defaultbutton;
    padding: 15px 60px;
    &:hover, &:active, &:visited {
        color: white;
    }
}
.tendersafe-instructions {
    margin-top: 30px;
    margin-bottom: 30px;
    h3 {
        font-weight: 700;
        margin-bottom: 30px;
    }
}