.search .page-title {
  display: none;
}
.search .paginator .p-page {
  font-size: 14px;
}
.search .paginator span.p-page {
  color: #337ab7;
}
.search .paginator a.p-page {
  padding: 10px;
  border: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.search .paginator a.p-page:hover {
  background: #337ab7;
  color: white;
}
.search .paginator .p-previous, .search .paginator .p-next {
  border: 0;
  font-size: 14px;
  padding: 10px 15px;
  margin: 0 40px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
@media screen and (max-width: 768px) {
  .search .paginator .p-previous, .search .paginator .p-next {
    margin: 0;
    padding: 10px;
    font-size: 12px;
  }
}
.search .paginator a.p-previous:hover,
.search .paginator a.p-next:hover {
  background: #337ab7;
  color: white;
}
.search .paginator .p-first, .search .paginator .p-last {
  display: none;
}
.search-form {
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .search-form {
    text-align: left;
  }
}
.search-form + p {
  text-align: center;
  margin: 15px 0;
}
.search input[type="text"], .search select {
  padding: 8px 16px;
  border: 1px solid #dedede;
  line-height: 1;
  height: 37px;
}
@media screen and (max-width: 768px) {
  .search input[type="text"], .search select {
    width: 100%;
    margin-bottom: 15px;
  }
}
.search input[type="text"] {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}
.search-label {
  display: inline-block;
  padding: 8px 0;
  float: none;
}
@media screen and (max-width: 768px) {
  .search-label {
    width: 100%;
  }
}
.search-submit {
 @include infobutton;
}

.content-nav {
  display: inline-block;
  margin-bottom: 15px;
}
.content-nav span {
  display: inline-block;
  padding: 4px 10px 8px;
  background: #f4f5f6;
}
.content-nav span i {
  color: #708090;
  font-size: 22px;
  -webkit-transform: translateY(6px);
          transform: translateY(6px);
  margin-right: 5px;
}
.content-nav span a, .content-nav span a:link {
  color: #3c4858 !important;
  font-weight: 500;
}
.content-nav--right {
  float: right; 
  margin-top: 15px;
}


.search__title {
    @include heading-1;
    margin-top: 70px;
    margin-bottom: 15px;
    text-align: center;
}
#searchPage {
    padding-bottom: 70px;
    font-weight: 300;
    h4 {
        @include heading-4;
        margin-bottom: 15px;
        a {
            display: block;
        }
    }
}
.searchResult {
    border-bottom: 1px solid $light-grey;
    padding: 35px 0 !important;
    margin: 0;
    max-width: 66.6%;
    @media screen and (max-width: 992px) {
        max-width: 100%;
    }
    .searchResultContent {
        margin: 0;
        padding: 0;
        p {
            margin: 0;
        }
    }
}